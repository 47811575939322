@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


.cp_component_deliverable-v2_feedback-page
  .back-to-content-page-tag
    margin: $space_2x 0
  h3
    font-size: px-to-rem(16px)
    margin-bottom: 0.5rem
  .feedback-input
    padding: $space_2x
    width: px-to-rem(496px)
  .submit-button
    margin: 0.3rem 0 0 $space_2x
.dark
  .cp_component_deliverable-v2_feedback-page
    