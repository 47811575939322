@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_page_dashboard
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0
  
  div.cp_page_dashboard-container
    display: flex
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative
    flex-direction: column
    flex-wrap: wrap

    > *:not(:first-child)
      margin-top: $space_2x

    div.cp_page_splash_container
      background-color: $color_fill-card
      border: 1px solid $color_border-colorcard
      flex-grow: 1
      padding-top: $space_4x

// Dark Theme Styles
.dark div.cp_page_dashboard
  div.cp_page_dashboard-container
    div.cp_page_splash_container
      background-color: dark.$color_fill-card
      border-color: dark.$color_border-colorcard
