@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_campaign-accounts-table_component

    .MuiDataGrid-columnHeader.account-header
        padding-left: 1.25rem
.dark

