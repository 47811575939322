@use "../../style/styles" as *

div.cp_component_infinite-scroll-container
    height: 100%
    flex-grow: 1
    overflow-y: scroll
    padding: 0
    width: 100%

    &.cp_component_infinite-scroll-container-sm
        height: px-to-rem(100px)
    &.cp_component_infinite-scroll-container-md
        height: px-to-rem(300px)
    &.cp_component_infinite-scroll-container-lg
        height: px-to-rem(500px)
    
    &::-webkit-scrollbar
        display: none

    .cp_component_infinite-scroll-loading-indicator-container
        display: flex
        justify-content: center
        padding: $space_2x
        width: 100%
