@use "../../../../style/theme_dark" as dark
@use "../../../../style/theme_light" as *
@use "../../../../style/_styles" as *
@use "../../../../style/_primitives" as *


.cp_lcv-accordion-details_display

  &_group
    display: flex
    flex-direction: column
    gap: $space_1x
    margin-bottom: $space_2x

    &:not(:first-child)
      border-top: 1px solid $color_border-input
      margin-top: $space_2x

    &_header
      @include textStyles($color_border-input, $font-size-sm, $font-weight-bold)
      margin-top: $space_1x
    
    &_metric-pills
      display: flex
      flex-wrap: wrap
      gap: $space_1x

      &_pill
        background-color: $color_fill-pill-1 !important
        border-color: $color_border-pill
        width: auto

    .highlights-demo-score-form
      background-color: $color_fill-card
      border-top: 1px solid $color_border-input

      &::before
        height: 0

      &-summary
        min-height: 24px
        padding: 0

        .cp_lcv-accordion-icon-md
          &.dsf-saved
            fill: $color_text-primary !important
          &.dsf-not-saved
            fill: $color_input-error !important

        &-header-container
          display: flex
          flex-direction: column

          .main-heading
            &.dsf-saved
              @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
            &.dsf-not-saved
              @include textStyles($color_input-error, $font-size-sm, $font-weight-bold)
          
          .form-note
            @include textStyles($color_border-input, $font-size-sm, $font-weight-bold)
            margin-top: $space_2x
      
      &-details.MuiAccordionDetails-root
        padding: 0

        .demographic-form
          display: flex
          flex-direction: column
          gap: $space_2x
          margin-top: $space_2x

          &-gender
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)

            .checkbox-label
              @include textStyles($color_text-inactive, $font-size-sm, $font-weight-normal)
              
            .gender-checkbox
              margin-left: $space_1x

          &-age-range
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
            &-form-control
              display: flex
              gap: $space_1x
              &-from
                margin-top: $space_1x
                .field-label
                  @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
              &-to
                .field-label
                  @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
          
          &-ethnicity
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x
          
          &-family
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x

          &-religion
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x

          &-income
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x

          &-location
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x
            &-form-control
              div.cp_component_input
                width: auto
              .autocomplete-selection-pills
                display: flex
                flex-wrap: wrap
                gap: px-to-rem(5px)
                margin-top: $space_1x

          &-occupations
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x
            &-form-control
              div.cp_component_input
                width: auto
              .autocomplete-selection-pills
                display: flex
                flex-wrap: wrap
                gap: px-to-rem(10px)
                margin-top: $space_1x

          &-affinities
            .section-label
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
              margin-bottom: $space_1x
            &-form-control
              div.cp_component_input
                width: auto
              .autocomplete-selection-pills
                display: flex
                flex-wrap: wrap
                gap: px-to-rem(10px)
                margin-top: $space_1x
          
          &-cta
            margin-top: $space_1x

    .highlights-relevancy-form
      background-color: $color_fill-card
      border-top: 1px solid $color_border-input

      &.MuiPaper-root.MuiAccordion-root.Mui-expanded
        margin: 0 0 16px 0

      .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
        min-height: 24px

      &::before
        height: 0

      &-summary
        min-height: 24px
        padding: 0

        .cp_lcv-accordion-icon-md
          &.dsf-saved
            fill: $color_text-primary !important
          &.dsf-not-saved
            fill: $color_input-error !important

        &-heading
          &.dsf-saved
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
          &.dsf-not-saved
            @include textStyles($color_input-error, $font-size-sm, $font-weight-bold)
      
      &-details.MuiAccordionDetails-root
        padding: 0

        .relevancy-form
          display: flex
          flex-direction: column
          gap: $space_2x
          margin-top: $space_2x

          .section-label
            @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
            margin-bottom: $space_1x

          &-cta
           margin-top: $space_1x

    .config-option-switch-additional_fields
        padding: $space_1x 
        div.cp_component_input 
          width: 100%
          margin-bottom: $space_2x

    .config-option-switch-pills
        display: flex
        gap: $space_1x
        flex-wrap: wrap

        &.has-image-tags, &.has-keywords
          margin-bottom: $space_2x
      
div.cp_display-highlights-dropdown
  width: fit-content !important  



.dark
  .cp_lcv-accordion-details_display
    &_group
      &_header
        @include textStyles(dark.$color_border-input, $font-size-sm, $font-weight-bold)
      
      &_metric-pills
        &_pill
          background-color: dark.$color_fill-pill-1 !important
          border-color: dark.$color_border-pill

      .highlights-demo-score-form
        background-color: dark.$color_fill-card
        &-summary
          .cp_lcv-accordion-icon-md
            &.dsf-saved
              fill: dark.$color_text-primary !important
            &.dsf-not-saved
              fill: dark.$color_input-error !important

          &-header-container
            .main-heading
              &.dsf-saved
                @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bold)
              &.dsf-not-saved
                @include textStyles(dark.$color_input-error, $font-size-sm, $font-weight-bold)
            
            .form-note
              @include textStyles(dark.$color_border-input, $font-size-sm, $font-weight-bold)
        
        &-details.MuiAccordionDetails-root
          .demographic-form
            &-gender
              .section-label
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)

              .checkbox-label
                @include textStyles(dark.$color_text-inactive, $font-size-sm, $font-weight-normal)

            &-age-range
              .section-label
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
              &-form-control
                &-from
                  .field-label
                    @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bold)
                &-to
                  .field-label
                    @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bold)
            
            &-ethnicity,
            &-family,
            &-religion,
            &-income,
            &-location,
            &-occupations,
            &-affinities
              .section-label
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
            
      .highlights-relevancy-form
        background-color: dark.$color_fill-card

        &-summary

          .cp_lcv-accordion-icon-md
            &.dsf-saved
              fill: dark.$color_text-primary !important
            &.dsf-not-saved
              fill: dark.$color_input-error !important

          &-heading
            &.dsf-saved
              @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bold)
            &.dsf-not-saved
              @include textStyles(dark.$color_input-error, $font-size-sm, $font-weight-bold)
        
        &-details.MuiAccordionDetails-root
          .relevancy-form
            .section-label
              @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
