@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.required-information-page
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: 1516px 972px
  background-position: 60% 35%

  &-content
    backdrop-filter: blur(3rem)
    height: 400px

    .cp_page_required-info-header
      backdrop-filter: blur(4rem)
      background-color: $color_fill-card
      opacity: 80%
      padding: $space_1x

      &-logo
        background-color: $color_fill-card
        background-image: url("../../asset/img/logo_radius_text_light.svg")
        background-size: 84px 28px
        width: px-to-rem(84px)
        height: px-to-rem(28px)

    .required-information-container
      display: flex
      height: calc( 100vh - 84px )
      justify-content: center
      margin-top: px-to-rem(155px)
      width: 100%

      &-content
        background-color: $color_fill-card
        border-radius: px-to-rem(10px)
        border: 1px solid $color_border-colorcard
        height: fit-content
        width: px-to-rem(707px)

        &-header,
        &-body,
        &-footer
          align-items: center
          display: flex
          flex-direction: column
          gap: $space_1x
          justify-content: center
          padding-top: $space_4x
          width: 100%

        &-header
          &-logo
            background-image: url("../../asset/img/logo_theme_light.svg")
            background-size: 40px 40px
            height: px-to-rem(40px)
            width: px-to-rem(40px)

          &-title
            @include textStyles($color_text-primary, 1.75rem, $font-weight-normal)
            margin-top: $space_2x
            
          &-sub-title
            @include textStyles($color_text-follower, px-to-rem(14px), $font-weight-normal)
        
        &-body
          &-input
            width: 100%

        &-footer
          margin-top: $space_4x

          &-button
            width: px-to-rem(381px)

          &-assistance-text
            @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)
            margin: $space_6x 0

.dark
  .required-information-page
    &-content
      .cp_page_required-info-header
        background-color: dark.$color_fill-card

        &-logo
          background-color: dark.$color_fill-card

      .required-information-container
        &-content
          background-color: dark.$color_fill-card
          border: 1px solid dark.$color_border-colorcard

          &-header
            &-title
              @include textStyles(dark.$color_text-primary, 1.75rem, $font-weight-normal)
            &-sub-title
              @include textStyles(dark.$color_text-follower, px-to-rem(14px), $font-weight-normal)

          &-footer
            &-assistance-text
              @include textStyles(dark.$color_text-secondary, $font-size-sm, $font-weight-normal)
