@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_customers-overview_container
  background: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  flex-direction: column
  flex: 1
  height: 100%
  gap: $space_1x
  overflow: auto
  padding: $space_3x
  position: relative

  .cp_customers-overview-header
    align-items: center
    justify-content: space-between
    display: flex
    &-search
      align-items: center
      display: flex
      gap: $space_2x
      flex: 1
      .cp_customers-overview_search-bar.cp_component_search-bar-input.cp_component_input
        margin: 0
        padding: 0
        width: 40%

.dark
  .cp_customers-overview_container
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard
