@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_followers-chart_component_loading
    display: flex
    justify-content: space-around
    
.cp_followers-chart_component

    &-title-container
        align-items: center
        display: flex
        justify-content: space-between

    &-title-metrics
        display: flex
        text-align: right

    &-title-metric:not(:last-child)
        margin-right: $space_5x
        
    &-title-metric-value
        color: $color_surface-brand
        font-size: $space_3x

    &-title-metric-name
        color: $color_text-secondary
        font-size: $font-size-sm
        font-weight: $font-weight-bold
        text-transform: uppercase
    &-title
        color: $color_text-primary
        font-size: $font-size-large

    &-chart
        padding: $space_4x $space_4x 0 $space_4x

        .highcharts-legend-box
            stroke: $color_border-section

        .highcharts-axis-line
            stroke: $color_text-primary

        .highcharts-background
            fill: $color_fill-card

        .highcharts-grid-line
            stroke: $color_brand-blue-teal

        .highcharts-axis-labels text
            color: $color_text-secondary !important
            fill: $color_text-secondary !important
            font-size: $font-size-sm !important
            font-weight: $font-weight-bolder 
            text-transform: capitalize

        .highcharts-legend-item text
            color: $color_text-primary !important
            fill: $color_text-primary !important
            font-size: $font-size-sm !important
            font-weight: $font-weight-bolder

.dark
    .cp_followers-chart_component
        &-title
            color: dark.$color_text-primary

        &-title-metric-value
            color: dark.$color_fill-logo

        &-title-metric-name
            color: dark.$color_text-secondary
            font-size: $font-size-sm

        &-chart
            .highcharts-legend-box
                stroke: dark.$color_border-section

            .highcharts-grid-line
                stroke: dark.$color_brand-blue-teal-faded

            .highcharts-axis-line
                stroke: dark.$color_text-primary

            .highcharts-background
                fill: dark.$color_fill-card

            .highcharts-axis-labels text
                color: dark.$color_text-secondary !important
                fill: dark.$color_text-secondary !important

            .highcharts-legend-item text
                color: dark.$color_text-primary !important
                fill: dark.$color_text-primary !important

        &-footer-note
            color: dark.$color_text-secondary
