@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

// the tooltip background is always dark. inherit the text color for proper styling
.cp-tooltip .cp_bolded-keywords_component
  color: inherit

.cp_bolded-keywords_component
  color: $color_text-primary

.dark
  .cp_bolded-keywords_component
    color: dark.$color_text-secondary

