@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use '../../../../style/_styles' as *

div.cp_component_unassigned-thread-preview
  display: flex
  flex-direction: column
  padding: $space_1x 0
  gap: 0.25rem
  height: 100%
  width: 100%
  .created-label
    color: $color_text-secondary
    font-size: px-to-rem(10px)
  .subject
    font-weight: 700
    font-size: px-to-rem(10px)
    display: -webkit-box
    overflow: hidden
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    white-space: normal
  .content
    color: $color_text-secondary
    font-size: px-to-rem(12px)
    display: -webkit-box
    overflow: hidden
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    white-space: normal
.dark
  div.cp_component_unassigned-thread-preview
    .created-label
      color: dark.$color_text-secondary
    .content
      color: dark.$color_text-secondary