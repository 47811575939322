@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_component_search-ai-audience

  &-age-slider.MuiSlider-root
    color: $color_button-primary
    margin: 0 0.5rem px-to-rem(20px) 0.5rem
    .MuiSlider-markLabel
      color: $color_text-secondary
      font-size: 0.75rem

  &-gender
    label.cp_gender_label-male.cp_has-min-results
      margin-bottom: px-to-rem(10px)
      display: inline-block

    div.cp_min_results-field:first-of-type
      margin-bottom: px-to-rem(10px)

  .cp_component_search-ai-audience-age
    column-gap: px-to-rem(30px)
    display: flex
    flex-direction: row
    flex-wrap: wrap
    
    &_wrapper
      flex: 1

      div.component_select.MuiFormControl-root 
        min-width: 100%

  &-min-results-age-range
    flex-basis: 100%
    padding-top: $space_1x

  .cp_component_search-ai-audience-income

    &-options
      column-gap: px-to-rem(15px)
      display: flex
      flex-direction: row

      label.MuiFormControlLabel-root
        width: calc(50% - 15px)

  &-location
    &-action 
      display: flex
      justify-content: flex-end
      margin-bottom: $space_1x

    &-pills
      display: flex
      flex-wrap: wrap
      gap: $space_1x

      &:has(div.cp-pill)
        padding-top: px-to-rem(10px)

    svg.MuiSvgIcon-root
      fill: $color_icon-exit
      height: px-to-rem(12px)
      width: px-to-rem(12px)

    .cp_autocomplete
      width: 100%
      margin-bottom: $space_2x

      svg.MuiSvgIcon-root
        fill: $color_icon-exit
        height: $space_2x
        width: $space_2x

.cp_component_search-ai-audience-min-results-location
  margin-bottom: $space_2x

.dark
  .cp_component_search-ai-audience
    &-age-slider
      color: dark.$color_text-inlinebutton
      .MuiSlider-markLabel
        color: dark.$color_text-secondary
    &-location
      svg.MuiSvgIcon-root
        fill: dark.$color_icon-exit

      .cp_autocomplete
        svg.MuiSvgIcon-root
          fill: dark.$color_icon-exit
