@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

@mixin textStyles($color, $fontSize, $fontWeight) 
  color: $color
  font-family: $font-family-primary
  font-size: $fontSize
  font-style: $font-style-primary
  font-weight: $fontWeight
  line-height: normal

.cp_component_modal-create-list
  .MuiDialog-container .MuiDialog-paperWidthXl
    max-width: px-to-rem(1300px)

  .cp_component_modal-create-list_tab-details
    .cp_component_modal-create-list
      &_column
        &:first-child
          height: 600px
          min-width: 740px
          overflow-y: auto
          @media screen and (max-width: $lg)
            min-width: 100%

        &:last-child
          flex-grow: 1
          @media screen and (max-width: $lg)
            min-width: 368px

      &_list-settings
        max-height: 370px
        overflow-y: scroll
        margin-bottom: $space_2x

      &_avatar-upload
        margin-bottom: $space_3x
        position: relative

        button.cp_avatar-removal
          position: absolute
          top: 62px
          left: 58px
          border-radius: 50%
          width: px-to-rem(22px)
          height: px-to-rem(22px)
          background-color: $color_surface-brand
          color: $color_surface-primary

          svg.MuiSvgIcon-root 
            width: $space_2x
            height: $space_2x

      &_logo
        display: flex
        margin-bottom: $space_3x
        label.image-upload
          > div.cp-avatar
            background: $color_fill-social-background
            border-radius: $border-radius-circle
            border: 2px solid $color_border-section
            height: 80px
            width: 80px
            cursor: pointer

            svg.MuiSvgIcon-root
              height: 2rem
              width: 2rem
              cursor: pointer

        .logo-info-text
          display: flex
          flex-direction: column
          justify-content: center
          margin-left: px-to-rem(20px)

          h5.title_medium
            font-family: $font-family-primary
            font-style: $font-style-primary
            letter-spacing: -0.32px
            line-height: normal
          p.action-info-text
            @include textStyles($color_text-secondary, px-to-rem(12px), 400)

      &_network
        border-radius: $content-border-radius
        border: 1px solid $color_border-section
        margin-right: $space-4x
        margin-top: $space-3x
        padding: px-to-rem(10px) px-to-rem(20px)
        position: absolute
        right: 0
        top: 0
        width: 280px

        h5
          font-family: $font-family-primary
          font-size: px-to-rem(14px)
          font-style: $font-style-primary
          font-weight: 700
          line-height: normal
          margin-bottom: px-to-rem(12px)
          text-transform: uppercase

        p
          @include textStyles($color_text-secondary, px-to-rem(11px), 600)

        &-buttons
          display: flex
          gap: px-to-rem(9px)
          margin-bottom: px-to-rem(12px)

          .cp-network-button
            padding: 0
            width: 40px

      &-basic_fields

        .cp_component_input
          margin-bottom: px-to-rem(20px)
          width: 350px

          &.create-list-long-description
            width: 486px

          p.label_small-caps-bold
            @include textStyles($color_text-primary, px-to-rem(12px), 600)

        .create-list-tags
          margin-bottom: $space_3x
          width: 350px
          .cp_component_input
            margin-bottom: 0
            div.MuiAutocomplete-inputRoot
              padding: 0 0.5rem

          small
            display: inline-block
            padding-left: px-to-rem(18px)
            padding: px-to-rem(10px)
            @include textStyles($color_text-secondary, px-to-rem(11px), 600)

      &_logo-avatar
        &.MuiAvatar-root
          border-radius: 50%
          border: 2px solid $color_fill-loading
          height: 80px
          margin-bottom: 1rem
          width: 80px

      &_tag_pills
        display: flex
        flex-wrap: wrap
        gap: $space_1x

      &_demographic-target      
        h4.headline-small
          margin-bottom: px-to-rem(6px)
        p.supporting-text
          @include textStyles($color_text-secondary, px-to-rem(14px), 400)
          margin-bottom: $space_2x

      &_demographic-wrapper
        display: flex
        gap: $space_2x

        legend.MuiFormLabel-root.section-label
          @include textStyles($color_text-primary, px-to-rem(14px), 700)
          text-transform: uppercase

      &_gender,
      &_age
        background: $color_surface-secondary
        border-radius: $content-border-radius
        padding: $space_2x
        .MuiButtonBase-root
          color: $color_border-radio-inactive
        .Mui-checked.MuiButtonBase-root
          color: $color_surface-brand

      &_age
        fieldset.MuiFormControl-root.age-range
          column-gap: px-to-rem(38px)
          display: flex
          flex-direction: row

          legend.MuiFormLabel-root
            margin-bottom: px-to-rem(10px)

      &_min-age,
      &_max-age
        legend.MuiFormLabel-root
          @include textStyles($color_text-primary, px-to-rem(12px), 600)

      &-settings
        &_mode,
        &_access,
        &_epsilon
          border-radius: $content-border-radius
          border: 1px solid $color_border-section
          padding: $space_2x
          &-label
            @include textStyles($color_text-primary, px-to-rem(14px), 700)
            margin-bottom: $space_1x
            text-transform: uppercase
          .MuiButtonBase-root
            color: $color_border-radio-inactive
          .Mui-checked.MuiButtonBase-root
            color: $color_surface-brand
        
        &_mode, &_access
          margin-bottom: $space_2x
          &-info
            @include textStyles($color_text-secondary, px-to-rem(10px), 700) 
            padding-left: px-to-rem(20px)

        &_access, &_epsilon
          &-code, &-notes-input
            width: 100%
            margin-bottom: $space_2x
            p.label_small-caps-bold, p.label_small
              @include textStyles($color_text-primary, px-to-rem(12px), 600)
              margin-bottom: 0.5rem
            
            div.MuiTextField-root
              @media screen and (max-width: $lg) 
                width: 486px
          &-notes
            &-label
              align-items: center
              display: flex
              justify-content: space-between
              margin-bottom: $space_1x
              p.label_small-caps-semibold
                color: $color_text-primary
              p.label_small-caps-bold
                color: $color_text-primary
                margin-bottom: 0
                &.counter
                  color: $color_text-secondary
                  font-weight: 400 

          &-radios
            span.MuiFormControlLabel-label
              @include textStyles($color_text-radiobutton, px-to-rem(12px), 400)
        
      &_list-notifications
        height: 214px
        max-height: 214px
        overflow-y: scroll

        h4.headline-small
          align-items: center
          display: flex
          margin-bottom: $space_2x

        .cp_icon_notification-bell
          color: $color_surface-brand
          height: px-to-rem(20px)
          margin-right: $space_1x
          width: px-to-rem(20px)

        &_team-members
          > legend.MuiFormLabel-root
            @include textStyles($color_text-primary, px-to-rem(12px), 600)
            margin-bottom: .25rem
            span
              @include textStyles($color_text-secondary, px-to-rem(12px), 400) 
        
          div.cp_component_input
            margin-bottom: $space_1x

            div.MuiAutocomplete-inputRoot
              padding: 0 0.5rem

        &_info
          margin-bottom: $space_2x
          padding-left: $space_2x
          @include textStyles($color_text-secondary, px-to-rem(11px), 600)

        &_pills
          display: flex
          flex-wrap: wrap
          gap: $space_1x

.dark
  .cp_component_modal-create-list
    div.MuiPaper-root
      h2.MuiDialogTitle-root
        color: dark.$color_text-primary

    .cp_component_modal-create-list
      &_tabs
        box-shadow: inset 0 -2px dark.$color_border-footer

      &_logo
        label.image-upload
          > div.cp-avatar
            background: dark.$color_fill-social-background
            border: 2px solid dark.$color_border-section

        .logo-info-text
          p.action-info-text
            color: dark.$color_text-secondary

      &_network
        border: 1px solid dark.$color_border-section
        p
          color: dark.$color_text-secondary

      &-basic_fields
        .cp_component_input
          p.label_small-caps-bold
            color: dark.$color_text-primary
        .create-list-tags
          small
            color: dark.$color_text-secondary

      &_logo-avatar
        &.MuiAvatar-root
          border: 2px solid dark.$color_fill-loading
      
      &_avatar-upload
        button.cp_avatar-removal
          background-color: $color_brand-blue-teal

      &_demographic-target
        p.supporting-text
          color: dark.$color_text-secondary

      &_demographic-wrapper
        legend.MuiFormLabel-root.section-label
          color: dark.$color_text-primary

      &_gender,
      &_age
        background: dark.$color_surface-secondary
        .MuiButtonBase-root
          color: dark.$color_border-radio-inactive
        .Mui-checked.MuiButtonBase-root
          color: dark.$color_brand-blue-teal

      &_min-age,
      &_max-age
        legend.MuiFormLabel-root
          color: dark.$color_text-primary

      &-settings
        &_mode,
        &_access,
        &_epsilon
          border: 1px solid dark.$color_border-section
          &-label
            color: dark.$color_text-primary
        &_mode
          &-info
            color: dark.$color_text-secondary
        &_access, &_epsilon
          .MuiButtonBase-root
            color: dark.$color_border-radio-inactive
          .Mui-checked.MuiButtonBase-root
            color: dark.$color_brand-blue-teal
          &-code, &-notes-input
            p.label_small-caps-bold, p.label_small
              color: dark.$color_text-primary
          &-notes
            &-label
              p.label_small-caps-semibold
                color: dark.$color_text-primary
              p.label_small-caps-bold
                color: dark.$color_text-primary
                &.counter
                  color: dark.$color_text-secondary
          &-radios
            span.MuiFormControlLabel-label
              color: dark.$color_text-radiobutton

      &_list-notifications
        &_team-members
          > legend.MuiFormLabel-root
            color: dark.$color_text-primary
            span
              color: dark.$color_text-secondary

        .cp_icon_notification-bell
          color: dark.$color_button-primary 

        &_info
          color: dark.$color_text-secondary

  
