@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

ul.cp_component_autocomplete_social-accounts
    padding: 0

    .MuiAutocomplete-option
        background-color: $color_border-section
        border-top: 1px solid $color_divider-section
        color: $color_text-primary
        font-size: $font-size-sm
        padding: $space_2x $space_1x
        text-transform: capitalize
    
        &.Mui-focused
        background-color: $color_fill-dropdown-select

.cp_component_autocomplete_social-account
    display: flex
    gap: $space_2x
    &-right
        display: flex
        flex-direction: column
        width: 100%
        &-followers
            color: $color_text-secondary
            font-size: $font-size-sm
            text-transform: capitalize
#autocomplete-social-accounts
    padding-bottom: 7px
    padding-top: 7px
    max-height: 10px


.cp_component_autocomplete_social-accounts

    &.MuiAutocomplete-root div.cp_component_input
        width: 100%

        .MuiAutocomplete-endAdornment
            color: $color_border-input
            cursor: pointer

        .MuiAutocomplete-popupIndicator
            background-color: transparent
            color: $color_input-inactive

            &.MuiAutocomplete-popupIndicatorOpen
                color: $color_input-active

.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_autocomplete_social-account
        &-right-followers
                color: dark.$color_text-secondary

    .cp_component_autocomplete_social-accounts

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .MuiAutocomplete-root div.cp_component_input 
        .MuiAutocomplete-endAdornment
            color: dark.$color_border-input
        
        .MuiAutocomplete-popupIndicator
            color: dark.$color_input-inactive

            &.MuiAutocomplete-popupIndicatorOpen
                color: dark.$color_input-active
