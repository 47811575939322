@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_deliverable_uploader
    display: flex
    gap: $space_1x
    flex-wrap: wrap
    label
        cursor: pointer
        .MuiAvatar-root
            background-color: $color_fill-dropdown-select
            color: $color_icon-dark-theme
            flex-direction: column
            font-size: 1.5rem
            height: px-to-rem(140px)
            width: px-to-rem(140px)
            p
                color: $color_text-inactive
                font-size: $font-size-sm

.dark
    .cp_component_deliverable_uploader
        label .MuiAvatar-root
            background-color: dark.$color_fill-dropdown-select
            color: dark.$color_text-inactive
            p
                color: dark.$color_text-inactive
