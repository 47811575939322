@use '../../style/styles' as *
@use '../../style/_theme_light' as *
@use "../../style/theme_dark" as dark

div.cp_component-network-statistic-card
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  display: flex
  flex-basis: 100%
  justify-content: space-between
  padding: $space_2x

  .statistic-container
    align-self: center
    display: flex
    flex-direction: column
    h4
      color: $color_text-primary
      font-size: px-to-rem(34px)
      font-weight: 700
    p
      color: $color_text-secondary
      font-size: px-to-rem(12px)

.dark
  div.cp_component-network-statistic-card
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard

    .statistic-container
      h4
        color: dark.$color_text-primary
      p
        color: dark.$color_text-secondary
