@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_search-results-post-details-cell
  width: 100%

  p
    line-height: 1.75rem
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

// Dark Theme Styles
.dark div.cp_component_search-account-results-post-details-cell
