@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use '../../../style/_styles' as *

div.cp_component_navigation-bar-customers-panel
    .cp_component_sliding-panel-header-container
        .MuiToggleButtonGroup-root
            .MuiButtonBase-root
                width: 4.5rem

    .cp_component_navigation-bar-customers-container
        flex-grow: 1
        overflow-y: scroll
        padding: 0
        display: flex
        flex-direction: column
        &::-webkit-scrollbar
            display: none

    // Light Theme Styles
    .cp_component_customers-table
        max-height: 100%
        width: 100%

        // Header Titles
        .cp_component_customers-table-custom-header.MuiTypography-root
            align-items: center
            color: $color_text-secondary
            display: flex
            font-size: 0.75rem
            font-weight: 700
            text-transform: uppercase

            &:hover
                cursor: pointer
            .cp_component_customers-table-unfold-icon
                display: inline-block
    
        .MuiDataGrid-row
            &:hover
                background-color: $color_fill-list-hovered
                cursor: pointer

                

    // Dark Theme Styles
.dark
    div.cp_component_navigation-bar-customers-panel 
        .cp_component_customers-table
            .cp_component_customers-table-custom-header.MuiTypography-root
                color: dark.$color_text-secondary
            .MuiDataGrid-row
                &:hover
                    background-color: dark.$color_fill-list-hovered
