@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_dashboard-campaigns_component
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    flex: 1
    gap: $space_1x
    margin: $space_2x 0 0 0
    overflow: auto
    padding: $space_3x
    position: relative

    .MuiCircularProgress-root
        margin: auto

    &-header
        display: flex
        justify-content: space-between
        margin-bottom: $space_2x
        &-title
            color: $color_text-primary
            font-size: 1.25rem
            font-weight: 600
        &-filter
            .MuiSelect-select#select-dashboard-campaigns
                padding-bottom: 0
                padding-top: 0
                font-size: 0.75rem
                font-weight: 700

    &-widgets
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: $space_2x
        @media screen and (max-width: $xxl)
            grid-template-columns: 1fr
        &-widget
    
    &-loading, &-error
        align-items: center
        display: flex
        justify-content: center
        flex: 1
        padding: $space_2x

.dark
    .cp_dashboard-campaigns_component
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard
        &-header
            &-title
                color: dark.$color_text-primary
            &-filter
                .MuiSelect-select
                    .MuiListItemText-root span

