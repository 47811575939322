@use "../../../../../style/_styles" as *
@use "../../../../../style/theme_light" as *
@use "../../../../../style/theme_dark" as dark

div.cp_component_modal-manage-profile
    .MuiDialogTitle-root
        border-bottom: 1px solid $color_border-section

    .cp_component_modal-manage-profile-content
        display: flex
        flex-direction: column
        height: px-to-rem(255px)
        padding: 0

        .cp_component_modal-manage-profile-header
            align-items: center
            background-color: $color_fill-table-header
            display: flex

            p
                font-size: $font-size-sm
                font-weight: $font-weight-bolder
                margin-left: px-to-rem(42px)
                text-transform: uppercase

        .cp_component_modal-manage-profile_profiles-container
            flex-grow: 1
            overflow-y: scroll
            padding: 0
            width: 100%
            
            &::-webkit-scrollbar
                display: none

            .cp_component-modal-add-to-buffer-container
                display: flex
                justify-content: center
                padding: $space_3x

            .cp_component_modal-manage-profile_profile-container
                align-items: center
                display: flex
                padding: $space_1x 0

                .cp-avatar
                    border: none
                    font-size: px-to-rem(10px)

                    &.cp_component_network-avatar
                        height: px-to-rem(30px)
                        width: px-to-rem(30px)

                &:not(.row-disabled):hover
                    cursor: pointer
                p
                    margin-left: $space_2x

                .cp_component_modal-manage-profile_sub-info
                    color: $color_text-dropdown-secondary
                    font-size: $font-size-sm
                    font-weight: $font-weight-bold
                    text-transform: uppercase
                    width: fit-content

.dark
    div.cp_component_modal-manage-profile
        .MuiPaper-root .MuiDialogTitle-root 
                border-bottom: 1px solid dark.$color_border-section
        .cp_component_modal-manage-profile-content
            .cp_component_modal-manage-profile-header
                background-color: dark.$color_fill-dropdown-select-secondary
            .cp_component_modal-manage-profile_profiles-container
                .cp_component_modal-manage-profile_profile-container
                    .cp_component_modal-manage-profile_sub-info
                        color: dark.$color_text-dropdown-secondary