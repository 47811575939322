@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_component_search-ai-content
  &-keywords,
  &-excludes,
  &-imagetags,
  &-brandlogos,
  &-postedon,
  &-geotag

    &-date-picker
      width: 100%
      > .MuiInputBase-root
        color: inherit
        border-radius: 2px
        > fieldset.MuiOutlinedInput-notchedOutline, fieldset.MuiOutlinedInput-notchedOutline:hover
          border-color: $color_input-inactive
          border-width: 1px

    &-pills
      display: flex
      flex-wrap: wrap
      gap: $space_1x

      &:has(div.cp-pill)
        padding-top: px-to-rem(10px)

    svg.MuiSvgIcon-root
      fill: $color_icon-exit
      height: px-to-rem(12px)
      width: px-to-rem(12px)

    // Adornments for autocomplete inputs
    .cp_autocomplete svg.MuiSvgIcon-root
      height: $space_2x
      width: $space_2x

    .has-advanced-link
      align-items: center
      display: flex
      justify-content: space-between

      button.cp_advanced_link.initial-bg
        background-color: initial
        color: $color_text-inactive
        font-size: px-to-rem(10px)
        justify-content: flex-end
        margin-bottom: px-to-rem(10px)
        padding: 0

        &:hover, &:active
          background-color: initial
          color: $color_text-inlinebutton
  
  &-brandlogos,
  &-geotag

    .cp_autocomplete
      width: 100%

      div.MuiChip-root,
      button.MuiAutocomplete-clearIndicator
        display: none

      svg.MuiSvgIcon-root
        fill: $color_icon-exit
        height: $space_2x
        width: $space_2x
        
  &-postedon
    &-header
      align-items: center
      display: flex
      justify-content: flex-end
      > #postedonMuiLegend.MuiFormLabel-root 
        margin-bottom: 0

      button.cp-btn.postedon-button.posted-button-active
          background-color: $color_button-hovered
          color: $color_button-text
          font-weight: $font-weight-bold
      button.cp-btn.postedon-button
        background-color: $color_button-exit
        color: $color_text-inactive

    &-range
      .range-posted-start
        margin-bottom: $space_2x

    &-range,
    &-date,
      .date,
      .range-posted-start,
      .range-posted-end,
        div.MuiStack-root
          padding-top: 0
          
          .cp_datePicker
            min-width: 100%

            fieldset.MuiOutlinedInput-notchedOutline
              border: 1px solid $color_border-input

              &:hover
                border: 1px solid $color_border-input

            > div.MuiInputBase-root
              border-radius: $content-border-radius
              padding-left: $space_1x
              padding-right: $space_1x

          input.MuiInputBase-input
            color: $color_text-primary
            font-family: $font-family-primary
            font-size: px-to-rem(14px)
            font-style: $font-style-primary
            font-weight: $font-weight-normal
            letter-spacing: 0.5px
            line-height: $space_3x
            padding: .6rem
            padding-right: 0

          button.MuiButtonBase-root
            margin-right: 0

  &-engagement
    .MuiInputAdornment-root
      p.MuiTypography-root 
        color: $color_icon-exit
        font-size: px-to-rem(12px)
        padding: $space_1x

.dark
  .cp_component_search-ai-content

    &-keywords,
    &-excludes,
    &-imagetags,
    &-postedon
      svg.MuiSvgIcon-root
        fill: dark.$color_icon-exit

    &-brandlogos,
    &-geotag,
      .cp_autocomplete
        svg.MuiSvgIcon-root
          fill: dark.$color_icon-exit
    
    &-postedon
      &-header
        button.cp-btn.postedon-button.posted-button-active
          background-color: dark.$color_button-hovered
          color: dark.$color_button-text
        button.cp-btn.postedon-button
          background-color: dark.$color_button-exit
          color: dark.$color_text-inactive

    &-range,
    &-date,
      .date,
      .range-posted-start,
      .range-posted-end,
        div.MuiStack-root

          .cp_datePicker
            fieldset.MuiOutlinedInput-notchedOutline
              border: 1px solid dark.$color_border-input

              &:hover
                border: 1px solid dark.$color_border-input
      
          input.MuiInputBase-input
            color: dark.$color_text-primary
    &-engagement
      .MuiInputAdornment-root
        p.MuiTypography-root 
          color: dark.$color_icon-exit
    
    &-keywords,
    &-imagetags,
    &-brandlogos
      .has-advanced-link
        button.cp_advanced_link
          color: dark.$color_text-inactive

          &:hover, &:active
            color: dark.$color_text-inlinebutton
