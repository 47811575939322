@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *


    
.cp_brands_component

    &-tabs-container
        background: $color_fill-card
        padding: $space_2x $space_1x
        position: relative

        .MuiTabs-scroller
            box-shadow: inset 0 -2px $color_border-section
       
        .cp_brands_component-tabs-controls
            align-items: flex-start
            display: flex
            justify-content: flex-end
            position: absolute
            right: $space_1x
            top: 1.25rem
    
    &-avatar
        min-height: $space_7x
        min-width: $space_7x

        &-badge > .MuiBadge-badge
            background: $color_surface-brand
            border: 1px solid $color_fill-card
            color: $color_fill-card

    &-date-item span.MuiListItemText-primary
        font-size: $font-size-sm
        
    &-filter
        margin-left: $space_1x
        max-height: $space_3x
        min-width: 200px

    &-date
        max-height: px-to-rem(38px)
        min-width: 200px
        
        .MuiListItemText-root > span
            font-size: $font-size-sm
            font-weight: $font-weight-normal

    div.cp_component_input .MuiTextField-root 
        .MuiInputBase-input
            padding: 0.25rem 0.6rem

    div.component_select .MuiSelect-select
        padding: 0

    &-content
        display: grid
        grid-template-columns: repeat(7, 1fr)
        grid-row-gap: $space_2x
        grid-column-gap: $space_5x
        margin-left: auto
        margin-right: auto
        margin-top: $space_1x
        max-height: px-to-rem(255px)
        overflow-y: auto
        padding-top: $space_2x
        text-align: center
        width: 100%
        @media screen and (max-width: $xxl)
            grid-template-columns: repeat(6, 1fr)
        @media screen and (max-width: $xl)
            grid-template-columns: repeat(5, 1fr)
        @media screen and (max-width: $lg)
            grid-template-columns: repeat(4, 1fr)
            

        &:has(.cp_brands_component-content-loading), &:has(.cp_brands_component-content-no-results)
            grid-template-columns: repeat(1, 1fr)

        .cp_avatar-brand_component-badge:hover
            cursor: pointer

        &-loading, &-no-results
            margin: auto
        &-brand
            background-color: $color_fill-card-panel
            border-radius: $content-border-radius
            height: px-to-rem(110px)
            width: px-to-rem(110px)
            padding: $space_2x
            &-grid
                align-items: center
                display: flex
                justify-content: center
            &-name
                -webkit-box-orient: vertical
                -webkit-line-clamp: 1
                color: $color_text-secondary
                display: -webkit-box
                font-size: $font-size-sm
                font-weight: $font-weight-bold
                margin-top: $space_1x
                max-width: px-to-rem(100px)
                overflow: hidden
                white-space: normal

.dark
    .cp_brands_component

        &-tabs-container
            background: dark.$color_fill-card

            .MuiTabs-scroller
                box-shadow: inset 0 -2px dark.$color_border-section

        &-content
            &-brand
                background-color: dark.$color_fill-card-panel
                &-name
                    color: dark.$color_text-secondary
                .cp_avatar-brand_component-badge > .MuiBadge-badge
                    background: dark.$color_fill-logo
                    border: 1px solid dark.$color_border-image-user
                    color: dark.$color_text-invert

       