@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

// Light Theme Styles
div.cp_component_search-results-table
  max-height: 100%
  width: 100%

  p.muted
    color: $color_text-inactive

  // LOADING INDICATOR/ERROR STATE
  > aside
    align-items: center
    display: flex
    height: 100%
    justify-content: center
    padding: 5em 0
    width: 100%

    div
      text-align: center

    svg
      color: $color_text-primary
      height: 6rem
      margin: 0 0 2rem 0
      width: 6rem

  // TABLE STYLES
  // Root Table
  .MuiDataGrid-root
    border: 0
    border-radius: 0
    font-family: "Open Sans", sans-serif

  // Empty/Placeholder State
  &-empty
    .MuiDataGrid-virtualScroller
      overflow: hidden

    div.cp_component_empty-splash
      margin: 6rem 0 0 0

  // Headers
  .MuiDataGrid-columnHeaders
    background-color: $color_fill-social-background
    border-bottom: none
    border-top-left-radius: 0
    border-top-right-radius: 0

  // Header Titles
  .MuiDataGrid-columnHeaderTitle
    color: $color_text-secondary
    font-size: 0.75rem
    font-weight: 700
    text-transform: uppercase

  // Table Data Container
  .MuiDataGrid-virtualScroller
    background-color: $color_fill-card

  // Pinned Columns
  .MuiDataGrid-pinnedColumnHeaders
    background-color: $color_fill-social-background
    box-shadow: none

  .MuiDataGrid-pinnedColumns
    background-color: $color_table-pinned-column-background
    box-shadow: none
    overflow: visible

  // Cells
  .MuiDataGrid-cell
    border-bottom: 1px solid $color_border-section
    cursor: pointer

  // Checkboxes
  .MuiDataGrid-cellCheckbox,
  .MuiDataGrid-columnHeader
    .MuiSvgIcon-root
      color: $color_border-radio-inactive
      font-size: 1rem

    .Mui-disabled .MuiSvgIcon-root
      color: $color_input-disabled
      opacity: 0.3

// Dark Theme Styles
.dark div.cp_component_search-results-table
  // LOADING INDICATOR/ERROR STATE
  > aside
    svg
      color: dark.$color_text-secondary

  // TABLE STYLES
  // Headers
  .MuiDataGrid-columnHeaders
    background-color: dark.$color_fill-modal
    border-bottom: 1px solid dark.$color_border-section

  // Header Titles
  .MuiDataGrid-columnHeaderTitle
    color: dark.$color_text-secondary

  // Pinned Columns
  .MuiDataGrid-pinnedColumnHeaders
    background-color: dark.$color_fill-modal
    box-shadow: none

  .MuiDataGrid-pinnedColumns
    background-color: dark.$color_table-pinned-column-background

  // Table Data Container
  .MuiDataGrid-virtualScroller
    background-color: dark.$color_fill-card

  // Cells
  .MuiDataGrid-cell
    border-bottom: 1px solid dark.$color_border-section

  // Checkboxes
  .MuiDataGrid-cellCheckbox,
  .MuiDataGrid-columnHeader
    .MuiSvgIcon-root
      color: dark.$color_border-radio-inactive
      font-size: 1rem

    .Mui-disabled .MuiSvgIcon-root
      color: dark.$color_input-disabled
      opacity: 0.3
