@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

div.cp_component_input
  width: 16rem

  &.input-full-width
    width: 100%

  // MODES
  // THIN
  &-thin
    .MuiTextField-root .MuiInputBase-root
      padding: 0 0.5rem

      input
        padding: 0.25rem
  
      svg
        font-size: 1.1rem

  // LABEL
  p.label_small-caps-bold
    margin-bottom: 0.5rem
    color: $color_text-primary

  // MATERIAL UI TEXTFIELD
  .MuiTextField-root
    width: 100%

    // Error Adornment
    svg.cp_component_input-success-adornment
      color: $color_color-success

    // Error Adornment
    svg.cp_component_input-error-adornment
      color: $color_input-error
      width: 1.25rem

    // Input Root
    .MuiInputBase-root
      border-radius: 2px
      padding: 0 0.5rem

      fieldset
        border-color: $color_input-inactive

    .MuiInputBase-root.Mui-focused
      fieldset
        border-color: $color_input-active
        border-width: 1px

    .MuiInputBase-root.Mui-disabled
      fieldset
        border-color: $color_input-disabled

    .MuiInputBase-root.Mui-error
      fieldset
        border-color: $color_input-error

    // Input
    .MuiInputBase-input
      color: $color_text-primary
      font-family: "Open Sans"
      font-size: 0.8rem
      font-weight: 400
      padding: 0.6rem

    // Input Adornment
    .MuiInputAdornment-root
      margin-left: 0


.dark div.cp_component_input
  // LABEL
  p.label_small-caps-bold
    color: dark.$color_text-primary

  // MATERIAL UI TEXTFIELD
  .MuiTextField-root
    // Error Adornment
    svg.cp_component_input-success-adornment
      color: dark.$color_color-success

    // Error Adornment
    svg.cp_component_input-error-adornment
      color: dark.$color_input-error

    // Input Root
    .MuiInputBase-root
      fieldset
        border-color: dark.$color_input-inactive

    .MuiInputBase-root.Mui-focused
      fieldset
        border-color: dark.$color_input-active

    .MuiInputBase-root.Mui-disabled
      fieldset
        border-color: dark.$color_input-disabled

    .MuiInputBase-root.Mui-error
      fieldset
        border-color: dark.$color_input-error

    // Input
    .MuiInputBase-input
      color: dark.$color_text-primary

      // Chrome fix for disabled dark mode inputs reverting to black
      &.Mui-disabled
        -webkit-text-fill-color: unset
