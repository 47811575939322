@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_list-profile_page
  .cp_list-profile_page
    &-metrics:has(> .suggestions-social)
      > .cp_profile_component-list-tabs-container
        margin-bottom: $space_2x
        
  .cp_profile_component-list-tabs-container
    &.sub-tabs
      margin-bottom: $space_2x
    .cp_component_tabs
      &-container
        padding: $space_2x px-to-rem(12px) 0

      &-controls
        .cp_profile_component-list-tabs-actions
          display: flex
          justify-content: center
          align-items: center
          p 
            @include textStyles($color_text-secondary, 0.75rem, $font-weight-bold)
            text-transform: uppercase
          .MuiSwitch-root
            .MuiSvgIcon-root
              fill: $color_fill-nav

.dark
  .cp_list-profile_page 
    .cp_profile_component-list-tabs-container 
      .cp_component_tabs-controls 
        .cp_profile_component-list-tabs-actions 
          p
            color: dark.$color_text-inlinebutton
          
