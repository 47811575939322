@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_list-group_management-table
    display: flex
    flex-direction: column
    gap: $space_1x
    width: calc(100% - $space_2x)
    height: calc( 100% - $space_2x )
    margin: $space_2x
    overflow: visible

    &-action-buttons
        align-self: flex-end
        align-items: center
        display: flex
        gap: $space_2x
        margin-right: $space_2x
        overflow: visible
        
        .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium
            color: $color_text-inactive
            overflow: visible

    &-data-table
        overflow: auto
        width: calc(100% - $space_2x)

    .MuiDataGrid-root
        .MuiDataGrid-pinnedColumns--left, .MuiDataGrid-virtualScrollerRenderZone
            .MuiDataGrid-cell
                padding-left: $space_4x

.dark
    .cp_list-group_management-table
        &-action-buttons
            .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium
                color: dark.$color_text-inactive