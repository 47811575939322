@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

ul.cp_relevant_posts-keywords-list
  display: flex
  gap: 12px
  justify-content: space-between
  list-style: none
  padding: 0

  li.cp_relevant_posts-keywords-item,
  li.cp_relevant_posts-keywords-empty 
    display: flex
    flex: 1
    position: relative
    &::before
      content: ''
      float: left
      padding-top: 100%

  li.cp_relevant_posts-keywords-empty,
  li.cp_relevant_posts-keywords-item.removal-in-process
    align-items: center
    background-color:$color_button-exit
    color: $color_text-inactive
    display: flex
    font-size: $font-size-sm
    justify-content: center
    justify-content: center

  li.cp_relevant_posts-keywords-item
    overflow: hidden
    img
      height: auto
      left: 0
      object-fit: cover
      position: absolute
      top: 0
      width: 100%

    button
      align-items: center
      background-color: $color_button-exit
      border-radius: 5px
      border: none
      cursor: pointer
      display: flex
      height: px-to-rem(28px)
      justify-content: center
      padding: px-to-rem(10px)
      position: absolute
      right: .25rem
      top: .25rem
      width: px-to-rem(28px)
      z-index: 1

      svg.MuiSvgIcon-root 
        color: $color_brand-blue-deep
        height: px-to-rem(14px)
        width: px-to-rem(14px)

.cp_relevant_post-keywords-fetching
  align-items: center
  display: flex
  justify-content: center
  min-height: 300px

.cp_component_empty-state
  padding: $space_2x 0
  article
      align-items: center
      background-image: url("../../../asset/img/background_webgraph_light.svg")
      background-position: 50% 50%
      background-repeat: no-repeat
      display: flex
      justify-content: center
      min-height: 20rem
  &-mag
      background-image: url("../../../asset/img/icon_mag_light.svg")
      background-repeat: no-repeat
      height: px-to-rem(186px)
      width: px-to-rem(190px)
  aside
      margin-left: auto
      margin-right: auto
      text-align: center
      width: 80%
