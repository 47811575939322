@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_profile-tabs_notes-content
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  border-radius: $content-border-radius
  display: flex
  flex-direction: column
  flex: 1 1
  margin-top: $space_2x
  overflow-y: auto
  padding: $space_2x

  &-header
    align-items: center
    display: flex
    gap: $space_1x
    margin-bottom: $space_2x
    h6
      color: $color_text-primary
  &-body
    display: flex
    flex-direction: column
    height: 100%
    gap: $space_2x
    overflow-y: auto

.dark
  .cp_component_chip.MuiChip-root
    background-color: dark.$color_fill-numbercounter
    color: dark.$color_text-primary

.dark
  .cp_component_profile-tabs_notes-content
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard

    &-header
      h6
        color: dark.$color_text-primary