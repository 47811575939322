@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_data-grid_status
    align-items: center
    display: flex
    gap: $space_1x
    
    &-label
        font-size: 0.75rem
        font-weight: $font-weight-bolder
