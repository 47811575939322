@use "../../style/theme_light" as *
@use "../../style/_styles" as *
@use "../../style/theme_dark" as dark

.cp_component_loading_indicator.MuiCircularProgress-root
    color: $color_fill-loading

.cp_component_loading_indicator-flex-wrapper
    align-items: center
    justify-content: center
    display: flex
    padding: $space_2x
    width: 100%

.dark
    .cp_component_loading_indicator.MuiCircularProgress-root
        color: $color_fill-loading
