@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_modal-campaign-association-warning
  p.modal-notification-text
    margin-top: $space_3x
  .edit-link-container
    align-items: center
    display: flex
    gap: $space_1x
    margin: $space_3x 0

    &:hover
      cursor: pointer

    .inline-button-text
      color: $color_text_inlinebutton
    .MuiSvgIcon-root
      fill: $color_text_inlinebutton
.dark
  .cp_component_modal-campaign-association-warning
    p.modal-notification-text
    .edit-link-container
      .inline-button-text
        color: dark.$color_text_inlinebutton
      .MuiSvgIcon-root
        fill: dark.$color_text_inlinebutton
