@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_campaign-account-row-context-menu_component-button .MuiSvgIcon-root
    color: $color_text-primary

.cp_campaign-account-row-context-menu_component-menu
    min-width: px-to-rem(175px)


    &-item
        &-label > .MuiTypography-root
            color: $color_text-primary
            font-size: $font-size-sm
        .MuiSvgIcon-root
            color: $color_icon-pill
            font-size: $font-size-sm

    &-popover .MuiMenu-list
            padding-bottom: 0
            padding-top: 0
    
    .MuiDivider-root
        border-bottom-color: $color_divider-dropdown
        margin:0 !important

.dark
    .cp_campaign-account-row-context-menu_component-button .MuiSvgIcon-root
        color: dark.$color_border-secondarybutton

    .cp_campaign-account-row-context-menu_component-menu
        background-color: dark.$color_fill-dropdown
        &-item
            &-label > .MuiTypography-root
                color: dark.$color_text-primary
            .MuiSvgIcon-root
                color: dark.$color_icon-pill
        
        &-popover .MuiMenu-paper
            background-color: transparent

        .MuiDivider-root
            border-bottom-color: dark.$color_divider-dropdown
            margin: 0 !important
