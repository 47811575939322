@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_list-group_page
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  .component_navigation-bar
    left: 0
    position: fixed
    top: 0

  &-container

    section.cp_list-group_page-overview,
    section.cp_list-group_page-list-management
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: row
      flex: 1
      justify-content: center
      align-items: center
      height: fit-content
      width: 100%

      &:not(:last-child)
        margin-bottom: $space_2x

.dark
  .cp_list-group_page
    &-container
      section.cp_list-group_page-overview,
      section.cp_list-group_page-list-management
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

