@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_list-management-toolbar_component
    display: flex
    flex: 1
    flex-direction: column
    gap: $space_1x
    margin-bottom: $space_2x
    width: 100%

    &-header
        align-items: center
        display: flex
        justify-content: space-between
        width: 100%
        
        &-title
            flex: 1
            font-size: $space_3x
            font-weight: $font-weight-normal
        &-controls
            display: flex
            flex: 1
            gap: $space_1x
            justify-content: flex-end

.dark
    .cp_list-management-toolbar_component
