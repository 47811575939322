@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *
@use "../../style/z_index_hierarchy" as *

.cp_autocomplete
  width: 100%

  .cp_component_input
    .MuiOutlinedInput-root
      padding: 0

    .MuiButtonBase-root
      padding: 8px

    svg.MuiSvgIcon-root
      fill: $color_border-input
      width: $space-2x
      height: $space-2x

    .MuiAutocomplete-popupIndicatorOpen
      svg.MuiSvgIcon-root
        fill: $color_brand-blue-ocean

.cp_autocomplete-dropdown
  z-index: $z_index_auto-complete

  > div
    border-radius: 2px
    border: 1px solid $color_border-section

    ul.MuiAutocomplete-listbox
      padding: 0
      li.MuiAutocomplete-option
        @include dropdownStyles

        &:hover, &:focus, &:active
          background-color: $color_fill-dropdown-select    
  
  .MuiAutocomplete-loading,
  .MuiAutocomplete-noOptions
    color: $color_text-primary
    font-family: "Open Sans", sans-serif
    font-size: 0.75rem
    font-weight: 700

.dark

  .cp_autocomplete
    .cp_component_input
      svg.MuiSvgIcon-root
        fill: dark.$color_border-input

      .MuiAutocomplete-popupIndicatorOpen
        svg.MuiSvgIcon-root
          fill: dark.$color_brand-blue-ocean

  .cp_autocomplete-dropdown
    color: dark.$color_text-primary

    > div
      border: 1px solid dark.$color_border-section

      ul.MuiAutocomplete-listbox
        background-color: dark.$color_fill-dropdown-dark

        li.MuiAutocomplete-option
          color: dark.$color_text-dropdown
          background-color: dark.$color_fill-dropdown-select-secondary
          border-bottom: 1px solid dark.$color_divider-section

          &:hover, &:focus, &:active
            background-color: dark.$color_fill-dropdown-dark

    .MuiAutocomplete-loading,
    .MuiAutocomplete-noOptions
      color: dark.$color_text-primary

