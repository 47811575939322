@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_media-feedback_component
    background-color: $color_fill-modal
    border-radius: $content-border-radius
    display: flex
    gap: $space_2x
    flex-direction: column
    padding: $space_2x

    &-new-message
        div.cp_component_input
            width: 100%
        &-controls
            display: flex
            gap: $space_1x
            justify-content: flex-start

.dark
    .cp_media-feedback_component
        background-color: dark.$color_fill-modal
