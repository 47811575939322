@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_dark" as dark
@use "../../../../style/theme_light" as *
@use "../../../../style/styles" as *

.dark
  .cp_component_download-report-csv-button
    .download-menu
      .MuiPaper-root
        background-color: dark.$color_fill-dropdown
        color: dark.$color_text-secondary
        li.MuiMenuItem-root
          font-size: px-to-rem(12px)
    