@use "../../style/_styles" as *
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

div.cp_component_toggle.MuiToggleButtonGroup-root
      margin-right: $space_2x

      .MuiButtonBase-root
        color: $color_text-inactive
        background-color: $color_button-exit
        border: none
        font-family: $font-family-primary
        font-size: px-to-rem(10px)
        font-weight: $font-weight-bold
        height: $space_3x
        text-transform: uppercase
        width: $space_8x
      & .Mui-selected
        background-color: $color_fill-card-blue
        color: $color_button-text

.dark
  div.cp_component_toggle.MuiToggleButtonGroup-root
      .MuiButtonBase-root
          color: dark.$color_text-inactive
          background-color: dark.$color_button-exit
      & .Mui-selected
          background-color: dark.$color_button-hovered
          color: dark.$color_button-text
