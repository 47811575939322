@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_public-list_page
  background-color: $color_fill-background
  display: flex
  position: relative
  min-height: 100vh
  width: 100%

// Dark Theme Styles
.dark .cp_public-list_page
  background-color: dark.$color_surface-primary
