@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

div.cp_component_modal-create-deliverable
  .MuiDialog-container .MuiPaper-root
      max-width: px-to-rem(930px)
  .MuiDialogContent-root
    padding: 0

    .MuiStepper-root
      margin: $space_3x 0

      .MuiStep-root
        // Default steps
        .MuiStepIcon-root
          border-radius: 100%
          border: 1px solid $color_border-section
          fill: $color_fill-card
          transition: fill 0.5s
          &:hover
            cursor: pointer
          .MuiStepIcon-text
            fill: $color_text-inactive
        .MuiStepConnector-root
          left: calc(-50% + px-to-rem(12px))
          right: calc(50% + px-to-rem(12px))
          .MuiStepConnector-line
            transition: border-color 0.5s
        .MuiStepLabel-label
          color: $color_text-inactive
          font-size: px-to-rem(10px)
          font-family: "Open Sans"
          font-weight: 400

        // Active steps
        &.step-active
          .MuiStepIcon-root
            border: 1px solid $color_border-pill
            fill: $color_fill-card
          .MuiStepLabel-label
            color: $color_text-primary
            font-weight: 700
          .MuiStepIcon-text
            fill: $color_text-primary
          .MuiStepConnector-line
            border-color: $color_border-pill

        // Completed steps
        &.step-completed
          .MuiStepIcon-root
            border: 1px solid $color_border-pill
            fill: $color_fill-pill-1
          .MuiStepLabel-label
            color: $color_text-primary
            font-weight: 700
          .MuiStepIcon-text
              fill: $color_text-primary
          .MuiStepConnector-line
            border-color: $color_border-pill

    .content-container
      justify-content: center
      display: flex
      padding: $space_3x $space_3x

.dark
  .cp_component_modal-create-deliverable
    .MuiDialogContent-root

      .MuiStepper-root

        .MuiStep-root
          // Default steps
          .MuiStepIcon-root
            border-color: dark.$color_border-section
            fill: dark.$color_fill-card
            .MuiStepIcon-text
              fill: dark.$color_text-inactive
            .MuiStepConnector-root
              .MuiStepConnector-line
          .MuiStepLabel-label
            color: dark.$color_text-inactive

          // Active steps
          &.step-active
            .MuiStepIcon-root
              border-color: dark.$color_border-pill
              fill: dark.$color_fill-card
            .MuiStepLabel-label
              color: dark.$color_text-primary
            .MuiStepIcon-text
              fill: dark.$color_text-primary
            .MuiStepConnector-line
              border-color: dark.$color_border-pill

          // Completed steps
          &.step-completed
            .MuiStepIcon-root
              border-color: dark.$color_border-pill
              fill: dark.$color_fill-pill-1
            .MuiStepLabel-label
              color: dark.$color_text-primary
            .MuiStepIcon-text
                fill: dark.$color_text-primary
            .MuiStepConnector-line
              border-color: dark.$color_border-pill
    