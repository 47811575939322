@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-media-confirmation_component
    padding: $space_2x
    background-color: $color_fill-modal
    border-radius: $content-border-radius

    .body_large
        color: $color_text-primary
        font-size: 1rem
        font-weight: $font-weight-bold

    &-columns.approved
        .cp_campaign-deliverable-media-card_component-controls .btn-approve-media
            background-color: $color_color-success
            color: $color_surface-primary
    &-columns
        display: flex
        gap: $space_2x
        .cp_campaign-deliverable-media-card_component-controls .btn-approve-media
            background-color: $color_button-visible
            color: $color_surface-primary
        &-right
            background-color: $color_surface-secondary
            padding: $space_2x
            &-header
                display: flex
                justify-content: flex-end
                margin-bottom: $space_1x
            &-body
                align-items: center
                display: flex
                gap: $space_1x
                .MuiSvgIcon-root
                    color: $color_fill-icon-list
                    height: px-to-rem(40px)
                    width: px-to-rem(40px)
                    &.approved
                        color: $color_color-success
            &-footer
                display: flex
                gap: $space_1x
                justify-content: flex-start
                margin-top: $space_4x


.dark
    .cp_campaign-deliverable-media-confirmation_component
        background-color: dark.$color_fill-modal
        .body_large
            color: dark.$color_text-primary

        &-columns.approved
            .cp_campaign-deliverable-media-card_component-controls .btn-approve-media
                background-color: dark.$color_color-success
                color: dark.$color_fill-modal
        &-columns
            .cp_campaign-deliverable-media-card_component-controls .btn-approve-media
                background-color: dark.$color_button-visible
                color: dark.$color_surface-primary
            &-right
                background-color: dark.$color_fill-deliverable-card
                &-body
                    .MuiSvgIcon-root
                        color: dark.$color_fill-icon-list
                        &.approved
                            color: dark.$color_color-success
