@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

div.cp_component_navigation-bar-logo
    background-image: url("../../asset/img/logo_theme_light.svg")
    background-position: 50% 50%
    background-repeat: no-repeat
    height: px-to-rem(28px)
    margin: auto
    margin-top: $space_1x
    padding: px-to-rem(24px) 0 px-to-rem(32px) 0
    width: px-to-rem(28px) 

    &.has-logo-tag
        background-image: url("../../asset/img/logo_radius_text_light.svg")
        margin: 0 $space_2x
        width: 12rem
        padding: 2rem


.dark
    div.cp_component_navigation-bar-logo
        background-image: url("../../asset/img/logo_theme_dark.svg")
        &.has-logo-tag
            background-image: url("../../asset/img/logo_radius_text_dark.svg")
