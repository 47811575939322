@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

ul.cp_component_autocomplete_positions
    padding: 0

    .MuiAutocomplete-option
        background-color: $color_border-section
        border-top: 1px solid $color_divider-section
        color: $color_text-primary
        font-size: $font-size-sm
        padding: $space_2x $space_1x
        text-transform: capitalize

        &:has(span.cp_component_loading_indicator)
            justify-content: center
    
        &.Mui-focused
        background-color: $color_fill-dropdown-select

.cp_component_autocomplete_positions
    div.MuiInputBase-root
        padding: 0
    .MuiAutocomplete-option:not(.cp_component_autocomplete_positions_loading)
        align-items: flex-start
        display: flex
        flex-direction: column
        p
            align-items: center
            color: $color_text-dropdown
            display: flex
            font-size: 0.75rem
            gap: 3px
            .MuiSvgIcon-root
                color: inherit
                font-size: inherit
            .cp-network-icon
                display: inline-flex
                height: 0.75rem
                width: 0.75rem
                i.cp-network-icon-svg
                    height: 66%
                    width: 66%
        strong
            color: $color_text-follower
            font-size: 0.75rem
            text-transform: none

    .cp_component_autocomplete_positions_loading.MuiAutocomplete-option
        justify-content: center

    &.MuiAutocomplete-root div.cp_component_input
        width: 100%

        .MuiAutocomplete-endAdornment
            color: $color_border-input
            cursor: pointer

        .MuiAutocomplete-popupIndicator
            background-color: transparent
            color: $color_input-inactive

            &.MuiAutocomplete-popupIndicatorOpen
                color: $color_input-active

.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_autocomplete_positions
        .MuiAutocomplete-option:not(.cp_component_autocomplete_positions_loading)
            p
                color: dark.$color_text-dropdown
            strong
                color: dark.$color_text-follower

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .MuiAutocomplete-root div.cp_component_input 
        .MuiAutocomplete-endAdornment
            color: dark.$color_border-input
        
        .MuiAutocomplete-popupIndicator
            color: dark.$color_input-inactive

            &.MuiAutocomplete-popupIndicatorOpen
                color: dark.$color_input-active
