@use "../../style/_styles" as *
@use "../../style/_primitives" as *
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

.cp_public-list-overview
    display: flex
    background-color: $color_fill-nav
    flex-direction: column
    width: $width_public-list-overview
    &.expanded
        width: $width_public-list-overview-expanded
    &.closed
        width: 0
        display: none
    &-card-section
        h4
            font-size: $font-size-normal
            padding: $space_1x $space_3x
        .MuiDivider-root
            border-color: $color_border-section
        &-container.MuiContainer-root
            align-items: center
            display: flex
            justify-content: space-between
            padding: $space_1x $space_2x
            &:not(:last-child)
                border-bottom: 1px solid $color_divider-list
            &:not(.row-selected)
                cursor: pointer
    .cp_public-list-return-to-group-button
        color: $color_text-inlinebutton
        font-size: px-to-rem(10px)
        margin: $space_4x 0 0 $space_4x
        &:hover
            cursor: pointer

.dark
    .cp_public-list-overview
        background-color: dark.$color_fill-nav
        .cp_public-list-return-to-group-button
            color: dark.$color_text-inlinebutton
        &-card-section
            .MuiDivider-root
                border-color: dark.$color_border-section
            &-container
                &:not(:last-child)
                    border-color: dark.$color_divider-list
