@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_list-group-header-card
    display: flex
    gap: $space_2x
    max-height: px-to-rem(85px)
    overflow: hidden auto
    width: 100%

    &-name-container
        align-items: center
        background-image: url("../../asset/img/background_start_pages_light.svg")
        background-size: 1516px 972px
        background-position: 60% 35%
        display: flex
        justify-content: space-around
        min-height: px-to-rem(85px)
        max-width: 20%
        min-width: px-to-rem(350px)

        &-flex
            align-items: center
            backdrop-filter: blur(3rem)
            display: flex
            flex-direction: row
            height: 100%
            width: 100%
            
        
            &-folder-icon.MuiSvgIcon-root
                fill: $color_text-invert
                height: px-to-rem(32px)
                margin: 0 px-to-rem(18px) 0 px-to-rem(32px)
                width: px-to-rem(32px)

            &-group-name
                @include textStyles($color_text-invert, px-to-rem(22px), $font-weight-bold)
                margin-right: $space_2x

    &-right-column
        display: flex
        flex: 1
        margin: $space_5x

        &-wrapper
            align-content: space-around
            align-items: center
            display: flex
            gap: $space_5x

            &-metric
                display: flex
                gap: $space_1x

                &-value
                    @include textStyles($color_text-primary, $font-size-normal, $font-weight-bolder)
                    text-transform: uppercase

.dark
    .cp_list-group-header-card
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard

        &-right-column
            &-wrapper
                &-metric
                    &-value
                        @include textStyles(dark.$color_text-invert, px-to-rem(22px), $font-weight-bold)
