@use './theme_light' as *
@use './theme_dark' as dark
@forward 'icons' 

// Selectors - can be used to interpolate selectors
  // Example: div:#{$middle-child}
$middle-children: "not(:first-child):not(:last-child)"
  
// Spacing
@function px-to-rem($px) 
  $rem-value: calc($px / 16px)
  @return #{$rem-value}rem

$default_unit:  1rem 
$space_1x: $default_unit * .5
$space_2x: $default_unit
$space_3x: $space_1x * 3
$space_4x: $space_1x * 4
$space_5x: $space_1x * 5
$space_6x: $space_1x * 6
$space_7x: $space_1x * 7
$space_8x: $space_1x * 8

// Font 
$font-family-primary: Open Sans
$font-style-primary: normal
$font-size-large: 1.25rem
$font-size-normal: $space_2x   
$font-size-sm: 0.75rem
$font-size-xs: 0.5rem
$font-weight-normal: 400
$font-weight-bold: 600
$font-weight-bolder: 700
$font-line-height-sm: $space_2x
$font-line-height-primary: 1.5
$font-line-height-secondary: 2

// Border
$border-icon-primary: 1px solid $color_border-image
$border-radius-circle: 50%
$content-border-radius: 2px

// Shadow
$box-shadow-dropdown: rgba(0, 0, 0, 0.14) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.14) 0 1px 18px 0

// Top Navigation Bar
$top-nav-height: 58px

// MUI default breakpoints
$xs: 0
$sm: 600px
$md: 900px
$lg: 1200px
$xl: 1536px
$xxl: 1700px

// Mixins
@mixin dropdownStyles 
  background-color: $color_surface-secondary
  border-bottom: 1px solid $color_divider-dropdown
  color: $color_text-dropdown
  font-family: $font-family-primary
  font-size: $font-size-sm
  font-style: $font-style-primary
  font-weight: $font-weight-normal
  line-height: $font-line-height-primary
  padding: $space_2x $space_1x

@mixin textStyles($color, $fontSize, $fontWeight) 
  color: $color
  font-family: $font-family-primary
  font-size: $fontSize
  font-style: $font-style-primary
  font-weight: $fontWeight
  line-height: normal

@mixin navStyles($color, $fontSize, $fontWeight) 
  color: $color
  font-family: 'Open Sans', 'IBM Plex Sans'
  font-size: $fontSize
  font-style: $font-style-primary
  font-weight: $fontWeight
  line-height: normal
 
