@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_list-configuration-wrapper
  column-gap: $space_2x
  display: flex
  height: 100%
  width: 100%

  .cp_list-configuration-preview.cp_lcp

    .cp_lcp

      &-top
        align-items: center
        display: flex
        margin-bottom: $space_2x
        h2
          @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
          line-height: $font-line-height-primary

      &-tabs
        border: solid 1px $color_border-image
        margin-bottom: $space_1x
        width: 100%

        &_container
          padding: $space_1x $space_3x
          width: 100%

          .cp_lcp-tabs_container-tabs-placeholder
            visibility: hidden

      &-preview
        border: solid 1px $color_border-image
        height: fit-content
        padding: $space_2x

        &_example-visualizations
          display: flex
          flex-wrap: wrap
          gap: $space_2x

          &.appendix, &.content, &.audience, &.insights
            flex-direction: column

            h2
              @include textStyles($color_text-primary, px-to-rem(22px), $font-weight-normal)
              line-height: $font-line-height-primary

        .cp_component_empty-splash
          display: flex
          flex-direction: column
          gap: $space_2x

          .cp_lcp-add-account-button
            margin-top: $space_3x


  .cp_list-configuration-visualizations.cp_lcv
    flex-shrink: 0
    height: 100%
    width: 268px

    .cp_lcv

      &-top
        align-items: center
        display: flex
        margin-bottom: $space_2x
        h2
          @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
          line-height: $font-line-height-primary

      &-help-icon 
        display: inline-block
        margin-left: px-to-rem(6px)

        svg
          fill: $color_text-radiobutton
          width: $space_2x
          height: $space_2x

      &-accordion
        border: 1px solid $color_border-image
        margin-bottom: px-to-rem(12px)
        padding: $space_1x

        &.last
          margin-bottom: $space_3x

        h3.cp_component_accordion-heading
          @include textStyles($color_text-secondary, px-to-rem(14px), $font-weight-bold)

        svg.MuiSvgIcon-root.cp_lcv-accordion-icon-md
          height: $space_2x
          width: $space_2x

        &-summary
          min-height: 0
          padding: 0
          div.MuiAccordionSummary-content
            display: flex
            justify-content: space-between
            margin: 0

            span.summary-counter
              @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-bolder)
              align-content: center
              background-color: $color_fill-numbercounter
              border-radius: 4px
              display: inline-block
              margin-right: $space_1x
              padding: 0 px-to-rem(5px)

          div.MuiAccordionSummary-expandIconWrapper
            svg.MuiSvgIcon-root
              fill: $color_text-secondary
              height: $space_2x
              width: $space_2x

            svg.MuiSvgIcon-root.cp_lcv-accordion-icon-md
              height: px-to-rem(20px)
              width: px-to-rem(20px)

          &.has-chevron-icon
            &.Mui-expanded
               margin-bottom: px-to-rem(12px)

            div.MuiAccordionSummary-expandIconWrapper
              &.Mui-expanded
                transform: rotate(90deg)

        &-details

          &_header
            @include textStyles($color_border-input, $font-size-sm, $font-weight-bold)
            margin-bottom: $space_1x
            margin-top: $space_2x
            
          &_group
            display: flex
            flex-direction: column
            gap: $space_1x
            margin: $space_2x 0

            &:not(:first-child + div)
              border-top: 1px solid $color_border-input
  
            &_header
              @include textStyles($color_border-input, $font-size-sm, $font-weight-bold)
              margin-top: $space_1x

            &_metric-pills
              display: flex
              gap: $space_1x
              flex-wrap: wrap

              &_pill
                background-color: $color_fill-pill-1 !important
                border-color: $color_border-pill
                width: auto

          &.config-options
            padding: 0

        &.Mui-expanded
          h3.cp_component_accordion-heading
            color: $color_input-active

          div.MuiAccordionSummary-expandIconWrapper
            svg.MuiSvgIcon-root
              fill: $color_input-active

  .cp_list-configuration-preview
    flex-grow: 1
    height: 100%
    overflow: auto

.dark .cp_list-configuration-wrapper
  .cp_list-configuration-preview.cp_lcp

    .cp_lcp

      &-top
        h2
          color: dark.$color_text-primary

      &-tabs
        border: solid 1px dark.$color_border-image

      &-preview
        border: solid 1px dark.$color_border-image

  .cp_list-configuration-visualizations.cp_lcv

    .cp_lcv

      &-top
        h2
          color: dark.$color_text-primary

      &-help-icon 
        svg
          fill: dark.$color_text-radiobutton

      &-accordion
        background: transparent 
        h3.cp_component_accordion-heading
          color: dark.$color_text-secondary

        &-summary
          div.MuiAccordionSummary-expandIconWrapper
            svg.MuiSvgIcon-root
              fill: dark.$color_text-secondary

        &-details
          &_header
            @include textStyles(dark.$color_border-input, $font-size-sm, $font-weight-bold)
          &_metric-pills
            &_pill
              background-color: dark.$color_fill-pill-1 !important
              border-color: dark.$color_border-pill

        &.Mui-expanded
          h3.cp_component_accordion-heading
            color: dark.$color_input-active

          div.MuiAccordionSummary-expandIconWrapper
            svg.MuiSvgIcon-root
              fill: dark.$color_input-active
 