@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *


.cp_component_tabs
    .MuiTab-root
        box-shadow: inset 0 -2px $color_border-section
        color: $color_text-tab-inactive
        margin-right: $space_3x
        min-width: unset
        padding: 8px 0
        text-transform: inherit
        .cp_component_tabs-tab-label
            @include navStyles($color_text-tab-inactive, 0.875rem, $font-weight-normal)
            display: flex
            align-items: center
        &.Mui-selected
            color: $color_text-primary
            font-weight: 600
            .cp_component_tabs-tab-label
                color: $color_text-primary
                font-weight: 600
        .cp_component_status
            margin-right: 8px
        .cp_component_tabs-tab-chip
            color: $color_text-secondary
            background-color: $color_fill-numbercounter
            border-radius: 4px
            padding: 2px
            font-size: 10px
            margin-left: 8px
            min-width: 16px

    .MuiTabs-indicator
        background-color: $color_fill-tab-active

    &-container
        background: inherit
        padding: $space_2x 0
        position: relative

        .MuiTabs-scroller
            box-shadow: inset 0 -2px $color_border-section
        
        .cp_component_tabs-controls
            right: $space_2x
            position: absolute
            top: 0.666rem

            .MuiIconButton-root
                border-radius: 5px
                background-color: $color_button-exit
                color: $color_icon-exit

.dark
    .cp_component_tabs
        .MuiTab-root
            color: dark.$color_text-tab-inactive
            box-shadow: inset 0 -2px dark.$color_border-section
            .cp_component_tabs-tab-label
                color: dark.$color_text-tab-inactive
            &.Mui-selected
                color: dark.$color_text-primary
                .cp_component_tabs-tab-label
                    color: dark.$color_text-primary
            .cp_component_tabs-tab-chip
                color: dark.$color_text-secondary
                background-color: dark.$color_fill-numbercounter
                border: solid 1px dark.$color_border-numbercounter
        .MuiTabs-indicator
            background-color: dark.$color_fill-tab-active

        &-container

            .MuiTabs-scroller
                box-shadow: inset 0 -2px dark.$color_border-section
            
            .cp_component_tabs-controls

                .MuiIconButton-root
                    background-color: dark.$color_button-exit
                    color: dark.$color_icon-exit
