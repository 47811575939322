@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_placeholder-empty-splash
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  padding: $space_6x
  margin-top: $space_2x

.dark  
  .cp_component_placeholder-empty-splash
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard

    
