@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_campaign-update-deliverable-metric-modal
  padding: $space_2x

  p.label_small-caps-bold
    color: $color_text-primary

  .cp_datePicker
    .MuiInputBase-root
      color: inherit
      input
        font-family: $font-family-primary
        font-size: $font-size-sm

      fieldset
        border-color: $color_input-inactive

  .return-tag
    margin-top: $space_2x

  .metric-input
    margin-top: $space_2x

.dark
  .cp_component_campaign-update-deliverable-metric-modal
    p.label_small-caps-bold
      color: dark.$color_text-primary

    .cp_datePicker
      .MuiInputBase-root
        border-color: dark.$color_input-inactive