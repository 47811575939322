@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_user-settings-tabs
  &_tabs-container
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    height: 100%
    padding: $space_1x $space_3x
    position: relative
    width: 100%

    .tab-controls
      align-items: center
      display: flex
      gap: $space_1x
      justify-content: flex-end
      visibility: hidden
            
  &-tabs-content

.dark
  .cp_component_user-settings-tabs
    &_tabs-container
      background: dark.$color_fill-card
      border: 1px solid dark.$color_border-colorcard

    &-tabs-content
