@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-media-list-v2_component
    padding: $space_2x
    background-color: $color_fill-modal
    border-radius: $content-border-radius

    &-body
        display: flex
        gap: $space_1x
        flex-wrap: wrap
        justify-content: flex-start

    .body_large
        color: $color_text-primary
        font-size: 1rem
        font-weight: $font-weight-bold


.dark
    .cp_campaign-deliverable-media-list-v2_component
        background-color: dark.$color_fill-modal
        .body_large
            color: dark.$color_text-primary
        &-text
            color: dark.$color_text-secondary
