@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/_styles' as *

.MuiDialog-container

  .MuiIconButton-root
    background-color: $color_button-exit
    border-radius: 5px
    color: $color_icon-exit
    height: $space_4x
    width: $space_4x

    svg
      font-size: 1.25rem

.dark

  .MuiDialog-container
    .MuiIconButton-root
      background-color: dark.$color_button-exit
      color: dark.$color_button-secondary
