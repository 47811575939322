@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_engagement-table_component_loading
    display: flex
    justify-content: space-around
    margin-top: $space_3x
    
.cp_engagement-table_component
    &-table-title
        font-size: $font-size-normal
        font-weight: $font-weight-bold
        margin: $space_2x 0

    &-table
        border-bottom: 2px solid $color_border-section
        text-align: left
        width: 100%

        thead 
            background-color: $color_fill-table-header
            th
                color: inherit
                font-size: $font-size-sm
                font-weight: $font-weight-bolder
                padding: $space_1x

        tbody 
            td
                font-size: $font-size-normal
                font-weight: $font-weight-normal
                padding: $space_1x
            th
                color: $color_text-secondary
                font-size: $font-size-normal
                font-weight: $font-weight-bold
                padding: $space_1x
            tr
                border-bottom: 1px solid $color_border-section
    &-note
        color: $color_text-follower
        font-size: 0.75rem
        margin: $space_3x 0 $space_5x 0
        &:last-child
            margin-bottom: 0


.dark
    .cp_engagement-table_component
        &-table-title
            font-size: $font-size-normal
            font-weight: $font-weight-bold
            margin: $space_2x 0

        &-table
            border-bottom: 2px solid dark.$color_border-section

            thead 
                background-color: dark.$color_fill-table-header
                th
                    color: dark.$color_text-primary

            tbody 
                th
                    color: dark.$color_text-secondary
                tr
                    border-bottom: 1px solid dark.$color_border-section
                    color: dark.$color_text-secondary
        &-note
            color: dark.$color_text-follower
