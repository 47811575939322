@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_profile-overview_component-tags-wrapper
  padding: $space_2x 0
  p
    font-weight: $font-weight-bolder
    margin-bottom: $space_1x
  &-pill-container
    width: 100%
    display: flex
    flex-wrap: wrap
    gap: px-to-rem(7px)

    .MuiChip-root
      height: px-to-rem(24px)
.dark
  .cp_profile-overview_component-tags-wrapper