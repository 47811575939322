@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_list_page
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  .component_navigation-bar
    left: 0
    position: fixed
    top: 0

  &-container
    min-height: calc(100vh - calc($top-nav-height + 3rem))
    width: 100%

    section.cp_list_page-overview,
    section.cp_list_page-list-management
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: row
      flex: 1
      justify-content: center
      align-items: center
      overflow: hidden auto
      width: 100%

      &:not(:last-child)
        margin-bottom: $space_2x

    section.cp_list_page-description
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: column
      flex: 1
      gap: $space_2x
      overflow: hidden auto
      padding: $space_3x
      width: 100%

      &:not(:last-child)
        margin-bottom: $space_2x
      
      div.cp_list_page-description-title
        @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
      
      div.cp_list_page-description-content
        @include textStyles($color_text-primary, $font-size-normal, $font-weight-normal)

// Dark Theme Styles
.dark .cp_list_page
  section.cp_list_page-overview, 
  section.cp_list_page-list-management
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard
  section.cp_list_page-description
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard
    color: dark.$color_text-primary

    div.cp_list_page-description-title
      @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
    
    div.cp_list_page-description-content
      @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-normal)
