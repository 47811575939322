@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *
    
.cp_engagement-map_component_loading
    display: flex
    justify-content: space-around
    
.cp_engagement-map_component
    &-tooltip-day
        font-size: $font-size-sm

    &-header
        align-items: flex-end
        display: flex
        justify-content: space-between
        margin-bottom: $space_2x
    
    &-tz-item span.MuiListItemText-primary
        font-size: $font-size-sm
        
    &-tz
        max-height: $space_3x
        min-width: 300px
        
        .MuiListItemText-root > span
            font-size: $font-size-sm
            font-weight: $font-weight-normal
    &-title
        color: $color_text-primary
        font-size: $font-size-large
        margin-bottom: 0.5rem

    &-chart
        margin-top: $space_4x

        .highcharts-yaxis-grid
            visibility: hidden

        .highcharts-heatmap-series
            path
                stroke: $color_fill-card

        .highcharts-background
            fill: $color_fill-card

        .highcharts-yaxis-labels text, .highcharts-xaxis text
            color: $color_text-secondary !important
            fill: $color_text-secondary !important
            font-size: $font-size-sm !important
            font-weight: $font-weight-bolder

        .highcharts-xaxis 
            text.highcharts-axis-title
                text-transform: uppercase
            path.highcharts-tick, path.highcharts-axis-line
                visibility: hidden
                stroke: none

        .highcharts-coloraxis-labels text
            fill: $color_text-secondary !important
    
    &-footer-note
        color: $color_text-follower
        font-size: $font-size-sm

.dark
    .cp_engagement-map_component           
        &-title
            color: dark.$color_text-primary

        &-chart

            .highcharts-heatmap-series
                path
                    stroke: dark.$color_fill-card

            .highcharts-background
                fill: dark.$color_fill-card

            .highcharts-yaxis-labels text, .highcharts-xaxis text
                color: dark.$color_text-secondary !important
                fill: dark.$color_text-secondary !important
            
            .highcharts-coloraxis-labels text
                fill: dark.$color_text-secondary !important

        &-footer-note
            color: dark.$color_text-follower
