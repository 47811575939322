@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_campaign_page
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.cp_component-data-grid
    padding: 1rem
    background-color: $color_fill-card
  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  div.cp_campaign_page-container
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative
    display: flex
    flex-direction: column
    flex-wrap: wrap

  section.cp_campaign_page-tabs
    overflow-x: hidden
    overflow-y: auto
    position: absolute
    right: 0
    top: 0
    width: 100%


// Dark Theme Styles
.dark
  div.cp_campaign_page
    div.cp_component-data-grid
      background-color: dark.$color_fill-card
      border-color: dark.$color_border-colorcard
      border: none
