@use "../../style/_styles" as *

div.cp_component_public-list-group-overview
    align-items: center
    display: flex
    flex-direction: column

    &-content
        display: flex
        flex-wrap: wrap
        gap: $space_3x
        justify-content: center
        padding: $space_3x