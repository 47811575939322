@use "../../../style/_theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *


.cp_dashboard-campaign_component
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    height: 100%
    padding: $space_3x
    position: relative
    margin: 0

    .MuiTab-root.Mui-selected > .cp_component_tabs-tab-label
        color: $color_text-primary

    &-header
        display: flex
        justify-content: space-between
        &-info
            align-items: center
            display: flex
            gap: $space_1x
            &-title
                color: $color_text-primary
            .cp-avatar.MuiAvatar-root.cp-avatar-lg
                border: none
                height: px-to-rem(48px)
                outline: 2px solid $color_border-image
                width: px-to-rem(48px)
        .MuiIconButton-root
            border-radius: 5px
            background-color: $color_button-exit
            color: $color_icon-exit

    &-body

.dark
    .cp_dashboard-campaign_component
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-colorcard
        .MuiTab-root.Mui-selected > .cp_component_tabs-tab-label
            color: dark.$color_text-primary
        &-header
            &-info
                &-title
                    color: dark.$color_text-primary
                .cp-avatar.MuiAvatar-root.cp-avatar-lg
                    outline: 2px solid dark.$color_border-image
            .MuiIconButton-root
                background-color: dark.$color_button-exit
                color: dark.$color_icon-exit
