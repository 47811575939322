@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

// Light Theme Styles
.cp-tooltip.MuiTooltip-tooltip
  background-color: $color_fill-tooltip
  border-radius: 5px
  color: dark.$color_text-primary // Tooltip background is always dark
  font-family: $font-family-primary
  font-size: $font-size-sm
  font-style: $font-style-primary
  font-weight: $font-weight-normal
  line-height: normal
  min-width: 3rem
  padding: 0.625rem
  color: $color_icon-light-theme

  .MuiTooltip-arrow:before
    background-color: $color_fill-tooltip

// Dark Theme Styles
.dark .cp-tooltip.MuiTooltip-tooltip
  background-color: dark.$color_fill-tooltip
  color: dark.$color_text-primary

  .MuiTooltip-arrow:before
    background-color: dark.$color_fill-tooltip
