@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_user-details
  display: flex
  flex-direction: column
  gap: $space_3x
  height: 100%
  margin: $space_2x
  width: 100%

  &_form-container
    .user-details-input
        .label_small-caps-bold,
        .label_small
          color: $color_text-primary

  &_profile-image
    &_photo-uploader
      align-items: center
      display: flex
      gap: px-to-rem(20px)
      justify-items: start
      margin-bottom: $space_2x
      p.action-info-text
        @include textStyles($color_text-secondary, $font-size-sm,$font-weight-normal)
    
    .image-upload .MuiAvatar-root
      background: $color_fill-social-background
      border: 2px solid $color_border-section
      cursor: pointer
      svg.MuiSvgIcon-root
        height: 2rem
        width: 2rem

    .cp-avatar.MuiAvatar-root
      font-size: xx-large
      height: px-to-rem(80px)
      width: px-to-rem(80px)
    .MuiBadge-badge
      background-color: inherit
      right: $space_1x
      top: $space_1x
      button.cp_avatar-removal
        background-color: $color_surface-brand
        border-radius: 50%
        color: $color_surface-primary
        height: px-to-rem(22px)
        position: absolute
        right: 0
        top: 40px
        width: px-to-rem(22px)
        svg.MuiSvgIcon-root 
          height: $space_2x
          width: $space_2x

  &_form-container
    display: flex
    flex-direction: column
    gap: $space_2x

    .user-details-input
      width: px-to-rem(350px)

.dark
  .cp_component_user-details
    &_form-container
      .user-details-input
        .label_small-caps-bold,
        .label_small
          color: dark.$color_text-primary
    &_profile-image
      &_photo-uploader
        p.action-info-text
           @include textStyles(dark.$color_text-secondary, $font-size-sm,$font-weight-normal)
      
      .image-upload .MuiAvatar-root
        background: dark.$color_fill-social-background
        border: 2px solid dark.$color_border-section

      .MuiBadge-badge
        background-color: inherit
        button.cp_avatar-removal
          background-color: dark.$color_surface-brand
          color: dark.$color_surface-primary
