@use "../../../../../../style/theme_light" as *
@use "../../../../../../style/theme_dark" as dark
@use "../../../../../../style/styles" as *

div.cp_component_unassigned-thread-view-modal
  .modal-body
    display: flex
    max-width: 100%
    min-height: px-to-rem(272px)
    width: 100%
    padding: $space_3x $space_1x
    gap: $space_2x
    .avatar
      
    .content-container
      display: flex
      flex-direction: column
      flex-grow: 1
      gap: $space_2x
      width: 90%
      .email-sender
        color: $color_text-inactive
        font-size: px-to-rem(12px)
      .email-content
        color: $color_text-primary
        font-size: px-to-rem(12px)
        overflow: hidden
        white-space: normal
      .email-signature
        color: $color_text-inactive
        font-size: px-to-rem(12px)

.dark
  div.cp_component_unassigned-thread-view-modal
    .modal-body
      .avatar
        
      .content-container
        .email-sender
          color: dark.$color_text-inactive
        .email-content
          color: dark.$color_text-primary
        .email-signature
          color: dark.$color_text-inactive