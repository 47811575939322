@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_campaign-notification-toggles
  display: flex
  flex-direction: column
  gap: $space_1x
  .toggle-row
    align-items: center
    display: flex
    justify-content: space-between
    padding: $space_1x
    
    &:not(:last-child)
      border-bottom: 1px solid $color_divider-list
    .row-header
      display: flex
      flex-direction: column
      h4
        font-size: px-to-rem(14px)
      p
        color: $color_text-secondary
        font-size: px-to-rem(12px)
.dark
  .cp_component_campaign-notification-toggles
    .toggle-row
      border-color: dark.$color_divider-list
      .row-header
        p
          color: dark.$color_text-secondary