@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

  
.cp_component_comm-group-email-autocomplete 
  div.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium
    background-color: $color_fill-pill

.cp_component_autocomplete_campaigns
  div.MuiAutocomplete-inputRoot 
    padding: 0

.dark
  .cp_component_comm-group-email-autocomplete
    div.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium
      background-color: dark.$color_fill-pill
