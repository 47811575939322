@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp-component_top-nav-title
  align-items: center
  display: flex
  flex-grow: 1

  .cp_component_top-nav-title_breadcrumb-link
    color: $color_text-follower
    margin: 0
    text-decoration: none

    &:hover
      cursor: pointer
      text-decoration: underline
  p
    font-size: 1.625rem
    line-height: unset
    margin: 0 $space_1x

.dark
  .cp-component_top-nav-title
    a
      color: dark.$color_text-follower
