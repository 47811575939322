@use "../../style/_styles" as *
@use "../../style/_primitives" as *
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

.cp_component-rich-text-editor
  background-color: $color_fill-input-paragraph
  border: 1px solid $color_border-section
  height: px-to-rem
  .text-editor-section
    height: px-to-rem(270px)
    padding: px-to-rem(10px)
    overflow-y: auto
    & *:focus-visible
      outline: none

    .tiptap
      :first-child
        margin-top: 0
      p
        font-size: px-to-rem(12px)
      /* List styles */
      ul
        list-style: disc
      ol
        list-style-type: decimal
      ul, ol
        margin: 1.25rem 1rem 1.25rem 0.4rem
        padding: 0 1rem

        li p
        font-size: px-to-rem(12px)
        margin-top: 0.25em
        margin-bottom: 0.25em

      /* Heading styles */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6
        line-height: 1.1
        text-wrap: pretty

      h1,
      h2
        margin-bottom: 1.5rem

      h1 
        font-size: 1.4rem

      h2 
        font-size: 1.2rem

      h3 
        font-size: 1.1rem

      h4,
      h5,
      h6 
        font-size: 1rem

      /* Code and preformatted text styles */
      code
        border-radius: 0.4rem
        font-size: px-to-rem(12px)
        padding: 0.25em 0.3em

      pre
        background: $color_fill-tooltip
        border-radius: 0.5rem
        font-family: 'JetBrainsMono', monospace
        margin: 1.5rem 0
        padding: 0.75rem 1rem

        code
          background: none
          color: dark.$color_text-primary
          font-size: px-to-rem(12px)
          padding: 0

      blockquote
        border-left: 3px solid $color_border-colorcard
        margin: 1.5rem 0
        padding-left: 1rem

      hr
        border: none
        border-top: 1px solid $color_border-colorcard
        margin: 2rem 0

.dark
  .cp_component-rich-text-editor
    background-color: dark.$color_fill-input-paragraph
    border-color: dark.$color_border-section
    .text-editor-section
      border-color: dark.$color_border-section
      .tiptap
        pre
          background: dark.$color_fill-tooltip
          code
            color: $color_text-primary
            font-size: px-to-rem(12px)
            padding: 0

        blockquote
          border-color: dark.$color_border-colorcard

        hr
          border-color: dark.$color_border-colorcard
