@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

div.cp_component_modal-brand-details
    overflow-y: hidden

    .MuiDialogContent-root
        display: flex
        overflow-y: hidden

    .cp_component_modal-brand-details-container
        display: flex
        flex-direction: column
        overflow-y: scroll
        padding: 0 $space_1x 0 0
        width: 100%

        &::-webkit-scrollbar
            background-color: transparent
            width: px-to-rem(8px)

        &::-webkit-scrollbar-thumb
            background-color: $color_input-disabled
            border-radius: 10px


        .infinite-scroll-component 
            overflow-y: hidden

    .cp_component_modal-loading-box
        display: flex
        justify-content: center
        width: 100%

.dark
    div.cp_component_modal-brand-details
        .cp_component_modal-brand-details-container
            &::-webkit-scrollbar-thumb
                background-color: dark.$color_icon-pill