@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/primitives" as primitives
@use "../../style/styles" as *

// Light Theme Styles 
article.cp_component_login-flow-logo
  background-repeat: no-repeat
  background-size: px-to-rem(62px) px-to-rem(62px)
  border-radius: 50%
  height: px-to-rem(62px)
  margin: 0 auto
  width: px-to-rem(62px)

  &.default
    background-image: url("../../asset/img/logo_radius_text_light.svg")
    background-size: px-to-rem(185px) px-to-rem(62px)
    border-radius: 0%
    width: px-to-rem(185px)

// Dark Theme Styles
.dark article.cp_component_login-flow-logo
  &.default
    background-image: url("../../asset/img/logo_radius_text_dark.svg")
    width: px-to-rem(185px)
