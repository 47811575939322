@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_search-results-ellipsis-menu-cell
  align-items: center
  display: flex
  height: 100%
  justify-content: center
  width: 100%

  > section
    // NOTE: This is a gross hack that is necessary because MUI's dropdown
    // breaks in the ellipsis menu cell is positioned relatively.
    height: 0
    position: relative
    width: 0

    > aside
      align-items: flex-start
      background-image: linear-gradient(to top right, transparent 50%, $color_color-success 50%)
      display: flex
      height: $space_4x
      justify-content: flex-end
      padding: 0 0.1rem
      position: absolute
      right: -2.1rem
      top: -2.5rem
      width: $space_4x

      svg
        color: dark.$color_text-primary // Intentionally using dark color
        height: $space_2x
        width: $space_2x

  button svg
    color: $color_text-primary
    height: $space_2x
    width: $space_2x
    z-index: 1
  .MuiListItemButton-root .cp_component_loading_indicator
    margin: auto

// Dark Theme Styles
.dark div.cp_component_search-results-ellipsis-menu-cell
  > section > aside
    background-image: linear-gradient(to top right, transparent 50%, dark.$color_color-success 50%)

    svg
      color: dark.$color_text-primary

  button svg
    color: dark.$color_text-primary
