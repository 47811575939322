@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_media-feedback_component
    background-color: $color_fill-modal
    border-radius: $content-border-radius
    display: flex
    gap: $space_2x
    flex-direction: column
    padding: $space_2x

    &-new-message
        div.cp_component_input
            width: 100%
        &-controls
            align-items: center
            justify-content: space-between
            display: flex
            gap: $space_1x

            .button-container
                display: flex
                gap: $space_1x
            .visibility-container
                align-items: center
                display: flex
                gap: $space_1x
                margin-top: $space_1x
                p
                    color: $color_text-secondary
                    font-size: px-to-rem(10px)
                    font-weight: 700
                    text-transform: uppercase

.dark
    .cp_media-feedback_component
        background-color: dark.$color_fill-modal

        &-new-message
            &-controls
                .visibility-container
                    p
                        color: dark.$color_text-secondary
                        