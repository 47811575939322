@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_approve-panel
  align-items: center
  background-color: $color_fill-nav
  border: 1px solid $color_border-modal
  display: flex
  flex-direction: column
  padding: $space_2x
  width: px-to-rem(500px)

  .MuiIconButton-root
    margin-left: auto

  &-icon.MuiSvgIcon-root
    color: $color_color-success
    height: px-to-rem(80px)
    margin: $space_6x 0
    width: px-to-rem(80px)

  h5
    font-size: px-to-rem(22px)
    margin-bottom: px-to-rem(106px)
    text-align: center
  
  .MuiButtonBase-root
    height: px-to-rem(40px)
    width: px-to-rem(256px)
    &:not(:last-child)
      margin-bottom: $space_2x
      

.dark
  .cp_component_approve-panel
    background-color: dark.$color_fill-nav
    border-color: dark.$color_border-modal
    &-icon
      color: dark.$color_color-success