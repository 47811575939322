@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_post-details-modal
  .MuiChip-root
    margin: 0 $space_1x $space_1x 0

  // CUSTOM TOP RIGHT ACTION BUTTONS
  button.cp_component_post-details-modal-custom-actions
    margin: 0 $space_1x 0 0

  // POST DATE
  span.cp_component_post-details-modal-date
    margin: 0 $space_2x 0 0

  // AI IMAGE ANALYSIS
  article.cp_component_post-details-modal-image-analysis
    svg.MuiSvgIcon-root
      fill: $color_icon-search
      height: px-to-rem(12px)
      margin: 0 0 0 $space_1x
      width: px-to-rem(12px)

  // CONTENT SECTION OF MODAL
  .MuiDialogContent-root
    > article
      padding: $space_3x 0
      border-bottom: 1px solid $color_border-section

    > article.cp_component_post-details-modal-stats
      align-items: center
      border-bottom: none
      display: flex
      justify-content: space-between
      padding: $space_5x 0 0 0

    aside.cp_component_post-stats
      gap: $space_4x

    p.label_large-bold,
    p.label_large-caps-bold
      margin-bottom: $space_3x

    // CAROUSEL
    section
      align-items: center
      display: flex
      height: calc(px-to-rem(350px) + $space_4x)
      justify-content: space-between
      padding: $space_3x
      position: relative

      // Content
      > a
        align-items: center
        display: flex
        height: px-to-rem(350px)
        justify-content: center
        position: relative
        text-decoration: none
        z-index: 1

        &.is-nsfw
          position: relative

          &::before
            background-color: $color_button-secondary-disabled 
            content: ""
            height: 100%
            left: 0
            mix-blend-mode: multiply
            pointer-events: none
            position: absolute
            top: 0
            width: 100%
            z-index: 2

          img,
          svg
            filter: blur(5px)
        img
          display: block
          height: px-to-rem(350px)

        svg
          color: dark.$color_text-primary // Intentionally using dark theme color here
          height: $space_6x
          left: calc(50% - $space_3x)
          position: absolute
          top: calc(50% - $space_3x)
          width: $space_6x

      // Media Indicators
      > aside
        align-items: flex-end
        bottom: 0
        display: flex
        gap: 0.25rem
        height: $space_2x
        justify-content: center
        left: calc(50% - 10rem)
        position: absolute
        width: 20rem

        div
          background-color: $color_text-inactive
          border-radius: 50%
          height: $space_1x
          width: $space_1x

          &.cp_component_post-details-modal-media-indicator_active
            background-color: $color_text-primary

      // Carousel Buttons
      > button
        background: transparent
        border: none
        color: transparent
        cursor: pointer
        padding: 0
        z-index: 1

        &.cp_component_post-details-modal-carousel-button_inactive
          opacity: 0
          pointer-events: none
        
        svg
          color: $color_text-primary
          height: $space_4x
          width: $space_4x

      // Backdrop
      > div
        background-position: 50% 50%
        background-size: cover
        filter: blur(3rem)
        height: 100%
        left: 0
        opacity: 0.5
        position: absolute
        top: 0
        width: 100%
        z-index: 0

// Dark Theme Styles
.dark div.cp_component_post-details-modal
  // AI IMAGE ANALYSIS
  article.cp_component_post-details-modal-image-analysis
    svg.MuiSvgIcon-root
      fill: dark.$color_icon-search

  // CONTENT SECTION OF MODAL
  .MuiDialogContent-root
    > article
      border-bottom: 1px solid dark.$color_border-section

    > article.cp_component_post-details-modal-stats
      border-bottom: none

    section
      // Content
      > a
        &.is-nsfw
          &::before
            background-color: dark.$color_button-textdisabled
        svg
          color: dark.$color_text-primary


      // Media Indicators
      > aside
        div
          background-color: dark.$color_text-inactive

          &.cp_component_post-details-modal-media-indicator_active
            background-color: dark.$color_text-primary

      // Carousel Buttons
      > button
        svg
          color: dark.$color_text-primary
