@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_campaign-notifications-settings
  padding: $space_2x
  .notifications-header
    font-size: px-to-rem(20px)
    font-weight: 600
    margin-bottom: $space_2x
