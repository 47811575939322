@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-media-card_component
    padding-top: $space_1x
    background-color: $color_fill-modal
    border-radius: $content-border-radius
    &-avatar
        position: relative

        &-download
            visibility: hidden
            left: 34px
            position: absolute
            top: 1px
        .MuiSvgIcon-root
            background-color: $color_fill-overlay-modal
            border-radius: 5px
            fill: $color_icon-light-theme
            position: absolute
            right: 5px
            top: 5px
        &:hover
            .cp_campaign-deliverable-media-card_component-avatar-download
                visibility: visible

    .MuiAvatar-root
        height: px-to-rem(125px)
        width: px-to-rem(125px)
    &-controls
        display: flex
        justify-content: flex-start
        margin-top: $space_1x
        gap: $space_1x
        .btn-approve-media
            &.approved.MuiIconButton-root
                background-color: $color_color-success
                color: $color_surface-primary
        .btn-feedback
            &.active.MuiIconButton-root
                background-color: $color_button-visible
                .MuiSvgIcon-root
                    color: $color_surface-primary
        .cp-badge-component .MuiBadge-badge
                background-color: $color_button-visible
                border: 1px solid $color_surface-primary
                color: $color_surface-primary
                top: 10%
                right: 10%


.dark
    .cp_campaign-deliverable-media-card_component
        background-color: dark.$color_fill-modal
        .body_large
            color: dark.$color_text-primary
        &-controls
            .btn-approve-media.approved.MuiIconButton-root
                    background-color: dark.$color_color-success
                    color: dark.$color_fill-modal
            .btn-feedback
                &.active.MuiIconButton-root
                    background-color: dark.$color_button-visible
                    .MuiSvgIcon-root
                        color: dark.$color_surface-primary
            .cp-badge-component .MuiBadge-badge
                background-color: dark.$color_button-visible
                border: 1px solid $color_surface-primary
                color: dark.$color_surface-primary
