@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_social_tracker_page
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)
  position: relative

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  div.cp_social_tracker_page-container
    display: flex
    flex-direction: row
    flex-wrap: wrap
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative
    width: 100%

  div.cp_social_tracker_page-empty
    align-items: center
    display: flex
    justify-content: center
    min-height: 350px

  div.cp_social_tracker_page-not-found
    align-items: center
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    height: calc(100vh - calc($top-nav-height + 3rem))
    justify-content: center

    .new-tracker-button
      height: px-to-rem(28px)
      margin-top: $space_4x
      .label-container
        align-items: center
        display: flex
        padding: 0.4rem 0.75rem
        gap: 0.5rem

  section.cp_social_tracker_page-overview
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    flex: 1
    height: 100%
    left: 0
    overflow: hidden auto
    position: absolute
    top: 0
    width: px-to-rem(350px)

  section.cp_social_tracker_page-relevant-posts
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    flex: 2
    height: 100%
    overflow-x: hidden
    overflow-y: auto
    padding: $space_3x
    position: absolute
    right: 0
    top: 0
    width: calc(100% - calc(350px + 1rem))

    p span.highlight-txt
      font-weight: $font-weight-bolder
      color: $color_surface-brand,

// Dark Theme Styles
.dark div.cp_social_tracker_page
  div.cp_social_tracker_page-not-found
    background: dark.$color_fill-card
    border-color: dark.$color_border-colorcard
  section.cp_social_tracker_page-overview
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard

  section.cp_social_tracker_page-relevant-posts
    border: none
    background: dark.$color_fill-card

    p span.highlight-txt
      color: dark.$color_text-highlight,
