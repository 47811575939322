@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_report-native-posts,
.cp_report-paid-media-section
  margin-bottom: $space_3x
  h2.section-heading
    @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-normal)
    background-color: $color_fill-pill-1
    margin-bottom: $space_3x
    padding: $space_1x
    width: 100%

  .cp_report-native-stories-table 
    div.cp_component-data-grid .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader, div.cp_component-data-grid .MuiDataGrid-pinnedColumnHeaders .MuiDataGrid-columnHeader
      padding-left: 1.2rem

  .cards-sections
    align-items: stretch
    display: flex
    gap: $space_4x
    height: 425px
    justify-content: flex-start
    margin-bottom: $space_4x
    overflow-x: auto
    width: 100%

    .cp_campaign-report_post-card
      flex: 0 0 320px
      width: 320px
      display: flex
      flex-direction: column
      transition: all 0.2s ease-in-out
      cursor: pointer

      .post-card_media
        height: 230px
        position: relative
        transition: height 0.2s ease-in-out
        width: 100%

        img.post-card_media-img
          height: 100%
          object-fit: cover
          width: 100%

        &-captions
          display: none

      .post-card_content
        flex-grow: 1
        border-radius: $content-border-radius
        border: 1px solid $color_border-section
        padding: $space_3x
        padding-bottom: $space_1x
        position: relative
        transition: all 0.2s ease-in-out

        .network-icon
          display: flex
          justify-content: center
          left: 0
          max-width: 100%
          padding: 0
          position: absolute
          top: px-to-rem(-21px)
        
        &-username
          @include textStyles($color_text-secondary, $font-size-sm, $font-weight-bold)
          margin-bottom: .75rem
          text-align: center

        &-engaments
          .engagement-stats
            margin-bottom: $space_2x
            div
              display: flex
              flex-wrap: wrap
              column-gap: $space_2x
              p
                @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-normal)
                flex: 1 1 calc(50% - .5rem)

        &-date_last_updated
          text-align: center
          @include textStyles($color_text-inactive, $font-size-sm, $font-weight-normal) 
        
      &.show-caption
        .post-card_media
          height: 295px
          &-captions
            align-items: center
            background-color: primitives.$color_black-a80
            color: $color_text-invert
            display: flex
            flex-direction: column
            height: 100%
            justify-content: center
            left: 0
            padding: $space_5x
            position: absolute
            top: 0
            width: 100%

            .captions-date_posted
              @include textStyles(primitives.$color_gray-dark-100, px-to-rem(14px), $font-weight-normal)
              margin-bottom: px-to-rem(22px)
            .caption
              overflow: auto
              @include textStyles(primitives.$color_gray-dark-100, $font-size-sm, $font-weight-normal)

        .post-card_content
          .engagement-stats
            display: none  

  &.stories,
  &.paid-media
    .cp_report-native-stories-table,
    .cp_report-paid-media-table
      div.cp_component-data-grid
        background-color: transparent
        border: 1px solid $color_divider-section

        div.MuiDataGrid-cell 
          span
            @include textStyles($color_text-secondary, px-to-rem(18px), $font-weight-normal)
            font-size: 0.75rem
          .cp_story-row-username
            @include textStyles($color_text-secondary, px-to-rem(16px), $font-weight-bold)
            font-size: 0.75rem
          p.cp_cell-paid-media-caption
            display: -webkit-box
            font-size: px-to-rem(10px)
            white-space: normal
            width: 100%
            overflow: hidden
            -webkit-box-orient: vertical
            -webkit-line-clamp: 3

  &.paid-media
    div.section
      margin-bottom: $space_3x


.dark
  .cp_report-native-posts,
  .cp_report-paid-media-section
    h2.section-heading
      background-color: dark.$color_divider-section
      color: dark.$color_text-primary

    .cards-sections
      .cp_campaign-report_post-card
        .post-card_content
          border-color: dark.$color_border-section
          &-username
            color: dark.$color_text-secondary

          &-engaments
            .engagement-stats
              p
                color: dark.$color_text-secondary

          &-date_last_updated
            color: dark.$color_text-inactive

    &.stories,
    &.paid-media
      .cp_report-native-stories-table,
      .cp_report-paid-media-table
        div.cp_component-data-grid
          border-color: dark.$color_divider-section

          div.MuiDataGrid-cell 
            span,
            .cp_story-row-username
              color: dark.$color_text-secondary






