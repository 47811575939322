@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *
@use '../../style/primitives' as primitives

.cp_profile-overview_component
  height: 100%
  margin: 0 $space_2x

  &_loading
    margin: auto

  &-scores-button.cp-btn.MuiButton-root.cp-btn_primary_text
    color: $color_text-primary
    cursor: pointer
    font-size: $font-size-normal
    padding: 0
    width: 100%

    &:hover
      background-color: transparent
      text-decoration: none

  &-avatar
    background-image: url("../../asset/img/background_start_pages_light.svg")
    background-size: 1516px 972px // From SVG dimensions
    background-position: 60% 35%
    height: px-to-rem(258px)
    margin: 0 calc($space_2x * -1)
    text-align: center

    &-outer-wrapper
      align-items: center
      backdrop-filter: blur(3rem)
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center
      width: 100%

    &-wrapper
      margin: auto

      .cp_profile-overview_component-avatar-image.MuiAvatar-root, .cp_profile-overview-placeholder-icon
        margin: auto
        height: px-to-rem(110px)
        width: px-to-rem(110px)

      .cp_profile-overview-placeholder-icon
        color: $color_icon-placeholder
        background-color: $color_fill-card
        border: 1px solid $color_border-image
        border-radius: 100%
        padding: 1.5rem

      .cp_profile-overview_component-avatar-name
        color: primitives.$color_white-50
        font-size: 1.75rem
        font-weight: $font-weight-normal
        margin: $space_1x 0

      .cp_profile-overview_component-avatar-email
        color: primitives.$color_white-50
        font-weight: $font-weight-bolder
        font-size: px-to-rem(14px)

  .cp_profile-overview_component-networks-wrapper
    display: flex
    flex-direction: column
    gap: $space_1x
    padding: $space_3x

    .cp_profile-overview_component-networks-heading
      font-weight: $font-weight-bolder
      text-align: center

    .cp_profile-overview_component-networks-list
      display: flex
      gap: $space_2x
      justify-content: center
      padding: 0 $space_1x

  .cp_profile-overview_component-scores-wrapper
    padding: $space_3x 0
    display: flex
    gap: $space_3x
    justify-content: space-around
    flex-direction: row
    p.label_large-bold
      text-align: center
      text-transform: uppercase
      &.clickable
        cursor: pointer

    .cp_profile-overview_component-scores-heading
      color: $color_text-follower
      font-weight: $font-weight-bolder
      margin-bottom: 0.25rem
      text-align: center

    .scores-value
      color: $color_text-secondary
      text-align: center

    .scores-value-button.cp-btn.MuiButton-root.cp-btn_primary_text
      color: $color_text-secondary
      font-size: 1rem
      font-weight: 700
      padding: 0
      text-transform: uppercase
      width: 100%
      
      &:hover
        background-color: transparent
        text-decoration: none

    .cp_profile-overview_component-scores-body
      text-transform: none
      font-weight: $font-weight-normal

  .cp_profile-overview_component-bio-wrapper
    padding: $space_2x 0

    .cp_profile-overview_component-bio-heading
      font-weight: $font-weight-bolder
      margin-bottom: $space_1x

    .cp_profile-overview_component-bio-body
      text-transform: none
      font-weight: $font-weight-normal

  .cp_profile-overview_component-contact-wrapper
    padding-top: $space_1x
    padding-bottom: $space_2x

    .cp_profile-overview_component-contact-heading
      align-items: center
      display: flex
      font-weight: $font-weight-bolder
      justify-content: space-between
      margin-bottom: $space_1x

    .cp_component_contact-information-button
      color: $color_icon-pill

    .cp_profile-overview_component-contact-title
      font-weight: $font-weight-normal
      text-transform: none
      display: flex
      align-items: center
      justify-content: flex-start
      margin-bottom: $space_1x
      font-weight: $font-weight-bolder

      .MuiSvgIcon-root
        color: $color_icon-list
        height: $space_2x
        width: $space_2x
        margin-right: $space_1x

    .cp_profile-overview_component-contact-email, .cp_profile-overview_component-contact-website
      font-weight: $font-weight-normal
      margin-bottom: $space_2x
      text-transform: none

    .cp_profile-overview_component-contact-email-wrapper
      display: flex
      justify-content: space-between
      margin-bottom: $space_2x

      > .cp_profile-overview_component-contact-email
        font-weight: $font-weight-normal
        margin-bottom: 0

    .cp_profile-overview_component-email_status
      align-items: center
      display: flex
      font-size: $font-size-sm
      font-weight: $font-weight-bolder
      justify-content: space-between

      .cp_component_status
        margin-right: $space_1x

    p.cp_profile-overview_component-email-select
      color: $color_text-primary
      margin-bottom: $space_1x
    #cp_profile-overview_select-email
      min-width: px-to-rem(270px)

  // ACCOUNT SECTION
  div.cp_profile-overview_component-social-account-wrapper
    padding: $space_2x 0

    > aside
      align-items: center
      display: flex
      justify-content: center
      padding: 0.25rem $space_2x

      &:first-of-type
        padding: 0 $space_2x 0.25rem $space_2x

      &:last-of-type
        padding: 0.25rem $space_2x 0 $space_2x

    p.account-handle
      background: $color_fill-banner
      border-radius: 0.75rem
      color: $color_text-primary
      font-size: $font-size-sm
      font-weight: $font-weight-bolder
      padding: 0.25rem $space_1x

    p.icon-text
      color: $color_text-secondary
      font-size: $font-size-sm
      overflow: hidden
      padding-left: 1.4rem
      position: relative
      text-overflow: ellipsis
      white-space: nowrap

      svg
        color: $color_icon-pill-2
        display: block
        font-size: px-to-rem(18px)
        left: 0
        position: absolute
        top: 0

  // CREATED/UPDATED/FOLLOWERS SECTION
  div.cp_profile-overview_component-created-updated-wrapper
    padding: $space_2x 0

    > p
      color: $color_text-secondary
      font-size: $font-size-sm

.dark
  .cp_profile-overview_component

    .cp_profile-overview_component-scores-wrapper
      .cp_profile-overview_component-scores-heading
        color: dark.$color_text-follower

      .scores-value
        color: dark.$color_text-secondary

      .scores-value-button.cp-btn.MuiButton-root.cp-btn_primary_text
        color: dark.$color_text-secondary

    .cp_profile-overview_component-avatar
      .cp_profile-overview-placeholder-icon
        color: dark.$color_icon-placeholder
        background-color: dark.$color_fill-card
        border-color: dark.$color_border-image
      .cp_profile-overview_component-avatar-name
        color: primitives.$color_white-50 // Same as light mode
      .cp_profile-overview_component-avatar-email
        color: primitives.$color_white-50 // Same as light mode

    &-scores-button.cp-btn.MuiButton-root.cp-btn_primary_text
      color: dark.$color_text-primary
      &:hover
        background-color: transparent
        text-decoration: none

    // ACCOUNT SECTION
    div.cp_profile-overview_component-social-account-wrapper
      p.account-handle
        background: dark.$color_fill-banner
        color: dark.$color_text-primary

      p.icon-text
        color: dark.$color_text-secondary

        svg
          color: dark.$color_icon-pill-2

    // CREATED/UPDATED/FOLLOWERS SECTION
    div.cp_profile-overview_component-created-updated-wrapper
      > p
        color: dark.$color_text-secondary
  .cp_profile-overview_component-contact-wrapper
    .cp_profile-overview_component-contact-title
      .MuiSvgIcon-root
        color: dark.$color_icon-list

    .cp_component_contact-information-button
      color: dark.$color_icon-pill

    p.cp_profile-overview_component-email-select
      color: dark.$color_text-primary
