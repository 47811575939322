@use '../../style/styles' as *
@use '../../style/_theme_light' as *
@use "../../style/theme_dark" as dark

div.cp_component-entity-row-info
    align-items: center
    display: flex
    flex-direction: row
    margin: $space_2x 0
    padding: 0

    .cp_component-entity-pending-actions-icon
        color: $color_icon-placeholder
        background-color: $color_fill-card
        border: 1px solid $color_border-image
        border-radius: 100%
        height: px-to-rem(40px)
        width: px-to-rem(40px)
        padding: 0.35rem
    .cp-avatar.MuiAvatar-root
        // border: none

    &:not(.row-disabled):hover
        cursor: pointer
    p
        margin-left: $space_2x

    .MuiBadge-root .MuiBadge-badge .cp-network-icon.cp_component_network-badge
        height: px-to-rem(18px)
        width: px-to-rem(18px)
        i
            height: px-to-rem(12px)
            width: px-to-rem(12px)

    .cp_component-entity-info-row-details
        display: flex
        flex-direction: column
        font-family: $font-family-primary

        > div
            display: flex
            align-items: center
            justify-content: flex-start

        svg
            color: $color_icon-pill
            height: 1.2rem
            width: 1.2rem

        .cp_component-copy-clipboard

            svg
                color: $color_icon-exit

    .cp_component-entity-info-row-sub-info
        color: $color_text-dropdown-secondary
        font-size: $font-size-sm
        font-weight: $font-weight-bold
        text-transform: uppercase
        width: fit-content
    .MuiSvgIcon-root
        margin-left: 0.25rem

    .MuiCheckbox-root
        height: px-to-rem(38px)
        width: px-to-rem(38px)

.dark
    div.cp_component-entity-row-info
        .cp_component-entity-pending-actions-icon
            color: dark.$color_icon-placeholder
            background-color: dark.$color_fill-card
            border-color: dark.$color_border-image
        .cp_component-entity-info-row-details
            svg
                color: dark.$color_icon-pill
