@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_name-deliverable-input-step
  margin-bottom: $space_3x
  h4
    font-size: px-to-rem(16px)
    font-weight: 600
  .deliverable-title-input-label
    display: flex
    justify-content: space-between
    margin-top: $space_2x

  div.cp_component_input.deliverable-title-input
    width: px-to-rem(500px)

  .client-note-input-label
    margin-top: $space_4x
    display: flex
    p.optional-text
      font-family: "Open Sans"
      font-size: px-to-rem(12px)
      font-style: italic
      margin-left: px-to-rem(4px)

  div.cp_component_input.client-note-input
    width: px-to-rem(500px)
    height: px-to-rem(80px)

    .MuiInputBase-root
      align-items: flex-start
      display: flex
      height: px-to-rem(80px)
      padding: 0 0.4rem

