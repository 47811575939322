@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
.cp_component_ai-search-filter.MuiStack-root
  background: $color_fill-card

  .cp_component_ai-search-filter-refresh
    background-color: $color_button-exit
    border-radius: 5px
    color: $color_icon-exit

  svg.beta-icon
    height: px-to-rem(14px)

  p.label_small-caps-bold
    color: $color_text-primary

  div.cp_component_ai-search-filter-target,
  div.cp_component_ai-search-filter-network
    align-items: flex-start
    background-color: $color_fill-card
    background: $color_fill-card
    border-radius: 4px
    border-bottom: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    gap: px-to-rem(10px)
    padding: px-to-rem(20px) px-to-rem(30px) px-to-rem(10px)

  .cp_component_ai-search-filter-target
    border-bottom: none
    position: relative

    &-select-control
      min-width: 100%

    &-input-label
      color: $color_text-follower
      font-family: $font-family-primary
      font-size: $font-size-normal
      font-style: $font-style-primary
      font-weight: $font-weight-normal
      line-height: px-to-rem(22px)
      padding-top: 0
      top: px-to-rem(-20px)

      &.MuiInputLabel-shrink
        display: none
        transform: none

    &-select
      margin-top: 0

      &.Mui-focused
        background-color: $color_fill-card

        [aria-expanded="false"]
          background-color: $color_fill-card

      .target-select-item 
        background-color: $color_fill-background
        border-bottom: 1px solid $color_divider-dropdown
        padding: $space_2x $space_1x

      .MuiSelect-select
        color: $color_text-secondary
        font-family: $font-family-primary
        min-height: px-to-rem(22px)
        padding-bottom: 0
        padding-top: 0

      &.MuiInput-underline:after,
      &.MuiInput-underline:before 
        border-bottom: none

      svg.MuiSelect-icon
        fill: $color_border-input

      &:hover:hover:not(.Mui-disabled, .Mui-error):before
        border-bottom: none

  .cp_component_ai-search-filter-network
    box-shadow: none

    &-container
      display: flex
      gap: px-to-rem(9px)
      padding-left: 0
      padding-right: 0

    &-filter-button
      padding-left: 0
      padding-right: 0

    &.search-filter_network
      border-top: none

// nested forms styles
.cp_component_search-ai

  &-audience,
  &-profile,
  &-content

    &.MuiContainer-root
      padding: 0

    fieldset.MuiFormControl-root
      margin-bottom: $space_3x

      span.MuiFormControlLabel-label
        color: $color_text-radiobutton
        font-family: $font-family-primary
        font-size: $font-size-sm
        font-style: $font-style-primary
        font-weight: $font-weight-normal
        line-height: normal

      legend.MuiFormLabel-root,
      .cp_component_input > p
        align-items: center
        color: $color_text-primary
        display: flex
        font-family: $font-family-primary
        font-size: $font-size-sm
        font-style: $font-style-primary
        font-weight: $font-weight-bolder
        line-height: normal
        margin-bottom: px-to-rem(10px)
        text-transform: uppercase

        svg.MuiSvgIcon-root
          cursor: pointer
          display: inline-block
          fill: $color_icon-search
          height: px-to-rem(12px)
          margin-left: px-to-rem(10px)
          width: px-to-rem(12px)

      div.cp_component_input
        margin-bottom: 0
// dropdown styles light
[aria-labelledby="component_ai-search-filter-target-select"].MuiList-root
  border-radius: $content-border-radius
  border: 1px solid $color_border-section 
  padding: 0

  .cp_component_ai-search-filter-target-select-item
    background-color: $color_button-exit
    border-bottom: 1px solid $color_divider-section
    color: $color_text-dropdown
    font-family: $font-family-primary
    font-size: $font-size-sm
    font-style: $font-style-primary
    font-weight: $font-weight-normal
    line-height: $font-line-height-sm
    padding: $space_2x $space_1x

    &:hover, 
    &:target,
    &:active
      background-color: $color_fill-dropdown-select
      

// Dark Theme Styles
.dark
  .cp_component_search-ai
    background: dark.$color_fill-card
    &-audience,
    &-profile,
    &-content
      fieldset.MuiFormControl-root
        span.MuiFormControlLabel-label
          color: dark.$color_text-radiobutton


        legend.MuiFormLabel-root,
          .cp_component_input > p
            color: dark.$color_text-primary

            svg.MuiSvgIcon-root
              fill: dark.$color_icon-search
  .cp_component_ai-search-filter.MuiStack-root
    background: dark.$color_fill-card
    .cp_component_ai-search-filter-refresh
      background-color: dark.$color_fill-modal
      color: dark.$color_icon-exit
    p.label_small-caps-bold
      color: dark.$color_text-primary

    .cp_component_search-filter-target,
    .cp_component_search-filter-network
      background-color: dark.$color_fill-card
      background: dark.$color_fill-card
      border-bottom: 1px solid dark.$color_border-colorcard
    
    .cp_component_search-filter-target
      &-input-label
        color: dark.$color_button-toggle-off

      &-select
        &.Mui-focused
          background-color: dark.$color_fill-card

          [aria-expanded="false"]
            background-color: dark.$color_fill-card
          
        svg.MuiSelect-icon
          fill: dark.$color_border-input
      
        .MuiSelect-select
          color: dark.$color_text-secondary

  // dropdown styles
  [aria-labelledby="component_search-filter-target-select"].MuiList-root
    border: 1px solid dark.$color_border-section 

    .cp_component_search-filter-target-select-item
      background-color: dark.$color_button-exit
      border-bottom: 1px solid dark.$color_divider-section
      color: dark.$color_text-dropdown

      &:hover, 
      &:target,
      &:active
        // TODO: token in figma is incorrect, will update when token is updated
        background-color: rgb(66, 70, 89) 







