@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_component_search-profile

  .cp_component_search-profile

    &-type

      .MuiRadio-root.cp-radio
        padding: 0 px-to-rem(5px)

      label.MuiFormControlLabel-root
        margin-left: px-to-rem(-7px) // override Mui
        margin-right: px-to-rem(20px)

    &-followers
      column-gap: px-to-rem(30px)
      display: flex
      flex-direction: row
      
      &_wrapper
        max-width: calc(50% - 15px)

    &-biokeywords

      &-pills
        display: flex
        flex-wrap: wrap
        gap: $space_1x

        &:has(div.cp-pill)
          padding-top: px-to-rem(10px)

      svg.MuiSvgIcon-root
        fill: $color_icon-exit
        height: px-to-rem(12px)
        width: px-to-rem(12px)

    &-engagement
      .MuiInputAdornment-root
        p.MuiTypography-root 
          color: $color_icon-exit
          font-size: px-to-rem(12px)
          padding: $space_1x

.dark

    .cp_component_search-profile

      &-biokeywords

        svg.MuiSvgIcon-root
          fill: dark.$color_icon-exit

      &-engagement
        .MuiInputAdornment-root
          p.MuiTypography-root 
            color: dark.$color_icon-exit

