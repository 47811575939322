@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


.cp_component-modal-edit-url-body
  padding: $space_2x
  padding-left: 0
  .input
    width: px-to-rem(646px)
  .inner-container
    display: flex
    flex-direction: column
    gap: $space_2x
    padding: $space_2x
    padding-left: 0