@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

// Icon background colors
$fill-social-instagram-light: #D93175
$fill-social-instagram-dark: #E94E8C
$fill-social-facebook-light: #3B579D
$fill-social-facebook-dark: #6187E6
$fill-social-snapchat-light: #FFFC00
$fill-social-snapchat-dark: #FFFD5C
$fill-social-youtube-light: #FF0000
$fill-youtube-facebook-dark: #F24F4F
$fill-social-tiktok-light: #000
$fill-social-tiktok-dark: #EFEFEF

// Icon styles 
.cp-network-icon
  align-items: center
  border-radius: $border-radius-circle
  display: flex
  height: $space_5x
  justify-content: center
  width: $space_5x

  .cp-network-icon-svg
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    display: inline-block
    height: $space_2x
    width: $space_2x

  &.cp-network-icon_inactive
    background-color: $color_fill-social-background

  &_instagram
    background-color: $fill-social-instagram-light

    .cp-network-icon-svg
      background-image: url($instagram-icon-base)

    .dark & 

      &.cp-network-icon_active
        background-color: $fill-social-instagram-dark
  
  &_facebook
    background-color: $fill-social-facebook-light

    .cp-network-icon-svg
      background-image: url($facebook-icon-base)

    .dark & 

      &.cp-network-icon_active
        background-color: $fill-social-facebook-dark

  &_youtube
    background-color: $fill-social-youtube-light

    .cp-network-icon-svg
      background-image: url($youtube-icon-base)

    .dark & 

      &.cp-network-icon_active
        background-color: $fill-youtube-facebook-dark

  &_snapchat
    background-color: $fill-social-snapchat-light

    .cp-network-icon-svg
      background-image: url($snapchat-icon-base)

    &.cp-network-icon_inactive

      .cp-network-icon-svg
        background-image: url($snapchat-icon-inactive)

    .dark & 

      &.cp-network-icon_active
        background-color: $fill-social-snapchat-dark

  &_tiktok
    background-color: $fill-social-tiktok-light

    .cp-network-icon-svg
      background-image: url($tiktok-icon-base)

    &.cp-network-icon_inactive

    .dark & 

      .cp-network-icon-svg
        background-image: url($tiktok-icon-dark)

      &.cp-network-icon_active
        background-color: $fill-social-tiktok-dark

      &.cp-network-icon_inactive
        
        .cp-network-icon-svg
          background-image: url($tiktok-icon-base)
  
  // NOTE: Temporary placeholder property. Will remove once twitter is removed from app entirely
  &_twitter
    background-color: $fill-social-facebook-light
.dark
  .cp-network-icon  
    &.cp-network-icon_inactive
      background-color: dark.$color_fill-social-background
      border: dark.$color_border-image



