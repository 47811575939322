@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *
    
div.cp_component-data-grid
    display: flex
    flex: 1
    flex-direction: column
    gap: $space_1x
    height: 100%
    width: 100%

    .MuiDataGrid-root
        border: none
        font-family: $font-family-primary

        .MuiDataGrid-cell
            border-bottom: 1px solid $color_border-section
            div.cp_component-entity-row-info
                padding: 0 0 0 $space-1x
        .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus-within
            outline: none

    .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders
        background-color: $color_fill-table-header
        border: none
        box-shadow: none

        .MuiDataGrid-columnHeader

    .MuiDataGrid-virtualScrollerRenderZone
        background-color: $color_fill-card
    
    .MuiDataGrid-columnHeaderTitle
        color: $color_border-secondarybutton
        font-size: 0.75rem
        font-weight: $font-weight-bolder
        text-transform: uppercase

    .MuiDataGrid-pinnedColumns
        background-color: $color_table-pinned-column-background
        box-shadow: none
        overflow: visible

    span.MuiDataGrid-checkboxInput.MuiCheckbox-root
        color: $color_border-radio-inactive

    // Checkboxes
    .MuiDataGrid-cellCheckbox,
    .MuiDataGrid-columnHeader
        .MuiSvgIcon-root
            color: $color_border-radio-inactive
            font-size: $font-size-normal

        .Mui-disabled .MuiSvgIcon-root
            color: $color_input-disabled
            opacity: 0.3

.dark
    div.cp_component-data-grid
        .MuiDataGrid-root
            .MuiDataGrid-cell
                border-bottom: 1px solid dark.$color_border-section
        .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders
            background-color: dark.$color_fill-table-header
        
        .MuiDataGrid-columnHeaderTitle
            color: dark.$color_border-secondarybutton

        .MuiDataGrid-pinnedColumns
            background-color: dark.$color_table-pinned-column-background

        .MuiDataGrid-virtualScrollerRenderZone
            background-color: dark.$color_fill-card

        span.MuiDataGrid-checkboxInput.MuiCheckbox-root
            color: dark.$color_border-radio-inactive

        // Checkboxes
        .MuiDataGrid-cellCheckbox,
        .MuiDataGrid-columnHeader
            .MuiSvgIcon-root
                color: dark.$color_border-radio-inactive
                font-size: 1rem

            .Mui-disabled .MuiSvgIcon-root
                color: dark.$color_input-disabled
                opacity: 0.3
