@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component-eebt-content
  margin-bottom: $space_2x
  margin-top: $space_2x
  margin-right: $space_3x

  div.cp_component_input
    width: 100%

  &_stack
    margin-top: $space_3x

  &_new-email-input
    margin-top: $space_3x
  
  &_emails-chips
    margin-top: $space_2x
    min-height: px-to-rem(200px)
  
  .email-chip
    margin-right: $space_1x
    margin-top: $space_1x
