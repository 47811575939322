@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_nsfw-icon
    font-size: inherit
    &.cp_component_nsfw-icon_active
        fill: $color_icon-nsfw

.cp_component_nsfw-label
    &-light,
    &-default
        background-size: contain
        background-repeat: no-repeat
        display: inline-block
        width: px-to-rem(30px)
        height: px-to-rem(12px)
        position: absolute
        top: calc(50% - px-to-rem(6px))
        left: calc(50% - px-to-rem(15px))
        z-index: 3

    &-default
        background-image: url($nsfw-icon-red)
    &-light
        background-image: url($nsfw-icon-white)

// custom tooltip styles
div.MuiTooltip-popper
  .cp_component_nsfw-icon
    &-tooltip_title,
    &-tooltip_mssg
      color: $color_text-invert
      font-size: $font-size-sm
      font-style: normal
      line-height: normal

    &-tooltip_title
      font-family: $font-family-primary
      font-weight: $font-weight-bolder
      margin-bottom: .25rem
    &-tooltip_mssg
      font-family: $font-family-primary
      font-weight: $font-weight-normal   

.dark
    .cp_component_nsfw-label
        &-default
          background-image: url($nsfw-icon-dark)
