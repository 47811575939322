@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_recent-posts_component
  align-items: center
  display: flex
  flex-wrap: nowrap
  gap: $space_3x
  justify-content: space-between
  padding-top: $space_3x

  .cp_recent-posts_component
    &-trigger
      align-items: center
      aspect-ratio: 1 / 1 
      border-radius: $content-border-radius
      border: $border-icon-primary
      display: flex
      flex: 1 1 auto
      justify-content: center
      max-width: calc(100% / 6 - $space_3x)
      overflow: hidden

    &-media
      height: auto 
      object-fit: cover
      width: 100%
