@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *


.cp_create-category_component
    padding-top: $space_2x

    &-accounts
        display: flex
        flex-direction: column
        gap: $space_1x
        
        &-container
            display: flex
            gap: $space_1x
            justify-content: flex-start

        &-username
            font-size: $font-size-normal
            font-weight: $font-weight-normal

    &-accounts-title
        font-size: $font-size-sm
        font-weight: $font-weight-bolder
        text-transform: uppercase
        margin: $space_2x 0 $space_1x 0
