@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

div.cp_component_modal-add-to
    .MuiDialogContent-root
        padding: 0 $space_2x

        .cp_component_modal-add-to-container
            height: px-to-rem(255px)
            overflow-y: scroll
            padding: 0
            width: 100%
            
            &::-webkit-scrollbar
                display: none
        
            .cp_component-modal-add-to-buffer-container
                display: flex
                justify-content: center
                padding: $space_3x

            .cp_component-modal-add-to-entity-row
                align-items: center
                display: flex
                padding: $space_1x 0

                .cp-avatar
                    border: none
                    font-size: px-to-rem(10px)

                    &.cp_component_network-avatar
                        height: px-to-rem(30px)
                        width: px-to-rem(30px)

                &:not(.row-disabled):hover
                    cursor: pointer
                p
                    margin-left: $space_2x
                
                .MuiBadge-root .MuiBadge-badge .cp-network-icon
                    height: px-to-rem(18px)
                    width: px-to-rem(18px)
                    i
                        height: px-to-rem(12px)
                        width: px-to-rem(12px)

                .cp_component-modal-add-to-entity-details
                    display: flex
                    flex-direction: column
                    font-family: $font-family-primary
                    & > div
                        display: flex
                        align-items: center
                        width: fit-content
                    .cp_component-modal-add-to-entity-row-sub-info
                        color: $color_text-dropdown-secondary
                        font-size: $font-size-sm
                        font-weight: $font-weight-bold
                        text-transform: uppercase
                        width: fit-content
                    .MuiSvgIcon-root
                        margin-left: 0.25rem



.dark
    div.cp_component_modal-add-to
    