@use '../../style/styles' as *

.cp_component_modal-create-list-group
    &-divider.MuiDivider-root
        width: 100%
    
    .cp_component_modal-create-list-group-input
        margin: 0
        padding: $space_2x $space_4x
        width: 100%
        
        .MuiSvgIcon-root

    .MuiDialogContent-root
        padding: 0

    .cp_component_modal-create-list-group-content
        height: px-to-rem(220px)
        padding: 0 $space_2x

        .cp_component-entity-row-info.entity-info
            p.cp_component-entity-info-row-sub-info
                text-transform: none