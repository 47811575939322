@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_list-profile-tabs_communications
  background-color: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  flex-direction: column
  margin-top: $space_2x
  height: 100%
  overflow-y: scroll
  .content-header
    align-items: center
    display: flex
    padding: $space_2x

    .add-to-group-button
      margin-left: auto

  .cp_component-data-grid
    height: auto

.dark
  .cp_component_list-profile-tabs_communications
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard
