@use '../../../style/_styles' as *
@use '../../../style/theme_light' as *
@use '../../../style/theme_dark' as dark

.draft-messages-view-empty
  display: flex
  flex-direction: column
  gap: $space_2x 

  p
    @include textStyles($color_text-secondary, px-to-rem(18px), $font-weight-normal)
    position: absolute
    top: px-to-rem(200px)
    width: 46%
    text-align: center
    z-index: 1

  
  .cp_ghost-box
    height: 106px
    width: 100%
    background-color: $color_pill-ghost-box

.dark
  .draft-messages-view-empty
    div.cp_ghost-box
      background-color: dark.$color_pill-ghost-box
