@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component-data-grid
    &-visible.MuiIconButton-root
        border-radius: 5px

    &-visible_active.MuiIconButton-root
        background-color: $color_button-visible
        color: $color_text-invert
    &-visible_inactive.MuiIconButton-root
        background-color: $color_button-visible-off
        color: $color_icon-exit
        
.dark
    .cp_component-data-grid
        &-visible_active.MuiIconButton-root
            background-color: dark.$color_button-visible
            color: dark.$color_text-invert
        &-visible_inactive.MuiIconButton-root
            background-color: dark.$color_button-visible-off
            color: dark.$color_icon-exit
