@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
aside.cp_component_post-stats
  align-items: center
  display: flex
  gap: $space_2x
  justify-content: flex-start

  svg
    height: 1.25rem
    margin-right: 0.25rem
    vertical-align: middle
    width: 1.25rem

// Dark Theme Styles
.dark aside.cp_component_post-stats
