@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp_component_modal-create-customer
  div.MuiDialogContent-root
   padding: px-to-rem(28px) px-to-rem(28px) 0

   .cp_create-customers
    .cp_create-customers
      &-logo-loader
        column-gap: px-to-rem(20px)
        display: flex
        margin-bottom: px-to-rem(28px)
        
        .cp-avatar.MuiAvatar-root
          background: $color_fill-social-background
          cursor: pointer
          height: 80px
          width: 80px
          svg.MuiSvgIcon-root
            height: $space_4x
            width: $space_4x

        div.logo-info-text
          display: flex
          flex-flow: column
          justify-content: center

      &-fields
        div.cp_create-customers-name_input
          margin-bottom: px-to-rem(28px)
          width: 350px
        div.cp_create-customers-vanity-code_input
          margin-bottom: $space_5x
          width: 400px
      &-white-labeling
        div.switch-wrapper
          align-items: center
          column-gap: px-to-rem(4px)
          display: flex
          margin-bottom: $space_4x
          p
            @include textStyles($color_text-primary, px-to-rem(14px),$font-weight-bold)
          .cp-switch.MuiSwitch-root.cp-switch_with-icon span svg
            fill: $color_surface-primary
            height: 1rem
            width: 1rem

        .cp-avatar.MuiAvatar-root
          background: $color_fill-social-background
          cursor: pointer
          height: 60px
          width: 60px
          svg.MuiSvgIcon-root
            height: $space_3x    
            width: $space_3x
      &_photo
        margin-bottom: px-to-rem(28px)
        &-badge
            margin-bottom: $space_2x
        &-uploader
          align-items: center
          display: flex
          gap: px-to-rem(20px)
          justify-items: start
          margin-bottom: $space_2x
          p.action-info-text
            @include textStyles($color_text-secondary, $font-size-sm,$font-weight-normal)
        
        .image-upload .MuiAvatar-root
            background: $color_fill-social-background
            border: 2px solid $color_border-section
            cursor: pointer
            svg.MuiSvgIcon-root
              height: 2rem
              width: 2rem

        .cp-avatar.MuiAvatar-root
            font-size: xx-large
            height: px-to-rem(80px)
            width: px-to-rem(80px)
        .MuiBadge-badge
            right: $space_1x
            top: $space_1x
            button.cp_avatar-removal
                background-color: $color_surface-brand
                border-radius: 50%
                color: $color_surface-primary
                height: px-to-rem(22px)
                position: absolute
                right: 0
                top: 40px
                width: px-to-rem(22px)
                svg.MuiSvgIcon-root 
                    height: $space_2x
                    width: $space_2x

      &-input-wrapper
        position: relative
        span
          @include textStyles($color_text-secondary, px-to-rem(11px),$font-weight-bold)
          position: absolute
          top: 0
        span.name-imput-max
          left: 325px
        span.code-imput-max
          left: 375px
.dark .cp_component_modal-create-customer
