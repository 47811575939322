@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp-radio.MuiRadio-root
  border-radius: $content-border-radius

  .MuiSvgIcon-root
    fill: $color_border-radio-active
    height: $space_2x
    width: $space_2x

  &.Mui-disabled, &:not(.Mui-checked)
    .MuiSvgIcon-root
      fill: $color_border-radio-inactive


.dark 
  .cp-radio.MuiRadio-root

    .MuiSvgIcon-root
      fill: dark.$color_border-radio-active

    &.Mui-disabled, &:not(.Mui-checked)
      .MuiSvgIcon-root
        fill: dark.$color_border-radio-inactive
