@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use '../../../../style/_styles' as *


div.cp_component_navigation-bar-templates-container
    flex-grow: 1
    overflow-y: scroll
    padding: 0
    display: flex
    flex-direction: column
    &::-webkit-scrollbar
        display: none

    .cp_component_navigation-bar-comms-wrapper
        flex-grow: 1
        padding: 0 $space_2x

    .cp_component_loading_indicator
        align-self: center
        justify-self: center
        margin: $space_4x

// Light Theme Styles
div.cp_component_comms-table
    max-height: 100%
    width: 100%

    .MuiDataGrid-iconButtonContainer
        display: none

    // Header Titles
    .cp_component_comms-table-custom-header.MuiTypography-root
        align-items: center
        color: $color_text-secondary
        display: flex
        font-size: 0.75rem
        font-weight: 700
        text-transform: uppercase
        
        .cp_component_comms-table-unfold-icon
            display: inline-block

    // Rows
    .MuiDataGrid-row
        min-height: 0
        height: $space_3x
        position: relative
        &:hover
            background-color: $color_fill-list-hovered
            cursor: pointer
        &:not(:last-child):before
            border-bottom: 1px solid $color_divider-section
            bottom: 0
            content: ""
            height: 1px
            left: 0
            margin: 0 $space_2x
            position: absolute
            width: calc(100% - $space_4x)


// Dark Theme Styles
.dark div.cp_component_comms-table
    // TABLE STYLES
    // Headers
    .MuiDataGrid-columnHeaders
        background-color: dark.$color_fill-modal
        border-bottom: 1px solid dark.$color_border-section

    // Header Titles
    .cp_component_comms-table-custom-header
        color: dark.$color_text-secondary

    // Rows
    .MuiDataGrid-row
        &:hover
            background-color: dark.$color_fill-list-hovered
        &:not(:last-child)    
            &:before
                border-bottom: 1px solid dark.$color_divider-section
