@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/_styles" as *


div.cp_component_navigation-bar-profiles-container
    display: flex
    flex-direction: column
    height: 100%
    overflow-y: scroll
    padding: 0
    &::-webkit-scrollbar
        display: none

    .cp_component_loading_indicator
        align-self: center
        justify-self: center
        margin: $space_4x
        

div.cp_component_navigation-bar-profile-container
    display: flex
    height: fit-content
    padding: $space_1x $space_2x 0.75rem $space_2x
    position: relative
    
    &:not(:last-child):before
        content: ""
        position: absolute
        left: 0
        bottom: 0
        height: 1px
        width: calc(100% - $space_4x)
        margin: 0 $space_2x
        border-bottom: 1px solid $color_divider-section

    .cp_component_navigation-bar-profile-avatar
        border: 1px solid $color_border-image
        font-family: $font-family-primary
        font-size: $font-size-normal
        font-weight: $font-weight-normal
        margin-top: px-to-rem(2px)

    .cp_component_navigation-bar-profile-row-content
        align-items: flex-start
        display: flex
        height: fit-content
        justify-content: space-between
        padding-top: px-to-rem(2px)
        width: 100%

        .cp_component_navigation-bar-profile-networks
            align-items: center
            display: flex
            padding-top: px-to-rem(10px) 
            .cp-network-icon
                height: 1.5rem
                padding: 0 0.25rem
                width: 1.5rem
                &:not(:last-child)
                    margin-right: 0.25rem
                i
                    width: 0.5rem
                    height: 0.5rem

        .cp_component_navigation-bar-profile-name-container
            margin-left: $space_2x
            width: px-to-rem(170px)
            .cp_component_navigation-bar-profile-name
                color: $color_text-primary
                display: -webkit-box
                font-family: $font-family-primary
                font-size: $font-size-normal
                font-weight: $font-weight-normal
                max-height: px-to-rem(72px)
                overflow: hidden
                text-overflow: ellipsis
                -webkit-box-orient: vertical
                -webkit-line-clamp: 3

            .cp_component_navigation-bar-profile-follower-count
                color: $color_text-follower
                font-family: $font-family-primary
                font-size: $font-size-sm
                font-weight: $font-weight-bold
                text-transform: uppercase

        
    &:hover
        background-color: $color_fill-list-hovered
        cursor: pointer        

.dark
    div.cp_component_navigation-bar-profile-container
        &:not(:last-child)
            &:before 
                border-color: dark.$color_divider-section
        &:hover
            background-color: dark.$color_fill-list-hovered

        .cp_component_navigation-bar-profile-avatar
            border-color: dark.$color_border-image
        .cp_component_navigation-bar-profile-row-content
            .cp_component_navigation-bar-profile-name
                color: dark.$color_text-primary
            .cp_component_navigation-bar_search-icon
                color: dark.$color_icon-search
            .cp_component_navigation-bar_tune-icon
                color: dark.$color_fill-icon-list
