@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_deliverable_v2_history-item
    padding: 0.75rem 0.75rem
    &:not(:last-child)
        border-bottom: 1px solid $color_divider-section

    .main-info
        display: flex
        .deliverable-history-entity-info-row
            margin: 0 0 $space_1x 0
            .cp_component-entity-info-row-sub-info
                font-weight: 400
                text-transform: capitalize
            p
                font-size: px-to-rem(10px)
        .status-tag-container
            display: flex
            gap: 0.5rem
            margin-left: auto
            .MuiSvgIcon-root
                height: px-to-rem(14px)
                width: px-to-rem(14px)
            .approve-icon
                fill: $color_color-success
            .request-revision-icon
                fill: $color_color-warning
            p, div
                font-size: px-to-rem(10px)
                white-space: nowrap


    .sub-info
        margin-left: $space_5x
        .history-attributes
            display: flex
            gap: $space_1x
            .MuiSvgIcon-root
                fill: $color_icon-pill
                height: px-to-rem(16px)
                width: px-to-rem(16px)
            p 
                font-size: px-to-rem(10px)
            .visibility-icon-toggle
                margin: 0
                padding: 0
                width: fit-content
                height: fit-content
                &:hover
                    cursor: pointer
        .history-item-content
            margin-top: $space_1x
            .history-item-action
                color: $color_text-body
                font-size: px-to-rem(12px)
            .history-item-details
                color: $color_text-body
                display: flex
                flex-direction: column
                font-size: px-to-rem(12px)
                gap: 0.5rem
                margin-top: 0.25rem
                white-space: normal
                overflow-wrap: anywhere

                // HTML Output formatting
                ul
                    list-style: disc
                ol
                    list-style-type: decimal
                ul, ol
                    margin: 0 1rem 0 0.4rem
                    padding: 0 1rem
                
                .small-img
                    width: px-to-rem(24px)
                    height: px-to-rem(24px)

                .image-container
                    position: relative
                    .downloadable-img
                        height: px-to-rem(60px)
                        width: px-to-rem(60px)
                    a
                        left: 34px
                        position: absolute
                        top: 1px
                        visibility: hidden
                        .MuiSvgIcon-root
                            background-color: $color_fill-overlay-modal
                            border-radius: 5px
                            fill: $color_icon-light-theme
                            position: absolute
                            right: 5px
                            top: 5px
                    &:hover
                        a
                            visibility: visible
        
.dark
    .cp_component_deliverable_v2_history-item
        &:not(:last-child)
            border-color: dark.$color_divider-section
        .main-info
            .status-tag-container
                .approve-icon
                    fill: dark.$color_color-success
                .request-revision-icon
                    fill: dark.$color_color-warning

        .sub-info
            .history-attributes
                .MuiSvgIcon-root
                    fill: dark.$color_icon-pill
            .history-item-content
                .history-item-action
                    color: dark.$color_text-body
                .history-item-details
                    color: dark.$color_text-body
