@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_modal-campaign-deliverable-history-tab_component
    background-color: #FFFFFF
    flex: 0 0 66%
    padding: $space_1x
    &-timeline-container
        background-color: $color_fill-background
        margin-top: $space_1x
        padding: $space_1x
    &-timeline
        background-color: $color_fill-modal
        padding: $space_1x

.dark
    .cp_modal-campaign-deliverable-history-tab_component
        background-color: dark.$color_fill-modal
        &-timeline-container
            background-color: dark.$color_surface-primary
        &-timeline
            background-color: dark.$color_fill-modal
