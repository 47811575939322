@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component_communications-group-accounts-search-container
  display: flex
  flex-direction: column
  flex: 1
  gap: $space_1x

  // Result Type Dropdown
  div.cp_component_search-results-type-dropdown
    align-items: center
    display: flex
    height: 100%
    justify-content: space-between

    svg
      color: $color_text-secondary


  .header-section
    display: flex
    justify-content: space-between
    z-index: 1

    div.cp_component_search-bar-input.cp_component_input.search-bar
      background: $color_fill-card
      margin: 0
      padding: 0
      width: px-to-rem(320px)

      .MuiInputBase-root fieldset
        border: 1px solid $color_border-colorcard

    .details-container
      align-items: center
      display: flex
      gap: $space_2x
      position: sticky
      right: $space_2x
    
  .communications-body
    display: flex
    flex-direction: row
    flex: 1
    gap: $space_2x

    &_search-form
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      height: calc(100vh - 192px)
      overflow: hidden auto
      width: 350px

      > aside.cp_page_search-button
        align-items: center
        background: $color_fill-card
        bottom: 0
        display: flex
        height: $space_8x
        justify-content: center
        left: 0
        position: sticky
        width: 100%

        > button
          width: calc(100% - 2rem)


    &_search-results
      height: calc(100vh - 192px)
      overflow: hidden
      width: calc(100vw - calc(560px + 9rem))

      .cp_component_search-results-table-placeholder
        width: calc(100vw - calc(560px + 9rem))

      .cp_component_search-results-table-empty
        height: calc(100vh - 192px)
        width: calc(100vw - calc(560px + 9rem))

      .cp_component_search-results-table
        height: calc(100vh - 192px)
        width: calc(100vw - calc(560px + 9rem))

    // TABLE STYLES
    // Root Table
    .MuiDataGrid-root
      border: 0
      border-radius: 0
      font-family: "Open Sans", sans-serif

    // Empty/Placeholder State
    &-empty
      .MuiDataGrid-virtualScroller
        overflow: hidden

      div.cp_component_empty-splash
        margin: 6rem 0 0 0

    // Headers
    .MuiDataGrid-columnHeaders
      background-color: $color_fill-social-background
      border-bottom: none
      border-top-left-radius: 0
      border-top-right-radius: 0

    // Header Titles
    .MuiDataGrid-columnHeaderTitle
      color: $color_text-secondary
      font-size: 0.75rem
      font-weight: 700
      text-transform: uppercase

    // Table Data Container
    .MuiDataGrid-virtualScroller
      background-color: $color_fill-card

    // Pinned Columns
    .MuiDataGrid-pinnedColumnHeaders
      background-color: $color_fill-social-background
      box-shadow: none

    .MuiDataGrid-pinnedColumns
      background-color: $color_table-pinned-column-background
      box-shadow: none
      overflow: visible

    // Cells
    .MuiDataGrid-cell
      border-bottom: 1px solid $color_border-section
      cursor: pointer

    // Checkboxes
    .MuiDataGrid-cellCheckbox,
    .MuiDataGrid-columnHeader
      .MuiSvgIcon-root
        color: $color_border-radio-inactive
        font-size: 1rem

      .Mui-disabled .MuiSvgIcon-root
        color: $color_input-disabled
        opacity: 0.3

.dark
  .cp_component_communications-group-accounts-search-container
    display: flex
    flex-direction: column
    flex: 1
    gap: $space_1x

    .header-section
      display: flex
      justify-content: space-between

      div.cp_component_search-bar-input.cp_component_input.search-bar
        background: dark.$color_fill-card
        margin: 0
        padding: 0
        width: px-to-rem(320px)

        .MuiInputBase-root fieldset
          border: 1px solid dark.$color_border-colorcard

      .details-container
        align-items: center
        display: flex
        gap: $space_2x
        position: sticky
        right: $space_2x
      
    .communications-body
      display: flex
      flex-direction: row
      flex: 1
      gap: $space_2x

      &_search-form
        background: dark.$color_fill-card
        border-radius: $content-border-radius
        border: 1px solid dark.$color_border-colorcard
        height: calc(100vh - 192px)
        overflow: hidden auto
        width: 350px

        > aside.cp_page_search-button
          align-items: center
          background: dark.$color_fill-card
          bottom: 0
          display: flex
          height: $space_8x
          justify-content: center
          left: 0
          position: sticky
          width: 100%

          > button
            width: calc(100% - 2rem)


      &_search-results
        height: calc(100vh - 192px)
        overflow: hidden
        width: calc(100vw - calc(560px + 9rem))

        .cp_component_search-results-table-placeholder
          width: calc(100vw - calc(560px + 9rem))

        .cp_component_search-results-table-empty
          height: calc(100vh - 192px)
          width: calc(100vw - calc(560px + 9rem))

        .cp_component_search-results-table
          height: calc(100vh - 192px)
          width: calc(100vw - calc(560px + 9rem))

      // TABLE STYLES
      // Root Table
      .MuiDataGrid-root
        border: 0
        border-radius: 0
        font-family: "Open Sans", sans-serif

      // Empty/Placeholder State
      &-empty
        .MuiDataGrid-virtualScroller
          overflow: hidden

        div.cp_component_empty-splash
          margin: 6rem 0 0 0

      // Headers
      .MuiDataGrid-columnHeaders
        background-color: dark.$color_fill-social-background
        border-bottom: none
        border-top-left-radius: 0
        border-top-right-radius: 0

      // Header Titles
      .MuiDataGrid-columnHeaderTitle
        color: dark.$color_text-secondary
        font-size: 0.75rem
        font-weight: 700
        text-transform: uppercase

      // Table Data Container
      .MuiDataGrid-virtualScroller
        background-color: dark.$color_fill-card

      // Pinned Columns
      .MuiDataGrid-pinnedColumnHeaders
        background-color: dark.$color_fill-social-background
        box-shadow: none

      .MuiDataGrid-pinnedColumns
        background-color: dark.$color_table-pinned-column-background
        box-shadow: none
        overflow: visible

      // Cells
      .MuiDataGrid-cell
        border-bottom: 1px solid dark.$color_border-section
        cursor: pointer

      // Checkboxes
      .MuiDataGrid-cellCheckbox,
      .MuiDataGrid-columnHeader
        .MuiSvgIcon-root
          color: dark.$color_border-radio-inactive
          font-size: 1rem

        .Mui-disabled .MuiSvgIcon-root
          color: dark.$color_input-disabled
          opacity: 0.3
