@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp-avatar
  &.MuiAvatar-root
    border: 1px solid $color_border-image-user
    &.cp-avatar-sm
      font-size: px-to-rem(10px)
      height: px-to-rem(26px)
      width: px-to-rem(26px)
    &.cp-avatar-md
      font-size: px-to-rem(16px)
      height: px-to-rem(40px)
      width: px-to-rem(40px)
    &.cp-avatar-lg
      font-size: px-to-rem(24px)
      height: $space_5x
      width: $space_5x
    &.cp-avatar-xl
      font-size: px-to-rem(50px)
      height: px-to-rem(110px)
      width: px-to-rem(110px)
    &:has(> img.MuiAvatar-img)
      background: $color_surface-primary


.cp-avatar.color_avatar-gradient-1
  background: $color_avatar-gradient-1
.cp-avatar.color_avatar-gradient-2
  background: $color_avatar-gradient-2
.cp-avatar.color_avatar-gradient-3
  background: $color_avatar-gradient-3
.cp-avatar.color_avatar-gradient-4
  background: $color_avatar-gradient-4
.cp-avatar.color_avatar-gradient-5
  background: $color_avatar-gradient-5
.cp-avatar.color_avatar-gradient-6
  background: $color_avatar-gradient-6
.cp-avatar.color_avatar-gradient-7
  background: $color_avatar-gradient-7
.cp-avatar.color_avatar-gradient-8
  background: $color_avatar-gradient-8

.dark
  .cp-avatar.MuiAvatar-root
    // border: 1px solid dark.$color_surface-primary
    border: 1px solid dark.$color_border-image-user
    &:has(> img.MuiAvatar-img)
      background: dark.$color_fill-avatar
      // background: dark.$color_surface-primary
  
  .cp-avatar.color_avatar-gradient-1:not(:has(> img))
    background: dark.$color_avatar-gradient-1
  .cp-avatar.color_avatar-gradient-1:has(> img)
      background: none
  .cp-avatar.color_avatar-gradient-2
    background: dark.$color_avatar-gradient-2
  .cp-avatar.color_avatar-gradient-3
    background: dark.$color_avatar-gradient-3
  .cp-avatar.color_avatar-gradient-4
    background: dark.$color_avatar-gradient-4
  .cp-avatar.color_avatar-gradient-5
    background: dark.$color_avatar-gradient-5
  .cp-avatar.color_avatar-gradient-6
    background: dark.$color_avatar-gradient-6
  .cp-avatar.color_avatar-gradient-7
    background: dark.$color_avatar-gradient-7
  .cp-avatar.color_avatar-gradient-8
    background: dark.$color_avatar-gradient-8
