@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_oath-tab
  display: flex
  flex-direction: column
  gap: $space_2x

  &_social-account-container:not(:last-child)
    border-bottom: solid $color_border-section

  &_social-account-container
    display: flex
    flex-direction: column
    gap: $space_1x
    padding-bottom: $space_2x
    width: px-to-rem(800px)
  
    .network-oauth-container
      align-items: center
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%

      &_network-info
        align-items: center
        display: flex
        flex-direction: row
        gap: $space_2x

        .cp-network-icon
          height: px-to-rem(75px)
          width: px-to-rem(75px)
          .cp-network-icon-svg
            height: px-to-rem(50px)
            width: px-to-rem(50px)
      
.dark
  .cp_component_oauth-tab
    &_social-account-container:not(:last-child)
      border-bottom: solid dark.$color_border-section