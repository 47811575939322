@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/_styles' as *

.cp_component_scorebreakdown
  &-loading
    display: flex
    justify-content: center
    align-items: center
    min-height: 250px 
  .header 
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    margin-bottom: $space_5x
    .score-box
      display: flex
      justify-content: center
      align-items: center
      width: px-to-rem(70px)
      height: px-to-rem(70px)
      background-color: $color_brand-blue-deep
      margin-bottom: px-to-rem(10px)
      border-radius: 4px
      &.grade-a
        background-color: $color_brand-blue-deep
      &.grade-b
        background-color: $color_brand-blue-ocean
      &.grade-c
        background-color: $color_color-live
      &.grade-d
        background-color: $color_icon-placeholder
      &.grade-f
        background-color: $color_icon-fail
      h2.score-grade
        text-transform: capitalize
        @include textStyles($color_text-invert, px-to-rem(44px), $font-weight-bolder)
        
    h2.score-title
      @include textStyles($color_text-primary, $space_3x, $font-weight-normal)
  .score-data
    display: flex
    > div
      flex: 1
    .breakdown
      margin-left: $space_5x
      padding-top: $space_1x
      .score-headers
        display: flex
        padding-bottom: 5px
        border-bottom: 1px solid $color_border-section
        margin-bottom: 10px
        .score-header
          flex: 1
          @include textStyles($color_text-secondary, 0.75rem, $font-weight-bolder)
        .score-header.descriptor
          flex: 2
      .component
        display: flex
        margin-bottom: px-to-rem(30px)
        > p
          flex: 1
        p.percentage 
          @include textStyles($color_text-secondary, px-to-rem(14px), $font-weight-normal)
        p.descriptor
          flex: 2
          @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-normal)
          span
            display: inline-block
            padding-top: px-to-rem(3px)
            @include textStyles($color_text-secondary, 0.75rem, $font-weight-normal)
        p.value 
          @include textStyles($color_text-secondary, px-to-rem(14px), $font-weight-normal)
        p.grade
          text-transform: capitalize
          @include textStyles($color_brand-blue-deep, px-to-rem(18px), $font-weight-bolder)
          &.grade-a
            color: $color_brand-blue-deep
          &.grade-b
            color: $color_brand-blue-ocean
          &.grade-c
            color: $color_color-live
          &.grade-d
            color: $color_icon-placeholder
          &.grade-f
            color: $color_icon-fail

    .histogram
      .info-link
        color: $color_text-inlinebutton
        display: block
        float: left
        font-size: $font-size-sm
        padding: 0.75rem px-to-rem(9px)
        position: relative
        text-decoration: none

        svg
          font-size: 1rem
          position: absolute
          right: -0.7rem
          top: 0.6rem

      .highcharts-title 
        fill: $color_text-secondary !important
        font-weight: normal !important
        font-style: italic !important
        font-size: px-to-rem(12px) !important
      .highcharts-background
        fill: transparent
      .highcharts-axis-line
        stroke: rgb(230, 230, 230)
      .highcharts-grid-line
        stroke: $color_divider-list
      .highcharts-xaxis-labels text
        fill: $color_text-secondary !important

.dark .cp_component_scorebreakdown
  .score-box
    &.grade-a
      background-color: dark.$color_button-primary
    &.grade-b
      background-color: dark.$color_button-pressed
    &.grade-c
      background-color: dark.$color_color-live
    &.grade-d
      background-color: dark.$color_color-support
    &.grade-f
      background-color: dark.$color_color-fail
    h2.score-grade
            @include textStyles(dark.$color_text-invert, px-to-rem(44px), $font-weight-bolder)
    
  h2.score-title
      @include textStyles(dark.$color_text-primary, $space_3x, $font-weight-normal)
  .score-data
    .breakdown
      .score-headers
        border-bottom: 1px solid dark.$color_border-section
        .score-header
          @include textStyles(dark.$color_text-secondary, 0.75rem, $font-weight-bolder)
      .component
        p.percentage 
          @include textStyles(dark.$color_text-secondary, px-to-rem(14px), $font-weight-normal)
        p.descriptor
          @include textStyles(dark.$color_text-primary, px-to-rem(14px), $font-weight-normal)
          span
            @include textStyles(dark.$color_text-secondary, 0.75rem, $font-weight-normal)
        p.value 
          @include textStyles(dark.$color_text-secondary, px-to-rem(14px), $font-weight-normal)
        p.grade
          @include textStyles($color_brand-blue-deep, px-to-rem(18px), $font-weight-bolder)
          &.grade-a
            color: dark.$color_button-primary
          &.grade-b
            color: dark.$color_button-pressed
          &.grade-c
            color: dark.$color_color-live
          &.grade-d
            color: dark.$color_color-support
          &.grade-f
            color: dark.$color_color-fail
    
    .histogram
      .info-link
        color: dark.$color_text-inlinebutton
      .highcharts-title 
        fill: dark.$color_text-secondary !important
      .highcharts-axis-line
        stroke: primitives.$color_gray-dark-100
      .highcharts-grid-line
        stroke: dark.$color_divider-list
        stroke-opacity: 50%
      .highcharts-xaxis-labels text
        fill: dark.$color_text-secondary !important
      .highcharts-point-hover
        fill: primitives.$color_blue-teal

