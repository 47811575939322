@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

div.cp_component_modal-comm-group div.MuiPaper-root
  // Title Overrides
  h2.MuiDialogTitle-root
    border-bottom: none

  // Main Modal Content
  div.MuiDialogContent-root
    display: flex
    gap: $space_2x

    h5
      margin: $space_2x 0
    
    h5:first-of-type
      margin: 0 0 $space_2x 0

    p.body_small
      padding: $space_1x 0 $space_2x 0

    p.input_description
      width: 16rem 

    .cp_component_modal-comm-group-form
      border: 1px solid $color_border-section
      flex: 0 0 calc(66% - $space_2x)
      height: 32rem
      overflow-y: auto
      padding: $space_2x $space_2x $space_4x $space_2x

      &-autocomplete
        width: 565px

        &-list div
          align-items: center
          border-bottom: 1px solid $color_brand-blue-teal
          display: flex
          justify-content: space-between
          padding: $space_2x 0

          p.cp-modal-comm-list-info
            align-items: center
            column-gap: $space_1x
            display: flex
            justify-content: center
            @include textStyles($color_text-primary, px-to-rem(18px), $font-weight-normal)
          button.MuiButtonBase-root svg
            font-size: $space_2x

      &-name-label
        align-items: center
        display: flex
        justify-content: space-between
        margin-bottom: $space_1x
        width: calc(100% - 2rem)

        .label_small-caps-bold
          color: $color_text-primary

    > section.cp_component_modal-comm-side-options
      flex: 0 0 33%

      > article
        border: 1px solid $color_border-section
        overflow-y: auto
        padding: $space_2x $space_2x $space_4x $space_2x

      // Campaign Section
      > article:first-of-type
        height: 14rem
        margin: 0 0 $space_2x 0

      // Notification Section
      > article:last-of-type
        height: 17rem

      aside
        align-items: flex-start
        display: flex
        justify-content: flex-start

        > svg.MuiSvgIcon-root
          color: $color_button-primary
          margin: 0 $space_1x 0 0
    
  // Notification Info Section
  div.cp_component_modal-comm-group-notifications-info
    background: $color_button-exit
    margin: 0 0 $space_2x 0
    padding: $space_2x

    p.sub-support-text
      @include textStyles($color_text-primary, $font-size-sm, $font-weight-normal)

  // Notifications Unordered List
  ul
    padding: $space_1x $space_2x

  li
    font-size: 0.75rem
    margin: $space_1x 0
    
    > svg.MuiSvgIcon-root
      color: $color_fill-icon-list
      height: $space_1x
      margin: 0 $space_1x 0 0
      width: $space_1x

  // General Inputs
  div.cp_component_input
    svg.MuiSvgIcon-root
        fill: $color_icon-exit
        height: 1rem
        width: 1rem

    button
      background-color: initial
      color: $color_text-inactive
      padding: 0

      &:hover, &:active
        background-color: initial
        color: $color_text-inlinebutton

  // Name Input
  div.cp_component_modal-comm-group-input
    width: calc(100% - $space_4x)

  .cp_component_modal-comm-group-campaign-info
    align-items: center
    border-bottom: 1px solid $color_brand-blue-teal
    column-gap: $space_1x
    display: flex
    padding: $space_2x 0

    p
      @include textStyles($color_text-primary, px-to-rem(18px), $font-weight-normal)

    button.MuiIconButton-root 
      margin-left: auto

  .cp_component_modal-comm-group-notifications-autocomplete
    div.MuiInputBase-root
      padding: 0 $space_1x

  .cp_component_modal-comm-group--notifications_pills
    display: flex
    flex-wrap: wrap
    gap: $space_1x
    margin: $space_1x 

  .cp_component_modal-comm-group-labels
    display: flex
    flex-wrap: wrap
    gap: $space_1x
    margin: $space_1x 0

    .cp-pill.pill-custom-color-contracting
      background-color: $color_fill-pill-2
      border: 1px solid $color_border-pill-2

    .cp-pill.pill-custom-color-opted-in
      background-color: $color_fill-pill-3
      border: 1px solid $color_border-pill-3

    .cp-pill.pill-custom-color-garnering
      background-color: $color_fill-pill-4
      border: 1px solid $color_border-pill-4


.dark div.cp_component_modal-comm-group div.MuiPaper-root
  
  // Title Overrides
  h2.MuiDialogTitle-root
    border-bottom: none

  // Main Modal Content
  div.MuiDialogContent-root
    > section.cp_component_modal-comm-group-form
      border: 1px solid dark.$color_border-section

    .cp_component_modal-comm-group-form
      &-autocomplete

        &-list div
          border-bottom: 1px solid dark.$color_brand-blue-teal

          p.cp-modal-comm-list-info
            color: dark.$color_text-primary

      &-name-label
        .label_small-caps-bold
          color: dark.$color_text-primary

    > section.cp_component_modal-comm-side-options
      > article
        border: 1px solid dark.$color_border-section

      aside
        > svg.MuiSvgIcon-root
          color: dark.$color_button-primary

  // Notification Info Section
  div.cp_component_modal-comm-group-notifications-info
    background: dark.$color_button-exit
    p.sub-support-text
      color: dark.$color_text-primary

  .cp_component_modal-comm-group-campaign-info
    p
      color: dark.$color_text-primary

  // Notification Unordered List
  li
    > svg.MuiSvgIcon-root
      color: dark.$color_fill-icon-list

  // General Inputs
  div.cp_component_input
    button
      color: dark.$color_text-inactive

      &:hover, &:active
        color: dark.$color_text-inlinebutton

  .cp_component_modal-comm-group-notifications-autocomplete
  .MuiDialog-container .MuiPaper-root .MuiDialogContent-root
    background-color: #3B3E50
    color: #DFE1E5

  .cp_component_modal-comm-group-labels
    .cp-pill.pill-custom-color-contracting
      background-color: dark.$color_fill-pill-2
      border: 1px solid dark.$color_border-pill-2

    .cp-pill.pill-custom-color-opted-in
      background-color: dark.$color_fill-pill-3
      border: 1px solid dark.$color_border-pill-3

    .cp-pill.pill-custom-color-garnering
      background-color: dark.$color_fill-pill-4
      border: 1px solid dark.$color_border-pill-4

.dark
  .MuiAutocomplete-popper 
    .cp_component_autocomplete_teammembers-list
      background-color: dark.$color_fill-loading
      li.MuiAutocomplete-option
        color: dark.$color_text-logo

