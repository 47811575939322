@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_list_page-list-add-account

  > button.MuiButtonBase-root
    margin-bottom: 1rem

  .cp_list_page-list-add-account
    &-search.cp_page_search
      padding: 0

      div.cp_page_search-container 
        height: calc(100vh - (258px + 3rem))
        position: relative
