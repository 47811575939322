@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-deliverable-assignreviewer
    .MuiPaper-root
        width: 30rem

.cp_deliverable-search-reviewergroup
    border-bottom: 1px solid var(--dividers-divider-section, $color_divider-section)
    &-container
        background-color: $color_fill-modal
        padding: 1rem
    &-body
        background-color: $color_fill-modal
        border-radius: 2px
        border: 1px solid var(--border-border-section, $color_fill-loading)
        display: flex
        flex-direction: column
        gap: 1rem
        padding: 1rem
        .reviewer-list
            height: 12rem
            overflow-y: auto
            &-selected
                background-color: $color_fill-dropdown-select
            li
                height: 4rem
                &:not(:last-child)
                    border-bottom: 1px solid $color_divider-list
        .search-bar
            padding-top: 0

.dark
    .cp_deliverable-search-reviewergroup
        border-bottom: 1px solid var(--dividers-divider-section, dark.$color_divider-section)
        &-container
            background-color: dark.$color_fill-modal
        &-body
            background-color: dark.$color_fill-modal
            border-color: dark.$color_border-section
        .reviewer-list
            li:not(:last-child)
                border-color: dark.$color_divider-list
            &-selected
                background-color: dark.$color_fill-dropdown-select
