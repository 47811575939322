@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_campaign_component
   
.cp_confirmation-modal_component

    .MuiDialog-container

        .MuiDialogContent-root
            padding-top: $space_2x

.dark
    .cp_confirmation-modal_component


