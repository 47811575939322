@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
section.cp_component_top-nav
  align-items: center
  background-color: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  justify-content: space-between
  margin-bottom: 1rem
  padding: 10px 20px
  
  .cp-component_top-nav-actions
    align-items: center
    display: flex
    justify-content: flex-end

    > *
      padding: 0 6px

  .cp-component_top-nav-name
    color: $color_text-secondary

  .dropdown-button
    .MuiButtonGroup-root
      max-height: 28px

    &:hover button
      background-color: $color_button-hovered

  .list-account
    .list-item-avatar
      margin-right: 6px
    div.MuiListItemButton-root.list-item-switch
      padding: 0


// Dark Theme Styles
.dark section.cp_component_top-nav
  background-color: dark.$color_fill-card
  border: 1px solid dark.$color_border-colorcard

  .cp-component_top-nav-actions
    > *
      padding: 0 6px

  .cp-component_top-nav-name
    color: dark.$color_text-secondary

  .dropdown-button:hover button
    background-color: dark.$color_button-hovered
