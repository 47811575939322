@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_select-media-input-step
  display: flex
  flex-direction: column
  width: 100%
  gap: $space_2x
