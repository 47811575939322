@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-customer-user

    &_vertical-description
        margin-bottom: $space_2x
        margin-top: $space_1x


    .MuiFormControlLabel-label
        font-size: $font-size-sm
        font-weight: $font-weight-normal

    &_input
        margin-bottom: $space_2x

    &-button_narrow
        max-height: $space_3x

    &-hidden
        visibility: hidden

    .MuiFormControlLabel-labelPlacementEnd
        min-width: px-to-rem(116px)
        margin-right: 0

    .cp_component_divider
        margin: px-to-rem(45px) 0 $space_3x 0


    &-details
        display: flex
        flex: 1
        flex-direction: column
        margin-top: $space_2x
        padding-right: $space_6x
        > .cp_component_input:not(:last-child), .MuiAutocomplete-root > .cp_component_input
            margin-bottom: $space_2x
        div.cp_component_input
            width: 100%
            button.MuiIconButton-root:hover, button.MuiIconButton-root
                background-color: transparent
            &.cp_component_modal-customer-user-details-input_password
                width: 92%
            &.cp_component_modal-customer-user-details-input_username
                width: 80%
                @media screen and (max-width: $md)
                    width: 100%
            .MuiFormHelperText-root
                color: inherit
                margin-top: $space_1x
        &-checkbox-email-label
            color: $color_text-secondary
            font-size: $font-size-sm
            margin-bottom: $space_1x
        &-label-username
            align-items: center
            display: flex
            justify-content: space-between
            margin-bottom: $space_1x
            width: 80%
            p.label_small-caps-semibold
                color: $color_text-primary
            p.label_small-caps-bold
                color: $color_text-primary
                margin-bottom: 0
                &.counter
                    color: $color_text-secondary
                    font-weight: 400
        &-label
            align-items: center
            display: flex
            justify-content: space-between
            margin-bottom: $space_1x
            p.label_small-caps-semibold
                color: $color_text-primary
            p.label_small-caps-bold
                color: $color_text-primary
                margin-bottom: 0
                &.counter
                    color: $color_text-secondary
                    font-weight: 400
        p.label_large-bold
            font-size: 0.9rem
            margin-bottom: $space_1x

            &-container
                margin-bottom: $space_2x
            &-spacer
                min-width: $space_1x
            p.label_small-caps-semibold
                color: inherit
                padding-bottom: $space_1x
                small
                    color: inherit
                    font-size: 0.75rem
                    font-weight: $font-weight-normal
                    margin-left: $space_1x
            .MuiInputAdornment-root .MuiIconButton-root
                background-color: transparent
                color: $color_icon-search
        &-pills
            > .MuiChip-root
                margin-bottom: $space_1x
                text-transform: uppercase

            > .MuiChip-root:not(:last-child)
                margin-right: $space_1x

    h4.headline_small
        font-size: $font-size-large

    .MuiDialog-container .MuiDialogTitle-root + .MuiDialogContent-root
        padding-top: 0

    &_photo
        &-badge
            margin-bottom: $space_2x
        &-uploader
            align-items: center
            display: flex
            gap: px-to-rem(20px)
            justify-items: start
            margin-bottom: $space_2x
        
        .image-upload .MuiAvatar-root
            background: $color_fill-social-background
            border: 2px solid $color_border-section
            cursor: pointer
            svg.MuiSvgIcon-root
              height: 2rem
              width: 2rem

        .cp-avatar.MuiAvatar-root
            font-size: xx-large
            height: px-to-rem(80px)
            width: px-to-rem(80px)
        .MuiBadge-badge
            right: $space_1x
            top: $space_1x
            button.cp_avatar-removal
                background-color: $color_surface-brand
                border-radius: 50%
                color: $color_surface-primary
                height: px-to-rem(22px)
                position: absolute
                top: 40px
                right: 0
                width: px-to-rem(22px)
                svg.MuiSvgIcon-root 
                    width: $space_2x
                    height: $space_2x

    &_row
        display: flex
        flex-direction: row
        gap: $space_3x
        width: 80%

        &-title
            font-size: $font-size-large
            font-weight: $font-weight-bold
            margin-bottom: $space_1x

.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_modal-campaign_verticals-list

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .cp_component_modal-customer-user
        &_panels
        &_photo .image-upload .MuiAvatar-root
            background: dark.$color_fill-social-background
            border: 2px solid dark.$color_border-section
        &_photo .MuiBadge-badge button.cp_avatar-removal
            background-color: $color_brand-blue-teal

        .MuiAutocomplete-inputRoot

            .MuiInputAdornment-root
                color: dark.$color_border-input

        &-details
            &-checkbox-email-label
            color: dark.$color_text-secondary
            &-label, &-label-username
                p.label_small-caps-semibold
                    color: dark.$color_text-primary
                p.label_small-caps-bold
                    color: dark.$color_text-primary
                    &.counter
                        color: dark.$color_text-secondary

            div.cp_component_input .MuiInputAdornment-root .MuiSvgIcon-root
                color: dark.$color_input-inactive
