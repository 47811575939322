@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/styles' as *

.cp_component_deliverable-container
    align-items: center
    display: flex
    flex-direction: column
    .cp_component_deliverable-status
        align-items: center
        border-radius: 50%
        display: flex
        &-label
            color: $color_text-secondary
            font-family: "Open Sans"
            font-size: 0.75rem
            font-weight: 700
            margin-left: $space_1x
            
        &.cp_component_deliverable-status_md
            width: $space_1x
            height: $space_1x

        &.cp_component_deliverable-status_sm
            width: 0.25rem
            height: 0.25rem

        &.cp_component_deliverable-status_pending
            background-color: $color_color-support
        &.cp_component_deliverable-status_pending-feedback
            background-color: $color_color-support
        &.cp_component_deliverable-status_pending-revision
            background-color: $color_color-support
        &.cp_component_deliverable-status_awaiting-content
            background-color: $color_color-error
        &.cp_component_deliverable-status_draft
            background-color: $color_color-error
        &.cp_component_deliverable-status_live
            background-color: $color_color-live
        &.cp_component_deliverable-status_uploaded
            background-color: $color_color-warning
        &.cp_component_deliverable-status_finalized
            background-color: $color_color-success

    .cp_component_deliverable-post
        align-items: center
        &-tracking
            color: $color_text-inactive
            font-family: "Open Sans"
            font-size: 0.75rem
            font-weight: 600
            // margin-left: $space_1x

.dark
    .cp_component_deliverable-status
        &-label
            color: dark.$color_text-secondary

        &.cp_component_deliverable-status_pending
            background-color: dark.$color_color-support
        &.cp_component_deliverable-status_awaiting-content
            background-color: dark.$color_color-error
        &.cp_component_deliverable-status_live
            background-color: dark.$color_color-live
        &.cp_component_deliverable-status_uploaded
            background-color: dark.$color_color-warning
        &.cp_component_deliverable-status_finalized
            background-color: dark.$color_color-success

