@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

div.cp_component_mentioned-post-card
    align-items: center
    background-color: $color_fill-background
    border: 1px solid $color_border-modal
    display: flex
    flex-direction: column
    padding: $space_2x
    width: 100%

    &:not(:last-child)
        margin-bottom: $space_3x

    .cp_component_mentioned-post-card-header
        align-items: center
        display: flex
        flex-grow: 1
        margin-bottom: $space_2x
        width: 100%
        .cp-network-icon
            margin-right: $space_2x
            height: px-to-rem(28px)
            width: px-to-rem(28px)

        p
            font-size: $font-size-sm
            font-weight: $font-weight-bolder

        .cp_component_mentioned-post-date-posted
            text-transform: uppercase

        .cp_component_mentioned-post-network-link
            align-items: center
            color: $color_text-inlinebutton
            display: flex
            font-family: $font-family-primary
            margin-left: auto
            text-transform: uppercase

            &:hover
                cursor: pointer

            .MuiSvgIcon-root
                margin-left: $space_1x

    .MuiDivider-root
        width: 100%

    .cp_component_mentioned-post-card-body
        display: flex
        margin-top: $space_3x
        width: 100%

        .cp_component_mentioned-post-avatar
            border: $color_border-image
            height: px-to-rem(100px)
            margin-right: $space_2x
            width: px-to-rem(100px)
        p
            word-wrap: break-word

.dark
    div.cp_component_mentioned-post-card
        background-color: dark.$color_fill-overlay-modal
        border-color: dark.$color_border-modal
        .cp_component_mentioned-post-card-header
            .cp_component_mentioned-post-date-posted

            .cp_component_mentioned-post-network-link
                color: dark.$color_text-inlinebutton

        .MuiDivider-root
            border-color: dark.$color_divider-section
        .cp_component_mentioned-post-card-body