@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_trait_component
    border: 1px solid $color_border-section
    border-radius: $content-border-radius
    margin: $space_2x 0 $space_2x 0
    padding: $space_2x
    position: relative

    &-equator
        bottom: 0
        border-left: 2px dashed $color_surface-brand
        left: 50%
        position: absolute
        top: 0
    
    &-header
        display: flex
        justify-content: space-between
        margin-bottom: $space_1x

        &-description
            font-weight: $font-weight-bold
            font-size: $font-size-normal
            text-transform: capitalize
        &-score
            font-size: $font-size-normal
    
    &-progress
        display: flex

        &-left
            flex: 1

            .MuiLinearProgress-root
                background-color: $color_progress-bar-0
                border-top-left-radius: 5px
                border-bottom-left-radius: 5px
                height: 0.75rem
                
                .MuiLinearProgress-bar
                    background-color: $color_progress-bar-1
                    border-top-left-radius: 5px
                    border-bottom-left-radius: 5px
                    bottom: 2px !important
                    top: 2px !important

        &-right
            flex: 1

            .MuiLinearProgress-root
                background-color: $color_progress-bar-0
                border-top-right-radius: 5px
                border-bottom-right-radius: 5px
                height: 0.75rem
                
                .MuiLinearProgress-bar
                    background-color: $color_progress-bar-1
                    border-top-right-radius: 5px
                    border-bottom-right-radius: 5px
                    bottom: 2px !important
                    top: 2px !important

    
    &-labels
        display: flex
        justify-content: space-between
        margin-top: $space_1x

        p
            font-size: $font-size-normal

    &-definition p
        color: $color_brand-blue-deep
        font-size: 0.75rem
        width: 50%
    
.dark
    .cp_trait_component
        border: 1px solid dark.$color_border-section

        &-equator
            border-left: 2px dashed dark.$color_surface-brand
        
        &-progress

            &-left
                .MuiLinearProgress-root
                    background-color: dark.$color_progress-bar-0
                    
                    .MuiLinearProgress-bar
                        background-color: dark.$color_progress-bar-3

            &-right

                .MuiLinearProgress-root
                    background-color: dark.$color_progress-bar-0
                    
                    .MuiLinearProgress-bar
                        background-color: dark.$color_progress-bar-3

        &-definition p
            color: dark.$color_surface-brand
