@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_dropdown
  button
    background: transparent
    border: none
    color: transparent
    cursor: pointer
    padding: 0

  .MuiButtonGroup-root
    box-shadow: none
    height: 28px

  .MuiButtonGroup-root .dropdown_icon.MuiButton-root.MuiButtonGroup-lastButton
    border-left: none
    padding-left: 0
    padding-right: 0
    min-width: 30px

  ~ .MuiTooltip-popper .MuiTooltip-tooltip
    background-color: $color_fill-dropdown
    border-radius: 6px
    box-shadow: $box-shadow-dropdown
    color: $color_text-primary
    min-width: 194px
    padding: 0.25rem

    .MuiTooltip-arrow
      color: $color_fill-dropdown

    .cp-switch.MuiSwitch-root.cp-switch_with-icon span svg
      fill: $color_fill-icon-list

    .MuiListItemButton-root
      padding: 5px
    
      .MuiListItemIcon-root
        margin-right: 6px
        min-width: unset

        .list-item-icon.MuiSvgIcon-root
          color: $color_fill-icon-list
          font-size: 16px

      .MuiListItemText-root
        margin-bottom: 0
        margin-top: 0

        .MuiTypography-root
          font-size: 14px
          font-weight: 400
          font-family: 'Open Sans'
          text-transform: capitalize

      .MuiDivider-root
        border-bottom-color: $color_divider-dropdown
        margin: 0


.dark
  div.cp_component_dropdown 
    .MuiAvatar-root
      border: 1px solid dark.$color_border-image-user

    ~ .MuiTooltip-popper .MuiTooltip-tooltip
      background-color: dark.$color_fill-dropdown
      color: dark.$color_text-primary

      .MuiTooltip-arrow
        color: dark.$color_fill-dropdown

      .cp-switch.MuiSwitch-root.cp-switch_with-icon span svg
        fill: $color_icon-dark-theme

      .MuiListItemIcon-root
        .list-item-icon.MuiSvgIcon-root
          color: dark.$color_fill-icon-list

      .MuiDivider-root
        border-bottom-color: dark.$color_divider-dropdown
        margin: 0
