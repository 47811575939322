@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_customer-table.cp_component-data-grid
  height: 80%

  .filter-icon
    color: $color_border-secondarybutton

  .email-label
    font-size: px-to-rem(12px)
    color: $color_text-secondary
  .status-stamp
    align-items: center
    display: flex
    gap: 0.25rem
    p
      font-size: px-to-rem(12px)
      color: $color_text-secondary
.cp_customer-table_component-no-results
    align-items: center
    color: $color_text-inactive
    display: flex
    height: 100%
    justify-content: center

.dark
  .cp_component_customer-table.cp_component-data-grid
    .filter-icon
      color: dark.$color_border-secondarybutton
    .email-label
      color: dark.$color_text-secondary
    .status-stamp
      p
        color: dark.$color_text-secondary
  .cp_customer-table_component-no-results
        color: dark.$color_text-inactive