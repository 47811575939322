@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_public-campaign-report
  background-color: $color_fill-background
  height: 100vh
  position: relative
  width: 100vw

  padding: $space_3x

  &-container
    padding: $space_2x
    height: 100%
    overflow-y: auto

// Dark Theme Styles
.dark .cp_public-campaign-report
  background-color: dark.$color_surface-primary
  &-container
    