@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_profile_component-list-tabs-account-header
  display: flex
  flex-direction: column
  width: 100%
  padding-top: $space_2x
  &-main
    display: flex
    &-container
      display: flex
      width: px-to-rem(550px)
      .MuiBadge-root
        height: px-to-rem(110px)
        width: px-to-rem(110px)
        margin: $space_2x
      &-info
        display: flex
        flex-direction: column
        padding: $space_2x
        a
          text-decoration: none
          margin-bottom: $space_1x
          h2
            font-size: px-to-rem(24px)
            font-weight: $font-weight-bold
        h4
          font-size: px-to-rem(14px)
        h5
          font-size: px-to-rem(10px)
          font-weight: 700
          margin-bottom: $space_2x
        &-scores
          display: flex
          gap: $space_3x
          div
            p
              color: $color_text_primary
              font-size: 0.75rem
              font-weight: 700
              height: px-to-rem(18px)
              margin-bottom: px-to-rem(4px)
            div.cp-pill.MuiChip-root
              height: 1.5rem
          .cp_component_social-avatar-e-badge-tool-tip.MuiTooltip-tooltip
            width: 10rem

          div.cp_component_public-list-overview-table-e-verfied-badge             
            background-image: url("../../../asset/img/e_badge.svg")
            background-position: right
            background-repeat: no-repeat
            background-size: contain
            cursor: pointer
            min-height: 1.5rem
            width: px-to-rem(75px)
        &-audience-type-notes
          color: $color_text_notes
          font-weight: $font-weight-normal
          font-size: px-to-rem(10px)
          letter-spacing: 0%
          margin: 0.25rem 0
    &-buttons
      display: flex
      height: 35px
      gap: $space_1x
      width: px-to-rem(80px)
      margin-left: auto
    .feedback-button
    .approve-button
      &.approved
        background-color: $color_color-success
        
  &-sub
    flex-grow: 1
    display: flex
    flex-direction: column
    padding: $space_2x $space_3x 0 $space_3x
    &-bio-title
      color: $color-text_primary
      font-size: 0.75rem
      font-weight: 700

    &-bio
      color: $color-text_secondary
      display: inline-block
      font-size: px-to-rem(12px)
      max-width: 100%
      margin-bottom: $space_1x
      white-space: normal
      padding-top: $space_1x
      margin-right: $space_2x


.dark
  .cp_profile_component-list-tabs-account-header
    &-main
      &-container
        &-info
          &-scores
            div
              p
                color: dark.$color_text_primary
            div.cp_component_public-list-overview-table-e-verfied-badge           
              background-image: url("../../../asset/img/e_badge_dark.svg")
          &-audience-type-notes
            color: dark.$color_text_notes
      &-more-info
        &-bio
          color: dark.$color-text_secondary
        .approve-button
        &.approved
          background-color: dark.$color_color-success
          margin-left: auto
