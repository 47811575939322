@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use '../../../style/_styles' as *


div.cp_component_navigation-bar-campaigns-container
    height: 100%
    overflow-y: scroll
    padding: 0
    display: flex
    flex-direction: column
    &::-webkit-scrollbar
        display: none

    .cp_component_loading_indicator
        align-self: center
        justify-self: center
        margin: $space_4x

div.cp_component_navigation-bar-campaign-avatar-container
    display: flex
    box-sizing: border-box
    height: px-to-rem(48px)
    padding: 0.75rem $space_3x 0.75rem $space_2x
    cursor: pointer

    .cp_component-context-menu-buttton
        margin-left: auto
        button.MuiButtonBase-root.MuiIconButton-root
            padding: 0
    
    .cp_component_navigation-bar-campaigns-avatar
        outline: 1px solid $color_border-image
        font-family: $font-family-primary
        font-size: $font-size-normal
        font-weight: $font-weight-normal

        &.MuiAvatar-root
            border: none


    .cp_component_navigation-bar-campaign-name
        color: $color_text-primary
        font-family: $font-family-primary
        font-size: $font-size-normal
        font-weight: $font-weight-normal
        margin-left: $space_1x
        overflow-x: hidden
        text-overflow: ellipsis
        white-space: nowrap

    &:hover
        background-color: $color_fill-list-hovered

.dark
    div.cp_component_navigation-bar-campaign-avatar-container
        .cp_component_navigation-bar-campaign-name
            color: dark.$color_text-primary
        &:hover
            background-color: dark.$color_fill-list-hovered
        .cp_component_navigation-bar-campaigns-avatar
            outline: 1px solid dark.$color_border-image

    .cp_component_navigation-bar_search-icon
        color: dark.$color_icon-search
    .cp_component_navigation-bar_tune-icon
        color: dark.$color_fill-icon-list
