@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_relevant_posts_preview.cp-relevant_post-social-tracker
  div.cp_relevant_posts_preview-post-image
    align-items: center
    display: flex
    flex-direction: column
    height: calc(px-to-rem(350px) + $space_4x)
    justify-content: space-between
    padding: $space_3x
    position: relative

    img
      display: block
      height: px-to-rem(350px)
      z-index: 1

    // Backdrop
    > div
      background-position: 50% 50%
      background-size: cover
      filter: blur(3rem)
      height: 100%
      left: 0
      opacity: 0.5
      position: absolute
      top: 0
      width: 100%
      z-index: 0

  p.post-content
    margin-top: $space_2x
