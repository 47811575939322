@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_feedback-panel
  align-items: center
  background-color: $color_fill-nav
  border: 1px solid $color_border-modal
  display: flex
  flex-direction: column
  height: 100vh
  width: primitives.$width_public-list-right-panel-expanded

  &-username-container
    display: flex
    justify-content: space-between
    padding: $space_2x $space_2x $space_2x $space_4x
    width: 100%

  &-header
    align-items: center
    background-color: $color_fill-table-header
    display: flex
    width: 100%
    padding: $space_1x $space_1x $space_1x $space_3x
    gap: $space_2x

  &-feedback
    align-items: center
    display: flex
    flex-direction: column
    flex-grow: 1
    gap: $space_2x
    overflow-y: auto
    padding: $space_2x
    width: 100%

  &-footer
    border-top: 1px solid $color_border-section
    display: flex
    flex-direction: column
    gap: $space_2x
    padding: $space_2x
    width: 100%
    
    .cp_component_input
      width: 100%
      .MuiInputBase-root
        align-items: flex-start
        display: flex
        height: px-to-rem(80px)
        padding: 0
    .MuiButtonBase-root
      width: px-to-rem(122px)
      

.dark
  .cp_component_feedback-panel
    background-color: dark.$color_fill-nav
    border-color: dark.$color_border-modal

    &-header
      background-color: dark.$color_fill-table-header

    &-footer
      border-color: $color_border-section
      background-color: dark.$color_fill-table-header