@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
div.cp_component-modal-copy-link
    &-body
        align-items: center
        display: flex
        margin: $space_3x 0

        .cp_component_input
            width: 60%
            margin-right: $space_2x
        .MuiButton-root
            width: px-to-rem(160px)
            height: px-to-rem(36px)
            p
                color: $color_button-text
                font-family: $font-family-primary
                font-size: $font-size-sm
                font-weight: $font-weight-bolder
                margin-left: 0.25rem
                text-transform: uppercase
