@use "../style/_primitives" as primitives
@use "../style/theme_dark" as dark
@use "../style/theme_light" as *
@use "../style/styles" as *

div.cp_guard_scopes
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  div.cp_guard_scopes-container
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative
