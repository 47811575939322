@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/_styles' as *

.MuiDialog-container

  .MuiDialogTitle-root
    border-bottom: $color_divider-section 1px solid
    padding: 20px

    + .MuiDialogContent-root
      color: $color_text-secondary

    .MuiIconButton-root
      align-self: flex-start

    .MuiTypography-root.cp_component_modal-title
      color: $color_text-primary
      font-family: $font-family-primary
      line-height: normal
    
    .MuiTypography-root.cp_component_modal-subtitle
      color: $color_text-secondary
      font-family: $font-family-primary
      font-size: px-to-rem(14px)
  
  .MuiDialog-paperWidthSm
    max-width: 300px

    .MuiDialogTitle-root
      padding: 20px 16px

    .MuiDialogContent-root
      padding: 24px 16px 32px 16px
  
    .MuiDialogActions-root
      justify-content: center

  .MuiDialog-paperWidthMd
    max-width: 500px

  .MuiDialog-paperWidthLg
    max-width: 800px

  .MuiDialog-paperWidthXl
    max-width: 1140px
  
  .MuiDialogActions-root
    background-color: $color_fill-footer
    border-top: $color_border-footer 1px solid
    justify-content: left
    padding: 16px

  .dialog-actions-container
    display: flex
    flex-direction: row
    justify-content: space-between

    #modal-cta-buittons
      display: flex
      flex-direction: row
      gap: $space_1x

.dark
  .MuiDialog-container
    color: dark.$color_text-secondary
    .MuiPaper-root
      background-color: dark.$color_fill-modal
      .MuiDialogTitle-root
        background-color: dark.$color_fill-modal
        border-bottom: dark.$color_divider-section 1px solid

        .MuiTypography-root.cp_component_modal-title
          color: dark.$color_text-primary

        .MuiTypography-root.cp_component_modal-subtitle
          color: dark.$color_text-secondary

      .MuiDialogContent-root
        background-color: dark.$color_fill-modal
        color: dark.$color_text-secondary
      
      .MuiDialogActions-root
        background-color: dark.$color_fill-footer
        border-top: dark.$color_border-footer 1px solid
        justify-content: left

      .dialog-actions-container
        display: flex
        flex-direction: row
        justify-content: space-between

        #modal-cta-buittons
          display: flex
          flex-direction: row
          gap: $space_1x
