@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_modal_new_tracker-advanced-modal
  div.MuiPaper-root
    height: 800px
    max-height: 100%
    overflow: auto

    button.cp_component_options-conten-link.MuiButton-textPrimary
      @include textStyles($color_text-inlinebutton, px-to-rem(12px), $font-weight-bold)
      margin-bottom: $space_4x
      margin-top: px-to-rem(18px)

span.auto-complete-network-icon
  margin-right: $space_1x
  scale: 0.6

.cp_modal_new_tracker
  div.MuiPaper-root
    height: 800px
    max-height: 80%
    overflow: auto

  &-wrapper
    padding: $space_4x
    > div
      margin-bottom: $space_4x
      h5
        @include textStyles($color_text-primary, px-to-rem(12px), $font-weight-bolder)

      .has-advanced-link
        align-items: center
        display: flex
        justify-content: space-between
        margin-bottom: px-to-rem(10px)
        button.cp_advanced_link.MuiButton-text
          @include textStyles($color_text-inactive, px-to-rem(12px), $font-weight-bolder)
          margin-left: 0
          margin-right: 0
          padding: 0

    div.cp_component_input.social-tracker-name
      width: 480px

    div.cp_modal_new_tracker-sponsored
      margin-bottom: 0

    .cp_modal_new_tracker-account_tags-pills
      .MuiChip-label.MuiChip-labelMedium
        position: relative
        top: 0
        left: -10px

      .cp_modal_new_tracker-account_tag-icon
        position: relative
        top: 0
        left: -10px
        scale: 0.6

    .cp_modal_new_tracker-keywords-keywords-pills,
    .cp_modal_new_tracker-image_tags-pills,
    .cp_modal_new_tracker-account_tags-pills
      display: flex
      gap: $space_1x
      padding-top: $space_1x

  &-networks
    h5
      @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bolder)
      margin-bottom: px-to-rem(12px)
    .network-buttons
      button:first-of-type
        padding-left: 0

  &-keywords, &-image_tags
    width: 440px
    div.cp_component_input
      width: 440px

  &-accounts
    h5
      margin-bottom: px-to-rem(10px)
    .cp_component_input
      width: 380px

  div.social-tracker-engagement-rate
    margin-bottom: px-to-rem(20px)
    width: 220px

    .MuiInputAdornment-root
      p
        font-size: 0.75rem
        padding: $space_1x

  &-sponsored
    align-items: center
    display: flex
    span.cp-switch
      margin-left: px-to-rem(-12px)
    p
      @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bold)


.dark
  .cp_modal_new_tracker-wrapper
    > div
      h5
        color: dark.$color_text-primary
