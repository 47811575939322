@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_profile_component-list-tabs-container
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    margin-bottom: $space_2x
    padding: $space_2x $space_1x
    position: relative

    &_loading

    .cp_profile_component-list-tabs-content
        flex: 1 1
        height: 100%
        overflow-y: auto

.cp_profile_component-list-tabs-controls
    display: flex
    gap: $space_1x

.cp_profile_component-list-tabs-content-loading
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    padding: $space_2x $space_1x
    margin-top: $space_1x

.dark
    .cp_profile_component-list-tabs-container
        background: dark.$color_fill-card
        border-radius: $content-border-radius
        border: 1px solid dark.$color_border-colorcard

    .cp_profile_component-list-tabs-content-loading
        background: dark.$color_fill-card
        border-color: dark.$color_border-colorcard
