@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_demographic_score
  height: 100%
  left: 0
  position: absolute
  top: 0
  width: 100%
  z-index: 2

  .cp_demographic_score
    &-wrapper
      max-height: 100%
      overflow-y: auto
      padding: $space_2x $space_4x

      hr.cp_component_divider
        margin-bottom: $space_3x

    &-back_button
      @include textStyles($color_text-secondary, px-to-rem(12px), 600)
      margin-bottom: $space_1x
      padding: 0
      text-transform: uppercase

    &-heading
      @include textStyles($color_text-primary, px-to-rem(20px), 600)

    &-additional-info
      @include textStyles($color_text-body, px-to-rem(12px), 400)
      margin-bottom: $space-3x
      padding-left: $space-1x

      span.cp_warning-message 
        display: block
        font-size: px-to-rem(10px)
        font-weight: $font-weight-bolder
        padding-top: $space_1x

    &-inputs

      .cp_component_modal-create-list_demographic-wrapper
        display: flex
        gap: $space_2x

        > div
          background-color:$color_fill-background
          border-radius: $content-border-radius
          padding: $space_2x

        legend.MuiFormLabel-root.section-label
          @include textStyles($color_text-primary, px-to-rem(14px), 700)
          text-transform: uppercase
    
        .cp_component_modal-create-list_gender
          label.MuiFormControlLabel-root
            span.MuiTypography-root
              @include textStyles($color_text-radiobutton, px-to-rem(12px), 400)

        .cp_component_modal-create-list_age
          > fieldset > legend.MuiFormLabel-root
            margin-bottom: $space_1x
           
          &-fields
            column-gap: px-to-rem(38px)
            display: flex
            flex-direction: column
            flex-wrap: wrap
            flex: 1 1
            > div
              flex-basis: calc(50% - px-to-rem(19px))
              flex-grow: 1
              flex-shrink: 0
              min-width: 160px

            > div:first-child
                margin-bottom: $space_2x
            
            @media (min-width: $lg)
              flex-direction: row
              flex-wrap: nowrap
              > div:first-child
                margin-bottom: 0

            legend.MuiFormLabel-root
              @include textStyles($color_text-primary, px-to-rem(12px), 600)
              margin-bottom: px-to-rem(10px)

      > div
        margin-bottom: $space_2x

      fieldset 
        legend.MuiFormLabel-root
          @include textStyles($color_text-primary, px-to-rem(14px), 700)
          text-transform: uppercase
        label span.MuiTypography-root
          @include textStyles($color_text-radiobutton, px-to-rem(12px), 400)

      &-income
        fieldset
          div.MuiFormGroup-root
            align-items: flex-start
            display: flex
            flex-wrap: wrap
            max-height: 160px

            label.cp_list-item
              margin-bottom: 10px
              margin-right: 10px 
              width: calc(33.333% - 10px) 

              &:nth-child(3n) 
                margin-right: 0

      &-location,
      &-occupations,
      &-affinities
        div.MuiAutocomplete-root
          p.label_small-caps-bold
            @include textStyles($color_text-primary, px-to-rem(14px), 700)
          div.MuiOutlinedInput-root
            padding: 0


.dark
  .cp_demographic_score

    .cp_demographic_score

      &-wrapper
        span.MuiCheckbox-root
          svg.MuiSvgIcon-root
            fill: dark.$color_icon-pill


      &-back_button
        color: dark.$color_text-secondary

      &-heading
        color: dark.$color_text-primary

      &-additional-info
        color: dark.$color_text-body

      &-inputs
        .cp_component_modal-create-list_demographic-wrapper
          > div
            background-color: dark.$color_fill-dropdown-select-secondary

          legend.MuiFormLabel-root.section-label
            color: dark.$color_text-primary

          .cp_component_modal-create-list_gender
            label.MuiFormControlLabel-root
              span.MuiTypography-root
                color: dark.$color_text-radiobutton

          .cp_component_modal-create-list_age
            &-fields
              legend.MuiFormLabel-root
                color: dark.$color_text-primary

        fieldset
          legend.MuiFormLabel-root
            color: dark.$color_text-primary
          label span.MuiTypography-root
            color: dark.$color_text-radiobutton

        &-location,
        &-occupations,
        &-affinities
          div.MuiAutocomplete-root
            p.label_small-caps-bold
              color: dark.$color_text-primary



