@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_customer-user-details_container
  background: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  flex-direction: column
  flex: 1
  height: 60%
  gap: $space_1x
  overflow: auto
  padding: $space_3x
  position: relative

  .cp_customer-user-table-container
    align-items: center
    display: flex
    gap: $space_2x
    .cp_customer-user-table-search-bar.cp_component_search-bar-input.cp_component_input
      margin: 0 
      padding: $space_1x 0
      width: 40%

    .cp_customer-user-table-add-campaign-button
      height: px-to-rem(30px)
      width: px-to-rem(162px)
      margin-left: auto
      .cp_customer-user-table_add-campaign-button-label
        align-items: center
        display: flex
        gap: 0.25rem
        .MuiSvgIcon-root
        p
          color: $color_button-text
          font-size: px-to-rem(10px)
          font-weight: 700

.dark
  .cp_customer-user-details_container
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard
    .cp_customer-user-table-container
      .cp_customer-user-table_add-campaign-button-label
        .MuiSvgIcon-root
        p
          color: dark.$color_button-text
