@use "../../style/_primitives" as primitives
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp-alert
    &.MuiAlert-root
        align-items: center   
        border: 1px   
        border-radius: 10px         
        display: flex
        height: px-to-rem(45px)
        &.success
            background-color: $color-fill-banner-success
            border-color: $color-border-banner

        &.warning
            background-color: $color-fill-banner-warn
            border-color: $color_border-pill-4

        .MuiAlert-icon .icon-mapper
            align-items: center
            color: $color_text-logo
            display: flex           
            .alert-close-icon
                color: $color_icon-exit
        .MuiAlert-message
            align-items: center
            display: flex
            flex-grow: 1
            justify-content: space-between
            .alert-links
                display: flex
                gap: 1rem
            .alert-message, .alert-link-primary, .alert-link-secondary
                color: $color_text-primary
                font-weight: 400
                text-decoration-color: $color_text-primary
.dark
    .cp-alert.MuiAlert-root
        &.success
            background-color: dark.$color-fill-banner-success
            border-color: dark.$color-border-banner

        &.warning
            background-color: dark.$color-fill-banner-warn
            border-color: dark.$color_border-pill-4

        .MuiAlert-icon .icon-mapper
            .alert-close-icon
                color: dark.$color_icon-exit
