@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use '../../../../style/_styles' as *


div.cp_component_navigation-bar-comm-sliding-panel
    .cp_component_navigation-bar-comms-container
        display: flex
        flex-direction: column
        flex-grow: 1
        overflow-y: scroll
        padding: 0
        &::-webkit-scrollbar
            display: none

        .cp_component_loading_indicator
            align-self: center
            justify-self: center
            margin: $space_4x

        .cp_component_navigation-bar-comm-container
            align-items: center
            display: flex
            box-sizing: border-box
            height: px-to-rem(48px)
            padding: 0.75rem $space_3x 0.75rem $space_2x
            &:hover
                cursor: pointer
                background-color: $color_fill-list-hovered

            .comm-avatar-badge
                > .MuiBadge-badge
                    background-color: $color_color-error
                .cp_component_navigation-bar-comm-avatar
                    font-family: $font-family-primary
                    font-size: $font-size-normal
                    font-weight: $font-weight-normal

                    &.MuiAvatar-root
                        border: none

            .MuiSvgIcon-root
                fill: $color_icon-pill
                height: px-to-rem(18px)
                width: px-to-rem(18px)
                margin-left: 0.3rem

            .cp_component_navigation-bar-comm-name
                color: $color_text-primary
                font-family: $font-family-primary
                font-size: $font-size-normal
                font-weight: $font-weight-normal
                margin-left: $space_1x
                overflow-x: hidden
                text-overflow: ellipsis
                white-space: nowrap
            
            
                
    .cp_component_navigation-bar_campaigns-input
        padding-top: $space_1x



.dark
    div.cp_component_navigation-bar-comm-sliding-panel
        .cp_component_navigation-bar-comm-container
            .comm-avatar-badge
                > .MuiBadge-badge
                    background-color: dark.$color_color-error
            .cp_component_navigation-bar-comm-name
                color: dark.$color_text-primary
            &:hover
                background-color: dark.$color_fill-list-hovered
            .MuiSvgIcon-root
                fill: dark.$color_icon-pill
