@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

// Input adornment button
.cp_component_modal-create-list-autocomplete-base.MuiAutocomplete-root
  div.cp_component_input .MuiAutocomplete-popupIndicator
    background-color: transparent

// Popper styles
.MuiAutocomplete-popper 
  .cp_component_modal-create-list-autocomplete
    background-color: $color_fill-dropdown
    li.MuiAutocomplete-option
      color: $color_text-logo
      font-size: 0.75rem

.dark
  // Popper styles
  .MuiAutocomplete-popper 
    .cp_component_modal-create-list-autocomplete
      background-color: dark.$color_fill-dropdown
      li.MuiAutocomplete-option
        color: dark.$color_text-logo
