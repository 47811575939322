@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/primitives" as primitives
@use "../../style/styles" as *

// Light Theme Styles 
div.cp_page_team
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: cover
  height: 100vh

  // Inner content styles in order to apply blur to background
  > div
    align-items: center
    backdrop-filter: blur(2rem)
    display: flex
    height: 100%
    justify-content: center
    width: 100%

  section.card
    background-color: $color_fill-card
    border-radius: 2rem
    box-shadow: 0 px-to-rem(4px) px-to-rem(4px) 0 primitives.$color_black-a50
    padding: 6rem 5rem
    width: 40rem

    // Logo
    > article
      background-image: url("../../asset/img/logo_radius_text_light.svg")
      background-repeat: no-repeat
      height: px-to-rem(62px)
      margin: 0 auto
      width: px-to-rem(185px)

    // Input Container
    > aside
      align-items: center
      border-bottom: 1px solid $color_border-underline
      display: flex
      justify-content: space-around

      &.email-container
        display: block

        .MuiInputBase-input
          padding: 0.6rem 0

      // Input
      div.cp_component_input
        width: 100%

      .MuiInputBase-root
        width: 100%
        padding: 0

        fieldset
          border: none

      // Input URL preface
      p
        width: 50%

    // Confirm Button
    > button.primary
      background: $color_button-primary
      border-radius: 1.5rem
      border: none
      color: dark.$color_text-primary // Intentionally use darkmode style here
      cursor: pointer
      font-size: 1.2rem
      font-weight: 700
      height: 3rem
      margin: 5rem 0 3rem 0
      text-transform: uppercase
      width: 100%

      &.Mui-disabled
        background: $color_button-disabled
        color: dark.$color_text-inactive // Intentionally use darkmode style here

      svg
        color: dark.$color_text-inactive // Intentionally use darkmode style here

    // Find Team Link
    > a,
    > a:link,
    > a:active,
    > a:visited
        color: $color_text-inactive
        cursor: pointer
        display: block
        font-size: 0.8rem
        margin: 0 0 3rem 0
        text-align: center

        &:hover
          color: $color_button-hovered

    // Typography
    > h1
      margin: 4rem 0 0 0

    > p
      margin: 1rem 0 4rem 0

    > h1, > p
      text-align: center

    > span // Error text
      color: $color_color-error
      line-height: 2rem


// Dark Theme Styles
.dark div.cp_page_team
  background-image: url("../../asset/img/background_start_pages_dark.svg")

  section.card
    background-color: dark.$color_fill-card

    // Logo
    > article
      background-image: url("../../asset/img/logo_radius_text_dark.svg")

    // Input Container
    > aside
      border-bottom: 1px solid dark.$color_border-underline

    // Confirm Button
    > button.primary
      background: dark.$color_button-primary
      color: $color_text-primary // Intentionally use lightmode style here

      &.Mui-disabled
        background: dark.$color_button-disabled
        color: $color_text-inactive // Intentionally use lightmode style here

      svg
        color: $color_text-inactive // Intentionally use lightmode style here
    
    // Find Team Link
    > a,
    > a:link,
    > a:active,
    > a:visited
        color: dark.$color_text-inactive

        &:hover
          color: dark.$color_button-hovered

    // Typography
    > span // Error text
      color: dark.$color_color-error
