@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_list-management_component
    display: flex
    flex: 1
    flex-direction: column
    gap: $space_1x
    padding: $space_2x
    
    &-loading, &-error
        align-items: center
        display: flex
        justify-content: center
        flex: 1
        padding: $space_2x

.dark
    .cp_list-management_component
        .MuiDataGrid-menuIcon .MuiSvgIcon-root, .MuiDataGrid-iconButtonContainer .MuiSvgIcon-root
            color: dark.$color_border-secondarybutton
