@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-finalize-deliverable-container
    padding-top: $space_2x

    &-body
        display: flex
        flex-direction: column
        gap: $space_2x
        text-align: center
        margin: auto
        max-width: 75%

        h6
            color: $color_text-primary
            font-weight: $font-weight-bold
            font-size: 2rem
            text-transform: uppercase

        span.MuiStepLabel-label
            font-weight: $font-weight-bolder
            color: $color_text-inactive
            &.Mui-completed
                color: $color_text-primary

        p strong
            font-weight: $font-weight-bolder

        .cp_component_input
            text-align: left
            width: 100%

.dark
    .cp_component_modal-finalize-deliverable-container
        &-head
            button.MuiButton-root
                color: dark.$color_text-inlinebutton
        &-body
            .MuiStepConnector-root.MuiStepConnector-horizontal span.MuiStepConnector-line
                border-color: inherit
            .MuiStepConnector-root.MuiStepConnector-horizontal.Mui-completed span.MuiStepConnector-line
                border-color: dark.$color_input-active

            h6
                color: dark.$color_text-primary

            span.MuiStepLabel-label
                color: dark.$color_text-inactive
                &.Mui-completed
                    color: dark.$color_text-primary
