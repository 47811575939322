@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

 
.cp_list-overview_component
    display: flex
    flex: 1
    flex-wrap: wrap
    
    &-loading, &-error
        align-items: center
        display: flex
        justify-content: center
        flex: 1
        padding: $space_2x

    &-info
        align-items: center
        background-image: url("../../asset/img/background_start_pages_light.svg")
        background-size: 1516px 972px
        background-position: 60% 35%
        display: flex
        justify-content: space-around
        min-height: px-to-rem(77px)
        min-width: px-to-rem(350px)
        &.epsilon-verified
            background-image: none

        &-flex
            backdrop-filter: blur(3rem)
            display: flex
            flex-direction: row
            height: 100%
            width: 100%
        
        &-name-epsilon-container
            margin: auto auto auto 0
            display: flex
            flex-direction: column  
            .cp_list-overview_component-info-epsilon-container-tool-tip.MuiTooltip-tooltip
                width: 12rem
            
            &-notes
                color: $color_text_notes
                font-weight: $font-weight-normal
                font-size: px-to-rem(10px)
                letter-spacing: 0%
                margin-top: 0.25rem
        
        &-epsilon-container
            background-image: url("../../asset/img/epsilon.svg")
            background-repeat: no-repeat
            background-size: contain
            cursor: pointer
            display: block
            float: left
            min-height: px-to-rem(13px)
            min-width: px-to-rem(46px)
            width: 50px

            

        &-name-container
            display: flex
            flex-direction: row
            margin: auto auto auto 0
            gap: .25rem

            &-icon-container
                margin-left: $space_1x
                margin-right: $space_2x
                &-icon
                    fill: $color_progress-bar-3 !important
                    color: $color_text-invert
        
        &-group-name
            color: $color_text-invert
            font-size: $font-size-sm
            font-weight: $font-weight-bold
            text-transform: uppercase
            &.epsilon-verified
                color: $color_text-primary
            
        &-creator-name
            color: primitives.$color_white-50
            font-size: $font-size-sm
            font-weight: $font-weight-normal
            padding-top: $space_1x

        &-created-date
            color: primitives.$color_white-50
            font-size: $font-size-sm
            font-weight: $font-weight-normal
        
        &-name
            align-items: center
            color: $color_text-invert
            font-size: px-to-rem(16px)
            font-weight: $font-weight-bold
            gap: 0.25rem
            justify-content: center
            &.epsilon-verified
                color: $color_text-primary

        &-avatar.cp-avatar
            font-size: 200%
            margin-left: px-to-rem(32px)
            margin-right: px-to-rem(18px)
            min-height: 42px
            min-width: 42px

        &-avatar-container
            align-items: center
            border-radius: 50%
            display: flex

        .cp-network-icon-svg
            height: 40% 
            width: 40% 

.dark
    .cp_list-overview_component
    
        &-info
            background-image: url("../../asset/img/background_start_pages_dark.svg")
            &.epsilon-verified
                background-image: none
            
            &-name-epsilon-container
                &-notes
                    color: dark.$color_text_notes
            &-epsilon-container
                background-image: url("../../asset/img/epsilon_dark.svg")
        
            &-name-container
                &-icon-container
                    &-icon
                        fill: dark.$color_progress-bar-3 !important
                        color: dark.$color_text-invert
            &-group-name
                color: dark.$color_text-logo
                &.epsilon-verified
                    color: dark.$color_text-primary
            &-creator-name
                color: primitives.$color_white-50

            &-created-date
                color: primitives.$color_white-50
            
            &-name
                &.epsilon-verified
                    color: dark.$color_text-primary

            &-avatar
                &-container
                    border-color: dark.$color_border-radio-inactive
