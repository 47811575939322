@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_campaign_component
    &-tabs-container
        background: $color_fill-card
        border-radius: $content-border-radius
        border: 1px solid $color_border-colorcard
        display: flex
        flex-direction: column
        height: 100%
        padding: $space_1x $space_3x
        position: relative

        .cp_component_tabs-controls
            align-items: center
            display: flex
            gap: $space_1x
            justify-content: flex-end
            .cp_campaign_component-tabs-container-created
                color: $color_text-tab-inactive
                font-size: $font-size-sm
                font-weight: $font-weight-normal
                text-transform: uppercase
    
        &-created
            color: $color_text-tab-inactive
            font-size: $font-size-sm
            font-weight: $font-weight-normal
            text-transform: uppercase

            
    &-tabs-content
        &-controls
            display: flex
            justify-content: flex-end
            padding: $space_1x 0 $space_3x 0

            &.accounts
                background-color: $color_fill-card
                justify-content: space-between

                div.cp_component_input .MuiTextField-root 
                    .MuiInputBase-input
                        padding: 0.25rem 0.6rem

                    .MuiSvgIcon-root.cp_component_search-results-search-icon 
                        fill: $color_icon-search
                        font-size: $space_2x

            &.search
                justify-content: flex-start

            &.empty,
            &.loading
                justify-content: center
                align-items: center

.cp_component_modal-campaign-dialog

  .MuiDialog-container 
    
    .MuiDialog-paperWidthSm
      max-width: 40%

    button.cp_icon-button_component
      position: absolute
      right: $space_2x
      top: $space_2x

    p.MuiDialogContentText-root
      margin-bottom: $space_2x

    .MuiDialogActions-root
      justify-content: left

.dark
    .cp_campaign_component
        &-tabs-container
            background: dark.$color_fill-card
            border-radius: $content-border-radius
            border-color: dark.$color_border-colorcard
            
            &-created
                color: dark.$color_text-tab-inactive

        &-tabs-content
            &-controls
                &.accounts
                    background-color: dark.$color_fill-card
                    border-left-color: dark.$color_border-colorcard
                    border-right-color: dark.$color_border-colorcard
                    border: none
                    div.cp_component_input .MuiTextField-root 
                        .cp_component_search-results-search-icon
                            fill: dark.$color_icon-search
                        &.svg.MuiSvgIcon-root
                            fill: dark.$color_icon-search
