@use "../../../../style/styles" as *
    
div.cp_component_modal-brand-details-header
    display: flex

    b
        font-weight: bold

    .MuiAvatar-root
        margin-right: $space_2x
        min-height: $space_6x
        min-width: $space_6x