@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_relevant_posts_preview
  padding: $space_3x $space_2x

  > p.MuiTypography-root
    @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
    text-transform: uppercase
    margin-bottom: $space_2x

  .cp_relevant_posts_preview  
    &-pills
      display: flex
      flex-wrap: wrap
      gap: px-to-rem(12px)
      margin-bottom: $space_4x

      div.cp-pill.MuiChip-root
        @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
        height: $space_3x
        text-transform: uppercase
        background-color: transparent
        border: 1px solid $color_border-pill-2

        &.active-pill
          background-color: $color_fill-pill-2

    &-post
      background: $color_fill-card
      border-radius: 2px
      border: 1px solid $color_border-colorcard
      display: flex
      flex-direction: column
      padding-top: $space_4x
      position: relative
      margin-bottom: $space_3x

      &-header
        padding: 0 $space_4x $space_4x
        display: flex
        justify-content: space-between
        align-items: center
      
      &-media
        padding: 0 $space_4x $space_4x
        div.cp_card-media
          background-color: $color_fill-overlay-modal
          padding: $space_3x $space_3x $space_5x $space_3x
          display: flex
          justify-content: space-around
          align-items: center
          margin-bottom: $space_4x

          div.MuiCardMedia-root
            width: 33.3%
            aspect-ratio: 1
          // Media Indicators
          > aside.media-indicators
            align-items: flex-end
            bottom: $space_2x
            display: flex
            gap: 0.25rem
            height: $space_2x
            justify-content: center
            left: calc(50% - 10rem)
            position: absolute
            width: 20rem

            div
              background-color: $color_text-inactive
              border-radius: 50%
              height: $space_1x
              width: $space_1x

              &.media-indicator_active
                background-color: $color_text-primary

          // Carousel Buttons
          > button
            background: transparent
            border: none
            color: transparent
            cursor: pointer
            padding: 0
            z-index: 1

            &.carousel-button_inactive
              opacity: 0
              pointer-events: none
            
            svg
              color: $color_text-primary
              height: $space_4x
              width: $space_4x

        aside.additional-info
          p.posted-time
            @include textStyles($color_text-secondary, $font-size-sm, $font-weight-bolder)
            margin-bottom: px-to-rem(20px)
          p.post-content
            @include textStyles($color_text-body, $font-size-sm, $font-weight-normal)

      &-socials-stats
        border-top: 1px solid $color_divider-section
        padding: $space_4x
        display: flex
        justify-content: space-between
        align-items: center
        div
          display: flex
          gap: $space_5x
          span
            align-items: flex-end
            column-gap: .25rem
            display: flex
            justify-content: center
            @include textStyles($color_icon-pill, px-to-rem(16px), $font-weight-bolder)

            .MuiSvgIcon-root
              height: px-to-rem(20px)
              width: px-to-rem(20px)

        p
          @include textStyles($color_icon-pill, px-to-rem(16px), $font-weight-bolder)
          span
            font-size: px-to-rem(16px)
            

        
.dark
  .cp_relevant_posts_preview
    > p.MuiTypography-root
      color: dark.$color_text-primary
    
    .cp_relevant_posts_preview  
        &-pills
          div.cp-pill.MuiChip-root
            color: dark.$color_text-primary
            border: 1px solid dark.$color_border-pill-2

            &.active-pill
              background-color: dark.$color_fill-pill-2

        &-post
          background: dark.$color_fill-card
          border: 1px solid dark.$color_border-colorcard

          &-media
            div.cp_card-media
              background-color: dark.$color_fill-overlay-modal
              // Media Indicators
              > aside.media-indicators
                div
                  background-color: dark.$color_text-inactive

                  &.media-indicator_active
                    background-color: dark.$color_text-primary

              // Carousel Buttons
              > button
                svg
                  color: dark.$color_text-primary

            aside.additional-info
              p.posted-time
                color: dark.$color_text-secondary
              p.post-content
                color: dark.$color_text-body

          &-socials-stats
            border-top: 1px solid dark.$color_divider-section
            div
              span
                color: dark.$color_icon-pill

            p
              color: dark.$color_icon-pill


          

  
