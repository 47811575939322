@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp_component-modal-customer-activation
  .cp_component-modal-customer-activation
    &-body
      padding-top: $space_2x
      p
        @include textStyles($color_text-primary, $font-size-normal, $font-weight-normal)
      p:last-of-type
        margin-bottom: $space_2x

      &-ctas 
        align-items: center
        column-gap: $space_2x
        display: flex

dark.cp_component-modal-customer-activation
  .cp_component-modal-customer-activation
    &-body
      p
        color: dark.$color_text-primary
