@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_basic-chart_component-container
    width: 100%

.cp_basic-chart_component
    padding-top: $space_3x
    width: 100%

    .highcharts-axis-title
        fill: $color_text-primary !important
        font-weight: $font-weight-bolder !important
        font-size: $font-size-sm !important

    .highcharts-background
        fill: none
    
    .highcharts-grid .highcharts-grid-line
        visibility: hidden

.dark
    .cp_basic-chart_component

        .highcharts-axis-line
            stroke: dark.$color_text-primary

        .highcharts-axis-title
            fill: dark.$color_text-primary !important

        .highcharts-yaxis-labels text, .highcharts-xaxis-labels text
            fill: dark.$color_text-secondary !important
