@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_modal-comms-create-template
  .MuiDialog-container .MuiDialogTitle-root
    border: none
  .MuiDialog-container .MuiDialogActions-root
    border: none
    background-color: inherit
  .content-container
    border: 1px solid $color_border-section
    display: flex
    flex-direction: column
    gap: $space_2x
    padding: $space_3x

  .input-counter-container
    width: fit-content
    .subject-label
      align-items: center
      display: flex
      justify-content: space-between
      margin-bottom: $space_1x
      p.label_small-caps-semibold
          color: $color_text-primary
      p.label_small-caps-bold
          color: $color_text-primary
          margin-bottom: 0
          &.counter
              color: $color_text-secondary
              font-weight: 400
  h2
    font-size: px-to-rem(20px)
    font-weight: 600
    margin-bottom: $space_2x

.dark
  .cp_component_modal-comms-create-template
    .content-container
      border-color: dark.$color_border-section

    .subject-label
      p.label_small-caps-semibold
        color: dark.$color_text-primary
      p.label_small-caps-bold
        color: dark.$color_text-primary
        &.counter
          color: dark.$color_text-secondary
