@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component-network-filter-menu_icon-button
  color: $color_button-secondary

.dark
  .cp_component-network-filter-menu_icon-button
    color: dark.$color_button-secondary