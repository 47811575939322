@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_modal-comm-create-message

  div.MuiDialogContent-root
    padding: 0

  .cp_modal-comm-create-message
    &-message-bar,
    &-template,
    &-configure
      padding: $space_2x

    &-template
      padding-bottom: $space_3x
      h5
        margin-bottom: $space_1x

      &-select
        padding-left: $space_1x
        p
          @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
          margin-bottom: px-to-rem(5px)
          text-transform: uppercase
        .component_select
          width: 350px

        .top-fields-container,
        .upload-link-container
          align-items: center
          display: flex

        .top-fields-container
          gap: $space_2x

        .upload-link-container
          label
            @include textStyles($color_text-radiobutton, $font-size-sm, $font-weight-normal)
    &-configure
      h5
        margin-bottom: $space_1x
      &-fields
        padding: 0 $space_1x

        div.MuiAutocomplete-root
          margin-bottom: $space_2x

        > p
          @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
          margin-bottom: px-to-rem(5px)
          text-transform: uppercase
        
        div.cp_autocomplete_comm-group-email_input
          &.cp_component_input
            width: 100%

        div.MuiInputBase-root
          padding: 0 20% 0 0
          position: relative

          div.cc-labels
            display: flex
            gap: $space_1x
            padding: 0 $space_2x
            position: absolute
            right: 0
            top: calc(50% - px-to-rem(9.5px))

            span
              @include textStyles($color_text-inlinebutton, px-to-rem(14px), $font-weight-normal)
              cursor: pointer
              text-transform: uppercase

          div.MuiChip-root
            height: $space_3x

          span.MuiChip-label 
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
            line-height:  $space_3x

          div.MuiButtonBase-root.MuiChip-root
            background-color: $color_fill-pill-1
            border: 1px solid $color_border-pill

          svg.MuiChip-deleteIcon
            color: $color_text-radiobutton
            height: px-to-rem(12px)
            width: px-to-rem(12px)

        div.cp_component_input.cp_modal-comm-create-message-subject
          margin-bottom: $space_2x
          width: 100%

div.MuiPaper-root
  .cp-comm-group-autocomplete-option
    .userName
      @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
      line-height: $font-line-height-primary
    .email
      padding-left: $space_1x

.dark
  .cp-comm-group-autocomplete-option
    .userName
      color: dark.$color_text-primary

  .cp_modal-comm-create-message

    .cp_modal-comm-create-message

      &-template

        &-select
          p
            color: dark.$color_text-primary

          .upload-link-container
            label
              color: dark.$color_text-radiobutton

      &-configure
        &-fields
          p
            color: dark.$color_text-primary

          div.MuiInputBase-root
            div.cc-labels
              span
                color: dark.$color_text-inlinebutton
            span.MuiChip-label 
              color: dark.$color_text-primary

            div.MuiButtonBase-root.MuiChip-root
              background-color: dark.$color_fill-pill-1
              border: 1px solid dark.$color_border-pill

            svg.MuiChip-deleteIcon
              color: dark.$color_text-radiobutton

  div.MuiPaper-root
    .cp-comm-group-autocomplete-option
      .userName
        @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)
      .email
        @include textStyles(dark.$color_text-secondary, $font-size-normal, $font-weight-normal)
