@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_account-insights-details_component
    display: flex
    flex-direction: column

    &-row
        display: flex
        gap: $space_2x
        flex-direction: row-reverse
        flex-wrap: nowrap
        margin: 0
        @media (max-width: $xl)
            flex-wrap: wrap
            gap: 0

    &-column-1
        display: flex
        flex: 1
        flex-basis: 100%
        flex-direction: column

    &-column-2
        display: flex
        flex-basis: 100%
        flex-direction: column
        flex: 2

    &-widget
        background: $color_fill-card
        border-radius: $content-border-radius
        border: 1px solid $color_border-colorcard
        padding: $space_3x
        position: relative
        margin: $space_1x 0

        &-title
            color: $color_text-primary
            font-size: $font-size-large
            margin-bottom: 0.5rem
        
        &-bio
            color: $color_text-secondary
            font-size: $font-size-sm
            font-weight: $font-weight-normal
            margin-top: 0.5rem

.dark
    .cp_account-insights-details_component
        &-widget
            background: dark.$color_fill-card
            border-radius: $content-border-radius
            border: 1px solid dark.$color_border-colorcard
            
            &-title
                color: dark.$color_text-primary

            &-bio
                color: dark.$color_text-secondary
