@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/_primitives' as *

.cp_component_message-bar
  align-content: center
  border-radius: px-to-rem(5px)
  display: flex
  flex-direction: row
  gap: $space_2x
  justify-content: flex-start
  padding: $space_1x
  width: 100%

  .message-bar-icon
    height: px-to-rem(16px)

  &.error
    background-color: $color_notif-error
    color: $color_text-invert

    p.body_small
      font-weight: 600
      color: $color_text-invert

  &.info
    background-color: $color_notif-info
    color: $color_text-invert

    p.body_small
      font-weight: 600
      color: $color_text-invert

  &.success
    background-color: $color_notif-success

    p.body_small
      font-weight: 600
  
  &.warning
    background-color: $color_notif-warning

    p.body_small
      font-weight: 600

.dark
  .cp_component_message-bar
    &.error
      background-color: $color_notif-error-darkmode

    &.info
      background-color: $color_notif-info

    &.success
      background-color: $color_notif-success-darkmode
    
    &.warning
      background-color: $color_notif-warning-darkmode

