@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp-badge-component
  .MuiSvgIcon-root
    color: $color_icon-exit
  .MuiBadge-badge
    background-color: $color_color-error
    top: 33%
    right: 33%

.dark
  .cp-badge-component
    .MuiSvgIcon-root
      color: dark.$color_icon-exit

    .MuiBadge-badge
      background-color: dark.$color_color-error
