@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


// Separate from root style due to portaling
ul.cp_component_modal-edit-list-profile_verticals-list
    padding: 0

    .MuiAutocomplete-option
        background-color: $color_border-section
        border-top: 1px solid $color_divider-section
        color: $color_text-primary
        font-size: $font-size-sm
        padding: $space_2x $space_1x
        text-transform: capitalize

        &:has(span.cp_component_loading_indicator)
            justify-content: center
    
        &.Mui-focused
        background-color: $color_fill-dropdown-select

.cp_component_modal-edit-list-profile
    // General styles for component
    input.MuiAutocomplete-input.MuiInputBase-input
        padding-bottom: 0
        padding-top: 0

        .MuiInputAdornment-root
            color: $color_border-input
            cursor: pointer

    .MuiFormControlLabel-label
        font-size: $font-size-sm
        font-weight: $font-weight-normal

    .cp_component_modal-edit-list-profile_input
        margin-bottom: $space_2x


    .MuiFormControlLabel-labelPlacementEnd
        min-width: px-to-rem(116px)
        margin-right: 0

    .cp_component_divider
        margin: $space_3x 0 $space_3x 0

    h4.headline_small
        font-size: $font-size-large

    .MuiDialog-container .MuiDialogTitle-root + .MuiDialogContent-root
        padding-top: 0

    .cp_component_modal-edit-list-profile_row
        display: flex
        flex-direction: row
        gap: $space_3x
        width: 100%
        
    .cp_component_modal-edit-list-profile_column
        display: flex
        flex: 1
        flex-direction: column
        gap: $space_2x

    // Root style
    .cp_component_modal-edit-list-profile_panels
      gap: $space_2x 

      > .cp_component_modal-edit-list-profile_column
          border-radius: $content-border-radius
          max-height: 565px
          overflow-y: auto
          padding: $space_3x $space_4x
          
          // Contact section
          .cp_component_modal-edit-list-profile-contact
            .cp_component_modal-edit-list-profile_row
                align-items: center
                justify-content: space-between

            > *
                margin-bottom: $space_2x

            .cp_component_modal-edit-list-profile_photo
                align-items: center
                display: flex
                gap: px-to-rem(20px)
                justify-items: flex-start
                margin: $space_2x 0 $space_2x 0
                
                .cp-avatar.MuiAvatar-root
                    font-size: xx-large
                    height: px-to-rem(80px)
                    width: px-to-rem(80px)

            .cp_component-modal-edit-list-profile_pills
                display: flex
                flex-wrap: wrap
                gap: 0.25rem
                
          // Stats section
          .cp_component_modal-edit-list-profile-stats

            .headline_small
                margin-bottom: $space_2x

            .MuiAutocomplete-popupIndicator
                background-color: transparent
                color: $color_input-inactive

                &.MuiAutocomplete-popupIndicatorOpen
                    color: $color_input-active

            .cp_component_modal-edit-list-profile_row
                display: flex
                justify-content: flex-start
              

            .cp_component_modal-edit-list-profile_column
                border: none

            .cp_component_modal-edit-list-profile_input
                width: px-to-rem(157px)

            .cp_component_modal_add_profile_gender-label
                color: $color_text-primary
                font-size: $font-size-sm
                font-weight: $font-weight-bold
                text-transform: uppercase

            .cp_component_modal_add_profile_gender-radios
                padding-left: $space_1x


          // Bio Section
          .cp_component_modal-edit-list-profile-bio
            .cp_component_modal-edit-list-profile_input
              .MuiInputBase-root
                align-items: flex-start
                display: flex
                width: px-to-rem(452px)
                height: px-to-rem(105px)
                padding: 0.4rem 0 0.25rem 1rem
                .MuiInputBase-input
                  padding: 0

          // Rationale Section
          .cp_component_modal-edit-list-profile-rationale
            .cp_component_modal-edit-list-profile_input
              .MuiInputBase-root
                align-items: flex-start
                display: flex
                width: px-to-rem(452px)
                height: px-to-rem(105px)
                padding: 0.4rem 0 0.25rem 1rem
                .MuiInputBase-input
                  padding: 0


.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_modal-edit-list-profile_verticals-list

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select
    
    .cp_component_modal-edit-list-profile
        .cp_component_modal-edit-list-profile_panels
            .cp_component_modal-edit-list-profile-stats
              .cp_component_modal_add_profile_gender-label
                    color: dark.$color_text-primary
              .MuiAutocomplete-popupIndicator
                  color: dark.$color_input-inactive

                  &.MuiAutocomplete-popupIndicatorOpen
                      color: dark.$color_input-active

            .MuiAutocomplete-inputRoot

                .MuiInputAdornment-root
                    color: dark.$color_border-input
