@use "../../../style/theme_dark" as dark
@use "../../../style/theme_light" as *
@use "../../../style/_styles" as *

div.cp_lcp-tabs_highlights-tab-content
  display: flex
  flex-direction: column
  gap: $space_1x
  margin-bottom: $space_3x

.dark
  .cp_lcp-tabs_highlights-tab-content