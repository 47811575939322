@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_modal-contact_component
    &-wrapper
        padding-top: $space_2x

    &-row
        align-items: flex-end
        display: flex
        flex-direction: row
        gap: $space_3x
        margin-bottom: $space_1x
        width: 100%

    &-input-email
        flex: 2

.dark
