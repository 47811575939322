@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_post-type-cell
  display: flex
  flex-wrap: wrap
  gap: $space_1x

// Dark Theme Styles
.dark div.cp_component_post-type-cell
