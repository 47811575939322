@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use '../../../../style/_styles' as *


div.cp_component_navigation-bar-comm-sliding-panel
    .cp_component_sliding-panel-container 
        h2
            margin-bottom: $space_1x

    .cp_component_navigation-bar-comm-tabs
        margin-bottom: $space_2x
        padding: 0 $space_2x
        .MuiTabs-scroller
            align-items: flex-end
            box-shadow: inset 0 -2px $color_border-section
            display: flex

        .MuiButtonBase-root
            min-height: $space_4x
            height: $space_4x

.dark
    div.cp_component_navigation-bar-comm-tabs
        .MuiTabs-scroller
            box-shadow: inset 0 -2px dark.$color_border-section
