@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_user-settings_page
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  .component_navigation-bar
    left: 0
    position: fixed
    top: 0
  
  &-container
    min-height: calc(100vh - calc($top-nav-height + 3rem))
    width: 100%
// No colors involved, no need for dark them style