@use "../../style/_styles" as *
@use "../../style/_primitives" as *
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

.cp_component_rich-text-editor_menu-bar
  display: flex
  height: px-to-rem(32px)
  .dropdown 
    border-radius: 0
    flex: 1 1
    height: 100%
    padding-right: 0 !important

    .MuiSelect-select
      color: $color_text-primary
      font-family: "Open Sans"
      font-size: px-to-rem(12px)

  .icon-section
    border: 1px solid $color_border-section
    .MuiIconButton-root
      background-color: inherit
      height: 100%
      &.active
        background-color: $color_fill-modal
      .MuiSvgIcon-root
        color: $color_text-primary
        height: px-to-rem(14px)
        width: px-to-rem(14px)

.cp_component_rich-text-editor-menu-bar-menu-item.MuiMenuItem-root
  font-size: px-to-rem(12px)
  font-family: "Open Sans"

.dark
  .cp_component_rich-text-editor_menu-bar
    .dropdown 
      .MuiSelect-select
        color: dark.$color_text-primary
      .MuiSvgIcon-root
        fill: dark.$color_button-arrow
    .icon-section
      border-color: dark.$color_border-section
      .MuiIconButton-root
        &.active
          background-color: dark.$color_fill-modal
        .MuiSvgIcon-root
          color: dark.$color_text-primary

.cp_component_rich-text-editor-select-menu-dropdown
  .MuiPaper-root
    background-color: dark.$color_fill-dropdown
  .cp_component_rich-text-editor-menu-bar-menu-item.MuiMenuItem-root
    color: dark.$color_text-secondary
