@use "../../../../../../style/theme_light" as *
@use "../../../../../../style/theme_dark" as dark
@use "../../../../../../style/styles" as *

div.cp_component_unassigned-thread-assign-modal
  .autocomplete-container
    display: flex
    flex-direction: column
    gap: $space_2x
    padding-top: $space_2x

    div.cp_component_input
      width: px-to-rem(381px)