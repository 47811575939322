@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component-rich-text-editor-attachments
  padding: px-to-rem(10px)
  .cp_rte-attachment
    align-items: center
    border-radius: $content-border-radius
    border: $border-icon-primary 
    display: flex
    justify-content: space-between
    margin-bottom: $space_1x
    max-width: 75%
    padding: $space_1x px-to-rem(12px)
    width: 355px

    a.MuiTypography-root
      @include textStyles($color_text-inlinebutton, $font-size-sm, $font-weight-bold)

    &:last-child
      margin-bottom: 0

.dark
  .cp_component-rich-text-editor-attachments
    .cp_rte-attachment
      border: dark.$color_border-image
      a.MuiTypography-root
        color: dark.$color_text-inlinebutton
