@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

// Light Theme Styles
.svg#cp_component_adornments-oauthed-icon,
.MuiSvgIcon-root#cp_component_adornments-oauthed-icon
  color: $color_icon-lock


// Dark Theme Styles
.dark .svg#cp_component_adornments-oauthed-icon,
.dark .MuiSvgIcon-root#cp_component_adornments-oauthed-icon 
  color: dark.$color_icon-lock

