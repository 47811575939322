@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.personalized-label-list-item:hover .modification-label-buttons
  background-color: $color_fill-dropdown-select
  visibility: visible

.personalized-label-list-item:has(.modification-label-buttons:hover)
  background-color: $color_fill-dropdown-select

.edit-label-container
  padding-top: px-to-rem(15px)
  margin-bottom: px-to-rem(10px)

  div.cp_component_input
    float: left
    margin-right: px-to-rem(2px)
    width: 14rem

  .update-label-check-button
    background-color: $color_button-primary
    border: 1px solid $color_button-primary
    border-color: $color_border-buttongroup
    color: $color_button-text
    cursor: pointer
    height: px-to-rem(18px)
    margin-top: px-to-rem(10px)
    margin-bottom: px-to-rem(4px)
    width: px-to-rem(18px)
  
    &:hover
      background-color: $color_button-hovered
      color: $color_button-text
      cursor: pointer

  .cancel-edit-label-button
    cursor: pointer
    height: px-to-rem(18px)
    margin-top: px-to-rem(8px)
    margin-bottom: px-to-rem(6px)
    width: px-to-rem(18px)

.created-label
  color: $color_text-secondary
  font-size: px-to-rem(10px)

.important-label-button
  color: $color_fill-icon-list

.add-account-button
  margin-left: px-to-rem(50px)

.custom-pill-label-color-contracting
  color: $color_fill-pill-2
  transform: scale(0.4)

.custom-pill-label-color-garnering-content
  color: $color_fill-pill-4
  transform: scale(0.4)

.custom-pill-label-color-opted-in
  color: $color_fill-pill-3
  transform: scale(0.4)

.standard-label-pill-color
  color: $color_fill-pill-1
  transform: scale(0.4)

.modification-label-buttons
  height: 100%
  padding-right: $space_2x
  visibility: hidden

  .edit-label-icon-button
    cursor: pointer
    height: px-to-rem(15px)
    margin-top: px-to-rem(8px)
    margin-bottom: px-to-rem(8px)
    width: px-to-rem(15px)

  .delete-label-icon-button
    cursor: pointer
    height: px-to-rem(15px)
    margin-top: px-to-rem(8px)
    margin-bottom: px-to-rem(8px)
    width: px-to-rem(15px)

.dark
  .personalized-label-list-item:hover .modification-label-buttons
    background-color: dark.$color_fill-dropdown-select
    visibility: visible

  .personalized-label-list-item:has(.modification-label-buttons:hover)
    background-color: dark.$color_fill-dropdown-select

  .edit-label-container
    padding-top: px-to-rem(15px)
    margin-bottom: px-to-rem(10px)

    div.cp_component_input
      float: left
      margin-right: px-to-rem(2px)
      width: 14rem

    .update-label-check-button
      background-color: dark.$color_button-primary
      border: 1px solid dark.$color_button-primary
      border-color: dark.$color_border-buttongroup
      color: dark.$color_button-text
      cursor: pointer
      height: px-to-rem(18px)
      margin-top: px-to-rem(10px)
      margin-bottom: px-to-rem(4px)
      width: px-to-rem(18px)
    
      &:hover
        background-color: dark.$color_button-hovered
        color: dark.$color_button-text
        cursor: pointer

    .cancel-edit-label-button
      cursor: pointer
      height: px-to-rem(18px)
      margin-top: px-to-rem(8px)
      margin-bottom: px-to-rem(6px)
      width: px-to-rem(18px)

  .created-label
    color: dark.$color_text-secondary
    font-size: px-to-rem(10px)

  .important-label-button
    color: dark.$color_fill-icon-list

  .add-account-button
    margin-left: px-to-rem(50px)

  .custom-pill-color-contracting
    color: dark.$color_fill-pill-2

  .custom-pill-color-garnering-content
    color: dark.$color_fill-pill-4

  .custom-pill-color-opted-in
    color: dark.$color_fill-pill-3

  .standar-pill-color
    color: dark.$color_fill-pill-1

  .modification-label-buttons
    height: 100%
    padding-right: $space_2x
    visibility: hidden

    .edit-label-icon-button
      cursor: pointer
      height: px-to-rem(15px)
      margin-top: px-to-rem(8px)
      margin-bottom: px-to-rem(8px)
      width: px-to-rem(15px)

    .delete-label-icon-button
      cursor: pointer
      height: px-to-rem(15px)
      margin-top: px-to-rem(8px)
      margin-bottom: px-to-rem(8px)
      width: px-to-rem(15px)
