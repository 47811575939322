@use "../../../style/theme_dark" as dark
@use "../../../style/theme_light" as *
@use "../../../style/_styles" as *

div.cp_lcp-tabs_account-tab-content
  display: flex
  flex-direction: column
  gap: $space_1x
  margin-bottom: $space_3x
  height: fit-content

.dark
  .cp_lcp-tabs_account-tab-content