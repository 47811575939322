@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.MuiListItemText-root.cp_move-categories-menu-item_component-title 
    background-color: $color_fill-background
    margin-bottom: 0
    margin-top: 0

    > .MuiTypography-root
        font-size: $font-size-sm
        font-weight: $font-weight-bolder
        padding: $space_1x 0
        margin: 0 0 0 $space_2x

li.cp_move-categories-menu-item_component-category_loading
    justify-content: center

div.component_select-dropdown-menu:has(.cp_move-categories-menu-item_component-category)
    min-width: 250px !important

    li.cp_move-categories-menu-item_component-category.MuiMenuItem-root
        padding-bottom: 0.25rem
        padding-top: 0.25rem

        .cp_move-categories-menu-item_component-category_checked.MuiListItemIcon-root
            color: $color_icon-list

        .cp_move-categories-menu-item_component-category-name .MuiTypography-root
                font-size: $font-size-sm
                font-weight: $font-weight-normal

        .cp_move-categories-menu-item_component-category-name_bold .MuiTypography-root
            font-size: $font-size-sm
            font-weight: $font-weight-bolder
        
        .MuiIconButton-root
            color: inherit

.dark
    .cp_move-categories-menu-item_component-title 
        background-color: dark.$color_fill-dropdown-select-secondary
        color: dark.$color_text-dropdown
        margin: 0

    div.component_select-dropdown-menu li.cp_move-categories-menu-item_component-category .cp_move-categories-menu-item_component-category_checked.MuiListItemIcon-root
        color: dark.$color_icon-list !important
