@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_profile_page
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  div.cp_profile_page-container
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative
    display: flex
    flex-direction: row
    flex-wrap: wrap
    width: 100%

  section.cp_profile_page-overview
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    height: 100%
    left: 0
    overflow: hidden auto
    position: absolute
    top: 0
    width: px-to-rem(350px)
    display: flex
    flex-direction: column
    flex: 1

  section.cp_profile_page-metrics
    height: 100%
    overflow-x: hidden
    overflow-y: auto
    position: absolute
    right: 0
    top: 0
    width: calc(100% - calc(350px + 1rem))
    display: flex
    flex-direction: column
    flex: 2

// Dark Theme Styles
.dark div.cp_profile_page
  section.cp_profile_page-overview
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard
