@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
  
.cp_datePicker
  .MuiInputBase-root
    &.Mui-focused .MuiOutlinedInput-notchedOutline
      border-width: 1px

    input
      color: $color_text-primary
      font-family: "Open Sans"
      font-size: 0.8rem
      font-weight: 400
      padding: 0.6rem

// NOTE: GLOBAL STYLES FOR DATEPICKER COMPONENT
.MuiDayCalendar-monthContainer
  .MuiButtonBase-root
    color: $color_text-primary
    &:focus.Mui-selected
      background-color: $color_button-primary
      color: $color_button-text

    &.Mui-selected
      background-color: $color_button-primary
      color: $color_button-text

  .MuiPickersDay-today
    border: 1px solid $color_button-primary
    &:not(.Mui-selected)
      border: 1px solid $color_button-primary

.dark
  .cp_datePicker
    .MuiInputBase-root
      input
        color: dark.$color_text-primary
      
  .MuiDayCalendar-monthContainer
    .MuiPaper-root
      background-color: dark.$color_fill-dropdown-select-secondary
      color: dark.$color_text-primary

    .MuiButtonBase-root
      color: dark.$color_text-primary

      &.Mui-selected
        background-color: dark.$color_button-primary
        color: dark.$color_button-text

    .MuiPickersDay-today
      border: 1px solid dark.$color_button-primary

  .MuiDayCalendar-header
    .MuiDayCalendar-weekDayLabel
      color: dark.$color_text-primary

  .MuiPickersCalendarHeader-root
    .MuiSvgIcon-root
      color: dark.$color_text-primary
