@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.MuiAvatar-root.cp-avatar.cp_component_network-avatar
    background-color: $color_fill-social-background
    
    &.cp_component_network-avatar-clickable 
      cursor: pointer

    + .MuiBadge-badge 
        .cp_component_network-badge
          height: $space_3x
          width: $space_3x
          & .cp_component_network-badge-small
            height: $space_1x
            width: $space_1x

    &.cp-avatar-xl
      + .MuiBadge-badge 
        .cp_component_network-badge
          height: px-to-rem(47px)
          width: px-to-rem(47px)


.dark
  .MuiAvatar-root.cp-avatar.cp_component_network-avatar
    background-color: dark.$color_fill-social-background
