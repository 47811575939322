@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_personality-traits_component
    
    &-header
        align-items: flex-end
        display: flex
        justify-content: space-between
        margin-bottom: 0.75rem

        &-title
            color: $color_text-primary
            font-size: $font-size-large

        &-sort
            max-height: $space_3x
            min-width: px-to-rem(200px)
            
            .MuiListItemText-root > span
                font-size: $font-size-sm
                font-weight: $font-weight-normal
    &-footer
        display: flex
        justify-content: space-around

        .MuiButton-root
            color: $color_text-inactive
            flex-direction: column
.dark
    .cp_personality-traits_component

        &-header
            &-title
                color: dark.$color_text-primary

            &-sort
                max-height: $space_3x
                min-width: px-to-rem(200px)
                
                .MuiListItemText-root > span
                    font-size: $font-size-sm
                    font-weight: $font-weight-normal
        &-footer
            .MuiButton-root
                color: dark.$color_text-inactive
