.cp_component_modal-engagement-score
  .MuiDialog-container 
    h2.MuiDialogTitle-root
      padding: 1rem
      border: none

  .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root
    border: none

.dark .cp_component_modal-engagement-score
  .MuiDialog-container .MuiPaper-root .MuiDialogTitle-root
    border: none
