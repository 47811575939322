@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_media-feedback-card_component
    background-color: $color_fill-background
    border-radius: $content-border-radius
    padding: $space_2x
    &-header
        display: flex
        justify-content: space-between
        .user-info-row
            margin: 0
            width: fit-content
            .cp_component-entity-info-row-name
                font-size: px-to-rem(12px)
            .cp_component-entity-info-row-sub-info
                font-size: px-to-rem(10px)
        .header-right
            display: flex
            gap: $space_1x
            .visibility-icon-toggle
                margin: 0
                padding: 0
                width: fit-content
                height: fit-content
                &:hover
                    cursor: pointer
                
                .MuiSvgIcon-root
                    fill: $color_icon-pill
                    height: px-to-rem(16px)
                    width: px-to-rem(16px)

    &-body 
        display: block
        p
            color: $color_text-body
            font-size: $font-size-sm
            white-space: pre-line
        ul
            list-style: disc
        ol
            list-style-type: decimal
        ul, ol
            font-size: $font-size-sm
            margin: 0 1rem 0 0.4rem
            padding: 0 1rem
        
    .MuiDivider-root
        margin-bottom: $space_1x
        margin-top: $space_1x

.dark
    .cp_media-feedback-card_component
        background-color: dark.$color_fill-section
        &-header
            &-name-company, &-date
                color: dark.$color_text-follower
            .header-right
                .visibility-icon-toggle
                    .MuiSvgIcon-root
                        fill: dark.$color_icon-pill

        &-body p
                color: dark.$color_text-body
