@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component_campaign-assign-reviewers-modal
  .MuiDialog-container

    .MuiDialogTitle-root
      > div
          display: block

div.cp_component-assign-reviewers-container
  width: px-to-rem(496px)
  padding: 1.5rem

  .header
    align-items: center
    display: flex
    justify-content: space-between
    background-color: $color_fill-background
    padding: $space_2x $space_3x
    h4
      font-size: px-to-rem(18px)
      font-weight: $font-weight-bold
  .add-btn
    width: px-to-rem(32px)
    height: px-to-rem(32px)
    border-radius: px-to-rem(5px)
    background-color: $color_button-primary
    color: $color_button-text

.dark
  div.cp_component-assign-reviewers-container
    .header
      background-color: primitives.$color_gray-dark-900-a25
      .add-btn
        background-color: dark.$color_button-primary
        color: dark.$color_button-text
