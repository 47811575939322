@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_cfg-prev_hero-banner
  border: solid 2.5px $color_border-colorcard
  display: flex
  flex-direction: row
  justify-content: center
  align-content: center
  gap: $space_1x
  padding: $space_2x
  width: 100%

  &_carousel
    display: flex
    width: calc(100% - 180px)
    overflow: hidden

    .carousel-card-100
      margin-top: .8rem
      width: px-to-rem(100px)

      p.body_large
        font-weight: 600
    
    .carousel-card-120
      margin-top: .8rem
      width: px-to-rem(120px)

      p.body_large
        font-weight: 600
    
    .carousel-card-145
      margin-top: .8rem
      width: px-to-rem(145px)

      p.body_large
        font-weight: 600

    .carousel-card-215
      margin-top: .8rem
      width: px-to-rem(215px)

      p.body_large
        font-weight: 600

    .carousel-card-225
      margin-top: .8rem
      width: px-to-rem(225px)

      p.body_large
        font-weight: 600

    .carousel-card-275
      margin-top: .8rem
      width: px-to-rem(275px)

      p.body_large
        font-weight: 600

    .carousel-card-300
      margin-top: .8rem
      width: px-to-rem(300px)

      p.body_large
        font-weight: 600



.dark.cp_component_cfg-prev_hero-banner