@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/_styles' as *

.cp_component_sliding-panel-backdrop
  backdrop-filter: blur(3px)
  
div.cp_component_sliding-panel-container
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  height: 100vh
  margin-left: primitives.$width_navigation-bar
  width: px-to-rem(480px)
  display: flex
  flex-direction: column

  .cp_component_sliding-panel-children-container
    flex-grow: 1
    overflow: hidden
    display: flex
    flex-direction: column
    .cp_component_search-bar-input
      padding-left: $space_2x
      padding-right: $space_2x

  .cp_component_sliding-panel-header-container
    align-items: flex-end
    display: flex
    justify-content: space-between
    padding: 0
    
    h2
      box-sizing: border-box
      color: $color_text-primary
      font-family: $font-family-primary
      font-size: $font-size-large
      font-style: normal
      font-weight: $font-weight-bold
      height: px-to-rem(28px)
      line-height: normal
      margin-bottom: $space_2x
      padding: $space_2x
    

// Dark Theme Styles
.dark
  div.cp_component_sliding-panel-container
    background-color: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard

    h2
      color: dark.$color_text-primary

    .MuiToggleButtonGroup-root
        .MuiButtonBase-root
          color: dark.$color_text-inactive
          background-color: dark.$color_button-exit
        & .Mui-selected
          background-color: dark.$color_button-hovered
          color: dark.$color_button-text
