@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_search-results-media-cell
  align-items: center
  display: flex
  height: 100%
  justify-content: center
  padding: 0.25rem
  width: 100%

  aside
    align-items: center
    background-repeat: no-repeat
    background-size: cover
    display: flex
    height: 100%
    justify-content: center
    width: 100%
    z-index: 1

    &.is-nsfw
      filter: blur(3px)

      &::before
        background-color: $color_button-secondary-disabled 
        content: ""
        height: 100%
        left: 0
        mix-blend-mode: multiply
        pointer-events: none
        position: absolute
        top: 0
        width: 100%
        z-index: 2

  svg
    color: dark.$color_text-primary // Intentionally using dark color here
    height: $space_5x
    width: $space_5x

// Dark Theme Styles
.dark div.cp_component_search-account-results-media-cell
  svg
    color: dark.$color_text-primary

  aside
    &.is-nsfw::before
        background-color: dark.$color_button-textdisabled
