@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_audience-details_component
    display: flex
    flex-direction: column

    &-row
        display: flex
        gap: $space_2x
        flex-direction: row
        flex-wrap: nowrap
        margin: 0
        @media (max-width: $xl)
            flex-wrap: wrap
            gap: 0

    &-column-1
        flex: 0 0 33%
        min-height: px-to-rem(300px)

    &-column-2
        flex: 0 0 60%
        min-height: px-to-rem(300px)

    &-widget
        background: $color_fill-card
        border-radius: $content-border-radius
        border: 1px solid $color_border-colorcard
        padding: $space_3x
        position: relative
        margin: $space_1x 0
        flex-grow: 1
        &-title
            color: $color_text-primary
            font-size: $font-size-large
            margin-bottom: 0.5rem

    &-charts
        align-items: center
        display: flex
        flex-wrap: wrap
        justify-content: center
        height: 100%

    
    &-charts:has(.cp_loading-indicator-card_component)
        min-height: px-to-rem(200px)
        
.dark
    .cp_audience-details_component
        &-widget
            background: dark.$color_fill-card
            border-radius: $content-border-radius
            border: 1px solid dark.$color_border-colorcard
            
            &-title
                color: dark.$color_text-primary

            &-bio
                color: dark.$color_text-secondary
