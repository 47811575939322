@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

div.component_select.MuiFormControl-root
  min-width: 10rem

  // MODES
  // THIN
  &.cp_component_select-thin
    .MuiInputBase-input
      padding: 0.22rem 0.25rem

    .MuiTypography-root
      font-size: 0.8rem
      font-weight: $font-weight-normal

    .MuiListItemText-root
      margin-bottom: 0
      margin-top: 0

    .MuiFormLabel-root
      transform: translate(0.6rem, 0.35rem) scale(1)

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.MuiFormLabel-filled
      font-size: 0.95rem
      transform: translate(16px, -8px) scale(0.75)

  // Input Root
  .MuiInputBase-root
    border-radius: 2px
    padding-left: 0.5em
    padding-right: 0.5rem

    .MuiSelect-icon
      color: $color_input-inactive

    fieldset
      border-color: $color_input-inactive

  .MuiInputBase-root.Mui-focused
    .MuiSelect-icon
      color: $color_input-active

    fieldset
      border-color: $color_input-active
      border-width: 1px

  .MuiInputBase-root.Mui-disabled
    .MuiSelect-icon
      color: $color_input-disabled

    fieldset
      border-color: $color_input-disabled
      border-width: 1px

  .MuiInputBase-root.Mui-error
    .MuiSelect-icon
      color: $color_input-error

    fieldset
      border-color: $color_input-error

  // Input
  .MuiInputBase-input
    color: $color_text-primary
    font-family: "Open Sans"
    font-size: 0.8rem
    font-weight: 400
    padding: 0.45rem

  // Label
  .MuiFormLabel-root
    color: $color_input-inactive
    font-family: "Open Sans"
    font-size: 0.8rem
    font-weight: 400
    transform: translate(0.75rem, 0.6rem) scale(1)

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root.Mui-focused.MuiFormLabel-filled
    color: $color_input-active

  .MuiFormLabel-root.MuiFormLabel-filled
    color: $color_text-secondary

  .MuiFormLabel-root.Mui-focused,
  .MuiFormLabel-root.MuiFormLabel-filled
    font-size: 0.95rem
    transform: translate(14px, -9px) scale(0.75)

  .MuiFormLabel-root.Mui-disabled
    color: $color_input-disabled

  .MuiFormLabel-root.Mui-error
    color: $color_input-error

  // Icon
  .MuiSelect-icon
    color: $color_text-primary
    width: 1.25rem

// Dropdown styles
div.component_select-dropdown-menu
  border-radius: 2px
  border: 1px solid $color_border-section
  ul.MuiMenu-list
    padding: 0
    li.MuiMenuItem-root
      @include dropdownStyles

      &:hover, &:focus, &:active
        background-color: $color_fill-dropdown-select

      &.Mui-disabled
        opacity: 1

.dark
  div.component_select.MuiFormControl-root
    // Input Root
    .MuiInputBase-root
      .MuiSelect-icon
        color: dark.$color_input-inactive

      fieldset
        border-color: dark.$color_input-inactive

    .MuiInputBase-root.Mui-focused
      .MuiSelect-icon
        color: dark.$color_input-active

      fieldset
        border-color: dark.$color_input-active

    .MuiInputBase-root.Mui-disabled
      .MuiSelect-icon
        color: dark.$color_input-disabled

      fieldset
        border-color: $color_input-disabled

    .MuiInputBase-root.Mui-error
      .MuiSelect-icon
        color: dark.$color_input-error

      fieldset
        border-color: dark.$color_input-error

    // Input
    .MuiInputBase-input
      color: dark.$color_text-primary

    // Label
    .MuiFormLabel-root
      color: dark.$color_input-inactive

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.Mui-focused.MuiFormLabel-filled
      color: dark.$color_input-active

    .MuiFormLabel-root.MuiFormLabel-filled
      color: dark.$color_text-secondary

    .MuiFormLabel-root.Mui-disabled
      color: dark.$color_input-disabled

    .MuiFormLabel-root.Mui-error
      color: dark.$color_input-error

    // Icon
    .MuiSelect-icon
      color: dark.$color_text-primary

  div.component_select-dropdown-menu
    border: 1px solid dark.$color_border-section
    ul.MuiMenu-list
      background-color: dark.$color_fill-dropdown-dark
      li.MuiMenuItem-root
        color: dark.$color_text-dropdown
        background-color: dark.$color_fill-dropdown-select-secondary
        border-bottom: 1px solid dark.$color_divider-section

        &:hover, &:focus, &:active
          background-color: dark.$color_fill-dropdown-dark
