@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


.cp_component_edit-url-card
  background-color: $color_fill-card
  padding: $space_2x
  .input
    width: px-to-rem(646px)
  .inner-container
    background-color: $color_fill-modal
    display: flex
    flex-direction: column
    gap: $space_2x
    padding: $space_2x
    .cta-button-container
      display: flex
      gap: $space_1x
      .cta-button
        width: px-to-rem(150px)

.dark
  .cp_component_edit-url-card
    background-color: dark.$color_fill-card
    .inner-container
      background-color: dark.$color_fill-modal