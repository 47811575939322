@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

ul.cp_component_modal-add-profile_verticals-list
    padding: 0

    .MuiAutocomplete-option
        background-color: $color_border-section
        border-top: 1px solid $color_divider-section
        color: $color_text-primary
        font-size: $font-size-sm
        padding: $space_2x $space_1x
        text-transform: capitalize

        &:has(span.cp_component_loading_indicator)
            justify-content: center
    
        &.Mui-focused
        background-color: $color_fill-dropdown-select

.cp_component_modal-add-profile_youtube-selection

    .MuiMenu-paper
        background-color: $color_button-exit

    ul.MuiMenu-list
        min-width: 16rem
        padding-bottom: 0
        padding-top: 0

        li:not(first-child)
            border-top: 1px solid $color_divider-section
        
        .MuiListItemText-primary
            font-weight: $font-weight-normal
            font-size: $font-size-sm

.MuiAutocomplete-paper
    background-color: $color_border-section
    border: 1px solid $color_border-section

.cp_component_modal-add-profile
    &-label
        align-items: center
        display: flex
        justify-content: space-between
        margin-bottom: $space_1x
    .label_small-caps-bold
        color: $color_text-primary
    .MuiDialogContent-root > .cp_component_loading_indicator
        display: block
        margin: $space_3x auto
    &_add-permissions
        article
            align-items: center
            background-image: url("../../asset/img/background_webgraph_light.svg")
            background-position: 50% 50%
            background-repeat: no-repeat
            display: flex
            justify-content: center
            min-height: 20rem
        &-mag
            background-image: url("../../asset/img/icon_mag_light.svg")
            background-repeat: no-repeat
            height: px-to-rem(186px)
            width: px-to-rem(190px)
        aside
            margin-left: auto
            margin-right: auto
            text-align: center
            width: 80%
    .cp_component_modal-add-profile_requirements
        background-color: $color_button-exit
        flex: 1
        margin-top: $space_2x
        padding: $space_2x

        > div.cp_component_modal-add-profile_row:#{$middle-children}
            margin: $space_2x 0

        .cp_component_modal-add-profile_network
            padding-left: $space_3x
            
        > p.label_small
            margin-bottom: $space_1x
            text-transform: uppercase
    
    .cp_component_modal-add-profile_connections-description
        padding: $space_2x 0

    .cp_component_modal-add-profile_network-label
        margin-bottom: $space_1x
        padding-top: $space_1x
        font-weight: 500
    .cp_component_modal-add-profile_connection.cp_component_modal-add-profile_row
        gap: 0.75rem
        justify-content: space-between
        margin-top: $space_2x
        min-height: $space_8x

        &:not(:last-child)
            border-bottom: 1px solid $color_divider-section
            margin-bottom: $space_1x

        .cp_component_modal-add-profile_connection-description
            flex: 2

        p.cp_component_modal-add-profile_connection-network
            color: $color_text-primary
            text-transform: capitalize

        .cp_component_modal-add-profile_add-connection-btn-placeholder
            width: $space_5x

        .cp_component_modal-add-profile_create-placeholder
            text-decoration: underline
            color: $color_text-inlinebutton
            cursor: pointer
            background-color: inherit
            outline: none
            box-shadow: none
            border: none
            font-size: inherit
            padding: 0

        .MuiFormHelperText-root
            line-height: 1

        .cp_component_modal-add-profile_network-input-helper
            color: $color_text-secondary
            font-weight: 400
            letter-spacing: normal
        
            &.cp_component_modal-add-profile_network-input-helper_success
                color: $color_color-success
        .Mui-error    
            .cp_component_modal-add-profile_network-input-helper
                color: $color_text-secondary

        > div
            display: flex
            align-items: flex-start
        
        .cp_component_modal-add-profile_input
            margin-bottom: 0
            width: 18rem
            p.MuiFormHelperText-root
                margin-bottom: 1rem
                margin-top: $space_1x
        button.cp-btn
            // height should match the input height
            height: px-to-rem(37.5px)

    .cp_component_modal-add-profile_vertical-description
        font-size: px-to-rem(11px)
        font-weight: 500
        margin-bottom: $space_2x
        margin-top: $space_1x

    .cp_component_modal-add-profile_pills

        > .MuiChip-root
            margin-bottom: $space_1x
            text-transform: uppercase

        > .MuiChip-root:not(:last-child)
            margin-right: $space_1x

    input.MuiAutocomplete-input.MuiInputBase-input
        .MuiInputAdornment-root
            color: $color_border-input
            cursor: pointer

    .MuiFormControlLabel-label
        font-size: $font-size-sm
        font-weight: $font-weight-normal

    .cp_component_modal-add-profile_input
        margin-bottom: $space_2x

    .cp_component_modal-add-profile-button_narrow
        max-height: $space_3x

    .cp_component_modal-add-profile-hidden
        visibility: hidden

    .MuiFormControlLabel-labelPlacementEnd
        min-width: px-to-rem(116px)
        margin-right: 0

    .cp_component_divider
        margin: $space_3x 0 $space_3x 0

    .cp_component_modal-add-profile-profile

        .MuiAutocomplete-popupIndicator
            background-color: transparent
            color: $color_input-inactive

            &.MuiAutocomplete-popupIndicatorOpen
                color: $color_input-active

        .cp_component_modal-add-profile_row
            justify-content: space-between

        .cp_component_modal-add-profile_column
            border: none

        .cp_component_modal_add_profile_flag-radios
            padding-left: $space_1x

        .cp_component_modal_add_profile_flags
            border: 1px solid $color_border-section
            border-radius: $content-border-radius
            padding: $space_2x

        .cp_component_modal_add_profile_flag-label
            color: inherit
            font-size: $font-size-sm
            font-weight: $font-weight-bold

        .headline_small
            margin-bottom: $space_2x

    .cp_component_modal-add-profile-contact
        .cp_component_modal-add-profile_row
            align-items: center
            justify-content: space-between

        > *
            margin-bottom: $space_2x

        > p
            margin-bottom: $space_1x

        p.cp_component_modal-add-profile_email
            font-size: $font-size-sm
            font-weight: $font-weight-bold
            margin-bottom: 0


    h4.headline_small
        font-size: $font-size-large

    .MuiDialog-container .MuiDialogTitle-root + .MuiDialogContent-root
        padding-top: 0

    .cp_component_modal-add-profile_photo
        align-items: center
        display: flex
        gap: px-to-rem(20px)
        justify-items: start
        margin: $space_2x 0 $space_2x 0
        
        .cp-avatar.MuiAvatar-root
            background: $color_fill-social-background
            border-radius: $border-radius-circle
            border: 2px solid $color_border-section
            font-size: xx-large
            height: px-to-rem(80px)
            width: px-to-rem(80px)

    .cp_component_modal-add-profile_row
        display: flex
        flex-direction: row
        gap: $space_3x
        width: 100%
        
    .cp_component_modal-add-profile_column
        display: flex
        flex: 1
        flex-direction: column
        
    .cp_component_modal-add-profile_column_2
        display: flex
        flex: 2
        flex-direction: column

    .cp_component_modal-add-profile_panels
        gap: $space_2x 
        max-height: 50vh

        > .cp_component_modal-add-profile_column
            border: 1px solid $color_border-section
            border-radius: $content-border-radius
            max-height: 565px
            overflow-y: auto
            padding: $space_3x $space_4x
            padding-bottom: 0

.dark

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_modal-add-profile_verticals-list

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .cp_component_modal-add-profile_youtube-selection

        .MuiMenu-paper
            background-color: dark.$color_button-exit

        ul.MuiMenu-list
            
            li:not(first-child)
                border-color: dark.$color_divider-section
                
                .MuiListItemText-primary
                    color: dark.$color_text-primary

    .cp_component_modal-add-profile
        &_photo .cp-avatar.MuiAvatar-root
            background: dark.$color_fill-social-background
            border: 2px solid dark.$color_border-section
        .cp_component_modal-add-profile_panels > .cp_component_modal-add-profile_column
            border: 1px solid dark.$color_border-section

        .label_small-caps-bold
            color: dark.$color_text-primary

        &_add-permissions
            article
                background-image: url("../../asset/img/background_webgraph_dark.svg")
            &-mag
                background-image: url("../../asset/img/icon_mag_dark.svg")
        .cp_component_modal-add-profile_requirements
            background-color: dark.$color_button-exit

        .cp_component_modal-add-profile-profile
            .cp_component_modal_add_profile_flags
                border: 1px solid dark.$color_border-section

            .MuiAutocomplete-popupIndicator
                color: dark.$color_input-inactive

                &.MuiAutocomplete-popupIndicatorOpen
                    color: dark.$color_input-active

        .cp_component_modal-add-profile_connection:not(:last-child)
            border-bottom: 1px solid dark.$color_divider-section

        .cp_component_modal-add-profile_connection-account

        .cp_component_modal-add-profile_connection-avatar
            background-color: dark.$color_fill-social-background

        .cp_component_modal-add-profile_connection.cp_component_modal-add-profile_row
            p.cp_component_modal-add-profile_connection-network
                color: dark.$color_text-primary

            .cp_component_modal-add-profile_network-input-helper
                color: dark.$color_text-secondary

                &.cp_component_modal-add-profile_network-input-helper_success
                    color: dark.$color_color-success

            .Mui-error    
                .cp_component_modal-add-profile_network-input-helper
                    color: dark.$color_text-secondary

            button.cp_component_modal-add-profile_create-placeholder
                color: dark.$color_border-radio-active

        .MuiAutocomplete-inputRoot

            .MuiInputAdornment-root
                color: dark.$color_border-input
