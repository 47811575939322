@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_modal-campaign-deliverable-history-event_component
    .MuiTimelineItem-root:before
        display: none
    .MuiTimelineItem-root
        .MuiTimelineContent-root
            padding-top: 0
    .MuiTimelineDot-root
        margin: 0
        .cp-avatar.MuiAvatar-root
            background: inherit
            border: none
    &-content
        &-timestamp
            color: $color_text-follower
            font-size: 0.75rem
            font-weight: 600
        &-header
            color: $color_text-primary
            font-size: 0.75rem
            font-weight: 400
            strong
                font-weight: 700
            span
                margin-right: $space_1x
                svg.live
                    color: $color_color-error
                svg.finalized, svg.approved-text, svg.approved-media
                    color: $color_color-success
                svg.created, svg.caption-created, svg.media-added
                    color: $color_fill-icon-list
        &-body
            > *
                margin: $space_1x 0 $space_1x 0
            a
                font-size: 0.75rem
                font-weight: 400
            p
                background-color: $color_surface-secondary
                font-size: 0.75rem
                font-weight: 400
                padding: $space_1x

            div.cp-deliverable-media-wrapper
                position: relative

                div.MuiAvatar-root
                    height: px-to-rem(80px)
                    width: px-to-rem(80px)
                    img
                        border-radius: $content-border-radius
                        border: 1px solid $color_border-image-user

                a.cp-deliverable-media-link
                    display: block
                    left: px-to-rem(4px)
                    position: absolute
                    top: px-to-rem(4px)

                    button.cp-deliverable-media-btn
                        background-color: transparent

                        svg.cp-deliverable-media-icon
                            fill: $color_icon-light-theme
                            height: $space_2x
                            width: $space_2x




.dark
    .cp_modal-campaign-deliverable-history-event_component
        background-color: dark.$color_fill-modal
        border-bottom: none
        &-left-details-demographics
            color: dark.$color_text-follower

        &-content
            &-header
                color: dark.$color_text-primary
                span
                    svg.live, svg.media-removed, svg.caption-removed, svg.unfinalized
                        color: dark.$color_color-error
                    svg.finalized, svg.approved-text, svg.approved-media
                        color: dark.$color_color-success
                    svg.created, svg.caption-created, svg.media-added
                        color: dark.$color_fill-icon-list
            &-body
                a
                        color: dark.$color_fill-icon-list
                p
                    background-color: dark.$color_surface-primary
