@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_list-group-overview_component
    display: flex
    flex: 1
    flex-wrap: wrap
    height: fit-content
    
    &-loading, &-error
        align-items: center
        display: flex
        flex: 1
        justify-content: center
        margin-left: $space_4x
        padding: $space_2x

    &-header
        display: flex
        flex: 1
        margin: $space_3x

        &-folder-icon.MuiSvgIcon-root
            fill: $color_icon-search
            height: px-to-rem(36px)
            margin-right: $space_2x
            width: px-to-rem(36px)

        &-info
            display: flex
            flex-direction: column

            &-title
                font-size: px-to-rem(28px)
                margin-bottom: $space_2x

            &-subinfo
                color: $color_text-secondary
                font-weight: $font-weight-bold
    &-right-column
        display: flex
        justify-content: space-between

    &-controls
        align-items: flex-start
        display: flex
        flex: 1
        gap: $space_1x
        justify-content: flex-end
        margin-bottom: auto

        .MuiIconButton-root
            border-radius: 5px
            background-color: $color_button-exit
            color: $color_icon-exit
            height: px-to-rem(34px)
            width: px-to-rem(34px)

.dark
    .cp_list-group-overview_component
        &-header
            &-folder-icon.MuiSvgIcon-root
                fill: dark.$color_icon-search

            &-info
                &-subinfo
                    color: dark.$color_text-secondary

        &-controls
            .MuiIconButton-root
                background-color: dark.$color_button-exit
                color: dark.$color_icon-exit
