@use "../../../../../style/theme_light" as *
@use "../../../../../style/theme_dark" as dark
@use '../../../../../style/_styles' as *


div.cp_component_navigation-bar-templates-container
    flex-grow: 1
    overflow-y: scroll
    padding: 0
    display: flex
    flex-direction: column

// Light Theme Styles
div.cp_component_unassigned-table
    max-height: 100%
    width: 100%

    .from-label-cell
        white-space: normal
        font-size: px-to-rem(14px)

    // Rows
    .MuiDataGrid-row
        min-height: 0
        height: $space_3x
        position: relative
        &:hover
            background-color: $color_fill-list-hovered
            cursor: pointer
        &:not(:last-child):before
            border-bottom: 1px solid $color_divider-section
            bottom: 0
            content: ""
            height: 1px
            left: 0
            margin: 0 $space_2x
            position: absolute
            width: calc(100% - $space_4x)


// Dark Theme Styles
.dark div.cp_component_unassigned-table
    // TABLE STYLES
    // Headers
    .MuiDataGrid-columnHeaders
        background-color: dark.$color_fill-modal
        border-bottom: 1px solid dark.$color_border-section

    // Header Titles
    .cp_component_unassigned-table-custom-header
        color: dark.$color_text-secondary

    // Rows
    .MuiDataGrid-row
        &:hover
            background-color: dark.$color_fill-list-hovered
        &:not(:last-child)    
            &:before
                border-bottom: 1px solid dark.$color_divider-section
