@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_empty-splash
  margin: $space_6x 0 0 0

  article
    align-items: center
    background-image: url("../../asset/img/background_webgraph_light.svg")
    background-position: 50% 50%
    background-repeat: no-repeat
    background-size: 100% 100%
    display: flex
    justify-content: center
    min-height: 4rem
    min-width: 4rem

  aside
    text-align: center

  div.cp_component_empty-splash-icon-mag
    background-image: url("../../asset/img/icon_mag_light.svg")
    background-repeat: no-repeat
    background-size: 100% 100%
    height: 8.125rem
    width: 8.125rem

  h4
    margin: $space_3x 0 0 0

  p
    margin: $space_3x 0 0 0

// Dark Theme Styles
.dark div.cp_component_empty-splash
  article
    background-image: url("../../asset/img/background_webgraph_dark.svg")

  div.cp_component_empty-splash-icon-mag
    background-image: url("../../asset/img/icon_mag_dark.svg")
 