@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *


.cp_list-management-table_component
    display: flex
    flex: 1
    flex-direction: column
    gap: $space_1x
    width: 100%

    &-table
        .MuiDataGrid-root
            border: none

            .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus-within
                outline: none

        .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders
            background-color: $color_fill-table-header
            box-shadow: none

        .MuiDataGrid-virtualScroller
            min-height: 100px

        .MuiDataGrid-pinnedColumns
            background-color: $color_table-pinned-column-background
            box-shadow: none

        span.MuiDataGrid-checkboxInput.MuiCheckbox-root
            color: $color_border-radio-inactive

        .MuiDataGrid-row:hover, .Mui-hovered
            background-color: inherit !important


    &-column
        &-context
        &-feedback
            cursor: pointer
            &-content
                color: $color_text-primary
                display: -webkit-box
                font-size: $font-size-sm
                font-weight: $font-weight-normal
                max-width: 200px
                overflow: hidden
                -webkit-box-orient: vertical
                -webkit-line-clamp: 2
                white-space: normal
                word-break: break-word

                &_none
                    color: $color_text-tab-inactive
                    font-size: $font-size-sm

            &-count
                color: $color_text-primary
                font-size: $font-size-sm
                font-weight: $font-weight-bolder
                margon-bottom: $space_1x

        &-status
            align-items: center
            display: flex
            gap: $space_1x

            &-label
                font-size: 0.75rem
                font-weight: $font-weight-bolder
            
        &-visible.MuiIconButton-root
            border-radius: 5px

        &-visible_active.MuiIconButton-root
            background-color: $color_button-visible
            color: $color_text-invert
        &-visible_inactive.MuiIconButton-root
            background-color: $color_button-visible-off
            color: $color_icon-exit
    
        &-name
            color: $color_border-secondarybutton
            font-size: 0.75rem
            font-weight: $font-weight-bolder

        &-account
            display: flex
            gap: $space_1x

            &-info
                display: flex
                flex-direction: column

            &-username
                color: $color_text-primary
                font-size: $font-size-normal
                font-weight: $font-weight-normal

            &-followers
                color: $color_text-follower
                font-size: $font-size-sm
                font-weight: $font-weight-bolder

        &-activity

            &-date
                color: $color_text-primary
                font-size: $font-size-sm
                font-weight: $font-weight-bolder

            &-creator
                color: $color_text-secondary
                font-size: $font-size-sm
                font-weight: $font-weight-normal

.dark
    .cp_list-management-table_component

        &-table

            .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders
                background-color: dark.$color_fill-table-header
                border: none

            .MuiDataGrid-pinnedColumns
                background-color: dark.$color_table-pinned-column-background

            .MuiDataGrid-withBorderColor
                border-color: dark.$color_border-section

            .MuiIconButton-root
                color: dark.$color_border-secondarybutton

            span.MuiDataGrid-checkboxInput.MuiCheckbox-root
                color: dark.$color_border-radio-inactive

        &-column
            &-context
                .MuiIconButton-root
                    color: dark.$color_text-primary

            &-feedback
                &-content
                    color: dark.$color_text-secondary
                    
                    &_none
                        color: dark.$color_text-tab-inactive

                &-count
                    color: dark.$color_text-primary
                
            &-visible_active.MuiIconButton-root
                background-color: dark.$color_button-visible
                color: dark.$color_text-invert

            &-visible_inactive.MuiIconButton-root
                background-color: dark.$color_button-visible-off
                color: dark.$color_text-secondary
        
            &-name
                color: dark.$color_border-secondarybutton

            &-account
                &-username
                    color: dark.$color_text-primary

                &-followers
                    color: dark.$color_text-follower

            &-activity

                &-date
                    color: dark.$color_text-primary

                &-creator
                    color: dark.$color_text-secondary

