@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component-campaign-stat-card
  align-items: center
  border: 1px solid $color_divider-section
  display: flex
  flex: 1 0 0
  flex-direction: column
  gap: $space_1x
  height: px-to-rem(124px)
  text-align: center
  padding: $space_3x $space_3x

  .statistic
    color: $color_text-inlinebutton
    font-size: px-to-rem(40px)
    font-weight: $font-weight-bold

  .title
    color: $color_text-secondary
    font-size: px-to-rem(12px)

.dark
  .cp_component-campaign-stat-card
    border-color: dark.$color_divider-section

    .statistic
      color: dark.$color_text-inlinebutton

    .title
      color: dark.$color_text-secondary
