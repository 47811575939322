@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_user-campaigns-table.cp_component-data-grid
  height: 80%

  .not-creator, .not-creator .MuiSvgIcon-root, .not-creator .cp_data-grid_activity-timestamp
    opacity: 0.3

  .MuiDataGrid-row:not(:has(.not-creator))
    cursor: pointer

  .campaign
    align-items: center
    display: flex
    gap: $space_1x
  .status-stamp, .permissions
    align-items: center
    display: flex
    gap: 0.25rem
    p, .MuiSvgIcon-root
      color: $color_text-secondary
.cp_component_user-campaigns-table-no-results
  align-items: center
  color: $color_text-inactive
  display: flex
  height: 100%
  justify-content: center

.dark
  .cp_component_user-campaigns-table.cp_component-data-grid
    .filter-icon
      color: dark.$color_border-secondarybutton
    .email-label
      color: dark.$color_text-secondary
    .status-stamp, .permissions
      p, .MuiSvgIcon-root
        color: dark.$color_text-secondary
  .cp_component_user-campaigns-table-no-results
    color: dark.$color_text-inactive
