@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_campaign-deliverable-caption-v2_component
    background-color: $color_fill-modal
    border-radius: $content-border-radius
    flex: 1 0 8rem
    padding: $space_2x

    &-text
        color: $color_text-secondary
        font-size: px-to-rem(12px)
        font-weight: $font-weight-normal
        overflow-wrap: break-word
        white-space: normal
        &.compact
            -webkit-box-orient: vertical
            -webkit-line-clamp: 4
            display: -webkit-box
            overflow: hidden
            white-space: pre-line


    .body_large
        color: $color_text-primary
        font-size: 1rem
        font-weight: $font-weight-bold
        margin-bottom: 0.25rem

    &-controls
        display: flex
        gap: $space_1x
        justify-content: flex-start
        margin-top: $space_1x
        .btn-approve-caption
            &.active.MuiIconButton-root
                background-color: $color_button-visible
                color: $color_surface-primary
            &.approved.MuiIconButton-root
                background-color: $color_color-success
                color: $color_surface-primary
        .btn-feedback
            &.active.MuiIconButton-root
                background-color: $color_button-visible
                .MuiSvgIcon-root
                    color: $color_surface-primary
        .cp-badge-component .MuiBadge-badge
            background-color: $color_button-visible
            border: 1px solid $color_surface-primary
            color: $color_surface-primary
            top: 10%
            right: 10%

.dark
    .cp_campaign-deliverable-caption-v2_component
        background-color: dark.$color_fill-modal
        &-text
            color: dark.$color_text-secondary
        .body_large
            color: dark.$color_text-primary
        &-controls
            .btn-approve-caption.approved.MuiIconButton-root
                    background-color: dark.$color_color-success
                    color: dark.$color_fill-modal
            .btn-feedback
                &.active.MuiIconButton-root
                    background-color: dark.$color_button-visible
                    .MuiSvgIcon-root
                        color: dark.$color_surface-primary
            .cp-badge-component .MuiBadge-badge
                background-color: dark.$color_button-visible
                border: 1px solid $color_surface-primary
                color: dark.$color_surface-primary
