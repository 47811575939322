@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_lcv-configuration-options
  .config-option
    align-items: center
    display: flex
    margin-bottom: px-to-rem(12px)
    padding-left: px-to-rem(10px)
    &.dragging
      opacity: 20%
    &-switch 
      &.MuiSwitch-root 
        height: $space_2x
        padding: 0 
        width: px-to-rem(28px)
      
      .MuiSwitch-switchBase 
        padding: px-to-rem(2px)

        &.Mui-checked 
          transform: translateX(12px)

          + .MuiSwitch-track 
            background-color: $color_button-primary
            opacity: 1
        
      .MuiSwitch-track 
        background-color: $color_button-toggle-off
        border-radius: $space_1x 
        opacity: 1 
      
      .MuiSwitch-thumb 
        background-color: $color_surface-primary
        height: px-to-rem(12px)
        width: px-to-rem(12px)
    
      &-additional_fields
        padding: $space_1x 
        div.cp_component_input 
          width: 100%
          margin-bottom: $space_2x

      &-pills
        display: flex
        gap: $space_1x
        flex-wrap: wrap
        margin-bottom: $space_2x
    
    &-label
      @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)
      display: inline-block
      line-height: 1.33 
      max-width: 190px 
      padding-left: $space_1x 
    &-dragicon
      cursor: grab
      fill: $color_fill-drag-icon
      font-size: $space_2x
      margin-left: auto

.dark .cp_lcv-configuration-options
  .config-option
    &-switch
      .MuiSwitch-switchBase 

        &.Mui-checked 

          + .MuiSwitch-track 
            background-color: dark.$color_button-primary

      .MuiSwitch-track 
        background-color: dark.$color_button-toggle-off
      

    &-label
      color: dark.$color_text-secondary
