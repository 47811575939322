@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_communications-group-accounts-container
  display: flex
  flex-direction: column
  flex: 7
  gap: $space_2x

  .header-section
    display: flex
    justify-content: space-between

    div.cp_component_search-bar-input.cp_component_input.search-bar
      background: $color_fill-card
      margin: 0
      padding: 0
      width: px-to-rem(320px)

      .MuiInputBase-root fieldset
        border: 1px solid $color_border-colorcard

    .details-container
      align-items: center
      display: flex
      gap: $space_2x
    
  .communications-body
    background: $color_fill-card
    border: 1px solid $color_border-colorcard
    border-radius: $content-border-radius
    flex: 1
    height: 90%
    width: 100%

    &_no-search-results-overlay
      @include textStyles($color_input-error, $font-size-normal, $font-weight-normal)
      height: 100%
      margin-top: $space_4x
      text-align: center
      vertical-align: middle
      width: 100%

    .contact-info-name
      font-size: px-to-rem(14px)

    .contact-info-email
      font-size: px-to-rem(12px)

    .table-add-to-group
      font-size: px-to-rem(14px)

    #label-pills
      display: flex
      flex-wrap: wrap
      align-content: center
      width: px-to-rem(350px)

      .cp-pill.MuiChip-root
        margin: px-to-rem(3px)

      .custom-pill-color-contracting
        background-color: $color_fill-pill-2
        border-color: $color_border-pill-2

      .custom-pill-color-garnering-content
        background-color: $color_fill-pill-4
        border-color: $color_border-pill-4

      .custom-pill-color-opted-in
        background-color: $color_fill-pill-3
        border-color: $color_border-pill-3
  
    // TABLE STYLES
    // Root Table
    .MuiDataGrid-root
      border: 0
      border-radius: 0
      font-family: "Open Sans", sans-serif

    // Empty/Placeholder State
    &-empty
      .MuiDataGrid-virtualScroller
        overflow: hidden

      div.cp_component_empty-splash
        margin: 6rem 0 0 0

    // Headers
    .MuiDataGrid-columnHeaders
      background-color: $color_fill-social-background
      border-bottom: none
      border-top-left-radius: 0
      border-top-right-radius: 0

    // Header Titles
    .MuiDataGrid-columnHeaderTitle
      color: $color_text-secondary
      font-size: 0.75rem
      font-weight: 700
      text-transform: uppercase

    // Table Data Container
    .MuiDataGrid-virtualScroller
      background-color: $color_fill-card

    // Pinned Columns
    .MuiDataGrid-pinnedColumnHeaders
      background-color: $color_fill-social-background
      box-shadow: none

    .MuiDataGrid-pinnedColumns
      background-color: $color_table-pinned-column-background
      box-shadow: none
      overflow: visible

    // Cells
    .MuiDataGrid-cell
      border-bottom: 1px solid $color_border-section
      cursor: pointer
      padding-bottom: $space_1x
      padding-top: $space_1x

    // Checkboxes
    .MuiDataGrid-cellCheckbox,
    .MuiDataGrid-columnHeader
      .MuiSvgIcon-root
        color: $color_border-radio-inactive
        font-size: 1rem

      .Mui-disabled .MuiSvgIcon-root
        color: $color_input-disabled
        opacity: 0.3

      .MuiDataGrid-overlayWrapper
        .MuiDataGrid-overlayWrapperInner
          .MuiDataGrid-overlay
            background-color: $color_fill-card
            color: $color_text-primary

.dark
  .cp_component_communications-group-accounts-container
    .header-section
      div.cp_component_search-bar-input.cp_component_input.search-bar
        background: dark.$color_fill-card
        .MuiInputBase-root fieldset
          border: 1px solid dark.$color_border-colorcard

    .communications-body
      background: dark.$color_fill-card
      border: 1px solid dark.$color_border-colorcard
      #label-pills
        .custom-pill-color-contracting
          background-color: dark.$color_fill-pill-2
          border-color: dark.$color_border-pill-2

        .custom-pill-color-garnering-content
          background-color: dark.$color_fill-pill-4
          border-color: dark.$color_border-pill-4

        .custom-pill-color-opted-in
          background-color: dark.$color_fill-pill-3
          border-color: dark.$color_border-pill-3
      
      // TABLE STYLES
      // Headers
      .MuiDataGrid-columnHeaders
        background-color: dark.$color_fill-social-background

      // Header Titles
      .MuiDataGrid-columnHeaderTitle
        color: dark.$color_text-secondary

      // Table Data Container
      .MuiDataGrid-virtualScroller
        background-color: dark.$color_fill-card

      // Pinned Columns
      .MuiDataGrid-pinnedColumnHeaders
        background-color: dark.$color_fill-social-background

      .MuiDataGrid-pinnedColumns
        background-color: dark.$color_table-pinned-column-background

      // Cells
      .MuiDataGrid-cell
        border-bottom: 1px solid dark.$color_border-section

      // Checkboxes
      .MuiDataGrid-cellCheckbox,
      .MuiDataGrid-columnHeader
        .MuiSvgIcon-root
          color: dark.$color_border-radio-inactive

        .Mui-disabled .MuiSvgIcon-root
          color: dark.$color_input-disabled

      .MuiDataGrid-overlayWrapper
        .MuiDataGrid-overlayWrapperInner
          .MuiDataGrid-overlay
            background-color: dark.$color_fill-card
            color: dark.$color_text-primary