@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
  
.communications-email-list-view
  &.error,
  &.loading,
  .email-list
    &-heading 
      align-items: center
      border-bottom: 1px solid $color_border-section
      display: flex
      height: px-to-rem(60px)
      padding: $space_2x

      span.MuiCheckbox-root
        padding: 0
        visibility: hidden

    &-email 
      border-bottom: 1px solid $color_divider-section
      column-gap: $space_1x
      cursor: pointer
      display: flex
      padding: $space_2x 0

      &.selected
        background-color: $color_fill_list-hovered

      &-selector
        align-items: flex-start
        display: flex
        justify-content: flex-end
        padding-left: $space_2x

        span.MuiCheckbox-root
          padding: 0

      .email-content
        flex: 1
        margin-right: $space_2x
        width: calc(100% - 2.5rem)
      .email-content-top
        align-items: center
        display: flex
        justify-content: space-between
        width: 100%

        &-right
          align-items: center
          column-gap: $space_1x
          display: flex
          justify-content: flex-end

          svg.email-attachment-icon 
            width: $space_2x
            path
              fill: $color_icon-pill
      .handle-name,
      .subject
        @include textStyles($color_text-primary, $font-size-sm, $font-weight-normal)
        gap: $space_1x
        margin-bottom: px-to-rem(6px)
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        &.message-failed
          color: #FF0000
          font-weight: 600
          vertical-align: middle
        
        .message-failed-icon
          padding-bottom: $space_1x
          transform: scale(0.8)
        
      .date
        @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bold)
      .body
        @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-normal)
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden

      .handle-name
        align-items: center
        display: flex

        .unread-message-qty
          @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-bolder)      
          background-color: $color_color-error-light
          border-radius: 4px
          display: inline-block
          margin-left: $space_1x
          padding: px-to-rem(3px) px-to-rem(5px)

      &.has-unread-messages
        .handle-name,
        .subject
          font-weight: $font-weight-bolder
  &.loading
    padding-top: $space_5x

  .communications-email-list-infinite-scrolling
    height: calc(100% - 60px)
    overflow-y: scroll
.dark
  .communications-email-list-view
    .email-list

      &-heading,
      &-email 
        border-bottom-color: dark.$color_border-section

      &-emails
        &.selected
          background-color: dark.$color_fill-deliverable-card

        .handle-name,
        .subject
          color: dark.$color_text-primary

        .handle-name
          .unread-message-qty
            background-color: dark.$color_color-error-light
            color: dark.$color_text-primary
          
        .date,
        .body
          color: dark.$color_text-secondary

        svg.email-attachment-icon 
          width: $space_2x
          path
            fill: dark.$color_icon-pill

