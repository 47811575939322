@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *


.cp_comment_component
    background-color: $color_surface-secondary
    border: 1px solid $color_border-modal
    border-radius: $content-border-radius
    padding: $space_2x

    &-header
        display: flex
        justify-content: space-between
        padding-bottom: $space_2x

        &-title
            font-size: $font-size-normal
            font-weight: $font-weight-bold

        &-timestamp
            color: $color_text-secondary
            font-size: $font-size-sm
            font-weight: $font-weight-bolder
            text-transform: uppercase
    
    &-content
        padding: $space_2x $space_3x 0 $space_3x
        word-break: break-word

.cp_create-feedback_component

    &-approved
        align-items: center
        display: flex

        &-label
            font-size: $font-size-sm
            font-weight: $font-weight-bolder
        
        .cp-switch.MuiSwitch-root.cp-switch_with-icon .Mui-checked .MuiSwitch-thumb
            background-image: url($switch-icon-checked-svg)

    &-content
        padding: 0 $space_1x $space_1x
        &-comments
            display: flex
            flex-direction: column
            gap: $space_1x
            border-top: 1px solid $color_border-modal
            padding: $space_2x $space_1x 0 $space_1x
            margin-top: $space_1x
            
            &-title
                font-size: $font-size-normal
                font-weight: $font-weight-bold

        &-feedback

            &-input
                margin-bottom: $space_2x
                min-width: px-to-rem(400px)
.dark
    .cp_comment_component
        background-color: dark.$color_fill-overlay-modal
        border: 1px solid dark.$color_border-modal

        &-header
            &-timestamp
                color: dark.$color_text-secondary
        
        &-content
            color: dark.$color_text-secondary


    .cp_create-feedback_component

        &-approved
            .cp-switch.MuiSwitch-root.cp-switch_with-icon .Mui-checked .MuiSwitch-thumb
                background-image: url($switch-icon-checked-dark-svg)
