@use "../../../style/primitives" as primitives
@use "../../../style/theme_dark" as dark
@use "../../../style/theme_light" as *
@use "../../../style/styles" as *
  
.cp_account-page-tabs-content
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  padding: px-to-rem(30px)

  &-heading
    @include textStyles($color_text-primary, px-to-rem(22px), $font-weight-normal)
    border-bottom: 1px solid $color_border-colorcard
    margin-bottom: $space_3x
    padding-bottom: px-to-rem(10px)

  .account-insights-v2,
  .audience-v2,
  .content-v2
    .cp_account-insights-details_component
      padding: 0 5%
      &-widget
        margin-bottom: $space_4x
        margin-top: 0

.dark .cp_account-page-tabs-content
  border-color: dark.$color_border-colorcard

  &-heading
    border-color: dark.$color_border-colorcard
    color: dark.$color_text-primary
