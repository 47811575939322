@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_social-avatar
  align-items: center
  display: flex
  gap: $space_2x
  justify-content: flex-start

  // Placeholder Account Text
  p.cp_component-social-avatar-placeholder
    color: $color_text-placeholder

  // Avatar and network icon
  > aside
    background-size: px-to-rem(50px) px-to-rem(50px)
    border-radius: 50%
    height: px-to-rem(50px)
    min-width: px-to-rem(50px)
    position: relative

    > span
      bottom: px-to-rem(-5px)
      height: $space_3x
      position: absolute
      right: px-to-rem(-5px)
      width: $space_3x

      i.cp-network-icon-svg
        height: 0.9rem
        width: 0.9rem

  // Account details
  > section
    max-width: 13rem

    // Indicator icons
    aside
      height: $space_2x
      display: flex
      column-gap: 10px
      align-items: center

      > .cp_component_nsfw-label-default
        position: relative
        top: 0
        left: 0

    div
      align-items: center
      display: flex
      gap: $space_1x
      justify-content: flex-start
      width: 100%

    svg
      height: $space_2x
      width: $space_2x

    svg.cp_component_social-avatar-blacklisted-icon
      color: $color_icon-blacklisted

    svg.cp_component_social-avatar-inactive-icon
      color: $color_icon-inactive

    svg.cp_component_social-avatar-placeholder-icon
      color: $color_icon-placeholder

    svg.cp_component_social-avatar-unsubscribed-icon
      color: $color_icon-unsubscribe

    // Account text
    p
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    
    .cp_component_social-avatar-e-badge-tool-tip.MuiTooltip-tooltip
      width: 12rem

    div.cp_component_social-avatar-e-badge             
      background-image: url("../../asset/img/e_badge.svg")
      background-position: right
      background-repeat: no-repeat
      background-size: cover
      cursor: pointer
      min-height: px-to-rem(17px)
      min-width: px-to-rem(50px)
      width: px-to-rem(57px)  
      margin-top: .25rem

// Dark Theme Styles
.dark
  div.cp_component_social-avatar-account-cell
    // Placeholder Account Text
    p.cp_component-social-avatar-placeholder
      color: dark.$color_text-placeholder

    // Account details
    > section
      svg.cp_component_social-avatar-blacklisted-icon
        color: dark.$color_icon-blacklisted

      svg.cp_component_social-avatar-inactive-icon
        color: dark.$color_icon-inactive

      svg.cp_component_social-avatar-placeholder-icon
        color: dark.$color_icon-placeholder

      svg.cp_component_social-avatar-unsubscribed-icon
        color: $color_icon-unsubscribe
  div.cp_component_social-avatar  
    > section
      div.cp_component_social-avatar-e-badge             
        background-image: url("../../asset/img/e_badge_dark.svg")
