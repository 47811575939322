@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_coming-soon-splash
  margin: $space_6x 0 0 0

  article
    align-items: center
    background-image: url("../../asset/img/background_webgraph_light.svg")
    background-position: 50% 50%
    background-repeat: no-repeat
    background-size: 100% 100%
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 15rem
    min-width: 15rem
    h4
      font-size: px-to-rem(24px)
    h2
      color: $color_brand-blue-deep
      font-size: px-to-rem(57px)


// Dark Theme Styles
.dark div.cp_component_coming-soon-splash
  article
    background-image: url("../../asset/img/background_webgraph_dark.svg")
    h2
      color: dark.$color_fill-logo