@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_communications-sidebar
  background: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  flex-direction: column
  flex: 1.2
  gap: $space_1x
  min-width: px-to-rem(210px)

  .comm-list-item-button
    color: $color_text-primary

    .comm-list-item-icon
      color: $color_text-primary

  .create-message-button
    margin: $space_2x

  .inbox-category-label
    align-items: center
    display: flex
    flex-direction: row
    gap: $space_1x
    padding: 0 $space_2x
    .MuiSvgIcon-root
      height: px-to-rem(16px)
      width: px-to-rem(16px)
      fill: $color_icon-list
    p
      color: $color_text-primary
      font-size: px-to-rem(14px)

.dark
  .cp_component_communications-sidebar
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard

    .comm-list-item-button
      color: dark.$color_text-primary

      .comm-list-item-icon
        color: dark.$color_text-primary

      .inbox-category-label
        .MuiSvgIcon-root
          fill: dark.$color_icon-list
        p
          color: dark.$color_text-primary

