@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

div.cp_component_profile-tab-account-container
    display: flex
    flex-direction: column
    padding: 0

    .cp_component-profile-tab-social-account-row
        align-items: center
        border-top: 1px solid $color_divider-section
        display: flex
        padding: $space_2x 0
        width: 100%
        &:hover
            cursor: pointer
        &:last-child
            border-bottom: 1px solid $color_divider-section

        .cp-avatar.MuiAvatar-root
            border: none

        p
            font-family: $font-family-primary
            margin-left: $space_2x

        .cp_component-profile-tab-chevron-right
            margin-left: auto

.dark
    div.cp_component_profile-tab-account-container
        .cp_component-profile-tab-social-account-row
            border-color: dark.$color_divider-section