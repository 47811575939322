@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component-ai-filter-panel
  background-color: $color_fill-modal
  border: 1px solid $color_border-search
  border-radius: 2px
  &-head
    border-bottom: 1px solid $color_divider-list
    &:has(.MuiIconButton-root:not(.expanded))
      border-bottom-width: 0
    .filter-panel-icon.MuiSvgIcon-root, svg.filter-panel-icon
      fill: $color_input-active
      height: 20px
      width: 20px
      &:not(.expanded)
        fill: $color_text-inactive
        transition: fill 0.3s
    .MuiBadge-badge
      color: $color_text-invert
      z-index: unset

  .MuiIconButton-root
    color: $color_text-inactive
    transform: rotate(-90deg)
    transition: all 0.3s
    &.expanded
      color: $color_input-active
      transform: rotate(0deg)
      transition: all 0.3s
  
  div.cp_component_input
    width: 100%

// Dark Theme Styles
.dark
  div.cp_component-ai-filter-panel
    background-color: dark.$color_fill-modal
    border: none
    &-head
      border-bottom: 1px solid #80B7B9
      &:has(.MuiIconButton-root:not(.expanded))
        border-bottom-width: 0
      .MuiIconButton-root
        color: dark.$color_input-inactive
        &.expanded
          color: dark.$color_brand-blue-teal
          transition: all 0.3s
      .filter-panel-icon.MuiSvgIcon-root, svg.filter-panel-icon
        fill: dark.$color_brand-blue-teal
        &:not(.expanded)
          fill: dark.$color_text-inactive
          transition: fill 0.3s
      .MuiBadge-badge
        color: inherit






