@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/styles' as *

.cp_component_status
    border-radius: 50%
    display: inline-block

    &.cp_component_status_md
        width: $space_1x
        height: $space_1x

    &.cp_component_status_sm
        width: 0.25rem
        height: 0.25rem

    &.cp_component_status_error
        background-color: $color_color-error
    &.cp_component_status_warning
        background-color: $color_color-warning
    &.cp_component_status_support
        background-color: $color_color-support
    &.cp_component_status_success
        background-color: $color_color-success
    &.cp_component_status_info
        background-color: $color_color-info
    &.cp_component_status_inactive
        background-color: $color_color-inactive

.dark
    .cp_component_status
        &.cp_component_status_error
            background-color: dark.$color_color-error
        &.cp_component_status_warning
            background-color: dark.$color_color-warning
        &.cp_component_status_support
            background-color: dark.$color_color-support
        &.cp_component_status_success
            background-color: dark.$color_color-success
        &.cp_component_status_inactive
            background-color: dark.$color_color-inactive