@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

$carousel-arrow-height: px-to-rem(20px)

section.cp_component_selection-carousel
  display: flex
  gap: $space_2x
  position: relative
  width: fit-content
  &.no-start-preivew
    padding-left: px-to-rem(60px)

  button.carousel-arrow
    background-color: transparent
    border: none
    position: absolute
    z-index: 10

    .MuiSvgIcon-root
      fill: $color_icon-pill

    &:hover
      cursor: pointer

    &.left-carousel-arrow
      top: calc(50% - ($carousel-arrow-height / 2))
      left: px-to-rem(15px)

    &.right-carousel-arrow
      top: calc(50% - ($carousel-arrow-height / 2))
      right: px-to-rem(15px)

  div.carousel-option
    position: relative
    border: 1px solid $color_border-image
    border-radius: 2px

    img
      width: px-to-rem(110px)
      height: px-to-rem(126px)

    // Selected Option styles
    &.option-selected
      border-color: $color_brand-blue-teal

    div.option-selected-icon
      position: absolute
      top: px-to-rem(5px)
      right: px-to-rem(5px)
      .MuiSvgIcon-root
        height: $carousel-arrow-height
        width: $carousel-arrow-height
        fill: $color_brand-blue-teal
    // Preview styles
    &.start-preview
      mask-image: linear-gradient(270deg,#000, transparent 50%, transparent)

    &.end-preview
      mask-image: linear-gradient(90deg,#000, transparent 50%, transparent)
section.cp_component_selection-carousel
  display: flex
  gap: $space_2x
  position: relative

  button.carousel-arrow
    background-color: transparent
    border: none
    position: absolute
    z-index: 10

    .MuiSvgIcon-root
      fill: $color_icon-pill

    &:hover
      cursor: pointer

    &.left-carousel-arrow
      left: px-to-rem(15px)

    &.right-carousel-arrow
      right: px-to-rem(15px)

  div.carousel-option
    position: relative
    border: 1px solid $color_border-image
    border-radius: 2px

    img
      width: px-to-rem(110px)
      height: px-to-rem(126px)

    // Selected Option styles
    &.option-selected
      border-color: $color_brand-blue-teal

    div.option-selected-icon
      position: absolute
      top: px-to-rem(5px)
      right: px-to-rem(5px)
      .MuiSvgIcon-root
        fill: $color_brand-blue-teal
    // Preview styles
    &.start-preview
      mask-image: linear-gradient(270deg,#000, transparent 50%, transparent)

    &.end-preview
      mask-image: linear-gradient(90deg,#000, transparent 50%, transparent)

.dark
  section.cp_component_selection-carousel
    button.carousel-arrow
      .MuiSvgIcon-root
        fill: dark.$color_icon-pill

    div.carousel-option
      border-color: dark.$color_border-image

      &.option-selected
        border-color: dark.$color_icon-list

      div.option-selected-icon
        .MuiSvgIcon-root
          fill: dark.$color_icon-list
