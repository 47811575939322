@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp_avatar-brand_component
  &-avatar
    min-height: $space_7x
    min-width: $space_7x

  &-badge > .MuiBadge-badge
        background: $color_surface-brand
        border: 1px solid $color_fill-card
        color: $color_fill-card

.dark
  .cp_avatar-brand_component
    &-badge
      > .MuiBadge-badge
        background: dark.$color_fill-card
        border: 1px solid dark.$color_border-image-user
        color: dark.$color_fill-logo
