@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_list_public-list-content-top-posts
  ul.cp_list_top-post-list
    display: flex
    gap: px-to-rem(12px)
    overflow-x: auto
    padding-bottom: $space_4x
    padding: px-to-rem(12px)

  .cp_list_top-post
    &-item,
    &-removed
      border: 1px solid $color_fill-trait-score
      height: 124px
      min-width: 116px
      overflow: hidden
      position: relative
      width: 116px
      img
        width: 100%
        height: 100%
        object-fit: cover

    &-item
      &-info
        background-color: transparent
        color: $color_text-invert
        display: flex
        flex-direction: column
        height: 100%
        left: 0
        opacity: 0
        overflow: auto
        padding-top: $space_3x
        position: absolute
        top: 0
        transform: translateY(100%)
        transition: all 0.5s ease
        width: 100%
        z-index: 1
        .cp_pif-date
          @include textStyles(primitives.$color_gray-dark-100, px-to-rem(14px), $font-weight-normal)
          margin-bottom: $space_3x
          padding: 0 $space_3x
          time
            margin-right: $space_1x

        .cp_pif-content
          @include textStyles(primitives.$color_gray-dark-100, px-to-rem(12px), $font-weight-normal)
          height: 150px
          margin-bottom: $space_2x
          overflow: auto
          padding: 0 $space_3x
        .cp_pif-social-data
          align-items: center
          background-color: primitives.$color_gray-920-a30
          column-gap: px-to-rem(18px)
          display: flex
          flex-grow: 1
          justify-content: center
          padding: 0 $space_3x
          span
            align-items: center
            column-gap: px-to-rem(6px)
            display: flex
          .cp_pif-rate
            .cp-pill.MuiChip-root
              background-color: primitives.$color_orange-light-a20
              border: 1px solid primitives.$color_orange-light
              color: primitives.$color_gray-dark-100
          .cp_pif-comments,
          .cp_pif-likes
            @include textStyles(primitives.$color_gray-dark-100, px-to-rem(12px), $font-weight-bold)
      span.MuiCircularProgress-root.cp_component_loading_indicator
        left: calc(50% - 9px)
        position: absolute
        top: calc(50% - 9px)

  ul.cp_list_top-post-list.overview-display-on
    flex-wrap: wrap
    gap: $space_3x
    max-width: 1090px
    overflow-x: auto
    padding: $space_5x
    .cp_list_top-post-item
      aspect-ratio: 16 / 15
      max-width: 320px
      min-height: 300px
      min-width: 265px
      width: 33.33%

      .MuiChip-root.cp_pif-reel-chip
        background-color: $color_fill-social-icon
        color: $color_text-secondary
        font-size: px-to-rem(10px)
        height: 20px

    .cp_list_top-post-item:hover
      .cp_list_top-post-item-info
        background-color: rgba(23, 28, 34, 1)
        opacity: 1
        transform: translateY(0)

    .cp_list_top-post-item.no-img
      .cp_list_top-post-item-info
        background-color: $color_fill-card-default
        opacity: 1
        transition: none
        transform: translateY(0)

        .MuiChip-root.cp_pif-reel-chip
          width: 56px
          margin-left: $space_3x

    .cp_list_top-post-item-remove
      display: none

.dark
  .cp_list_public-list-content-top-posts
    ul.cp_list_top-post-list.overview-display-on
      .cp_list_top-post-item.no-img
        .cp_list_top-post-item-info
          background-color: dark.$color_fill-card-default
