@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/_styles" as *

div.cp_component_social-tracker-network-icons
  display: flex
  gap: 0.3rem
  .social-tracker-network-icon
    height: px-to-rem(24px)
    width: px-to-rem(24px)

    i
      height: px-to-rem(12px)
      width: px-to-rem(12px)