@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_menu-item.MuiMenuItem-root
    color: $color_text_secondary
    display: flex
    justify-content: space-between
    min-width: px-to-rem(115px)
    
    &:hover
        background-color: $color_fill-dropdown-select

    p
        color: $color_text-dropdown
        font-family: $font-family-primary
        font-size: $font-size-sm
    .MuiSvgIcon-root
        fill: $color_icon-pill
        height: px-to-rem(12px)
        width: px-to-rem(12px)

.dark
    .cp_component_menu-item.MuiMenuItem-root
        color: dark.$color_text_secondary
        &:hover
            background-color: dark.$color_fill-dropdown-select
        p
            color: dark.$color_text-dropdown
        .MuiSvgIcon-root
            fill: dark.$color_icon-pill