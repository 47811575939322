@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_relevant_posts-keywords
  padding: 0 .75rem
  .cp_relevant_posts-top-controls
    display: flex
    justify-content: space-between
    padding: px-to-rem(20px) 0

    .cp_relevant_posts-top-controls
      &-remove
        display: flex
        justify-content: flex-end
        align-items: center
        color: $color_text-inlinebutton
        svg
          color: $color_text-inlinebutton
        p
          @include textStyles($color_text-inlinebutton, px-to-rem(10px), $font-weight-bolder)
        button.MuiButtonBase-root:hover
          background-color: transparent


.dark
  .cp_relevant_posts-keywords
    .cp_relevant_posts-top-controls
      .cp_relevant_posts-top-controls
        &-remove
          color: dark.$color_text-inlinebutton
          svg
            color: dark.$color_text-inlinebutton
          p
            color: dark.$color_text-inlinebutton
