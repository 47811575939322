@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component_search-filter
  width: 350px

  p.label_small-caps-bold
    color: $color_text-primary

  div.cp_component_search-filter-target,
  div.cp_component_search-filter-network
    align-items: flex-start
    background-color: $color_fill-card
    background: $color_fill-card
    border-radius: 4px
    border-bottom: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    gap: px-to-rem(10px)
    padding: px-to-rem(20px) px-to-rem(30px) px-to-rem(10px)

  .cp_component_search-filter-target
    border-bottom: none
    position: relative

    &-select-control
      min-width: 100%

    &-input-label
      color: $color_text-follower
      font-family: $font-family-primary
      font-size: $font-size-normal
      font-style: $font-style-primary
      font-weight: $font-weight-normal
      line-height: px-to-rem(22px)
      padding-top: 0
      top: px-to-rem(-20px)

      &.MuiInputLabel-shrink
        display: none
        transform: none

    &-select
      margin-top: 0

      &.Mui-focused
        background-color: $color_fill-card

        [aria-expanded="false"]
          background-color: $color_fill-card

      .target-select-item 
        background-color: $color_fill-background
        border-bottom: 1px solid $color_divider-dropdown
        padding: $space_2x $space_1x

      .MuiSelect-select
        color: $color_text-secondary
        font-family: $font-family-primary
        min-height: px-to-rem(22px)
        padding-bottom: 0
        padding-top: 0

      &.MuiInput-underline:after,
      &.MuiInput-underline:before 
        border-bottom: none

      svg.MuiSelect-icon
        fill: $color_border-input

      &:hover:hover:not(.Mui-disabled, .Mui-error):before
        border-bottom: none

  .cp_component_search-filter-network
    box-shadow: none

    &-container
      display: flex
      gap: px-to-rem(9px)
      padding-left: 0
      padding-right: 0

    &-filter-button
      padding-left: 0
      padding-right: 0

    &.search-filter_network
      border-top: none

// dropdown styles light
[aria-labelledby="cp_component_search-filter-target-select"].MuiList-root
  border-radius: $content-border-radius
  border: 1px solid $color_border-section 
  padding: 0

  .cp_component_search-filter-target-select-item
    background-color: $color_button-exit
    border-bottom: 1px solid $color_divider-section
    color: $color_text-dropdown
    font-family: $font-family-primary
    font-size: $font-size-sm
    font-style: $font-style-primary
    font-weight: $font-weight-normal
    line-height: $font-line-height-sm
    padding: $space_2x $space_1x

    &:hover, 
    &:target,
    &:active
      background-color: $color_fill-dropdown-select
      

// Dark Theme Styles
.dark
  div.cp_component_search-filter

    p.label_small-caps-bold
      color: dark.$color_text-primary

    .cp_component_search-filter-target,
    .cp_component_search-filter-network
      background-color: dark.$color_fill-card
      background: dark.$color_fill-card
      border-bottom: 1px solid dark.$color_border-colorcard
    
    .cp_component_search-filter-target
      &-input-label
        color: dark.$color_button-toggle-off

      &-select
        &.Mui-focused
          background-color: dark.$color_fill-card

          [aria-expanded="false"]
            background-color: dark.$color_fill-card
          
        svg.MuiSelect-icon
          fill: dark.$color_border-input
      
        .MuiSelect-select
          color: dark.$color_text-secondary

  // dropdown styles
  [aria-labelledby="cp_component_search-filter-target-select"].MuiList-root
    border: 1px solid dark.$color_border-section 

    .cp_component_search-filter-target-select-item
      background-color: dark.$color_button-exit
      border-bottom: 1px solid dark.$color_divider-section
      color: dark.$color_text-dropdown

      &:hover, 
      &:target,
      &:active
        // TODO: token in figma is incorrect, will update when token is updated
        background-color: rgb(66, 70, 89) 







