@use "./style/theme_light" as *
@use "./style/theme_dark" as dark
  
@import "./style/reset"
@import "./style/font"
@import "./style/typography"

// Global Body Styles
body
  background-color: $color_fill-background
  font-family: "Open Sans", sans-serif
  font-size: 16px
  font-weight: 400

  // Scrollbar Styles
  scrollbar-width: thin
  scrollbar-color: $color_scrollbar transparent
  
  // Global MUI Styles
  // MUI Data Grid Filter Panel
  .MuiDataGrid-panel
    .MuiDataGrid-paper
      border-radius: 0.75rem
    .MuiDataGrid-panelWrapper
      .MuiDataGrid-panelContent
        button.MuiIconButton-root .MuiSvgIcon-root
          color:  $color_icon-exit
      .MuiDataGrid-panelFooter
        button.MuiButton-root
          color: $color_text-inlinebutton
          span.MuiButton-icon
            display: none

// Global Body Dark Mode Styles
body.dark
  background-color: dark.$color_fill-background

  // Scrollbar Styles
  scrollbar-color: dark.$color_scrollbar transparent

  // Global MUI Styles
  // MUI Data Grid Filter Panel
  .MuiDataGrid-panel
    .MuiDataGrid-paper
      background-color: dark.$color_fill-dropdown
    .MuiDataGrid-panelWrapper
      .MuiDataGrid-panelContent
        .MuiFormLabel-root, .MuiInput-root
          color: dark.$color_text-primary
        .MuiFormLabel-root.Mui-focused
          color: dark.$color_input-active
        .MuiInput-root.Mui-focused::after
          border-bottom-color: dark.$color_input-active
        .MuiInput-root::before
          border-bottom-color: dark.$color_border-input
        button.MuiIconButton-root .MuiSvgIcon-root
          color:  dark.$color_icon-exit
      .MuiDataGrid-panelFooter
        button.MuiButton-root
          color: dark.$color_text-inlinebutton
      svg.MuiNativeSelect-icon
        color: dark.$color_icon-campaign
  .MuiDataGrid-menuList
    .MuiListItemIcon-root
      color: dark.$color_icon-campaign


