@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_deliverable-content-overview_component
    align-items: center
    background-color: $color_surface-secondary
    border-bottom: 1px solid $color_border-section
    display: flex
    justify-content: space-between
    padding: $space_2x
    margin-left: -$space_3x
    margin-right: -$space_3x
    &-left
        display: flex
        gap: $space_2x
        &-details
            display: flex
            flex-direction: column
            &-demographics
                color: $color_text-follower
                font-size: 0.75rem
                font-weight: 700
    &-right
        align-items: center
        display: flex
        gap: $space_1x

.dark
    .cp_deliverable-content-overview_component
        background-color: dark.$color_surface-primary
        border-bottom: 1px solid dark.$color_border-section
        &-left-details-demographics
            color: dark.$color_text-follower
