@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_vocation_component
    width: 100%

    .cp_component_input .MuiSvgIcon-root
    
    .cp_component_tabs-container
        padding: 0 0 $space_2x 0
    &-header
        align-items: center
        display: flex
        justify-content: space-between

    &-content
        height: px-to-rem(300px)
        overflow-y: auto
        &-demo
            display: flex
            flex-direction: column
            gap: $space_2x
            padding: $space_2x $space_1x $space_2x 0
            
            &-header
                align-items: center
                display: flex
                justify-content: space-between
                padding: 0 $space_1x 0 $space_1x
                &-value
                    font-weight: $font-weight-bold
                
            .MuiLinearProgress-root
                background-color: $color_progress-bar-0
                height: 3px

                .MuiLinearProgress-bar
                    background-color: $color_progress-bar-1
            &-no-data
                text-align: center
.dark
    .cp_vocation_component
        .cp_component_input .MuiSvgIcon-root
            fill: dark.$color_icon-search
        
        &-content-demo
                    
                .MuiLinearProgress-root
                    background-color: dark.$color_progress-bar-0

                    .MuiLinearProgress-bar
                        background-color: dark.$color_progress-bar-3


