@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_profile-tabs_notes-input-card
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  border-radius: $content-border-radius
  display: flex
  flex-direction: column
  gap: $space_2x
  margin-top: $space_1x
  padding: $space_2x

  &-input
    .MuiInputBase-root
      align-items: flex-start
      display: flex
      height: px-to-rem(110px)
      padding: 0
      width: px-to-rem(580px)
  &-button
    width: px-to-rem(122px)

.dark
  div.cp_component_profile-tabs_notes-input-card
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard
