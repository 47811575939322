@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/_styles' as *
    
$checked_icon_width: px-to-rem(20px)

.cp_component_filter-menu-list
    width: px-to-rem(194px)

.cp_component_checked-icon
    &.MuiListItemIcon-root
        color: $color_fill-icon-list

.cp_component_filter-menu-title
    & .MuiTypography-root
        color: $color_text-primary
        font-weight: $font-weight-bold
        margin-left: $space_2x
        text-transform: uppercase
    &:not(:first-child)
        margin-top: $space_2x

.cp_component_filter-menu-item
    &.MuiButtonBase-root
        padding-left: px-to-rem(12px)

        .MuiListItemText-inset
            padding-left: $checked_icon_width
        .MuiListItemIcon-root
            min-width: 0
            width: $checked_icon_width
    
    .MuiTypography-root
        padding-left: px-to-rem(6px)
        color: $color_text-dropdown

    &.MuiMenuItem-root:hover
        background-color: $color_fill-dropdown-select
        
.cp_component_menu-item_selected
    .MuiTypography-root
        color: $color_text-primary
        font-weight: $font-weight-bolder

.dark
    .cp_component_filter-menu-title
        & .MuiTypography-root
            color: dark.$color_text-primary
    .cp_component_checked-icon
        &.MuiListItemIcon-root
            color: dark.$color_fill-icon-list

    .cp_component_filter-menu-item
        .MuiTypography-root
            color: dark.$color_text-secondary
        &.MuiMenuItem-root:hover
            background-color: dark.$color_fill-dropdown-select
    .cp_component_menu-item_selected
        .MuiTypography-root
            color: dark.$color_text-primary
