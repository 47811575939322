@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_review-and-create-step
  display: flex
  flex-direction: column
  margin-bottom: $space_3x
  width: 100%
  h4
    font-size: px-to-rem(16px)
    font-weight: 600
    margin-bottom: 0.2rem
  p.sub-title
    font-size: px-to-rem(12px)
  
  .cp_component_divider
    border-color: $color_divider-list
    margin: $space_2x 0

  div
    &:not(&:last-child)
      margin-bottom: $space_3x

    p.label_small-caps-bold
    .deliverable-title, .caption
      font-size: px-to-rem(14px)
    img
      border: 1px solid $color_border-image
      height: px-to-rem(100px)
      width: px-to-rem(90px)
      &:not(:last-child)
        margin-right: $space_1x
        
.dark
  .cp_component_review-and-create-step
    .cp_component_divider
      border-color: dark.$color_divider-list

    div
      p.label_small-caps-bold
      img
        border-color: dark.$color_border-image
