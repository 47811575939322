@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_customers-overview-context-menu_icon-button .MuiSvgIcon-root
    color: $color_text-primary

.cp_customers-overview-context-menu_menu-component
    min-width: px-to-rem(175px)


    .menu-item
        .menu-item-label > .MuiTypography-root
            color: $color_text-primary
            font-size: $font-size-sm

    .menu-list .MuiMenu-list
      padding-bottom: 0
      padding-top: 0

.dark
    .cp_customers-overview-context-menu_icon-button .MuiSvgIcon-root
        color: dark.$color_border-secondarybutton

    .cp_customers-overview-context-menu_menu-component
        .menu-item
            .menu-item-label > .MuiTypography-root
                color: dark.$color_text-primary
        
        .MuiMenu-paper
            background-color: dark.$color_fill-dropdown
