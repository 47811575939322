@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_modal-campaign-deliverable-v2-history-tab_component
    background-color: $color_fill-background
    margin-top: $space_1x
    padding: $space_2x
    > p
        font-size: 1rem
        font-weight: 600
    .deliverable-history-items-container
        margin-top: $space_2x
        padding: 0 $space_1x 
        background-color: $color_fill-modal

.dark
    .cp_modal-campaign-deliverable-v2-history-tab_component
        // background-color: dark.$color_fill-modal
        background-color: dark.$color_surface-primary
        .deliverable-history-items-container
            background-color: dark.$color_fill-modal
