@use "../../../../../../style/_primitives" as primitives
@use "../../../../../../style/theme_light" as *
@use "../../../../../../style/theme_dark" as dark
@use "../../../../../../style/styles" as *

.cp_component_modal-manager-brand-managers
  p.label_small-caps-bold
    color: $color_text-primary
    margin-bottom: $space_1x


  .modal-body
    display: flex
    gap: $space_2x
    margin: $space_2x $space_1x
    padding: $space_1x

    .search-container
      border: 1px solid $color_border-section
      padding: $space_2x
      width: px-to-rem(370px)

      .client-select
        margin-bottom: $space_2x

      .auto-complete-input
        width: 100%
          
    
    .active-permissions-container
      margin-top: $space_1x
      .permission-row
        align-items: center
        display: flex
        padding: 0 $space_2x
        &:not(:first-child)
          border-top: 1px solid $color_divider-list
        &-selected
          background-color: $color_fill-dropdown-select


    .permissions-container
      border: 1px solid $color_border-section
      flex-grow: 1
      height: fit-content
      min-height: px-to-rem(162px)
      padding: $space_2x
      .cp_radio-group
        margin-left: $space_1x


.dark
  .cp_component_modal-manager-brand-managers
    p.label_small-caps-bold
      color: dark.$color_text-primary


    .modal-body
      .search-container
        border-color: dark.$color_border-section
            
      .active-permissions-container
        .permission-row
          &:not(:first-child)
            border-color: dark.$color_divider-list
          &-selected
            background-color: dark.$color_fill-dropdown-select

      .permissions-container
        border-color: dark.$color_border-section
