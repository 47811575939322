@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_placeholder-card
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  padding: $space_6x

  .content
    align-items: center
    background-color: $color_fill-background
    display: flex
    gap: $space_2x
    padding: $space_4x
    
    .MuiSvgIcon-root
      color: $color_icon-placeholder
      background-color: $color_fill-card
      border: 1px solid $color_border-image
      border-radius: 100%
      height: px-to-rem(60px)
      width: px-to-rem(60px)
      padding: 0.75rem

.dark  
  .cp_component_placeholder-card
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard

    .content
      background-color: dark.$color_fill-background
      
      .MuiSvgIcon-root
        color: dark.$color_icon-placeholder
        background-color: dark.$color_fill-card
        border-color: dark.$color_border-image

    
