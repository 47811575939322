@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/_styles' as *
  
.cp_component_return-to-tag
  color: $color_text-inlinebutton
  font-size: px-to-rem(12px)
  width: fit-content
  margin: 0 0 $space_2x $space_1x
  &:hover
    cursor: pointer

.dark
  .cp_component_return-to-tag
      color: dark.$color_text-inlinebutton