@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_list-group-public-header-card
    align-self: center
    display: flex
    flex: 1
    margin: $space_3x

    &-folder-icon.MuiSvgIcon-root
        fill: $color_icon-search
        height: px-to-rem(36px)
        margin-right: $space_2x
        width: px-to-rem(36px)

    &-info
        display: flex
        flex-direction: column

        &-title
            font-size: px-to-rem(28px)
            margin-bottom: $space_2x

        &-subinfo
            color: $color_text-secondary
            font-weight: $font-weight-bold

.dark
    .cp_list-group-public-header-card
        &-folder-icon.MuiSvgIcon-root
            fill: dark.$color_icon-search

        &-info
            &-subinfo
                color: dark.$color_text-secondary
                