// Light Theme Styles 
div.cp_page_loading
  align-items: center
  display: flex
  height: 100vh
  justify-content: center
  width: 100%

// Dark Theme Styles
.dark div.cp_page_loading
