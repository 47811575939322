@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_square-chip.MuiChip-root
  background-color: $color_fill-numbercounter
  border-radius: px-to-rem(4px)
  color: $color_text-primary
  font-family: $font-family-primary
  height: px-to-rem(22px)
  width: px-to-rem(22px)
  padding: 0
  .MuiChip-label
    padding: 0

.dark
  .cp_component_square-chip.MuiChip-root
    background-color: dark.$color_fill-numbercounter
    border: 1px solid dark.$color_border-numbercounter
    color: dark.$color_text-primary
