@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

// Light Theme Styles
div.cp_component-prompt-ai

  &-pill
    margin-top: $space_1x
  .MuiSvgIcon-root.cp_component-prompt-ai-icon-muted
      color: $color_icon-search
  div.cp_component_input
    width: 100%
    .cp_component-prompt-ai-input-adornment
      color: $color_text-inactive
// Dark Theme Styles
.dark
  div.cp_component-prompt-ai
    background-color: dark.$color_fill-modal
    border: none
    .MuiSvgIcon-root.cp_component-prompt-ai-icon-muted
      color: dark.$color_icon-search
    .MuiIconButton-root
      color: dark.$color_brand-blue-teal
    div.cp_component_input
      width: 100%
      .cp_component-prompt-ai-input-adornment
        color: dark.$color_text-inactive
  





