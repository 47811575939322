@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_public-list-overview-card
    background-color: inherit
    padding: $space_2x $space_4x
    min-width: px-to-rem(380px)
    &-main
      &-entity-row
        display: flex
        justify-content: space-between
        &-info .cp_component-entity-info-row-name
            font-size: px-to-rem(22px)
            font-family: $font-family-primary
        .MuiIconButton-root
          color: $color_button-pressed
          height: px-to-rem(40px)
          width: px-to-rem(40px)
      &-description
          color: $color_text-secondary
          font-size: 0.75rem
          margin-bottom: $space_3x
          margin-top: $space_4x
          padding-right: $space_3x
          word-wrap: break-word
      &-demographic
        height: px-to-rem(50px)
        margin-bottom: $space_3x
        &-text
            color: $color_text-primary
            font-weight: $font-weight-bolder
            font-size: $font-size-sm
            text-transform: uppercase
            margin-bottom: $space_1x
        &-pill
            height: px-to-rem(24px)
            div.cp-pill.MuiChip-root
                height: 1.5rem  
            
      &-epsilon-logo-tooltip
            width: 8rem
    
      &-epsilon-logo
            background-image: url("../../asset/img/epsilon.svg")
            background-repeat: no-repeat
            background-size: contain
            cursor: pointer
            display: block
            float: left
            margin-left: 3.65rem
            min-height: px-to-rem(13px)
            min-width: px-to-rem(46px)
            width: 50px

    &-divider.MuiDivider-root
        align-self: center
        border-color: $color_divider-section
        width: 100%
        margin: 0 auto $space_3x auto
    &-stats
        display: flex
        gap: $space_4x
        &-info
            display: flex
            flex-direction: column
            justify-content: space-between
            &-network
                height: px-to-rem(28px)
                padding: px-to-rem(8px)
                width: px-to-rem(28px)
            h4
                color: $color_icon-logo
                font-size: px-to-rem(24px)
                text-transform: uppercase
            &-title
                color: $color_text-primary
                font-weight: $font-weight-bolder
                font-size: $font-size-sm
                text-transform: uppercase
    div.cp_component-entity-row-info
        margin: 1rem 0 0 0

.dark
    .cp_component_public-list-overview-card
        background-color: inherit
        &-main
          &-entity-row
            .MuiIconButton-root
              color: dark.$color_button-pressed
            &-description
                color: dark.$color_text-secondary
            &-text
                color: dark.$color_text-primary
          &-epsilon-logo
            background-image: url("../../asset/img/epsilon_dark.svg")
        &-divider.MuiDivider-root
            border-color: dark.$color_divider-section
        &-stats
            &-info
                h4
                    color: dark.$color_fill-icon-list
                &-title
                    color: dark.$color_text-primary
