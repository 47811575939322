@use "../../../style/styles" as *
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark

div.cp_component_profile-tabs_lists
  background-color: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  margin-top: $space_1x
  flex: 1 1
  height: 100%
  overflow-y: scroll

  div.cp_component_empty-splash
    margin: 0

.dark
  div.cp_component_profile-tabs_lists
    background-color: dark.$color_fill-card
    border-color: dark.$color_border-colorcard
