@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/_styles' as *

.cp_icon-button_component
  &.MuiIconButton-root
    color: $color_icon-list
    height: $space_4x
    width: $space_4x

    svg
      font-size: 1.25rem

    &.outlined, &.filled
      border-radius: 5px
    &.outlined
      background-color: $color_fill-card
      border: 1px solid $color_border-colorcard
      color: $color_icon-exit

    &.filled
      background-color: $color_button-visible
      color: $color_text-invert
      border: none

    &.MuiIconButton-sizeSmall
      height: px-to-rem(24px)
      width: px-to-rem(24px)

      svg
        height: px-to-rem(18px)
        width: px-to-rem(18px)
    
    &.small
      height: px-to-rem(24px)
      width: px-to-rem(24px)

      &.outlined, &.filled
        border-radius: 2px

      svg
        height: px-to-rem(18px)
        width: px-to-rem(18px)

    &.MuiIconButton-sizeMedium
      height: px-to-rem(32px)
      width: px-to-rem(32px)

.dark
  .cp_icon-button_component
    &.MuiIconButton-root
      color: dark.$color_icon-list
      &.outlined
        background-color: dark.$color_fill-card
        border-color: dark.$color_border-colorcard
        color: dark.$color_icon-exit
        
      &.filled
        background-color: dark.$color_button-visible
        color: dark.$color_text-invert
