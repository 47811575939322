@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_customers-overview-data-grid.cp_component-data-grid
  height: 80%

  .filter-icon
    color: $color_border-secondarybutton

.dark
  .cp_component_customers-overview-data-grid.cp_component-data-grid
    .filter-icon
      color: dark.$color_border-secondarybutton