@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_location_component
    width: 100%

    div.cp_component_input .MuiTextField-root .MuiInputBase-input
        padding: 0.25rem 0.6rem

    .cp_component_input .MuiSvgIcon-root
        font-size: 1.1rem

    .cp_component_tabs-container
        padding: 0 0 $space_2x 0

    &-container
        display: flex
        gap: $space_2x
        flex-wrap: wrap
        &-chart
            flex: 1 0 66%
                max-width: 55vw
            @media (min-width: $sm)
                max-width: 50vw
            @media (min-width: 1400px)
                max-width: 40vw
            @media (min-width: $xxl)
                max-width: 45vw
        &-search
            flex: 1 0 33%
            padding: 0 0 0 
            
            &-input
                margin-left: auto
                position: sticky

    &-content
        height: px-to-rem(340px)
        overflow-y: auto
        margin-top: $space_2x

        &-demo
            display: flex
            flex-direction: column
            gap: $space_2x
            padding: $space_2x $space_1x $space_2x 0
            
            &-header
                align-items: center
                display: flex
                justify-content: space-between
                padding: 0 $space_1x 0 $space_1x
                &-value
                    font-weight: $font-weight-bold
                
            .MuiLinearProgress-root
                background-color: $color_progress-bar-0
                height: 3px

                .MuiLinearProgress-bar
                    background-color: $color_progress-bar-1
            &-no-data
                text-align: center
.dark
    .cp_location_component
        .cp_component_input .MuiSvgIcon-root
            fill: dark.$color_icon-search
        
        &-content-demo
                    
                .MuiLinearProgress-root
                    background-color: dark.$color_progress-bar-0

                    .MuiLinearProgress-bar
                        background-color: dark.$color_progress-bar-3


