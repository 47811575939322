@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_social_tracker_sidepanel
  div.cp_social_tracker_sidepanel-sections
    border-bottom: 1px solid $color_divider-section
    padding: $space_3x $space_4x

  .section-title
    @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
    margin-bottom: $space_1x

  p.missing-info-message
    @include textStyles($color_text-inactive, $font-size-sm, $font-weight-normal)

  p.sponsor-value
    @include textStyles($color_text-primary, $font-size-sm, $font-weight-normal)

  div.pill-container
    display: flex
    flex-wrap: wrap
    gap: $space_1x

  .actions-wrapper
    display: flex
    gap: $space_1x
    .action-button.cp_icon-button_component
      color: $color_icon-exit
      background-color: $color_fill-background
      border-radius: 5px

.dark .cp_social_tracker_sidepanel
  div.cp_social_tracker_sidepanel-sections
    border-bottom: 1px solid dark.$color_divider-section
    padding: $space_3x $space_4x

  div.cp_social_tracker_sidepanel-networks
    border-bottom-color: dark.$color_divider-section

  .section-title
    color: dark.$color_text-primary

  p.missing-info-message
    color: dark.$color_text-inactive

  p.sponsor-value
    color: dark.$color_text-primary

  .actions-wrapper
    display: flex
    gap: $space_1x
    .action-button.cp_icon-button_component
      color: dark.$color_icon-white
      background-color: dark.$color_fill-card-panel
      border-radius: 5px
