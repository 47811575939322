@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/_styles" as *

div.cp_component_socila-tracker-download-csv-modal
  .input-container
    padding: $space_2x 0

    .date-pickers-container
      display: flex
      gap: $space_2x
      margin-top: $space_2x
      p
        color: $color_text-primary
      .date-picker
        .MuiInputBase-root
          input
            color: $color_text-primary
            font-size: px-to-rem(14px)  
            padding: 0.75rem
          fieldset
            border: 1px solid $color_border-input
.dark      
  div.cp_component_socila-tracker-download-csv-modal
    .date-pickers-container
      p
        color: dark.$color_text-primary
      .date-picker
        .MuiInputBase-root
          input
            color: dark.$color_text-primary
          fieldset
            border-color: dark.$color_border-input
      