@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_profile_component-tabs-container
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: column
    margin-bottom: $space_1x
    padding: $space_2x $space_2x 0 $space_2x
    position: relative
    
    &_loading

    .cp_profile_component-tabs-content
        flex: 1 1
        height: 100%
        overflow-y: auto

    .cp_component_tabs-controls
        .cp_profile_component-tabs-controls
            display: flex
            gap: $space_1x

div.cp_account-insights-details_component
    h6.cp_engagement-chart_component-title,
    h6.cp_account-insights-details_component-widget-title,
    h6.cp_engagement-map_component-title,
    h6.cp_personality-traits_component-header-title
        font-weight: $font-weight-normal

.cp_profile_component-list-tabs-content-loading
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    padding: $space_2x $space_1x
    margin-top: $space_1x

.dark
    .cp_profile_component-tabs-container
        background: dark.$color_fill-card
        border-radius: $content-border-radius
        border: 1px solid dark.$color_border-colorcard

    .cp_profile_component-list-tabs-content-loading
        background: dark.$color_fill-card
        border-color: dark.$color_border-colorcard
