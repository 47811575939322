@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


.cp-component_ga-filter-content
  height: 100%
  width: 100%

  &_filters
    border: 1px solid $color_border-section
    float: left
    padding-left: px-to-rem(8px)
    padding-right: px-to-rem(8px)
    margin-top: px-to-rem(8px)
    margin-bottom: px-to-rem(8px)
    padding-bottom: px-to-rem(8px)
    width: 49%

    ._date-picker
      input
        color: $color_text-body

    .filter-header
      margin-bottom: px-to-rem(8px)
      margin-top: px-to-rem(8px)

    .MuiTextField-root
      margin-right: px-to-rem(6px)

    .default-labels
      text-transform: capitalize

    .form-control-label
      .MuiButtonBase-root
        padding-left: px-to-rem(9px)
        padding-right: px-to-rem(9px)
        padding-top: 0
        padding-bottom: 0

      .MuiFormControlLabel-label
        color: $color_text-body
        font-size: 0.75rem
        font-weight: 400
        line-height: 133%
    
    .MuiStack-root
      padding: 0
      margin-right: px-to-rem(-5px)
      overflow: hidden

      .MuiInputBase-input
        padding-top: 0
        padding-bottom: 0
    
    .cp-network-button
      &__icon
        height: px-to-rem(20px)
        width: px-to-rem(20px)
    
  &_selected-filters
    border: 1px solid $color_border-section
    float: right
    padding: px-to-rem(8px)
    width: 49%
    margin-top: px-to-rem(8px)
    margin-bottom: px-to-rem(8px)

    .selected-filters-title-section
      display: flex
      justify-content: space-between
      width: 100%

      &_title
        padding-top: px-to-rem(4px)

    .applied-filter-divider
      margin-top: px-to-rem(8px)
      margin-bottom: px-to-rem(8px)
    
    .filter-chip
      margin-right: $space_1x
      margin-top: $space_1x

    .MuiChip-root
      font-size: 0.75rem
      font-weight: 600
      line-height: 133%
      letter-spacing: px-to-rem(.1px)

      .MuiChip-label
        text-transform: capitalize

.dark
  .cp-component_ga-filter-content
    height: 100%
    width: 100%

    &_filters
      border: 1px solid dark.$color_border-section
      float: left
      padding: px-to-rem(8px)
      width: 49%

      &_date-picker
        input
          color: dark.$color_text-body

      .form-control-label
        .MuiFormControlLabel-label
          color: dark.$color_text-body

    &_selected-filters
      border: 1px solid dark.$color_border-section
      float: right
      padding: px-to-rem(8px)
      width: 49%

      .applied-filter-divider
        margin-top: px-to-rem(8px)
        margin-bottom: px-to-rem(8px)

