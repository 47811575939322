@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark


.cp_component_deliverable-v2_update-status-modal
  .select-label
    color: $color_text-primary
    margin-top: $space_2x
  .status-select

.dark
  .cp_component_deliverable-v2_update-status-modal
    .select-label
      color: dark.$color_text-primary
