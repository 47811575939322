@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/primitives" as primitives
@use "../../style/styles" as *

// Light Theme Styles 
article.cp_component_login-flow-customer
  align-items: center
  display: flex
  gap: $space_2x
  justify-content: flex-start
  width: 100%

  > div
    background-repeat: no-repeat
    background-size: px-to-rem(32px) px-to-rem(32px)
    border-radius: 50%
    height: px-to-rem(32px)
    width: px-to-rem(32px)

// Dark Theme Styles
.dark article.cp_component_login-flow-customer
