@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-campaign-deliverable-content

    h2.MuiDialogTitle-root 
        column-gap: $space_1x
        > div
            display: flex
            > .cp_component_modal-title
                overflow-wrap: anywhere

    &-columns
        display: flex
        gap: $space_2x
        word-break: break-all
    &-tabs
        .cp_component_tabs-container
            padding-left: 0
            padding-right: 0
        
        &-tctm-id
            display: inline
            margin: 0 $space_1x

        .cp_component_tabs-controls
            button
                margin-left: $space_1x

    .MuiFormControlLabel-label
        font-size: $font-size-sm
        font-weight: $font-weight-normal

    &_input
        margin-bottom: $space_2x

    &-button_narrow
        max-height: $space_3x

    &-hidden
        visibility: hidden

    .MuiFormControlLabel-labelPlacementEnd
        min-width: px-to-rem(116px)
        margin-right: 0

    .cp_component_divider
        margin: $space_3x 0 $space_3x 0
    &-radios 
        span.MuiFormControlLabel-label
            @include textStyles($color_text-radiobutton, px-to-rem(12px), 400)

    &_social-account
        border: 1px solid $color_border-input
        border-radius: $content-border-radius
        display: flex
        gap: $space_2x
        padding: $space_2x
        &-right
            display: flex
            flex-direction: column
            width: 100%
            &-followers
                color: $color_text-secondary
                font-size: $font-size-sm
                text-transform: capitalize
    &-helper
        color: $color_text-secondary
        font-size: $font-size-sm
        margin-bottom: $space_2x

    &-details
        > .cp_component_input:not(:last-child)
            margin-bottom: $space_2x
        div.cp_component_input
            width: 100%
            .MuiFormHelperText-root
                color: inherit
        &-label
            align-items: center
            display: flex
            justify-content: space-between
            margin-bottom: $space_1x
        p.label_small-caps-bold
            color: inherit
            margin-bottom: 0
        p.label_large-bold
            font-size: 0.9rem
            margin-bottom: $space_1x
        &-themes
            display: flex
            gap: $space_1x
            margin-bottom: $space_2x
            
            &-category, &-subcategory
                flex: 0 0 49%
                p.label_small-caps-bold
                    color: inherit
                    margin-bottom: $space_1x
                .component_select
                    width: 100%

    &-content
        > .cp_component_input:not(:last-child)
            margin-bottom: $space_2x
        div.cp_component_input
            width: 100%
            .MuiFormHelperText-root
                color: inherit
        &-label
            align-items: center
            display: flex
            justify-content: space-between
            margin-bottom: $space_1x
        p.label_small-caps-bold
            color: inherit
            margin-bottom: 0
        p.label_large-bold
            font-size: 0.9rem
            margin-bottom: $space_1x
        &-formats
            margin-top: $space_2x
        &-media
            margin-top: $space_2x
            &-img.MuiAvatar-root
                height: px-to-rem(140px)
                width: px-to-rem(140px)
            &-badge .MuiBadge-badge
                top: px-to-rem(25px)
                right: px-to-rem(25px)


    h4.headline_small
        font-size: $font-size-large

    .MuiDialog-container .MuiDialogTitle-root + .MuiDialogContent-root
        padding-top: 0

    &_row
        display: flex
        flex-direction: row
        gap: $space_3x
        width: 100%
        
    &_column
        display: flex
        flex: 0 0 50%
        flex-direction: column
        gap: $space_2x
        
    &_panels
        gap: $space_2x 
        justify-content: center
        margin-top: $space_2x
        max-height: px-to-rem(565px)

        > .cp_component_modal-campaign-deliverable_column 
            border: 1px solid $color_border-input
            border-radius: $content-border-radius
            overflow-y: auto
            padding: $space_3x $space_4x
        &-title
            font-size: $font-size-large
            font-weight: $font-weight-bold
            margin-bottom: $space_1x
    .cp_deliverable-content-overview_component-right
        float: left

.dark
    .content-history
        background-color: dark.$color_surface-primary
        &-title
            color: var(--text-text-primary, dark.$color_text-primary)

    .MuiAutocomplete-paper
        background-color: dark.$color_border-section
        border: 1px solid dark.$color_border-section

    .cp_component_modal-campaign-deliverable_accounts-list

        .MuiAutocomplete-option
            background-color: dark.$color_button-exit
            border-top: 1px solid dark.$color_divider-section
            color: dark.$color_text-primary

            &.Mui-focused
                background-color: dark.$color_fill-dropdown-select

    .cp_component_modal-campaign-deliverable

        .MuiAutocomplete-inputRoot

            .MuiInputAdornment-root
                color: dark.$color_border-input

        &-details

            div.cp_component_input .MuiInputAdornment-root .MuiSvgIcon-root
                color: dark.$color_icon-search
        &-radios 
            span.MuiFormControlLabel-label
                color: dark.$color_text-radiobutton
            svg
                fill: dark.$color_text-radiobutton
            .Mui-checked svg
                fill: dark.$color_border-radio-active
        p.label_small-caps-bold
            color: dark.$color_text-primary
