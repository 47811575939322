@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_communications-overview
  display: flex
  flex-direction: row
  flex: 1
  height: 100%
  width: 100%
  gap: $space_2x

.dark
  .cp_component_communications-overview
    