@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-campaign-deliverable-v2-assign-reviewer
  background-color: $color_fill-background
  display: flex
  flex-direction: column
  flex-shrink: 0
  height: 18.75rem
  padding: 1rem
  &-header
    padding-bottom: 1rem
  &-body
    background-color: $color_fill-modal
    flex: 1 1
    overflow: auto
  &-body-empty
    align-content: center
    height: 100%
    text-align: center
    .cp_btn_component_highlight
      text-decoration: underline
    margin-top: 0.5rem
  .body_large
    font-size: 1rem
    font-weight: $font-weight-bold
    margin-bottom: 0.5rem

  .user-row
    align-items: center
    background-color: $color_fill-modal
    display: flex
    gap: $space_1x
    padding-left: $space_1x
    .MuiSvgIcon-root
      height: px-to-rem(18px)
      width: px-to-rem(18px)
      &.approve-icon
        fill: $color_color-success
      &.request-revision-icon
        fill: $color_color-warning
    .assign-reviewers-entity-row
      .cp_component-entity-info-row-name
        font-size: px-to-rem(10px)
        font-weight: 600
        height: px-to-rem(18px)
        margin-left: px-to-rem(10px)
      .cp_component-entity-info-row-sub-info
        color: $color_text-secondary
        font-size: px-to-rem(10px)
        font-weight: 400
        height: px-to-rem(18px)
        text-transform: capitalize
        margin-left: px-to-rem(10px)

.dark
  .cp_component_modal-campaign-deliverable-v2-assign-reviewer
    background-color: dark.$color_fill-card
    &-body
      background-color: dark.$color_fill-modal
    .user-row
      background-color: dark.$color_fill-modal
      .MuiSvgIcon-root
        &.approve-icon
          fill: dark.$color_color-success
        &.request-revision-icon
          fill: dark.$color_color-warning
      .assign-reviewers-entity-row
        .cp_component-entity-info-row-name
        .cp_component-entity-info-row-sub-info
          color: dark.$color_text-secondary
