@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

@mixin textStyles($color, $fontSize, $fontWeight) 
  color: $color
  font-family: $font-family-primary
  font-size: $fontSize
  font-style: $font-style-primary
  font-weight: $fontWeight
  line-height: normal
  
.cp_component_modal-create-list

  .cp_component_modal-create-list_tab-display-options
    .cp_component_modal-create-list

      &_column
        flex: 1
        &:first-child
          position: relative
          min-width: 50%

      &-engagement

        &_form-control
          position: unset
        h4.headline-small
          margin-bottom: $space_2x

        &_radio-options
          label.cp_radio-label
            span
              @include textStyles($color_text-primary, px-to-rem(14px), 600)
          small
            @include textStyles($color_text-secondary, px-to-rem(12px), 400)
            margin-bottom: $space_1x
            margin-top: - .25rem
            padding-left: px-to-rem(25px)

            span.cp_warning-message 
              display: block
              padding-top: $space_1x
              font-size: px-to-rem(10px)
              font-weight: $font-weight-bolder
 
          .cp_component_contextual-relevancy
            margin-bottom: $space_2x
            padding-left: px-to-rem(25px)
            &_label 
              @include textStyles($color_text-primary, px-to-rem(12px), 600)
              margin-bottom: .25rem
            .component_select
              width: 350px

      &-accordion
        max-width: 520px
        &_insights,
        &_audience,
        &_content
          margin: 0 0 $space_2x
          &::before
            height: 0

        &_content.hiddenToggle
          display: none

        &_insights.Mui-expanded
          margin: 0 0 $space_2x

        div.MuiAccordion-root
          border-radius: 2px
          border: 1px solid $color_border-modal

        div.MuiAccordionSummary-root
          margin: 0 $space_3x
          padding: 0

          &.Mui-expanded
            border-bottom: 1px solid $color_border-modal
            margin-bottom: $space_2x
            h3.cp_component_accordion-heading,
            .MuiAccordionSummary-expandIconWrapper,
            div.cp_component_accordion-toggle-details
              color: $color_surface-brand

          h3.cp_component_accordion-heading
            @include textStyles($color_text-primary, px-to-rem(20px), 600)
            letter-spacing: 0.38px

          div.cp_component_accordion-toggle-details
            @include textStyles($color_text-secondary, px-to-rem(14px), 400)
            padding-right: $space_1x

          .MuiAccordionSummary-content 
            display: flex
            justify-content: space-between
          .MuiAccordionSummary-expandIconWrapper.Mui-expanded
            transform: rotate(90deg)

        div.MuiAccordionDetails-root
          margin: 0 $space_3x
          padding: 0

          .cp_component_accordion-content
            padding: 0  0 $space_2x px-to-rem(12px)
      
      &-switch
        &-item
          border-bottom: 1px solid $color_divider-list
          display: flex
          flex-wrap: wrap
          justify-content: space-between
          padding-bottom: $space_2x

        &-content
          flex: 1
          h4
            @include textStyles($color_text-primary, px-to-rem(14px), 600)
            margin-bottom: .25rem
          p
            @include textStyles($color_text-secondary, px-to-rem(12px), 400)
        &-control
          > label.MuiFormControlLabel-root
            margin-right: 0
            padding-left: $space_5x

          .cp-switch.MuiSwitch-root.cp-switch_with-icon span svg
            fill: $color_surface-primary
            height: 1rem
            width: 1rem

        &-additional_fields
          flex-basis: 100%
          padding-top: $space_1x

          div.MuiAutocomplete-inputRoot
            padding: 0



        &-image_tags
          .cp_component_input
            div.MuiAutocomplete-inputRoot
              padding: 0

        &-pills
          display: flex
          flex-wrap: wrap
          gap: $space_1x
          padding: $space_1x 0

.dark
  .cp_component_modal-create-list

    .cp_component_modal-create-list_tab-display-options
      .cp_component_modal-create-list

        &-engagement
          &_radio-options
            label.cp_radio-label
              span
                color: dark.$color_text-primary
            small
              color: dark.$color_text-secondary

            .cp_component_contextual-relevancy
              &_label 
                color: dark.$color_text-primary

        &-accordion
          div.MuiAccordion-root
            border: 1px solid dark.$color_border-section

          div.MuiAccordionSummary-root

            &.Mui-expanded
              border-bottom: 1px solid dark.$color_border-modal

              h3.cp_component_accordion-heading,
              .MuiAccordionSummary-expandIconWrapper,
              div.cp_component_accordion-toggle-details
                color: dark.$color_input-active

            h3.cp_component_accordion-heading,
            .MuiAccordionSummary-expandIconWrapper,
            div.cp_component_accordion-toggle-details
              color: dark.$color_text-primary
        
        &-switch
          &-item
            border-bottom: 1px solid dark.$color_divider-list

          &-content
            h4
              color: dark.$color_text-primary
            p
              color: dark.$color_text-secondary

          &-control
            .cp-switch.MuiSwitch-root.cp-switch_with-icon span svg
              // fill: dark.$color_surface-primary



