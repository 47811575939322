@use "../../../../../style/_primitives" as primitives
@use "../../../../../style/theme_light" as *
@use "../../../../../style/theme_dark" as dark
@use "../../../../../style/styles" as *

.cp_component-campaign-overview-details-user-list-card
  border: 1px solid $color_divider-section
  display: flex
  flex-direction: column
  flex: 1
  min-height: px-to-rem(225px)
  .header
    align-items: center
    border-bottom: 2px solid $color_divider-section
    display: flex
    justify-content: space-between
    padding: $space_2x $space_3x
    h4
      font-size: px-to-rem(18px)
      font-weight: $font-weight-bold
  .users
    display: flex
    height: 100%
    flex-direction: column
    max-height: px-to-rem(256px)
    overflow-y: auto
    padding: $space_1x $space_3x

    .link-row
      align-items: center
      display: flex
      gap: $space_1x
      justify-content: flex-start
      margin-bottom: $space_1x
      .MuiSvgIcon-root
        fill: $color_text-inlinebutton
      .MuiTypography-root
        color: $color_text-inlinebutton
        font-size: px-to-rem(14px)
        font-weight: normal
        text-transform: capitalize
    .user-row
      align-items: center
      display: flex
      justify-content: space-between
      .cp_component-entity-row-info
        margin: $space_1x 0

      .user-icon-containers
        align-items: center
        display: flex
        gap: 0.25rem

        .MuiSvgIcon-root
          fill: $color_icon-pill

    .empty-text-container
      margin: auto

      .empty-text
        color: $color_text-inactive
        font-size: px-to-rem(14px)

.dark
  .cp_component-campaign-overview-details-user-list-card
    border-color: dark.$color_divider-section
    .header
      border-color: dark.$color_divider-section
    .users
      .empty-text-container
        .empty-text
          color: dark.$color_text-inactive

      .link-row
        .MuiSvgIcon-root
          fill: dark.$color_text-inlinebutton
        .MuiTypography-root
          color: dark.$color_text-inlinebutton

      .user-row
        .user-icon-containers
          .MuiSvgIcon-root
            fill: dark.$color_icon-pill
