@use "../../../../style/_primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component-campaign-overview-details
  .stat-container
    display: flex
    gap: $space_3x
    margin-bottom: $space_3x
    margin-top: $space_2x
  .description-container
    border: 1px solid $color_divider-section
    margin-bottom: $space_3x
    min-height: px-to-rem(300px)
    padding: $space_3x
    .description-header
      font-size: px-to-rem(18px)
      margin-bottom: $space_3x
    .description
      color: $color_text-secondary
  .user-cards-container
    display: flex
    flex-wrap: wrap
    gap: $space_3x
    justify-content: space-between
    margin-bottom: $space_3x

  .recent-deliverables-container
    border: 1px solid $color_divider-section
    padding: $space_3x
    .header-container
      align-items: center
      display: flex
      justify-content: space-between
      margin-bottom: $space_3x
      .header
        font-size: px-to-rem(20px)
  
  .MuiDataGrid-columnHeader.account-header
    padding-left: 1.25rem

.dark
  .cp_component-campaign-overview-details
    .stat-container
    .description-container
      border-color: dark.$color_divider-section
      .description
        color: dark.$color_text-secondary
    .recent-deliverables-container
      border-color: dark.$color_divider-section
      