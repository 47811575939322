@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/_styles" as *

.cp_component_logo-header
    align-items: center
    display: flex
    height: primitives.$width_navigation-bar
    width: 100%

    .MuiDivider-root
        border-color: $color_brand-blue-teal
        height: 65%
        margin: 0 $space_4x
    h2
        color: $color_text-secondary
        font-family: $font-family-primary
        font-size: $font-size-normal
        font-weight: $font-weight-bold

.dark
    .cp_component_logo-header
        h2
            color: dark.$color_text-secondary