@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_data-grid_activity-timestamp
    &-date
        color: $color_text-primary
        font-size: $font-size-sm
        font-weight: $font-weight-bolder
    &-subInfo
        color: $color_text-secondary
        font-size: $font-size-sm
        font-weight: $font-weight-normal

.dark
    .cp_data-grid_activity-timestamp
        &-date
            color: dark.$color_text-primary
        &-subInfo
            color: dark.$color_text-secondary