@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_profile_component-comms-content
    align-items: center
    display: flex
    flex-direction: column
    flex-grow: 1
    justify-content: flex-end
    padding: $space_2x

    &-header
        display: flex
        margin-bottom: $space_2x
        width: 100%
        .MuiButtonBase-root
            margin-left: auto
