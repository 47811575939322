@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

$color-fill-icon: #FF4F6D

div.cp_component_campaign-assign-reviewers-modal
   .filter-by-customer
      font-weight: bold
      margin-bottom: $space_1x
      font-size: $font-size-sm
   .add-reviewer-group-container
      margin-top: $space_2x
      padding-bottom: px-to-rem(100px)
   .edit-group-name
      min-width: 100%

   .user-filter-container
      border: 1px solid $color_border-section
      border-radius: $content-border-radius
      overflow-y: auto
      padding: px-to-rem(10px)
      min-height: px-to-rem(402px)
      max-height: px-to-rem(402px)
   .user-filter-list
      max-height: px-to-rem(260px)
      overflow-y: auto
      margin-top: px-to-rem(10px)
   .user-filter-list-loading
      display: flex
      justify-content: center
      align-items: center
      height: px-to-rem(300px)

   .permissions-toggle-container
      border: 0.5px solid $color_border-section
      border-radius: $content-border-radius
      overflow-y: auto
      display: flex
      flex-direction: column
      .permissions-title
         font-weight: bold
         margin-bottom: 8px
      .switch-component
         top: px-to-rem(-25px)
      .permissions-list
         background-color: $color_fill-background
         margin: px-to-rem(15px)
         max-width: px-to-rem(500px)
         border-radius: $content-border-radius
      .permissions-icon
         background-color: $color-fill-icon
         border-radius: px-to-rem(8px)
         padding: px-to-rem(4px)
         margin-right: px-to-rem(8px)
         color: $color_button-text
         min-width: px-to-rem(30px)
         height: px-to-rem(30px)

.dark
   div.cp_component_campaign-assign-reviewers-modal
      .edit-group-name
         input:--internal-autofill-selected
            background-color: dark.$color_fill-dropdown-select
      .permissions-toggle-container
         .permissions-list
            background: dark.$color_fill-card
