@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_page_search
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  div.cp_page_search-container
    height: calc(100vh - calc($top-nav-height + 3rem))
    position: relative

  section.cp_page_search-form
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    height: 100%
    left: 0
    overflow: hidden auto
    position: absolute
    top: 0
    width: 350px

    > aside.cp_page_search-button
      background: $color_fill-card
      align-items: center
      bottom: 0
      display: flex
      height: $space_8x
      justify-content: center
      left: 0
      position: sticky
      width: 100%

      > button
        width: calc(100% - 2rem)

  section.cp_page_search-results
    height: 100%
    overflow: hidden
    position: absolute
    right: 0
    top: 0
    width: calc(100% - calc(350px + 1rem))

// Dark Theme Styles
.dark div.cp_page_search
  section.cp_page_search-form
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard

    > aside.cp_page_search-button
      background: dark.$color_fill-card
