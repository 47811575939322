@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_communications-body-main-container
  display: flex
  flex-direction: column
  flex: 7
  gap: $space_2x
  overflow: hidden

  .header-section
    display: flex
    justify-content: space-between
    div.cp_component_search-bar-input.cp_component_input.search-bar
      background: $color_fill-card
      margin: 0
      padding: 0
      width: px-to-rem(320px)
      .MuiInputBase-root fieldset
        border: 1px solid $color_border-colorcard
    .details-container
      align-items: center
      display: flex
      gap: $space_4x
      .created-label
        color: $color_text-secondary
        font-size: px-to-rem(10px)
      .campaign-page-button
        align-items: center
        display: flex
        gap: $space_1x
        .MuiSvgIcon-root
          fill: $color_text-inlinebutton
          height: px-to-rem(18px)
          width: px-to-rem(18px)
        p
          color: $color_text-inlinebutton
          font-size: px-to-rem(10px)
        &:hover
          cursor: pointer
    &.is-draft
      justify-content: flex-end

  .communications-body-wrapper
    column-gap: $space_2x
    display: flex
    flex-grow: 1
    height: calc(100% - px-to-rem(53.59px))

    .cp_component-drafts-folder-view,
    .communications-email-list-view,
    .communications-email-conversation-detail
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      flex: 1
      min-width: 0

.dark
  .cp_component_communications-body-main-container
    .header-section
      div.cp_component_search-bar-input.cp_component_input.search-bar
        background: dark.$color_fill-card
        border-color: dark.$color_border-colorcard
        .MuiInputBase-root fieldset
          border-color: dark.$color_border-colorcard
      .details-container
        .created-label
          color: dark.$color_text-secondary
        .campaign-page-button
          .MuiSvgIcon-root
            fill: dark.$color_text-inlinebutton
          p
            color: dark.$color_text-inlinebutton

    .cp_component-drafts-folder-view,
    .communications-email-list-view,
    .communications-email-conversation-detail
      background: dark.$color_fill-card
      border-color: dark.$color_border-colorcard
  