@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_roles-overview
  background: $color_fill-card
  border-radius: $content-border-radius
  border: 1px solid $color_border-colorcard
  display: flex
  flex-direction: column
  flex: 1
  gap: $space_2x
  padding: $space_2x
  width: 100%

  &_header
    display: flex
    flex-direction: row
    justify-content: space-between

    &_search
      align-items: center
      display: flex
      gap: $space_2x
      width: 35%

      .search-bar.cp_component_search-bar-input.cp_component_input
        margin: 0
        padding: 0
        width: 100%
    
    &_cta
      display: flex
      flex-direction: row
      gap: $space_1x

      .cp_component_roles_create-role-button
        width: px-to-rem(150px)

  &_body
    height: calc(100vh - 180px)
    width: 100%

    // TABLE STYLES
    // Root Table
    .MuiDataGrid-root
      border: 0
      border-radius: 0
      font-family: "Open Sans", sans-serif

    // Empty/Placeholder State
    &-empty
      .MuiDataGrid-virtualScroller
        overflow: hidden

      div.cp_component_empty-splash
        margin: 6rem 0 0 0

    // Headers
    .MuiDataGrid-columnHeaders
      background-color: $color_fill-social-background
      border-bottom: none
      border-top-left-radius: 0
      border-top-right-radius: 0

      span.MuiBadge-badge
        display: none

    // Header Titles
    .MuiDataGrid-columnHeaderTitle
      color: $color_text-secondary
      font-size: 0.75rem
      font-weight: 700
      text-transform: uppercase

    // Table Data Container
    .MuiDataGrid-virtualScroller
      background-color: $color_fill-card

    // Cells
    .MuiDataGrid-cell
      border-bottom: 1px solid $color_border-section

    // Footer
    .MuiDataGrid-rowCount
      color: $color_text-secondary



.dark
  .cp_component_roles-overview
    background: dark.$color_fill-card
    border: 1px solid dark.$color_border-colorcard

    &_body
      .MuiDataGrid-columnHeaders
        background-color: dark.$color_fill-social-background
      .MuiDataGrid-columnHeaderTitle
        color: dark.$color_text-secondary
      .MuiDataGrid-virtualScroller
        background-color: dark.$color_fill-card
      .MuiDataGrid-cell
        border-bottom: 1px solid dark.$color_border-section
      .MuiDataGridPro-footerContainer
        color: dark.$color_text-secondary
      .MuiDataGrid-rowCount
        color: dark.$color_text-secondary
