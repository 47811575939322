@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_campaign_reporting_tab
  background: $color_fill-card
  border-radius: $content-border-radius

  &.settings
    .MuiPaper-root.MuiPaper-outlined
      border-radius: 2px
      &:before
        display: none

  &.loading 
    align-items: center
    display: flex
    height: 200px
    justify-content: center
  &.report
    .cp_campaign_reporting_tab
      &-actions
        justify-content: space-between
        margin-bottom: $space_4x
    .cp_card-metrics_sections
      .cp_platform_insight-sections
        .section
          h2.section-heading
            @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-normal)
            background-color: $color_fill-pill-1
            margin-bottom: $space_3x
            padding: $space_1x
            width: 100%

          .section-metrics
            display: flex
            flex-wrap: wrap
            gap: $space_4x
            justify-content: flex-start
            margin-bottom: px-to-rem(36px)
            padding: 0 px-to-rem(70px)
            .metric-card
              border-radius: 4px
              border: 2px solid $color_border-section
              width: px-to-rem(160px)
              display: flex
              flex-direction: column
              justify-content: space-between
              svg.MuiSvgIcon-root
              margin-bottom: $space_2x
              .metric-card-body
                padding: $space_3x $space_2x
                text-align: center
                .metric-value
                  @include textStyles($color_brand-blue-deep, $space_3x, $font-weight-bold)
                  margin-bottom: .25rem
                .metric-title
                  @include textStyles($color_brand-blue-deep, $font-size-sm, $font-weight-normal)
                  height: 2.5rem
                .metric-definition
                  @include textStyles($color_text-primary, $font-size-xs, $font-weight-normal)
                  text-align: left
                  margin-top: 8px
              &.MuiButton-text
                color: inherit
                border: none
                min-width: fit-content
                margin-left: $space_1x
                margin-right: $space_1x
              .metric-see-more
                @include textStyles($color_brand-blue-deep, $font-size-xs, $font-weight-normal)
                text-align: right
                margin-right: $space_1x
                margin-bottom: $space_1x
                align-self: flex-end

  &.public
    padding: $space_2x

  &-actions
    align-items: center
    display: flex
    justify-content: flex-end
    margin-bottom: px-to-rem(20px)
    padding-top: .25rem

  > div.MuiPaper-root
    background: $color_fill-card
    margin-bottom: $space_2x

    div.MuiCollapse-root
      div.MuiAccordionDetails-root
        div.cp_component-data-grid
          background-color: initial
          border: none
          padding: 0

  div.MuiAccordionSummary-root
    div.MuiAccordionSummary-expandIconWrapper
      color: $color_border-input
      &.Mui-expanded
        color: $color_input-active
        transform: rotate(90deg)
    svg.MuiSvgIcon-root
      height: px-to-rem(12px)
      width: px-to-rem(12px)
  
  div.MuiAccordionSummary-content
    display: flex
    justify-content: space-between
    margin: 0
    padding: $space_2x 0
    > div
      align-items: center
      column-gap: .25rem
      display: flex
      justify-content: center
      span.MuiCheckbox-root
        padding: .25rem
        svg.MuiSvgIcon-root
          height: $space_2x
          width: $space_2x
      span.cp_accordion-toggle-totals
        @include textStyles($color_input-active, px-to-rem(14px), $font-weight-normal)
        min-width: px-to-rem(108px)
        padding-right: $space_2x

  div.MuiAccordionDetails-root
    border-top: 1px solid $color_border-section
    margin: 0 $space_2x
    padding: $space_3x 0 $space_2x

  .cp_component_accordion
    &-heading
      @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)
      span
        display: inline-block
        font-size: $font-size-sm
        font-weight: $font-weight-normal
        padding-left: px-to-rem(12px)
    &-report-name
      margin-bottom: $space_4x
      min-width: 16rem
      width: 40%
    &-radiogroup
      h4.headline-small
        font-size: px-to-rem(14px)
        font-weight: $font-weight-bolder
        margin-bottom: px-to-rem(10px)
      label.cp_radio-label
        padding:  0 px-to-rem(10px) px-to-rem(10px)
        span.MuiRadio-root
          padding: 0 
        span.MuiFormControlLabel-label
          padding-left: px-to-rem(10px)
          @include textStyles($color_text-radiobutton, $font-size-sm, $font-weight-normal)

  .cp_component-data-grid
    div:not(.MuiDataGrid-pinnedColumns)
      div.MuiDataGrid-row
        div.MuiDataGrid-cell
          padding-left: $space_3x
          .shaded
            background-color: $color_surface-secondary
    p.cp_native-metric
      @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)

    span.MuiSwitch-root
      svg.MuiSvgIcon-root
        fill: $color_surface-primary
        height: px-to-rem(18px)
        width: px-to-rem(18px)

  .cp_campaign_reporting_tab-accordion_settings
    .cp_formcontrol-settings
      padding-left: $space_2x
      legend.MuiFormLabel-root
        @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bolder)

      > div.MuiFormGroup-root
        span.MuiTypography-root
          @include textStyles($color_text-radiobutton, $font-size-sm, $font-weight-normal)

.dark
  .cp_campaign_reporting_tab
    background: dark.$color_fill-card
    &.settings
      .MuiPaper-root.MuiPaper-outlined
        border-color: dark.$color_border-section
    &.report
      .cp_card-metrics_sections
        .cp_platform_insight-sections
          .section
            h2.section-heading
              background-color: dark.$color_divider-section
              color: dark.$color_text-primary

            .section-metrics
              .metric-card
                border-color: dark.$color_border-section
                svg.MuiSvgIcon-root
                  fill: dark.$color_icon-supporting-green-neon
                .metric-value
                  color: dark.$color_button-hovered
                .metric-title
                  color: dark.$color_button-hovered
                .metric-definition
                  color: dark.$color_text-primary
                .metric-see-more
                  color: dark.$color_button-hovered

    > div.MuiPaper-root
      background: dark.$color_fill-card

    div.MuiAccordionSummary-root
      div.MuiAccordionSummary-expandIconWrapper
        color: dark.$color_border-input
        &.Mui-expanded
          color: dark.$color_input-active
      div.cp_component-data-grid 
       border-top-color: dark.$color_border-colorcard

    div.MuiAccordionDetails-root
      border-top-color: dark.$color_border-section

    .cp_component_accordion
      &-heading
        color: dark.$color_text-primary

      &-radiogroup
        label.cp_radio-label
          span.MuiFormControlLabel-label
            color: dark.$color_text-radiobutton

    .cp_component-data-grid
      div.MuiDataGrid-row
        div.MuiDataGrid-cell:first-of-type
          background-color: dark.$color_surface-secondary
      p.cp_native-metric
        color: dark.$color_text-secondary

      span.MuiSwitch-root
        svg.MuiSvgIcon-root
          fill: dark.$color_surface-primary

      .cp_campaign_reporting_tab-accordion_settings
    
    .cp_campaign_reporting_tab-accordion_settings
      .cp_formcontrol-settings
        legend.MuiFormLabel-root
          color: dark.$color_text-primary
        > div.MuiFormGroup-root
          span.MuiTypography-root
            color: dark.$color_text-radiobutton

    div.MuiAccordionSummary-content
      span.cp_accordion-toggle-totals
        @include textStyles(dark.$color_input-active, px-to-rem(14px), $font-weight-normal)
