@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_pl-account-page
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: 1516px 972px
  background-position: 60% 35%

  &-background
    backdrop-filter: blur(3rem)
    height: 400px

  &-header
    backdrop-filter: blur(4rem)
    background-color: $color_fill-card
    border: solid 1px #f1f1f1
    opacity: 80%
    padding: $space_1x

    &-logo
      background-color: $color_fill-card
      background-image: url("../../asset/img/logo_radius_text_light.svg")
      background-size: 84px 28px
      height: px-to-rem(28px)
      width: px-to-rem(84px)

  &-error,
  &-loading,
  &-content
    background-color: $color_fill-card
    border: 1px solid $color_border-colorcard
    border-radius: px-to-rem(10px)
    display: flex
    flex-direction: column
    gap: $space_2x
    height: calc( 100vh - 90px )
    margin: $space_3x
    padding: px-to-rem(34px) px-to-rem(46px)
    overflow: scroll

    &-header
      display: flex
      flex-direction: row
      justify-content: space-between

      &-breadcrumbs
        .cp-btn.MuiButton-root
          padding: 8px 0px

      &-cta
        &-content
          display: flex
          flex-direction: row
          gap: $space_2x

          &-approve-button,
          &-feedback-button
            align-content: center
            display: flex
            flex-direction: row
            gap: $space_1x

          &-approve-button
            .approved-button
              border: none !important

              &.approved-state
                background-color: $color_color-success
                border-radius: px-to-rem(10px)
                color: $color_text-invert

              &.pending-approval-state
                background-color: $color_button-pressed
                border-radius: px-to-rem(10px)
                color: $color_text-invert

            .approved-label
              @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bolder)
              align-content: center
              text-transform: uppercase
              

          &-feedback-button
            .feedback-badge
              .feedback-button
                border: none
              .MuiBadge-badge
                font-size: $font-size-xs
                height: 16px
                min-width: 16px
            .feedback-label
              @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bolder)
              align-content: center
              text-transform: uppercase

    &-overview
      display: flex
      flex-direction: row
      justify-content: space-between
      width: 100%

      &-carousel
        align-self: center
        display: flex
        flex-direction: row
        margin-left: $space_6x
        overflow: hidden
        width: calc(100% - 180px)

        .cp_component_carousel_card-slider_box

          .carousel-card
            margin-top: .8rem
            text-transform: uppercase

            p.body_small
              font-weight: 700

    &-tabs
      .cp_account-page-tabs
        &-hidden-placeholder
          visibility: hidden

    &-approval-overlay
      align-items: center
      border: 1px solid $color_border-colorcard
      border-radius: $content-border-radius
      display: flex
      height: 100%
      justify-content: center
      width: 100%

      &-content
        align-items: center
        display: flex
        flex-direction: column
        gap: $space_2x
        width: px-to-rem(350px)

        p
          @include textStyles($color_text-primary, $font-size-large, $font-weight-normal)

      &-check-icon
        height: px-to-rem(80px)
        margin-bottom: $space_4x
        width: px-to-rem(80px)

        .checkmark-icon
          fill: $color_color-success
          height: px-to-rem(80px)
          width: px-to-rem(80px)

      &-actions
        display: flex
        flex-direction: column
        gap: $space_2x
        margin-top: $space_8x
        width: 100%

    &-feedback
      border: 1px solid $color_border-colorcard
      border-radius: $content-border-radius
      display: flex
      flex-direction: column
      gap: $space_2x
      height: 100%
      padding: $space_3x
      width: 100%

      &-header
        border-bottom: 1px solid $color_border-colorcard
        display: flex
        flex-direction: row
        justify-content: space-between
        padding-bottom: $space_1x

        &-avatar
          display: flex
          flex-direction: column
          gap: $space_3x

          &-title
            align-items: center
            display: flex
            flex-direction: row
            gap: $space_1x

            .static-feedback-title
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-bold)

            .feedback-counter
              @include textStyles($color_text-primary, px-to-rem(10px), $font-weight-bolder)
              background-color: $color_fill-numbercounter
              border: 1px solid $color_border-numbercounter
              border-radius: px-to-rem(4px)
              gap: px-to-rem(10px)
              height: px-to-rem(16px)
              letter-spacing: 0%
              padding-left: px-to-rem(4px)
              width: px-to-rem(16px)

        &-closer
          &-icon
            fill: $color_text-inactive

      &-content
        align-items: center
        display: flex
        flex-direction: column
        flex: 1
        gap: $space_2x
        overflow: auto

        &-no-feedback
          align-items: center
          display: flex
          flex-direction: column
          margin: auto

          .no-feedback-text
            @include textStyles($color_text-inactive, $font-size-normal, $font-weight-normal)

        .cp_component_pl-comment-card
          background-color: $color_fill-section-content
          padding: $space_2x
          width: px-to-rem(643px)

          &-header
            border-bottom: 1px solid $color_border-colorcard
            padding-bottom: $space_1x

            &-name
              @include textStyles($color_text-primary, $font-size-normal, $font-weight-normal)

            &-company
              @include textStyles($color_text-follower, $font-size-normal, $font-weight-bolder)
              
          &-content
            @include textStyles($color_text-secondary, $font-size-sm, $font-weight-normal)
            padding: $space_3x 0

          &-footer
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
            display: flex
            flex-direction: row
            justify-content: flex-end

      &-footer
        background-color: $color_fill-footer
        display: flex
        flex-direction: column
        gap: $space_2x
        padding: px-to-rem(18px) px-to-rem(30px) px-to-rem(16px) px-to-rem(30px)

        &-feedback-message
          width: 100% !important

          .MuiFormControl-root
            .MuiInputBase-root
              .MuiInputBase-input
                height: px-to-rem(100px) !important


        &-submit-button
          width: px-to-rem(227px)


.dark
  .cp_component_pl-account-page
    &-header
      background-color: dark.$color_fill-card
      &-logo
        background-color: dark.$color_fill-card

    &-error,
    &-loading,
    &-content
      background-color: dark.$color_fill-card
      border: 1px solid dark.$color_border-colorcard
      &-header
        &-cta
          &-content
            &-approve-button
              .approved-button
                &.approved-state
                  background-color: dark.$color_color-success
                  color: dark.$color_text-invert
                &.pending-approval-state
                  background-color: dark.$color_button-pressed
                  color: dark.$color_text-invert

              .approved-label
                @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-bolder)

            &-feedback-button
              .feedback-label
                @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-bolder)

      &-approval-overlay
        border: 1px solid dark.$color_border-colorcard
        &-content
          p
            @include textStyles(dark.$color_text-primary, $font-size-large, $font-weight-normal)

        &-check-icon
          .checkmark-icon
            fill: dark.$color_color-success

      &-feedback
        border: 1px solid dark.$color_border-colorcard
        &-header
          border-bottom: 1px solid dark.$color_border-colorcard
          &-avatar
            &-title
              .static-feedback-title
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-bold)

              .feedback-counter
                @include textStyles(dark.$color_text-primary, px-to-rem(10px), $font-weight-bolder)
                background-color: dark.$color_fill-numbercounter
                border: 1px solid dark.$color_border-numbercounter

          &-closer
            &-icon
              fill: dark.$color_text-inactive

        &-content
          &-no-feedback
            .no-feedback-text
              @include textStyles(dark.$color_text-inactive, $font-size-normal, $font-weight-normal)

          .cp_component_pl-comment-card
            background-color: dark.$color_fill-section-content
            &-header
              border-bottom: 1px solid dark.$color_border-colorcard
              &-name
                @include textStyles(dark.$color_text-primary, $font-size-normal, $font-weight-normal)
              &-company
                @include textStyles(dark.$color_text-follower, $font-size-normal, $font-weight-bolder)
                
            &-content
              @include textStyles(dark.$color_text-secondary, $font-size-sm, $font-weight-normal)

            &-footer
              @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bolder)

        &-footer
          background-color: dark.$color_fill-footer
