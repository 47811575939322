@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_image-analysis_component
    min-width: 300px

    &-occurence
        display: flex
        font-size: $font-size-sm
        font-weight: $font-weight-normal
        justify-content: space-between
        padding: $space_1x 0
    
        &-count
            font-size: $font-size-sm


        &-name
            color: $color_text-primary
            text-decoration: none

            &:hover
                color: $color_text-inlinebutton


.dark
    .cp_image-analysis_component
        
        &-occurence-name
            color: dark.$color_text-primary

            &:hover
                color: dark.$color_text-inlinebutton
        