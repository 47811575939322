@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_list-acct-data-point-modal

  p.body_large.header
    margin: $space_2x 0

  p.body_small.content

