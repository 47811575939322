@use "../../../style/_primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_profile_component-public-list-tabs-empty-container
    align-items: center
    display: flex
    height: 100%
    justify-content: center
    width: 100%

.cp_profile_component-public-list-tabs
    background-color: $color_fill-background
    box-sizing: border-box
    padding: $space_2x
    width: calc(100% - primitives.$width_public-list-overview)

    &.overview-expanded
        width: calc(100% - primitives.$width_public-list-overview-expanded)
    
    &.right-panel-expanded
        width: calc(100% - primitives.$width_public-list-right-panel-expanded)

    .cp_profile_component-public-list-tabs-container
      border-radius: $content-border-radius
      display: flex
      flex-direction: column
      padding: $space_2x $space_1x
      position: relative
      
      &_loading

      .cp_profile_component-public-list-tabs-content
          flex: 1 1
          height: 100%
          overflow-y: auto
    &-logo-container
        display: flex
        justify-content: center
        div.cp_component_navigation-bar-logo.has-logo-tag.logo
            margin: $space_3x


.dark
    .cp_profile_component-public-list-tabs
        background-color: dark.$color_fill-background
        .cp_profile_component-public-list-tabs-container
            border-radius: $content-border-radius

