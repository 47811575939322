@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

.cp_component_user-settings
  display: flex
  flex-direction: column
  gap: $space_2x
  &-overview
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    height: px-to-rem(85px)
    overflow: hidden auto
    width: 100%
    &_user-avatar
      background-color: $color_fill-card-blue
      display: flex
      gap: $space_2x
      padding: $space_1x
      width: px-to-rem(324px)
      .avatar_image-container
        &_avatar.cp-avatar
          font-size: 200%
          margin: auto
          min-height: px-to-rem(66px)
          min-width:  px-to-rem(66px)
      .avatar_user-info
        display: flex
        flex-direction: column
        gap: $space_1x
        margin-top: $space_1x
        &_name
          color: $color_text-invert
          font-size: 1.2rem
          font-weight: 400
        &_company
          color: $color_text-invert
          font-size: 0.75rem
          font-weight: 400


  &-tabs
    background: $color_fill-card
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    display: flex
    flex-direction: row
    flex: 1
    justify-content: center
    align-items: center
    overflow: hidden auto
    width: 100%

.dark
  .cp_component_user-settings
    &-overview
      background: dark.$color_fill-card
      border: 1px solid dark.$color_border-colorcard
      &_user-avatar
        .avatar_user-info
          &_name
            color: dark.$color_text-primary
          &_company
            color: dark.$color_text-primary

    &-tabs
      background: dark.$color_fill-card
      border: 1px solid dark.$color_border-colorcard