@use '../../style/styles' as *

.cp_component_accounts_contact_info_modal
  &-divider.MuiDivider-root
    width: 100%

  .cp_component_accounts_content
    width: 100%

    div.cp_component_dropdown
      width: 100%
      
    .input_first_last_name
      width: 100%

    .dropdown_primary_email
      width: 100%
