@use "../../../style/theme_dark" as dark
@use "../../../style/theme_light" as *
@use "../../../style/_styles" as *
@use "../../../style/_primitives" as *

.cp_lcp_highlights-previews
  display: flex
  flex-direction: column
  gap: $space_2x

  &_account-container
    background-color: $color_fill-card
    border: 1px solid $color_border-image
    display: flex
    flex-direction: column
    gap: $space_3x
    padding: $space_2x

    &_ribbon-container
      display: flex
      flex-direction: column
      gap: $space_3x

      &_metrics-ribbon-banner
        background-color: $color_fill-banner
        display: flex
        flex-direction: row
        gap: $space_3x
        padding: px-to-rem(5px) px-to-rem(16px) px-to-rem(5px) px_to_rem(18px)

        .cp_lcp_hpm_rb_metric
          display: flex
          flex-direction: row
          gap: $space_1x

          &_value
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bolder)
            text-transform: uppercase
            white-space: nowrap

          &_label
            white-space: nowrap

    &_public-account-details
      display: flex
      flex-direction: column
      gap: $space_2x

      .public-account-details
        &_rationale,
        &_bio,
        &_tags
          display: flex
          flex-direction: column
          gap: $space_1x

          .section-title
            @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bold)
            
          .section-pills
            display: flex

            div.MuiChip-root
              margin: 0 $space_1x 0 0

    &_account-insights
      display: flex
      flex-direction: column
      gap: $space_4x

      .account-insights
        &_eng-rate-over-time,
        &_heat-map,
        &_media-stories,
        &_ai-image-analysis,
        &_pi-traits
          border: solid 1px $color_border-image
          padding: 0 $space_2x $space_2x $space_2x
        
        &_heat-map,
        &_ai-image-analysis,
        &_pi-traits
          padding-top: $space_2x

        &_rate-by-post-type
          &_header
            @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bold)
            margin-bottom: $space_1x
          &_widget
            border: solid 1px $color_border-image
            padding: 0 $space_2x $space_2x $space_2x

        &_brands-mentioned-worked
          &_header
            @include textStyles($color_text-primary, px-to-rem(16px), $font-weight-bold)
            margin-bottom: $space_1x
          &_widget
            border: solid 1px $color_border-image
            padding: 0 $space_2x $space_2x $space_2x


        &_ai-image-analysis
          background-color:  $color-fill-traits
          border-radius: px-to-rem(15px)

          &_header
            @include textStyles($color_text-primary, px-to-rem(12px), $font-weight-bolder)
            text-transform: uppercase
          
          .cp_image-analysis_component
            display: flex
            gap: $space_2x
            padding-top: $space_1x

            &_image
              border: solid 1px $color_border-secondarybutton
              border-radius: px-to-rem(100px)
              display: flex
              flex-direction: row
              gap: $space_1x
              padding: 0 px-to-rem(8px) 0 px-to-rem(8px)

              &_name
                @include textStyles($color_text-follower, px-to-rem(12px), $font-weight-bolder)
                text-transform: uppercase

              &_count
                @include textStyles($color_text-primary, px-to-rem(12px), $font-weight-bolder)

            &_image:hover
              cursor: pointer

        &_pi-traits
          &_header
            @include textStyles($color_text-primary, px-to-rem(14px), $font-weight-bold)
            margin-bottom: $space_1x

          .cp_pi-traits_component
            background-color: $color-fill-traits
            border-radius: px-to-rem(15px)
            display: flex
            flex-direction: row
            flex-wrap: wrap
            gap: $space_2x
            padding: $space_2x
            
            &_container
              display: flex
              flex-direction: column

              &_description
                @include textStyles($color_text-primary, px-to-rem(12px), $font-weight-bolder)
                margin-bottom: px-to-rem(5px)
                text-transform: uppercase

              &_pill
                border: solid 1px $color_border-secondarybutton
                border-radius: px-to-rem(100px)
                display: flex
                flex-direction: row
                padding: 0 px-to-rem(8px) 0 px-to-rem(8px)

                &_high
                  border-right: 1px solid $color_text-follower
                  display: flex
                  flex-direction: row
                  gap: $space_1x
                  padding-right: px-to-rem(8px)

                &_low
                  display: flex
                  flex-direction: row
                  gap: $space_1x
                  padding-left: px-to-rem(8px)

                &_high,
                &_low
                  .value
                    @include textStyles($color_text-primary, px-to-rem(12px), $font-weight-bolder)
                  .descriptor
                    @include textStyles($color_text-follower, px-to-rem(12px), $font-weight-bolder)

.dark
  .cp_lcp_highlights-previews
    &_account-container
      background-color: dark.$color_fill-card
      border: 1px solid dark.$color_border-image
      &_metrics-ribbon-banner
        background-color: dark.$color_fill-banner
        .cp_lcp_hpm_rb_metric
          &_value
            @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bolder)

      &_account-insights
        .account-insights
          &_eng-rate-over-time,
          &_heat-map,
          &_media-stories,
          &_ai-image-analysis,
          &_pi-traits
            border: solid 1px dark.$color_border-image
          
          &_rate-by-post-type
            &_header
              @include textStyles(dark.$color_text-primary, px-to-rem(14px), $font-weight-bold)
            &_widget
              border: solid 1px dark.$color_border-image

          &_brands-mentioned-worked
            &_header
              @include textStyles(dark.$color_text-primary, px-to-rem(16px), $font-weight-bold)
            &_widget
              border: solid 1px dark.$color_border-image

          &_ai-image-analysis
            background-color: dark.$color-fill-traits
            &_header
              @include textStyles(dark.$color_text-primary, px-to-rem(12px), $font-weight-bolder)
            .cp_image-analysis_component
              &_image
                border: solid 1px dark.$color_border-secondarybutton
                &_name
                  @include textStyles(dark.$color_text-follower, px-to-rem(12px), $font-weight-bolder)
                &_count
                  @include textStyles(dark.$color_text-primary, px-to-rem(12px), $font-weight-bolder)

          &_pi-traits
            &_header
              @include textStyles(dark.$color_text-primary, px-to-rem(14px), $font-weight-bold)
            .cp_pi-traits_component
              background-color: dark.$color-fill-traits
              &_container
                &_description
                  @include textStyles(dark.$color_text-primary, px-to-rem(12px), $font-weight-bolder)
                &_pill
                  border: solid 1px dark.$color_border-secondarybutton
                  &_high
                    border-right: 1px solid dark.$color_border-secondarybutton

                  &_high,
                  &_low
                    .value
                      @include textStyles(dark.$color_text-primary, px-to-rem(12px), $font-weight-bolder)
                    .descriptor
                      @include textStyles(dark.$color_text-follower, px-to-rem(12px), $font-weight-bolder)


      &_public-account-details
        .public-account-details
          &_rationale,
          &_bio,
          &_tags
            .section-title
              @include textStyles(dark.$color_text-primary, px-to-rem(14px), $font-weight-bold)
