@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp-switch.MuiSwitch-root
  height: px-to-rem(48px)
  width: px-to-rem(72px)

  .MuiButtonBase-root
    color: $color_button-text
    left: px-to-rem(6px)
    top: px-to-rem(6px)

    .MuiSwitch-thumb
      height: px-to-rem(18px)
      width: px-to-rem(18px)

  .MuiSwitch-track 
    background-color: $color_button-toggle-off
    border-radius: 30px
    opacity: 1

  .Mui-checked
    transform: translateX(24px)

  .Mui-checked ~ .MuiSwitch-track
    background-color: $color_button-primary
    opacity: 1
  
  &.cp-switch_with-icon 
    .MuiSwitch-thumb
        background-image: url($switch-icon-unchecked-svg)
        background-position: center center
        background-repeat: no-repeat
        background-size: contain  

    .Mui-checked
      .MuiSwitch-thumb
        background-image: url($switch-icon-checked-svg)

    span svg
      fill: $color_icon-dark-theme
      height: px-to-rem(16px)
      width: px-to-rem(16px)


    span.cp-switch_dark-icon, span.cp-switch_light-icon 
      align-items: center
      background-color: $color_icon-light-theme
      border-radius: 50%
      display: flex
      height: px-to-rem(18px)
      justify-content: center
      width: px-to-rem(18px)

.dark
  .cp-switch.MuiSwitch-root

    .MuiButtonBase-root
      color: $color_button-text

    .MuiSwitch-track 
      background-color: dark.$color_button-toggle-off
      opacity: 1

    .Mui-checked ~ .MuiSwitch-track
      background-color: dark.$color_button-primary
      opacity: 1

    &.cp-switch_with-icon 

      .Mui-checked
        .MuiSwitch-thumb
          background-image: url($switch-icon-checked-dark-svg)




