@use '../../style/_styles' as *
@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_modal-ttcm-link-container
    padding-top: $space_2x

    &-body
        display: flex
        flex-direction: column
        gap: $space_2x
        text-align: center
        margin: auto
        max-width: 75%

.dark
    .cp_component_modal-ttcm-link-container
