@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component_campaign-reporting-trends
  min-height: px-to-rem(200px)
  .dropdown-controls
    display: flex
    justify-content: space-between
    margin-bottom: $space_2x

    .MuiButtonBase-root
      align-items: center
      display: flex
      justify-content: space-between
      padding: 0 $space_1x
      height: px-to-rem(32px)
      width: px-to-rem(250px)

    .MuiListItemText-root.selected
      span.MuiTypography-root.MuiListItemText-primary
        font-weight: 600

    .dropdown-right-controls
      display: flex
      gap: $space_2x

  .bar-charts
    display: flex
    flex-direction: column
    gap: $space_2x
    .bar-chart-container
      border: 1px solid $color_divider-section
      border-radius: 2px
      padding: $space_2x

      .header-container
        display: flex
        justify-content: space-between
        margin-bottom: $space_2x
        .name, .metric
          font-weight: 600
      .MuiLinearProgress-root
        background-color: $color_fill-social-background
        height: px-to-rem(12px)
        border-radius: 4px
        .MuiLinearProgress-bar
          background-color: $color_fill-progress-bar

.dark      
  .cp_component_campaign-reporting-trends
    .dropdown-controls
      .MuiButtonBase-root

      .dropdown-right-controls

    .bar-charts
      .bar-chart-container
        border-color: dark.$color_divider-section
        .header-container
          .name
          .metric
        .MuiLinearProgress-root
          background-color: dark.$color_fill-social-background
          .MuiLinearProgress-bar
            background-color: dark.$color_fill-progress-bar
        