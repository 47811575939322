@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use "../../style/styles" as *

.cp_component_create-role-modal

  &_body
    display: flex
    flex-direction: column
    gap: $space_1x
    margin: $space_2x

    &_basics
      border: px-to-rem(1px) solid $color_border-section
      display: flex
      flex-direction: column
      gap: $space_2x
      padding: $space_2x
      width: 100%

      .basics-input
        display: flex
        flex-direction: column
        gap: $space_1x
        width: 50%

        &_label
          display: flex
          flex-direction: row
          justify-content: space-between

        .cp_component_input
          width: 100%
  
    .pm_component_scope-exp-panel
      &_summary
        .MuiAccordionSummary-content
          display: flex
          flex-direction: row
          justify-content: space-between

          .header-title
            padding-top: px-to-rem(2px)

          .details-container
            width: px-to-rem(185px)

            .details-message
              color: $color_button-primary

  div.scope-pair
    display: flex
    flex-direction: row
    justify-content: space-between    
    margin: $space_2x

    &_scope
      border-bottom: px-to-rem(1px) solid $color_border-pill
      display: flex
      flex-direction: row
      justify-content: space-between
      padding-top: $space_2x
      padding-bottom: $space_2x
      width: 48%

.dark
  .cp_component_create-role-modal

    &_body
      &_basics
        border: px-to-rem(1px) solid dark.$color_border-section

      .pm_component_scope-exp-panel
        &_summary
          .MuiAccordionSummary-content
            .details-container
              .details-message
                color: dark.$color_button-primary

    div.scope-pair
      &_scope
        border-bottom: px-to-rem(1px) solid dark.$color_border-pill
