@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_account-content-search_component
    &-row
      display: flex
      gap: $space_2x
      flex-wrap: no-wrap
      margin: -$space_1x 0

    &-column
      display: flex
      flex-basis: 0
      flex-direction: column

      &:first-child
        flex-grow: 350
        max-width: 350px

      &:last-child
        flex-grow: 557

        .cp_page_search-container
          height: 100%
          position: relative
          .cp_page_search-results 
            height: 100%
            overflow: hidden
            position: absolute
            right: 0
            top: 0
            width: 100%

            // Override results component height 
            section.cp_component_search-results-table-container
              height: 100%


    &-widget
      background: $color_fill-card
      border-radius: $content-border-radius
      border: 1px solid $color_border-colorcard
      margin: $space_1x 0
      overflow: hidden
      padding: $space_3x
      position: relative

      &-title
        color: $color_text-primary
        font-size: $font-size-large
        margin-bottom: 0.5rem
      
      &-bio
        color: $color_text-secondary
        font-size: $font-size-sm
        font-weight: $font-weight-normal
        margin-top: 0.5rem
      
      &:last-child
        padding: 0

    &-content-filter
      .cp_page_search-button
        .cp-btn.MuiButton-root.cp-btn_primary
          width: 100%

    div.cp_component_search-filter-network
      margin-bottom: $space_4x

.dark
  .cp_account-content-search_component
    &-widget
      background: dark.$color_fill-card
      border-radius: $content-border-radius
      border: 1px solid dark.$color_border-colorcard
      
      &-title
        color: dark.$color_text-primary

      &-bio
        color: dark.$color_text-secondary
