@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

// Light Theme Styles
div.cp_component_audience-authenticity-charts
  align-items: flex-start
  display: flex
  gap: $space_4x
  justify-content: space-evenly

  section.chart-container
    flex: 1 0 0

    // Score Button
    .cp-btn.MuiButton-root.score-button
      background-color: $color_brand-blue-ocean
      box-shadow: none
      margin: 0 0 0 $space_3x
      min-width: auto
      padding: px-to-rem(6px) px-to-rem(10px)

    // Chart Cards
    article
      align-items: flex-start
      background-color: primitives.$color_chart-background-light
      border-radius: 2px
      display: flex
      justify-content: space-between

    // Highchart Styles
    .highcharts-background
      fill: none

    tspan.highcharts-text-outline
        stroke: none

    .highcharts-data-label text
        font-size: 0.9rem !important

    // Chart Details
    aside
      align-items: center
      display: flex
      justify-content: center
      padding: 0 $space_3x
      height: 260px
      width: 50%

    h6
      height: 1.8rem

    h4, h6
      margin-bottom: $space_3x

    p.body_large
      line-height: 150%

// Dark Theme Styles
.dark
  div.cp_component_audience-authenticity-charts
    section.chart-container
      // Score Button
      .cp-btn.MuiButton-root.score-button
        background-color: dark.$color_button-pressed
        
      article
        background-color: primitives.$color_chart-background-dark
