@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/primitives" as primitives
@use "../../style/styles" as *

// Light Theme Styles 
div.cp_page_login
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: cover
  height: 100vh

  // Inner content styles in order to apply blur to background
  > div
    align-items: center
    backdrop-filter: blur(2rem)
    display: flex
    height: 100%
    justify-content: center
    width: 100%

  section.card
    background-color: $color_fill-card
    border-radius: 2rem
    box-shadow: 0 px-to-rem(4px) px-to-rem(4px) 0 primitives.$color_black-a50
    padding: 6rem 5rem
    position: relative
    width: 40rem

  section.card
    background-color: $color_fill-card
    border-radius: 2rem
    box-shadow: 0 px-to-rem(4px) px-to-rem(4px) 0 primitives.$color_black-a50
    max-height: 90vh
    overflow-y: auto
    padding: 6rem 5rem
    position: relative
    width: 40rem

    // Hide scrollbar on card
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      display: none

    // Back Link
    > div.back
      align-items: flex-start
      display: flex
      justify-content: center
      left: 3rem
      position: absolute
      top: 3rem

      svg
        color: $color_text-inactive
        height: 0.9rem
        width: 0.9rem

    // Customer Component
    article.cp_component_login-flow-customer
      margin: 0 0 $space_4x 0

    // Input Container
    > div.cp_component_input
      border-bottom: 1px solid $color_border-underline
      width: 100%

      &.username
        margin: 0 0 3rem 0

      // Input
      .MuiInputBase-root
        width: 100%
        padding: 0

        fieldset
          border: none

      .MuiInputBase-input
        padding: 0.6rem 0

    // Sign In Button
    > button
      background: $color_button-primary
      border-radius: 1.5rem
      border: none
      color: dark.$color_text-primary // Intentionally use darkmode style here
      cursor: pointer
      font-size: 1.2rem
      font-weight: 700
      height: 3rem
      margin: 2rem 0 0 0
      text-transform: uppercase
      width: 100%

      &.Mui-disabled
        background: $color_button-disabled
        color: dark.$color_text-inactive // Intentionally use darkmode style here

      svg
        color: dark.$color_text-inactive // Intentionally use darkmode style here

    // Forgot Password/Email Sign In Links Container
    > aside
      align-items: center
      display: flex
      justify-content: space-between
      margin: 1.5rem 0 0 0

    // Typography
    > h1
      margin: 4rem 0 0 0

    > p
      margin: 1rem 0 4rem 0

    > h1, > p
      text-align: center

    > span // Error text
      color: $color_color-error
      line-height: 2rem

    a,
    a:link,
    a:active,
    a:visited
      color: $color_text-inactive
      font-size: 0.8rem
      font-weight: 600
      margin: 0 0 3rem 0
      text-align: center
      text-decoration: none

      &.large
        font-size: 0.9rem
        font-weight: 400
        text-transform: uppercase

      &:hover
        color: $color_button-hovered


// Dark Theme Styles
.dark div.cp_page_login
  background-image: url("../../asset/img/background_start_pages_dark.svg")

  section.card
    background-color: dark.$color_fill-card

    // Back Link
    > div.back svg
      color: dark.$color_text-inactive

    // Input Container
    > div.cp_component_input
      border-bottom: 1px solid dark.$color_border-underline

    // Sign In Button
    > button
      background: dark.$color_button-primary
      color: $color_text-primary // Intentionally use lightmode style here

      &.Mui-disabled
        background: dark.$color_button-disabled
        color: $color_text-inactive // Intentionally use lightmode style here

      svg
        color: $color_text-inactive // Intentionally use lightmode style here

    // Typography
    > span // Error text
      color: dark.$color_color-error

    a,
    a:link,
    a:active,
    a:visited
      color: dark.$color_text-inactive

      &:hover
        color: dark.$color_button-hovered
