@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *

.cp_component-user-filter-list
  position: relative

  .auto-complete-input
    width: 100%

  .selected-users-container
    margin-top: $space_1x
    .user-row
      align-items: center
      display: flex
      padding: 0 $space_2x
      &:not(:first-child)
        border-top: 1px solid $color_divider-list
      &-selected
        background-color: $color_fill-dropdown-select

    .cp_component_search-bar-search-icon
      color: $color_icon-search
    
  // NOTE Feb 5, 2025:
  // Bug ID-3621
  // These styles were bleeding over globally into other parts of the dashboard.
  // Styles MUST have a more specific parent selector to avoid this.
  // For now, adding a tab to these styles to avoid the bleed. DO NOT
  // make the following selector more general.
  .MuiDialog-container

    .MuiDialogTitle-root
      > div
          display: block

.dark
  .cp_component-user-filter-list
    .search-container
      border-color: dark.$color_border-section
      .cp_component_search-bar-search-icon
        color: dark.$color_icon-search

    .selected-users-container
      .user-row
        &:not(:first-child)
          border-color: dark.$color_divider-list
        &-selected
          background-color: dark.$color_fill-dropdown-select
