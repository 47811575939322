@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
.cp_public-list-group_page
  background-color: $color_fill-background
  height: 100vh
  position: relative
  width: 100vw

  padding: $space_3x

  &-container
    padding: $space_2x
    height: calc(100% - primitives.$width_navigation-bar)
    overflow-y: scroll
    &::-webkit-scrollbar
            background-color: transparent
            width: px-to-rem(8px)

    &::-webkit-scrollbar-thumb
        background-color: $color_input-disabled
        border-radius: 10px

// Dark Theme Styles
.dark .cp_public-list-group_page
  background-color: dark.$color_surface-primary
  &-container
    &::-webkit-scrollbar-thumb
        background-color: dark.$color_icon-pill
