@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

button.MuiIconButton-root.cp_component-copy-clipboard
  height: px-to-rem(18px)
  width: px-to-rem(18px)
  margin-left: $space_1x
  svg.cp_component-copy-clipboard-icon
    color: $color_icon-exit
    height: px-to-rem(12px)
    width: px-to-rem(12px)



