@use "../../../../style/primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *


div.cp_component-campaign-ads-container
  .redirect-button
    color: $color_text-inlinebutton
    font-size: px-to-rem(12px)
    width: fit-content
    margin: 0 0 $space_2x $space_1x
    &:hover
      cursor: pointer

  .table-header-container
    align-items: center
    display: flex
    justify-content: space-between

    &.all-ads
      margin-top: $space_2x

    .table-header
      font-size: 1.25rem
      font-weight: $font-weight-bold
      margin-bottom: $space_2x

    .action-container
      align-items: center
      display: flex
      gap: $space_2x

      .button
        min-width: px-to-rem(200px)

      .search-bar.cp_component_input.cp_component_search-bar-input
        width: px-to-rem(300px)
        .MuiInputBase-root
          height: px-to-rem(26px)
          svg.MuiSvgIcon-root.cp_component_search-bar-search-icon
            font-size: $space_2x

  div.cp_component-campaign-ads-table
    padding: 0
    background-color: $color_fill-card
    border: none

    .ad-set-name
      font-size: px-to-rem(12px)
      white-space: pre-wrap
    div[data-field="matching-creatives"]
      padding-left: $space_4x
    .matching-creatives
      border: 1px solid $color_button-secondary
      color: $color_button-secondary
      padding: px-to-rem(5px) $space_2x
      text-transform: uppercase
      font-size: px-to-rem(10px)
      font-weight: 700
      overflow: hidden auto
      width: 100%

    .capitalize-text
      text-transform: capitalize

    .MuiDataGrid-pinnedColumnHeaders
      .MuiDataGrid-columnHeader:first-child
        padding: 0
        
.dark
  div.cp_component-campaign-ads-container
    .redirect-button
      color: dark.$color_text-inlinebutton
    .cp_component-campaign-ads-table
      background-color: dark.$color_fill-card
      
      .matching-creative
        color: dark.$color_button-secondary
        border-color: dark.$color_button-secondary
    