@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_customer-overview-header
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  display: flex
  .cp_customer-overview-header_info-container
    align-items: center
    background-color: $color_fill-profile-background
    width: px-to-rem(370px)

    display: flex
    .cp_customer-overview-header_avatar-container
      padding: $space_2x
      .cp_customer-overview-header_avatar

    .cp_customer-overview-header_details
      display: flex
      flex-direction: column
      p.cp_customer-overview-header_group-name
        color: $color_brand-blue-teal
        font-size: px-to-rem(22px)
      p.cp_customer-overview-header_created
        color: $color_text-invert
        font-size: px-to-rem(10px)
        font-weight: 700
      p.cp_customer-overview-header_vanity-url
        color: $color_text-invert
        font-size: px-to-rem(10px)
        font-weight: 700
        
        .copy-vanity-url-button
          &_icon
            color: $color_text-invert
            height: px-to-rem(10px)
            width: px-to-rem(10px)

  .cp_customer-overview-header_stats
    align-items: center
    display: flex
    flex-grow: 1
    justify-content: space-between
    padding: 0 $space_1x 0 $space_3x
    
    .MuiIconButton-root
      border-radius: 5px
      background-color: $color_button-exit
      color: $color_icon-exit
      height: px-to-rem(34px)
      width: px-to-rem(34px)

    .cp_customer-overview-header_user-count-container
      h4
        font-size: px-to-rem(12px)
        font-weight: 700
      h5
        color: $color_fill-icon-list
        font-size: px-to-rem(24px)
        font-weight: 600

.dark
  .cp_customer-overview-header
    border-color: dark.$color_border-colorcard
    background-color: dark.$color_fill-card
    .cp_customer-overview-header_info-container
      background-color: dark.$color_fill-profile-background
      .cp_customer-overview-header_avatar-container
        .cp_customer-overview-header_avatar

      .cp_customer-overview-header_details
        p.cp_customer-overview-header_group-name
          color: dark.$color_brand-blue-teal
        p.cp_customer-overview-header_created
    .cp_customer-overview-header_stats
      .MuiIconButton-root
        background-color: dark.$color_button-exit
        color: dark.$color_icon-exit
      .cp_customer-overview-header_user-count-container
        h4
        h5
          color: dark.$color_fill-icon-list

