@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use '../../style/_styles' as *

div.cp_component_search-bar-input
    padding: $space_2x 0 0 0
    margin-bottom: $space_2x
    
    &.cp_component_input
        width: 100%
    .cp_component_dropdown
        display: flex
        justify-content: center
    .cp_component_search-bar-search-icon
        color: $color_icon-search
    .cp_component_search-bar-tune-icon
        color: $color_fill-icon-list


.dark
    div.cp_component_search-bar-input
        .cp_component_search-bar-search-icon
            color: dark.$color_icon-search        
        .cp_component_search-bar-tune-icon
            color: dark.$color_fill-icon-list