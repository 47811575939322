@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_component_carousel
  display: flex
  flex-direction: row

  &_nav-before-button
    justify-self: flex-start
    min-width: 10px !important

  &_card-slider
    height: 100%
    width: calc(100% - 2px)
    overflow: hidden

    &_box.no-display
      display: none

    &_box
      height: 100%
      width: 100%

  &_nav-next-button
    justify-self: flex-end
    min-width: 10px !important

.dark.cp_component_carousel