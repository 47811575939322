@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/z_index_hierarchy" as *
@use "../../style/styles" as *

// Light Theme Styles
div.component_navigation-bar
  background-color: $color_fill-nav
  height: 100vh
  width: primitives.$width_navigation-bar
  z-index: $z_index_navbar

  aside
    align-items: center
    display: flex
    height: 100%
    justify-content: center

    svg
      color: $color_text-inactive
      fill: $color_text-inactive

    svg.ai-search-icon, svg.ai-search-icon-inactive
      height: 30px
      width: 30px

    &.active
      color: $color_text-primary
      background-color: $color_fill-nav-surface
      border-left: 3px solid $color_fill-nav-highlight

      p.label_extrasmall-caps-semibold.nav-text, svg
        color: $color_text-primary
        fill: $color_text-primary
    p.label_extrasmall-caps-semibold.nav-text
      @include navStyles($color_text-inactive, $space_1x, $font-weight-bolder)

  button
    background-color: transparent
    border: 0
    cursor: pointer
    display: block
    height: 54px
    margin-bottom: 0.3rem
    padding: 0
    width: 100%

// Dark Theme Styles
.dark
  div.component_navigation-bar
    background-color: dark.$color_fill-nav

    aside
      svg
        color: dark.$color_text-inactive
        fill: dark.$color_text-inactive

      &.active
        background-color: dark.$color_fill-nav-surface
        border-left: 3px solid dark.$color_fill-nav-highlight

        p.label_extrasmall-caps-semibold.nav-text, svg
          color: dark.$color_text-primary
          fill: dark.$color_text-primary
      p.label_extrasmall-caps-semibold.nav-text
        color: dark.$color_text-inactive
