@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp_component_modal-create-list-success-dialog

  .MuiDialog-container 
    
    .MuiDialog-paperWidthSm
      max-width: 80%
      width: 500px

    p.MuiDialogContentText-root
      margin-bottom: $space_2x

    .MuiDialogActions-root
      justify-content: left
    
