@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/_styles" as *
  
div.cp_component_social-tracker-slide-out-panel-empty-container
  align-items: center
  display: flex
  flex-direction: column
  gap: $space_4x
  
  .create-tracker-button
    height: px-to-rem(28px)
    .label-container
      align-items: center
      display: flex
      padding: 0.4rem 0.75rem
      gap: 0.5rem


div.cp_component_social-tracker-slide-out-panel-container
  display: flex
  flex-wrap: wrap
  padding: $space_3x
  justify-content: space-between