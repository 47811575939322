@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/primitives" as primitives
@use "../../style/styles" as *

// Light Theme Styles 
div.cp_page_error-public
  background-image: url("../../asset/img/background_start_pages_light.svg")
  background-size: cover
  height: 100vh

  // Inner content styles in order to apply blur to background
  > div
    align-items: center
    backdrop-filter: blur(2rem)
    display: flex
    height: 100%
    justify-content: center
    width: 100%

  section.card
    background-color: $color_fill-card
    border-radius: 2rem
    box-shadow: 0 px-to-rem(4px) px-to-rem(4px) 0 primitives.$color_black-a50
    padding: 6rem 5rem 8rem 5rem
    position: relative
    width: 40rem

    // Typography
    > h1
      margin: 4rem 0 0 0

    > p
      margin: 1rem 0 4rem 0

    > h1, > p
      text-align: center

  // Error Page Splash Content
  div.error-content
    article
      align-items: center
      background-image: url("../../asset/img/background_webgraph_light.svg")
      background-position: 50% 50%
      background-repeat: no-repeat
      display: flex
      justify-content: center
      min-height: 22rem

    aside
      text-align: center

    h4
      margin: 0 0 $space_4x 0


// Dark Theme Styles
.dark div.cp_page_error-public
  background-image: url("../../asset/img/background_start_pages_dark.svg")

  section.card
    background-color: dark.$color_fill-card

  div.error-content
    article
      background-image: url("../../asset/img/background_webgraph_dark.svg")
