@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_note-card
  align-items: center
  background-color: $color_surface-secondary
  border: 1px solid $color_border-modal
  display: flex
  flex-direction: column
  padding: $space_2x
  width: px-to-rem(747px)

  &-header
    align-items: center
    display: flex
    flex-direction: row
    justify-content: space-between
    width: 100%
        
  .MuiDivider-root
    width: 95%
    margin: $space_2x 0
  &-body
    color: $color_text-secondary
    display: flex
    width: 100%
    min-height: px-to-rem(60px)
    overflow-wrap: anywhere

.dark
  .cp_component_note-card
    background-color: dark.$color_surface-secondary
    border-color: dark.$color_border-modal

    &-header
          
    .MuiDivider-root
    &-body
      color: dark.$color_text-secondary