@use "../../style/_styles" as *
@use "../../style/_primitives" as *
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark

.cp_component_link-input-popover
  .main-container
    display: flex
    gap: $space_1x
    padding: $space_1x
    
    .MuiButtonBase-root
      width: px-to-rem(80px)
.dark
  .cp_component_link-input-popover
    .main-container
      background: dark.$color_fill-modal
      display: flex
      gap: $space_1x
      padding: $space_1x
      
      .MuiButtonBase-root
        width: px-to-rem(80px)