@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

// Note: styles are intentionally the same for both light and dark mode
.cp_component_email-status-label
  align-items: center
  border-radius: 100px
  color: $color_text-invert
  display: flex
  font-size: px-to-rem(10px)
  justify-content: center
  width: px-to-rem(64px)
  height: px-to-rem(14px)
  

  &.status-delivered
    background-color: $color_fill-card-default
  &.status-opened, &.status-clicked
    background-color: $color_fill-pill-5
  &.status-failed
    background-color: $color_color-error
