@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_recent-media_component
    display: grid
    grid-template-columns: repeat(4, 1fr)
    grid-template-rows: repeat(auto-fill, 160px)
    grid-row-gap: $space_2x
    grid-column-gap: $space_5x
    margin-left: auto
    margin-right: auto
    max-width: 70%
    padding-top: $space_2x
    text-align: center

    @media (max-width: 1800px)
        max-width: 100%
    @media (max-width: $lg)
        grid-template-columns: repeat(3, 1fr)
    @media (max-width: $md)
        grid-template-columns: repeat(1, 1fr)

    &:has(.cp_recent-media_component-load-indicator)
        grid-template-columns: repeat(1, 1fr)
        
    &-load-indicator
        margin: auto
                
    &-figure
        margin: auto
        width: fit-content

        &-btn
            background-color: transparent
            border: none
            box-shadow: none
            cursor: pointer
            outline: none

        &-image
            background-size: cover
            background-repeat: no-repeat
            background-position: center
            border-radius: $content-border-radius
            height: px-to-rem(120px)
            width: px-to-rem(140px)

        &-metrics
            align-items: center
            color: $color_text-primary
            display: flex
            font-size: $font-size-sm
            font-weight: $font-weight-bolder
            gap: 0.75rem
            justify-content: flex-start
            padding: $space_1x 0

            aside
                align-items: center
                display: flex
                gap: 0.25rem
                justify-content: flex-start

            svg
                font-size: 1rem

.dark
    .cp_recent-media_component
        &-figure-metrics
            color: dark.$color_text-primary
