@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_public-list-info-modal
  background-color: $color_surface-primary
  display: flex
  flex-direction: column
  align-items: center
  padding: $space_5x
  height: 100vh
  width: 100vw

  &-icon-button.MuiIconButton-root
    position: absolute
    top: px-to-rem(30px)
    right: px-to-rem(30px)

  &-container
    align-items: center
    border: 1px solid $color_border-colorcard
    border-radius: 2px
    display: flex
    flex-direction: column
    margin-top: $space_6x
    padding: $space_6x $space_8x
    h2
      font-size: px-to-rem(32px)
    h6
      margin-bottom: $space_6x
      margin-top: $space_2x
    .cp_component_public-list-input-access-code
      width: px-to-rem(381px)
    .error-text
      color: $color_input-error
      height: px-to-rem(20px)
      margin-bottom: 0.25rem
      padding: px-to-rem(4px)
      &.hidden
        visibility: hidden
    
    .cp_component_public-list-input
      margin-bottom: $space_3x
      width: px-to-rem(381px)

    .cp_component_public-list-button
      width: px-to-rem(200px)
      margin-bottom: $space_6x
    p
      font-size: px-to-rem(12px)

.dark
  .cp_component_public-list-info-modal
    background-color: dark.$color_fill-background
    &-container
      background-color: dark.$color_fill-card
      border-color: dark.$color_border-colorcard
      h6
        color: dark.$color_text-primary
      .error-text
        color: dark.$color_input-error