@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/_styles" as *

div.cp_component_social-tracker-card
  background-color: $color_fill-card-panel
  .MuiSvgIcon-root
      fill: $color_fill-pill-5
      font-size: 1rem
  border-radius: 2px
  height: px-to-rem(140px)
  padding: $space_2x
  margin-bottom: $space_3x
  width: px-to-rem(200px)
  .header
    height: 50%
    display: flex
    justify-content: space-between
    p
      font-size: px-to-rem(16px)
      font-weight: 600
      font-family: "Open Sans"
  .MuiDivider-root
    margin-bottom: $space_2x
  .footer
    align-items: center
    display: flex
    justify-content: space-between
    height: 40%

    .MuiSvgIcon-root
      fill: $color_fill-pill-5

div.cp_component_social-tracker-menu
  .MuiPaper-root
    background-color: $color_fill-dropdown
    .menu-item
      &-label > .MuiTypography-root
        align-items: center
        color: $color_text-primary
        display: flex
        justify-content: space-between
        gap: $space_2x
        font-family:"Open Sans"
      .MuiSvgIcon-root
          color: $color_icon-pill

  
.dark
  div.cp_component_social-tracker-card
    background-color: dark.$color_fill-card-panel
    .MuiSvgIcon-root
      fill: dark.$color_border-pill-3
      font-size: 1rem

  div.cp_component_social-tracker-menu
    .MuiPaper-root
      background-color: dark.$color_fill-dropdown
      .menu-item
        &-label > .MuiTypography-root
          color: dark.$color_text-primary

        .MuiSvgIcon-root
            color: dark.$color_icon-pill

