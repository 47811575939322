@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_category-management-toolbar_component
    display: flex
    flex: 1
    flex-direction: column
    gap: $space_1x
    margin-bottom: $space_2x
    margin-top: $space_3x
    width: 100%

    &-header
        align-items: center
        display: flex
        justify-content: space-between
        width: 100%
        
        &-title
            flex: 1
            font-size: $space_3x
            font-weight: $font-weight-normal

        &-controls
            display: flex
            flex: 1
            gap: $space_1x
            justify-content: flex-end

            .MuiIconButton-root
                border-radius: 5px
                background-color: $color_button-exit
                color: $color_icon-exit

.cp_category-management-toolbar_component-menu
    min-width: px-to-rem(200px)

    &-item
        &-label > .MuiTypography-root
            color: $color_text-primary
            font-size: $font-size-sm
        .MuiSvgIcon-root
            color: $color_icon-pill
            font-size: $font-size-sm

    &-popover .MuiMenu-list
            padding-bottom: 0
            padding-top: 0

.dark
    .cp_category-management-toolbar_component
        &-header-controls .MuiIconButton-root
            background-color: dark.$color_button-exit
            color: dark.$color_icon-exit
    .cp_category-management-toolbar_component-menu
        background-color: dark.$color_fill-dropdown
        &-item
            &-label > .MuiTypography-root
                color: dark.$color_text-primary
            .MuiSvgIcon-root
                color: dark.$color_icon-pill
        
        &-popover .MuiMenu-paper
            background-color: transparent
