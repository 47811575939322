@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_account-content-search_component

  .cp_account-content-search_component-content-filter

    &.cp_component_search-content

        &.MuiContainer-root
          padding: 0

        fieldset.MuiFormControl-root
          margin-bottom: $space_3x

          span.MuiFormControlLabel-label
            color: $color_text-radiobutton
            font-family: $font-family-primary
            font-size: $font-size-sm
            font-style: $font-style-primary
            font-weight: $font-weight-normal
            line-height: normal

          legend.MuiFormLabel-root,
          .cp_component_input > p
            align-items: center
            color: $color_text-primary
            display: flex
            font-family: $font-family-primary
            font-size: $font-size-sm
            font-style: $font-style-primary
            font-weight: $font-weight-bolder
            line-height: normal
            margin-bottom: px-to-rem(10px)
            text-transform: uppercase

            svg.MuiSvgIcon-root
              cursor: pointer
              display: inline-block
              fill: $color_icon-search
              height: px-to-rem(12px)
              margin-left: px-to-rem(10px)
              width: px-to-rem(12px)

          div.cp_component_input
            margin-bottom: 0

        .cp_component_search

          &-audience,
          &-profile,
          &-content          

            &-gender
              span.MuiCheckbox-root
                padding: 0 px-to-rem(5px)

              div.MuiFormGroup-root
                label.MuiFormControlLabel-root
                  margin-left: px-to-rem(-7px) // override Mui

                > label:first-child
                  margin-bottom: px-to-rem(10px)

            &-footer
              display: flex
              justify-content: center
              margin-bottom: $space_2x

            div.component_select div.MuiSelect-select,
  
        .cp_component_input input.MuiInputBase-input
          color: $color_input-active-text
          font-family: $font-family-primary
          font-size: px-to-rem(14px)
          font-style: $font-style-primary
          font-weight: $font-weight-normal
          letter-spacing: 0.5px
          line-height: px-to-rem(24px)
        
        div.cp_component_input
          width: 100%
.dark
  .cp_account-content-search_component
    .cp_account-content-search_component-content-filter
      &.cp_component_search-content
          fieldset.MuiFormControl-root
            span.MuiFormControlLabel-label
              color: dark.$color_text-radiobutton

            legend.MuiFormLabel-root,
            .cp_component_input > p
              color: dark.$color_text-primary

              svg.MuiSvgIcon-root
                fill: dark.$color_icon-search
    
          .cp_component_input input.MuiInputBase-input
            color: dark.$color_input-active-text
