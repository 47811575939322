@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_select-caption-input-step
  width: 100%
  h4
    font-size: px-to-rem(16px)
    font-weight: 600
    margin-bottom: $space_2x

  .MuiAccordion-root
    border: 1px solid $color_border-section
    border-radius: 4px
    margin-bottom: $space_2x
    .MuiAccordionSummary-root
      // Default Accordion Styles
      .MuiAccordionSummary-expandIconWrapper
        .MuiSvgIcon-root
          fill: $color_icon-pill
          height: px-to-rem(12px)
          width: px-to-rem(12px)
        &.Mui-expanded
          transform: rotate(90deg)
      h5
        font-weight: 700
        font-family: "Open Sans"
        font-size: px-to-rem(14px)
        margin-right: 0.25rem
        line-height: px-to-rem(14px)
      p.accordion-summary-subtext
        color: $color_text-secondary
        font-size: px-to-rem(12px)
        line-height: px-to-rem(14px)

      // Active Accordion Styles
      &.active-accordion
        h5, p.accordion-summary-subtext
          color: $color_input-active

        .MuiAccordionSummary-expandIconWrapper
          .MuiSvgIcon-root
            fill: $color_input-active

    .MuiAccordionDetails-root
      div.snippet-selection
        align-items: flex-start
        display: flex
        gap: $space_1x
        &:not(:last-child)
          border-bottom: 1px solid $color_divider-list

    .MuiDivider-root.cp_component_divider
      margin-bottom: $space_2x

    div.custom-caption-input-label
      display: flex
      margin-bottom: 0.3rem
      p.optional-text
        font-family: "Open Sans"
        font-size: px-to-rem(12px)
        font-style: italic
        margin-left: px-to-rem(4px)

    div.cp_component_input.custom-caption-input
      width: px-to-rem(500px)
      height: px-to-rem(80px)

      .MuiInputBase-root
        align-items: flex-start
        display: flex
        height: px-to-rem(80px)
        padding: 0 0.4rem

.dark
  .cp_component_select-caption-input-step
    h4
    .MuiAccordion-root
      border-color: dark.$color_border-section
      .MuiAccordionSummary-root
        // Default Accordion Styles
        .MuiAccordionSummary-expandIconWrapper
          .MuiSvgIcon-root
            fill: dark.$color_icon-pill
        h5
        p.accordion-summary-subtext
          color: dark.$color_text-secondary

        // Active Accordion styles
        &.active-accordion
          h5, p.accordion-summary-subtext
            color: dark.$color_input-active
          .MuiAccordionSummary-expandIconWrapper
            .MuiSvgIcon-root
              fill: dark.$color_input-active

      .MuiAccordionDetails-root
        div.snippet-selection
          &:not(:last-child)
            border-color: dark.$color_divider-list
