@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/_styles" as *

.cp_component-context-menu-button-menu
    padding-top: 0
    padding-bottom: 0
    
    .MuiMenuItem-root
        display: flex
        justify-content: space-between

        &:hover
            background-color: $color_fill-dropdown-select

        .MuiTypography-root
            color: $color_text-dropdown
            font-family: $font-family-primary
            font-size: $font-size-sm
            margin-right: $space_2x
        .MuiSvgIcon-root
            fill: $color_icon-pill
            height: px-to-rem(12px)
            width: px-to-rem(12px)
                
.dark
    .cp_component-context-menu-buttton
        .MuiSvgIcon-root
            fill: dark.$color_icon-pill
    .cp_component-context-menu-button-menu
        .MuiPaper-root
            background-color: dark.$color_fill-dropdown
            .MuiList-root
                background-color: dark.$color_fill-dropdown
                .MuiMenuItem-root
                    &:hover
                        background-color: dark.$color_fill-dropdown-select
                    .MuiTypography-root
                        color: dark.$color_text-dropdown
                    .MuiSvgIcon-root
                        fill: dark.$color_icon-pill
