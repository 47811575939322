@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_feedback-card
  align-items: center
  background-color: $color_surface-secondary
  border: 1px solid $color_border-modal
  display: flex
  flex-direction: column
  min-height: px-to-rem(150px)
  width: 100%
  padding: $space_2x

  &-header
    h5
      font-weight: $font-weight-bolder
      text-transform: uppercase

    align-items: center
    display: flex
    flex-direction: row
    width: 100%
        
  .MuiDivider-root
    width: 95%
    margin: $space_2x 0
  &-body
    display: flex
    flex-direction: column
    width: 100%
    min-height: px-to-rem(70px)
    p
      color: $color_text-secondary
      font-size: px-to-rem(12px)
      padding: 0 $space_2x
      width: 100%
    &-timestamp
      margin-top: auto
      margin-left: auto
    

.dark
  .cp_component_feedback-card
    background-color: dark.$color_surface-secondary
    border-color: dark.$color_border-modal

    &-header
          
    .MuiDivider-root
    &-body
      color: dark.$color_text-secondary