@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_family-chart_component
    display: flex
    flex: 0 0 50%
    justify-content: center
    .highcharts-background
        fill: none
    tspan.highcharts-text-outline
        stroke: none

    .highcharts-data-label text
        font-size: 0.9rem !important

.dark

