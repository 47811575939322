@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark

.cp_component_options-input-title
  margin-bottom: 10px

.cp_component_advanced-options-lists
  display: flex
  justify-content: space-between
  margin-top: 24px

  .cp_component_options-list-label
    margin-bottom: 10px

  .cp_component_options-list
    border: 1px solid $color_border-input
    height: 380px
    overflow: auto
    padding: 0px
    width: 320px

  .cp_component_options-list-item_selected
    background-color: $color_fill-list-select
    color: $color_text-list-select

  .cp_component_advanced-options-list-buttons
    align-items: center
    display: flex

    > *
      margin: 0px 6px

    .MuiIconButton-root
      background-color: transparent
      color: $color_button-secondary


.dark
  .cp_component_advanced-options-lists
    .cp_component_options-list-item_selected
      background-color: dark.$color_fill-list-select
      color: dark.$color_text-list-select

    .cp_component_options-list
      border-color: dark.$color_border-input

    .MuiIconButton-root
      color: dark.$color_button-secondary
