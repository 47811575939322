@use '../../../style/_styles' as *
@use '../../../style/theme_light' as *
@use '../../../style/theme_dark' as dark

.cp_component-drafts-folder-view
  overflow: hidden auto
  &.loading,
  &.error,
  .draft-messages-view
    &-header
      align-items: center
      border-bottom: 1px solid $color_border-section
      display: flex
      height: px-to-rem(60px)
      justify-content: space-between
      padding: $space_2x

      span.MuiCheckbox-root
        padding: 0

      &_actions
        display: inline

        .actions-delete
          color: $color_icon-pill
          cursor: pointer
          margin-top: px-to-rem(5px)
          width: $space_2x

          &.hidden
            visibility: hidden
    
    &-messages
      height: calc(100% - 60px)
      overflow: hidden auto

      &_no-drafts
        p
          @include textStyles($color_text-secondary, px-to-rem(18px), $font-weight-normal)
          margin-top: $space_5x
          text-align: center

      .draft-message
        border-bottom: 1px solid $color_divider-section
        column-gap: $space_1x
        cursor: pointer
        display: flex
        padding: $space_2x 0

        &.selected
          background-color: $color_fill_list-hovered
      
        &_selector
          align-items: flex-start
          display: flex
          justify-content: flex-end
          padding-left: $space_2x

          span.MuiCheckbox-root
            padding: 0
        
        &_content
          flex: 1
          padding-right: $space_2x

          &_top
            align-items: center
            display: flex
            justify-content: space-between
            width: 100%

            .handle-name
              @include textStyles($color_text-primary, $font-size-sm, $font-weight-normal)
              margin-bottom: px-to-rem(6px)

              &_constant
                color: #FF0000
                display: inline
                font-weight: 600

            &-right
              align-items: center
              column-gap: $space_1x
              display: flex
              justify-content: flex-end

              .delete-draft-message-icon
                color: $color_icon-pill
                cursor: pointer
                width: $space_2x
              
              .draft-message-has-attachment
                svg.email-attachment-icon 
                  width: $space_2x
                  path
                    fill: $color_icon-pill
              
              .date
                @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-bold)

          &_subject
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-normal)
            margin-bottom: px-to-rem(6px)
          
          &_body
            @include textStyles($color_text-secondary, px-to-rem(10px), $font-weight-normal)

    &_edit-header
      align-items: center
      border-bottom: 1px solid $color_border-section
      display: flex
      height: px-to-rem(60px)
      justify-content: space-between
      padding: $space_2x

      .hidden-icon-for-spacing
        visibility: hidden

    &_edit-template
      padding: $space_2x

      .edit-template-select
        &-container
          &-label
            color: $color_text-primary
            font-size: 0.7rem
            font-weight: 700
            text-transform: uppercase

          .upload-link-container
            margin-top: $space_2x

            span.MuiCheckbox-root
              padding: 0

            &-label
              color: $color_text-primary
              font-size: 0.7rem
              padding-left: $space_1x

    &_edit-configure
      padding: $space_2x

      .draft-message-fields
        &-label
          color: $color_text-primary
          font-size: 0.7rem
          font-weight: 700
          text-transform: uppercase

        .cp_autocomplete_comm-group-email_input
          margin-bottom: $space_2x
          width: 100%

          .cc-labels
            .cc-text
              cursor: pointer
              color: $color_button_primary
              font-size: 0.9rem

        &-subject
          margin-bottom: $space_2x
          width: 100%

        div.MuiInputBase-root
          padding: 0 20% 0 0
          position: relative

          div.cc-labels
            display: flex
            gap: $space_1x
            padding: 0 $space_2x
            position: absolute
            right: 0
            top: calc(50% - px-to-rem(9.5px))

            span
              @include textStyles($color_text-inlinebutton, px-to-rem(14px), $font-weight-normal)
              cursor: pointer
              text-transform: uppercase

          div.MuiChip-root
            height: $space_3x

          span.MuiChip-label 
            @include textStyles($color_text-primary, $font-size-sm, $font-weight-bold)
            line-height:  $space_3x

          div.MuiButtonBase-root.MuiChip-root
            background-color: $color_fill-pill-1
            border: 1px solid $color_border-pill

          svg.MuiChip-deleteIcon
            color: $color_text-radiobutton
            height: px-to-rem(12px)
            width: px-to-rem(12px)

        div.cp_component_input.cp_modal-comm-create-message-subject
          margin-bottom: $space_2x
          width: 100%

    &_edit-actions
      padding: $space_2x

.dark
  .cp_component-drafts-folder-view
    .draft-messages-view
      &-header
        border-bottom: 1px solid dark.$color_border-section

        &_actions
          .actions-delete
            color: dark.$color_icon-pill

      &-messages
        &_no-drafts
          p
            @include textStyles(dark.$color_text-secondary, px-to-rem(18px), $font-weight-normal)

        .draft-message
          border-bottom: 1px solid dark.$color_divider-section

          &.selected
            background-color: dark.$color_fill_list-hovered
        
          &_content
            &_top
              .handle-name
                @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-normal)

                &_constant
                  color: #FF0000

              &-right
                .draft-message-has-attachment
                  .email-attachment-icon 
                    fill: dark.$color_icon-pill
                
                .delete-draft-message-icon
                  color: dark.$color_icon-pill

                .date
                  @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-bold)

            &_subject
              @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-normal)
            
            &_body
              @include textStyles(dark.$color_text-secondary, px-to-rem(10px), $font-weight-normal)

      &_edit-header
        border-bottom: 1px solid dark.$color_border-section

      &_edit-template
        .edit-template-select
          &-container
            &-label
              color: dark.$color_text-primary
            
            .upload-link-container
              &-label
                color: dark.$color_text-primary

      &_edit-configure
        .draft-message-fields
          &-label
            color: dark.$color_text-primary
          .cp_autocomplete_comm-group-email_input
            .cc-labels
              .cc-text
                color: dark.$color_button_primary

          div.MuiInputBase-root
            div.cc-labels
              span
                @include textStyles(dark.$color_text-inlinebutton, px-to-rem(14px), $font-weight-normal)
            span.MuiChip-label 
              @include textStyles(dark.$color_text-primary, $font-size-sm, $font-weight-bold)
            div.MuiButtonBase-root.MuiChip-root
              background-color: dark.$color_fill-pill-1
              border: 1px solid dark.$color_border-pill
            svg.MuiChip-deleteIcon
              color: dark.$color_text-radiobutton
