@use '../../style/theme_light' as *
@use '../../style/theme_dark' as dark
@use '../../style/styles' as *

.cp-pill.MuiChip-root
  background-color: $color_fill-pill-1
  border: 1px solid $color_border-pill
  color: $color_text-primary
  font-family: $font-family-primary
  font-size: $font-size-sm
  font-style: $font-style-primary
  font-weight: $font-weight-bold
  height: $space_4x
  letter-spacing: 0.5px
  line-height: $font-line-height-secondary
  min-width: $space_6x
  padding: 0 $space_1x

  &.MuiChip-colorWarning
    background-color: $color_fill-pill-4
    border-color: $color_border-pill-4
    color: $color_text-primary

  &.MuiChip-colorInfo
    background-color: $color_fill-pill-2
    border-color: $color_border-pill-2
    color: $color_text-primary
  
  &.MuiChip-colorSuccess
    background-color: $color_fill-pill-3
    border-color: $color_border-pill-3
    color: $color_text-primary


  .MuiChip-label
    padding: 0

  .MuiSvgIcon-root
    margin: 0 0 0 3px

  .MuiChip-deleteIcon
    color: $color_icon-pill
    font-size: $font-size-sm
.dark 
  .cp-pill.MuiChip-root
    background-color: dark.$color_fill-pill-1
    border: 1px solid dark.$color_border-pill
    color: dark.$color_text-primary

    .MuiChip-deleteIcon
      color: dark.$color_icon-pill
    
    &.MuiChip-colorWarning
      background-color: dark.$color_fill-pill-4
      border-color: dark.$color_border-pill-4
      color: dark.$color_text-primary

    &.MuiChip-colorInfo
      background-color: dark.$color_fill-pill-2
      border-color: dark.$color_border-pill-2
      color: dark.$color_text-primary

    &.MuiChip-colorSuccess
      background-color: dark.$color_fill-pill-3
      border-color: dark.$color_border-pill-3
      color: dark.$color_text-primary
