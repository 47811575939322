@use "../../style/primitives" as primitives
@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *

.cp_component_modal_oauth-user-account
  &_container
    display: flex
    flex-direction: column
    gap: $space_2x
    margin: $space_2x

    .bold-message
      @include textStyles($color_text-body, $space_2x, $font-weight-bolder)

    p.body_medium
      .google-api-user-data-policy-link
        color: $color_button-primary

    .will-not-list-items
      display: flex
      flex-direction: column
      gap: $space_1x

      &_item
        align-items: center
        display: flex
        flex-direction: row

        .list-item-icon
          margin-right: $space_2x

  .MuiDialog-container
    .MuiPaper-root
      .dialog-actions-container
        .cta-other-actions
          button
            background-color: $color_fill-footer
            color: $color_button-primary

.dark
  .cp_component_modal_oauth-user-account
    &_container
      .bold-message
        @include textStyles(dark.$color_text-body, $space_2x, $font-weight-bolder)

      p.body_medium
        .google-api-user-data-policy-link
          color: dark.$color_button-primary

    .MuiDialog-container
      .MuiPaper-root
        .dialog-actions-container
          .cta-other-actions
            button
              background-color: dark.$color_fill-footer
              color: dark.$color_button-primary
