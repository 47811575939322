@use "../../../style/_theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *


.cp_campaign-widget-deliverables_component-no-results
    align-items: center
    color: $color_text-inactive
    display: flex
    height: 100%
    justify-content: center
.cp_campaign-widget-deliverables_component.loading
    display: flex
    height: 75%
.cp_campaign-widget-deliverables_component.MuiDataGrid-root
    border: none
    height: 300px
    .MuiDataGrid-columnHeaders
        display: none
        visibility: hidden
    .MuiDataGrid-row
        &:hover
            background-color: inherit
            cursor: pointer
        .MuiDataGrid-cell.MuiDataGrid-withBorderColor
            border-color: $color_divider-list
    .cp-avatar.MuiAvatar-root
        outline: 1px solid $color_border-image
        border: none
        height: px-to-rem(48px)
        width: px-to-rem(48px)
    .caption
        display: -webkit-box
        max-width: 200px
        overflow: hidden
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        white-space: normal
        &-content
            word-break: break-word
            font-family: "Open Sans"
            font-size: 0.75rem
            font-weight: 400
            text-wrap: wrap

.dark
    .cp_campaign-widget-deliverables_component-no-results
        color: dark.$color_text-inactive
    .cp_campaign-widget-deliverables_component
        div.cp_component-entity-row-info p.cp_component-entity-info-row-sub-info
            color: dark.$color_text-follower
        .MuiDataGrid-row
            .MuiDataGrid-cell.MuiDataGrid-withBorderColor
                border-color: dark.$color_divider-list
        .cp-avatar.MuiAvatar-root
            outline: 1px solid dark.$color_border-image
        .caption
            &-content
