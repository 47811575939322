@use "../../../../../style/primitives" as primitives
@use "../../../../../style/theme_light" as *
@use "../../../../../style/theme_dark" as dark
@use "../../../../../style/styles" as *

div.cp_component-data-grid.cp_component-campaign-deliverables-table
  .capitalize-text
    text-transform: capitalize
  .MuiDataGrid-columnHeaders
    .MuiDataGrid-columnHeader:first-child
      padding: 0

.dark
  div.cp_component-data-grid.cp_component-campaign-deliverables-table
    .MuiDataGrid-columnHeaders
      background-color: dark.$color_table-pinned-column-background