@use "../../style/primitives" as primitives
@use "../../style/theme_dark" as dark
@use "../../style/theme_light" as *
@use "../../style/styles" as *

// Light Theme Styles
div.cp_page_error
  height: 100vh
  position: relative
  padding: 1rem 1rem 0 calc(primitives.$width_navigation-bar + 1rem)

  div.component_navigation-bar
    left: 0
    position: fixed
    top: 0

  // Error Page Content Container
  section.cp_page_error-container
    align-items: center
    display: flex
    height: 100%
    justify-content: center
    width: 100%

  // Error Page Splash Content
  div.cp_page_error-content
    article
      align-items: center
      background-image: url("../../asset/img/background_webgraph_light.svg")
      background-position: 50% 50%
      background-repeat: no-repeat
      display: flex
      justify-content: center
      min-height: 22rem

    aside
      text-align: center

    div.cp_page_error-icon-mag
      background-image: url("../../asset/img/icon_mag_light.svg")
      background-repeat: no-repeat
      height: px-to-rem(186px)
      width: px-to-rem(190px)

    h4
      margin: 0 0 $space_4x 0

  // Error Message Text
  p.cp_page_error-message
    padding: $space_2x 0 0 0

// Dark Theme Styles
.dark div.cp_page_error
  // Error Page Splash Content
  div.cp_page_error-content
    article
      background-image: url("../../asset/img/background_webgraph_dark.svg")

    div.cp_page_error-icon-mag
      background-image: url("../../asset/img/icon_mag_dark.svg")
