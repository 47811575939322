@use "../../../style/primitives" as primitives
@use "../../../style/theme_light" as *
@use "../../../style/theme_dark" as dark
@use "../../../style/styles" as *

.cp_component_change-pw
  display: flex
  flex-direction: column
  gap: $space_4x

  &_input
    display: flex
    flex-direction: column
    gap: $space_2x
    margin-top: $space_2x

    .pw-visibility-icon
      margin-right: px-to-rem(5px)

  &_cta
    .user-settings-save-password-btn
      margin-bottom: $space_2x

.dark
  .cp_component_change-pw