@use "../../style/theme_light" as *
@use "../../style/theme_dark" as dark
@use "../../style/styles" as *
@use "../../style/z_index_hierarchy" as *

// Light Theme Styles
div.cp_component_toaster
  max-height: 100vh
  overflow: hidden
  padding: 1rem
  position: fixed
  right: 0
  top: 0
  width: calc(350px + 2rem)
  z-index: $z_index_toast

  > aside
    background-color: $color_fill-banner-status
    border-radius: $content-border-radius
    border: 1px solid $color_border-colorcard
    min-height: 72px
    max-height: 200px
    margin-bottom: px-to-rem(10px)
    padding: 1rem 1rem 1rem 3.5rem
    position: relative
    width: 100%

    &.cp_component_toaster_success
      border-left: 4px solid $color_color-success

      svg
        color: $color_color-success

    &.cp_component_toaster_warning
      border-left: 4px solid $color_color-warning

      svg
        color: $color_color-warning

    &.cp_component_toaster_error
      border-left: 4px solid $color_color-error

      svg
        color: $color_color-error

    .cp-toast-link
      color: inherit

  svg
    position: absolute
    top: calc(50% - 0.75rem)
    left: 1rem
    
  p.body_small
      color: $color_text-secondary

// Dark Theme Styles
.dark div.cp_component_toaster
  > aside
    background-color: dark.$color_fill-banner-status
    border: 1px solid dark.$color_border-colorcard

    &.cp_component_toaster_success
      border-left: 4px solid dark.$color_color-success

      svg
        color: dark.$color_color-success

    &.cp_component_toaster_warning
      border-left: 4px solid dark.$color_color-warning

      svg
        color: dark.$color_color-warning

    &.cp_component_toaster_error
      border-left: 4px solid dark.$color_color-error

      svg
        color: dark.$color_color-error
    
  p.body_small
    color: dark.$color_text-secondary
