@use "../../../../style/_primitives" as primitives
@use "../../../../style/theme_light" as *
@use "../../../../style/theme_dark" as dark
@use "../../../../style/styles" as *


.cp_profile_component-public-list-content-tabs-container
  background-color: $color_fill-card
  border: 1px solid $color_border-colorcard
  border-radius: $content-border-radius
  display: flex
  flex-direction: column
  padding: $space_2x $space_1x
  position: relative
  
  .cp_component_tabs
    padding: 0 $space_2x

  .cp_profile_component-public-list-tabs-content
    flex: 1 1
    height: 100%
    overflow-y: auto
  
  h4
    border-bottom: 1px solid $color_border-section
    font-size: px-to-rem(22px)
    padding: 0 0 $space_1x $space_2x 

.dark
  .cp_profile_component-public-list-content-tabs-container
    border-color: dark.$color_border-colorcard
    background-color: dark.$color_fill-card
    h4
      border-color: dark.$color_border-section